/* istanbul ignore file */
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'

import { InspectionArea, InspectionItem, InspectionSheet, InspectionSheetDiagram } from 'interfaces/inspection'
import { Project } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { addDataSheet } from './DataSheet'
import { addDiagramSheet } from './DiagramSheet'
import { addGridValuesSheet } from './GridValuesSheet'
import { isDiagramShown } from './common'

/**
 * Creates a file and forces for user to save it
 *
 * @param workbook ExcelJS Workbook
 * @param filename Name of the file
 * @returns
 */
export const saveFile = (workbook: Workbook, filename: string): Promise<boolean> =>
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, filename)
    return true
  })

/**
 * Creates a new workbook.
 *
 * @returns ExcelJS Workbook
 */
export const newWorkbook = (): Workbook => {
  const workbook = new Workbook()
  workbook.creator = 'DataLabs Hatsuly'
  workbook.lastModifiedBy = 'DataLabs Hatsuly'
  workbook.created = new Date()

  return workbook
}

/**
 * Main function to be called to generate the XLSX file for the project.
 *
 * @param project Project
 * @param inspectionAreas Inspection Areas of the project.
 * @param inspectionItems Inspection Items of the project.
 * @param shapes Shapes of the project.
 */
export const generateXLSX = async (
  fileName: string,
  project: Project,
  inspectionAreas: InspectionArea[],
  inspectionSheets: InspectionSheet[],
  inspectionItems: InspectionItem[],
  shapes: Shapes,
  diagrams: Record<string, InspectionSheetDiagram>,
  forProject = false,
) => {
  const workbook = newWorkbook()

  addDataSheet(workbook, project, inspectionAreas, inspectionSheets, inspectionItems, shapes, forProject)

  if (isDiagramShown(project)) {
    addDiagramSheet(
      workbook,
      project,
      inspectionAreas,
      inspectionSheets[0],
      inspectionItems,
      shapes,
      diagrams,
      forProject,
    )
  }

  if (project.sheet_diagram_visibility?.grid_diagram) {
    addGridValuesSheet(workbook, project, inspectionAreas, inspectionSheets[0], inspectionItems, shapes)
  }

  await saveFile(workbook, `${fileName}_検査帳票.xlsx`)

  return true
}
