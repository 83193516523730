import { FC, useEffect, useRef } from 'react'

import { Alert, Box, Button, Center, Container, Flex, Spacer, useToast } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import PageFooter from 'pages/common/PageFooter'
import StorageUsageBar from 'project-dashboard-library/dist/components/storageUsageBar/molecules/StorageUsageBar'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store/app'

import { AddIcon, RightArrowIcon } from 'assets/icons'

import { UPLOAD_LIMIT_BYTE } from 'config/constants'
import { CONTAINER_MAX_WIDTH, TOAST_CONFIG } from 'config/styles'

import ProjectsTable from './ProjectsTable'
import ProjectFormModal from './components/ProjectFormModal'
import { useDashboard } from './hooks/dashboard'

const Dashboard: FC = () => {
  useDashboard()
  const toast = useToast()

  // Store
  const permissionSet = useSelector((state: RootState) => state.dashboard.permissionSet)
  const projects = useSelector((state: RootState) => state.dashboard.projects)
  const projectsLoaded = useSelector((state: RootState) => state.dashboard.projectsLoaded)
  const userType = useSelector((state: RootState) => state.user.userType)
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)
  const hasDeprecatedSubscription = useSelector((state: RootState) => state.user.hasDeprecatedSubscription)

  // Project creation modal
  const createProjectModalRef = useRef<{ openModal: () => void }>()
  const openCreateProjectModal = () => createProjectModalRef.current?.openModal()

  // Permission check
  const isAllowedToCreateProject = permissionSet.CREATE_PROJECT_GROUP.includes(userType)
  const isAllowedToActivateFeatures = permissionSet.ACTIVATE_FEATURES.includes(userType)

  // React router
  const navigate = useNavigate()

  const onProjectCreated = () => {
    toast({
      ...TOAST_CONFIG,
      title: '工事を作成しました',
      containerStyle: { top: '120px', position: 'relative' }, // push it down to a more visible position
    })
  }

  useEffect(() => {
    if (!projectsLoaded) {
      return
    }

    mixpanel.people.set('Projects Count', projects.length)

    // Do analytics here to guarantee only ran once
    mixpanel.register({
      Page: 'Dashboard',
    })

    mixpanel.track('Page View')
  }, [projects, projectsLoaded])

  return (
    <Flex flex={1} bg="gray.50" flexDirection="column">
      <Navbar />

      <Container
        maxW={CONTAINER_MAX_WIDTH}
        data-testid="project-list-container"
        id="inner-container"
        className="dashboard"
        flex={1}
      >
        <PageHeading>工事一覧</PageHeading>
        <StorageUsageBar size={userProfile?.stats?.total_size || 0} maxSize={UPLOAD_LIMIT_BYTE} />
        <Box>
          {isAllowedToCreateProject && (
            <Button
              variant="outlinePrimary"
              size="md"
              mt={10}
              mb={7}
              onClick={openCreateProjectModal}
              rightIcon={<AddIcon />}
            >
              工事を作成
            </Button>
          )}
          {featureFlags?.selfManagedSubscriptions.enabled &&
            isAllowedToActivateFeatures &&
            hasDeprecatedSubscription === false && (
              <Center>
                <Alert
                  status="info"
                  borderRadius={5}
                  mt={5}
                  mb={10}
                  w="75%"
                  fontSize="sm"
                  flexDirection="column"
                  colorScheme="gray"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  現在、お使いのアカウントでは新しい工事を作成できません。
                  <br />
                  より多くの機能にアクセスするには、アカウントをアップグレードしてください。
                  <Button
                    variant="outlinePrimary"
                    bgColor="white"
                    size="md"
                    mt={2}
                    onClick={() => navigate('/user#plan')}
                  >
                    有償機能利用はこちらから
                    <RightArrowIcon size="1.2rem" />
                  </Button>
                </Alert>
              </Center>
            )}
        </Box>
        {!isAllowedToCreateProject &&
          !(
            featureFlags?.selfManagedSubscriptions.enabled &&
            userProfile?.need_confirmation === false &&
            isAllowedToActivateFeatures
          ) && <Box height={10} />}
        <ProjectsTable />
        <Spacer />
        <PageFooter />
      </Container>
      <ProjectFormModal ref={createProjectModalRef} onProjectCreated={onProjectCreated} projects={projects} />
    </Flex>
  )
}

export default Dashboard
