/* istanbul ignore file */
import dayjs from 'dayjs'

import {
  CADFileType,
  CuboidDirection,
  CuboidKey,
  InspectionItemPreDefinedThresholdsKey,
  MeasureKey,
  ShapeKey,
} from 'interfaces/interfaces'

export const UPLOAD_LIMIT_BYTE = 1024 ** 3 * 100 //* 100GB
export const UPLOAD_LIMIT_GIGABYTE = 100 //* 100GB
export const UPLOAD_LIMIT_FILE_BYTE = 1024 ** 3 * 1 // 1GB
export const UPLOAD_LIMIT_FILE_GIGABYTE = 1 // 1GB
export const UPLOAD_LIMIT_FILE_LAZ_BYTE = 1024 ** 2 * 100 // 100MB
export const UPLOAD_LIMIT_FILE_LAZ_MEGABYTE = 100 // 100MB

export const COOKIE_EXPIRE = dayjs().add(30, 'day').toDate()
export const EDITOR_BACKGROUND_COOKIE_NAME = 'editor.backgroundColor'

export const MODAL_TYPES = {
  ALERT: 'ALERT',
  ERROR: 'ERROR',
  CONFIRMATION: 'CONFIRMATION',
  CONFIRMATION_CRITICAL: 'CONFIRMATION_CRITICAL',
  LOADING: 'LOADING',
}

export const USER_TYPES = {
  ADMIN: 'admin',
  PAYING_USER: 'paying_user',
  NON_PAYING_USER: 'non_paying_user',
  BETA_USER: 'beta_user',
  BASE_USER: 'base_user',
}

export enum SubscriptionType {
  None = 'none',
  Base = 'base',
  Features = 'features',
}

export const EDITOR_TOOLS = {
  MOVE: 'MOVE',
  FOCUS: 'FOCUS',
  COMMENT: 'COMMENT',
  CYLINDER: 'CYLINDER',
  CYLINDER_CUBOID: 'CYLINDER_CUBOID',
  DISTANCE: 'DISTANCE',
  CUSTOM_MEASUREMENT: 'CUSTOM_MEASUREMENT',
  POLYLINE: 'POLYLINE',
  CLIPPING: 'CLIPPING',
  PLANE: 'PLANE',
  TORUS: 'TORUS',
  TORUS_CUBOID: 'TORUS_CUBOID',
  VOLUME: 'VOLUME',
  VOLUME_RECTANGLE: 'VOLUME_RECTANGLE',
  VOLUME_POLYGON: 'VOLUME_POLYGON',
  DEPTH: 'DEPTH',
  PCD_TRIM_CUBOID: 'PCD_TRIM_CUBOID',
  GRID: 'GRID',
  CAMERA_PROFILE: 'CAMERA_PROFILE',
}

export const EDITOR_TOOLS_LABELS: Record<keyof typeof EDITOR_TOOLS, string> = {
  MOVE: '選択',
  FOCUS: 'カメラ中心の設定',
  COMMENT: 'コメント',
  CYLINDER: '鉄筋の検出',
  CYLINDER_CUBOID: '円柱',
  DISTANCE: '距離の計算',
  CUSTOM_MEASUREMENT: '距離の計算',
  POLYLINE: '延長の計算',
  CLIPPING: 'クリッピング',
  PLANE: '面積の計算',
  TORUS: 'トーラス',
  TORUS_CUBOID: 'トーラス',
  VOLUME: '体積測定',
  VOLUME_RECTANGLE: '長方形',
  VOLUME_POLYGON: '多角形',
  DEPTH: 'かぶり厚測定',
  PCD_TRIM_CUBOID: '注目領域の設定',
  GRID: 'グリッドを用いた深さ測定',
  CAMERA_PROFILE: 'カメラプロファイル',
}

export const BLUEPRINT_TOOLS = {
  COMMENT: 'COMMENT',
}
export const EDITOR_SUB_TOOLS = {
  CUBOID: {
    ROTATE: 'rotate',
    SCALE: 'scale',
    TRANSLATE: 'translate',
  },
  RESET: 'reset',
}
export const EDITOR_TOOL_CURSOR_CLASSES: Record<string, string> = {
  MOVE: 'tooling-move',
  FOCUS: 'tooling-crosshair',
  CYLINDER: 'tooling-crosshair',
  CYLINDER_CUBOID: 'tooling-crosshair',
  DISTANCE: 'tooling-crosshair',
  CLIPPING: 'tooling-move',
  PLANE: 'tooling-crosshair',
  VOLUME: 'tooling-crosshair',
  VOLUME_POLYGON: 'tooling-crosshair',
  POLYLINE: 'tooling-crosshair',
  TORUS: 'tooling-crosshair',
  TORUS_CUBOID: 'tooling-crosshair',
  PCD_TRIM_CUBOID: 'tooling-crosshair',
}
export const DEFAULT_EDITOR_TOOL = EDITOR_TOOLS.MOVE
export const DEFAULT_BLUEPRINT_TOOL = ''
export const DEFAULT_EDITOR_SUB_TOOL = {
  CUBOID: EDITOR_SUB_TOOLS.CUBOID.SCALE,
}

export const MAX_EDITOR_LAYERS = 999

export const getPrecision = (step: number): number => String(step).split('.')[1].length
export const EDITOR_COORD_INPUT_STEP = 0.000001
export const EDITOR_COORD_INPUT_PRECISION = getPrecision(EDITOR_COORD_INPUT_STEP)
export const EDITOR_DIAMETER_INPUT_STEP = 0.1
export const EDITOR_DIAMETER_INPUT_PRECISION = getPrecision(EDITOR_DIAMETER_INPUT_STEP)
export const EDITOR_DIAMETER_INPUT_MIN = 0.1
export const EDITOR_DIAMETER_INPUT_MAX = 100
export const EDITOR_DIAMETER_INPUT_UNIT = 'mm'
export const MILLIMETER_SCALE = 1000

export const EDITOR_BASE_LINE_THICKNESS = 0.01

export const EDITOR_SHAPES_SITUATIONS = {
  CYLINDERS_ON_AXIS: 'cylinders_on_axis',
  TORI_ON_AXIS: 'tori_on_axis',
  CYLINDERS_ON_ARC: 'cylinders_on_arc',
}
export const EDITOR_SHAPES_SITUATIONS_LABEL = {
  [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC]: '円周上に並んでいる主筋',
  [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS]: '1列に並んでいる縦筋または横筋',
  [EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS]: '1列に並んでいる環状形フープ筋',
}

// millimeter
export const DIAMETERS: Record<string, number> = {
  D10: 9.53,
  D13: 12.7,
  D16: 15.9,
  D19: 19.1,
  D22: 22.2,
  D25: 25.4,
  D29: 28.6,
  D32: 31.8,
  D35: 34.9,
  D38: 38.1,
  D41: 41.3,
  D51: 50.8,
}

export const EDITOR_DECIMAL_BASE = '0.0001'

export const EDITOR_SHAPE_KEYS: Record<string, ShapeKey> = {
  CYLINDERS: 'cylinders',
  POLYGONS: 'polygons',
}
export const EDITOR_MEASURE_KEYS: Record<string, MeasureKey> = {
  DISTANCE: 'distance',
  DETECTED_PLANE_TO_CYLINDER_DISTANCE: 'detectedPlaneToCylinderDistance',
  DETECTED_CYLINDER_TO_PLANE_DISTANCE: 'detectedCylinderToPlaneDistance',
}
export const EDITOR_CUBOID_KEY: CuboidKey = 'cuboid'
export const EDITOR_TOOL_KEYS = {
  [EDITOR_TOOLS.CYLINDER]: EDITOR_SHAPE_KEYS.CYLINDERS,
}
// cylinder requires 2 points. Torus, plane: 3 points
export const EDITOR_REQUIRED_ANCHORS: Record<ShapeKey | CuboidKey, number> = {
  cylinders: 2,
  cuboid: 4,
  polygons: 0, // needed only to satisfy lint
}

export const EDITOR_TOOLS_REQUIRED_SHAPES: Record<string, number> = {
  [EDITOR_TOOLS.VOLUME]: 2,
}

export const EDITOR_CUBOID_DIRECTIONS: Record<string, CuboidDirection> = {
  X: 'x',
  Y: 'y',
  Z: 'z',
}

export const EDITOR_SHAPE_TEMP_ID_PREFIX = 'temp_'
export const JOBS_WATCHING_INTERVAL = 5000 // 5 seconds
export const JOBS_WATCHING_TIMEOUT = 30 * 60 * 1000 // 30 minutes

export enum PanelType {
  Settings,
  Elements,
  Tool,
}

export const EDITOR_POINT_SIZE_INTERVAL = 0.001 // 1mm

export const CAD_FILE_TYPES: Record<string, CADFileType> = {
  ifc: 'ifc',
  dxf: 'dxf',
}

export const EDITOR_MOUSE_EVENT_DELAY = 50 // 0.05ms
export const EDITOR_MOUSE_DRAG_THRESHOLD = 10

export const EDITOR_DOUBLE_CLICK_INTERVAL = 500 // less than 0.5 second
export const EDITOR_DOUBLE_CLICK_POS_THRESHOLD = 15 // less than 15px
export const EDITOR_CUBOID_QUICK_SIZE = 3 // * 3 times of the current diameter
export const EDITOR_CUBOID_MIN_SIZE = 0.5 // * 0.5 times of the current diameter each side
export const EDITOR_DRAG_DETECTION_THRESHOLD = 10 // in pixels
export const EDITOR_ANCHOR_CLOSING_PROXIMITY = 0.02 // in 3D units

export const MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER = 50
export const MAXIMUM_NUMBER_OF_SHAPE_DETECTION_POLYGON = 20

export const TEMP_COMMENT_ID = 'temp-comment'
export const TEMP_REPLY_ID = 'temp-reply'
export const TEMP_IMAGE_ID = 'temp-image'

export const BLUEPRINT_MIN_SCALE = 25 // 25%
export const BLUEPRINT_MAX_SCALE = 500 // 500%
export const BLUEPRINT_SCALE_STEP = 10 // 10%

export const BLUEPRINT_MOUSE_EVENT_DELAY = 100 // 0.1ms

export const BLUEPRINT_COMMENT_AREA_MIN_SIZE = 32
export const BLUEPRINT_COMMENT_AREA_BORDER_SIZE = 3

export const BLUEPRINT_VIEWIER_CONTAINER_ID = '.react-pdf__Document'

export const EDITOR_CANVAS_CONTAINER_ID = 'editor-main-canvas-container'

export const PLANE_SIDE_LABELS = { upper: '仕上がり面', lower: '斫り面' }

/**
 * Button styles
 */
export const BUTTON_FONT_SIZE = { base: 'xl', xl: 'lg' }
export const BUTTON_ICON_SIZE = { base: '3xl', xl: '2xl' }
export const EDITOR_TOOL_BUTTON_SIZE = { base: 12, xl: 10 }

export const HELP_CENTER_URL = 'https://datalabs-jp.notion.site/Hatsuly-Help-Center-66c3998d14fa4255b7dce709249315f6'

/**
 * Editorview Navbar
 */
export const EDITOR_NAVBAR_HEIGHT = 10
export const EDITOR_NAVBAR_HEIGHT_TABLET = 14
export const EDITOR_NAVBAR_PADDING = 2

/**
 * Inspection sheet
 */
export const PRE_DEFINED_THRESHOLDS: { [name in InspectionItemPreDefinedThresholdsKey]: string } = {
  designed_value: '設計値',
  tolerance: '規格値',
  upper_tolerance: '上限値',
  lower_tolerance: '下限値',
}

export type CalculatedInspectionValueKey = 'difference_value' | 'is_passing_thresholds'
export const CALCULATED_INSPECTION_VALUE: { [name in CalculatedInspectionValueKey]: string } = {
  difference_value: '差',
  is_passing_thresholds: '判定結果',
}

export const USER_DEFINED_THRESHOLDS: { [name: string]: string } = {
  smaller_than: 'Smaller than (<)',
}

export const DefaultSettings = Object.freeze({
  default_construction_properties: {
    construction_project_name: '',
    construction_type: '',
    construction_type_detailed: '',
  },
  sheet_models_visibility: {
    volume: true,
    plane: true,
    length_with_distance_tool: true,
    polyline: true,
  },
  sheet_rows_visibility: {
    polyon_length_1: true,
    polyon_length_2: true,
    polygon_perimeter: true,
    polygon_area: true,
    grid: true,
    volume: true,
    polyline_length: true,
    min_grid_depth: false,
    max_grid_depth: false,
    length_with_distance_tool: true,
    upper_plane_to_cylinders_distance: true,
    lower_plane_to_cylinders_distance: true,
  },
  sheet_cols_visibility: {
    estimated_value: true,
    designed_value: true,
    tolerance: true,
  },
  sheet_diagram_visibility: {
    plane_diagram: true,
    grid_diagram: true,
    polyline_diagram: true,
  },
})

export const GRACE_HOURS_BEFORE_RENEWAL = 2
