import 'react-day-picker/dist/style.css'

import {
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  forwardRef,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import ja from 'date-fns/locale/ja'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import mixpanel from 'mixpanel-browser'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { DayPicker } from 'react-day-picker'
import { isMobile } from 'react-device-detect'
import { useMatches } from 'react-router-dom'

import { FolderIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { useUploadPcd } from 'hooks/uploadPcd'

import {
  MODAL_TYPES,
  UPLOAD_LIMIT_FILE_BYTE,
  UPLOAD_LIMIT_FILE_GIGABYTE,
  UPLOAD_LIMIT_FILE_LAZ_BYTE,
  UPLOAD_LIMIT_FILE_LAZ_MEGABYTE,
} from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import { InspectionArea, InspectionSheet, Project } from 'interfaces/interfaces'

import { ERROR_PROCESS, getErrorForToast } from 'services/ErrorHandler'
import {
  deleteInspectionArea,
  getInspectionAreas,
  getShapes,
  patchInspectionArea,
  postInspectionArea,
} from 'services/InspectionArea'
import { getInspectionSheets, updateInspectionSheet } from 'services/InspectionSheet'
import { getWorkflowTrackingId } from 'services/Tracking'
import { returnContentType, shapesExist } from 'services/Util'
import { checkSize } from 'services/Validation'

dayjs.extend(utc)

export interface InspectionAreaFormModalForwardRef {
  openModal: (
    /**
     * The project to which the inspection area will be added.
     */
    project: Project,

    /**
     * The inspection area to be edited. If this is provided, the modal will be in edit mode.
     */
    inspectionArea?: InspectionArea,

    /**
     * The list of inspection areas in the project. This is used to check for duplicate names.
     */
    inspectionAreas?: InspectionArea[] | null,

    /**
     * The inspection sheet for the selected project and inspection area.
     */
    inspectionSheet?: InspectionSheet | null,

    /**
     * Callback function to be called after the inspection area is saved.
     * Will provide the inspection area object that was saved.
     */
    onSaved?: (inspectionArea: InspectionArea, inspectionSheet?: InspectionSheet) => Promise<void> | void,
  ) => void

  closeModal: () => void
}

const InspectionAreaFormModalFunc: ForwardRefRenderFunction<
  InspectionAreaFormModalForwardRef,
  {
    allowClose?: boolean
    requireFile?: boolean
    useOverlay?: boolean
  }
> = ({ requireFile = false, allowClose = true, useOverlay = true }, ref) => {
  // Route matching to check current page
  const matches = useMatches()
  const isEditorPage = matches.some((row) => row.id.includes('editor'))
  const toast = useToast()

  // pcd uploader hook
  const { processSelectedFile, uploadPcd } = useUploadPcd()

  //* モーダル制御変数
  const { isOpen, onOpen, onClose } = useDisclosure()

  // refs
  const inspectionAreaInputRef = useRef<HTMLInputElement>(null)
  const uploadFileRef = useRef<HTMLInputElement>(null)

  //* contexts, hooks呼び出し
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  //* 入力値管理変数
  const [isLoading, setIsLoading] = useState(false)
  const [inspectionAreaName, setInspectionAreaName] = useState('')
  const [file_name, setFileName] = useState('')
  const [upload_file, setUploadFile] = useState<File | null>(null)
  const [isEditingCreatorDate, setIsEditingCreatorDate] = useState(false)
  const [isEditingObserverDate, setIsEditingObserverDate] = useState(false)
  const [shouldShowWarningMissingPCD, setShouldShowWarningMissingPCD] = useState(requireFile)

  // selected objects
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [selectedInspectionArea, setSelectedInspectionArea] = useState<InspectionArea | null>(null)
  const [inspectionSheet, setInspectionSheet] = useState<InspectionSheet | null>(null)

  // duplicate inspection area check
  const [inspectionAreasForDuplicationCheck, setInspectionAreasForDuplicationCheck] = useState<InspectionArea[]>([])

  //* 工事作成処理進捗管理変数
  const [isCreating, setIsSaving] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)

  // Callback functions
  const [onSaved, setOnSaved] = useState<(inspectionArea: InspectionArea, inspectionSheet?: InspectionSheet) => void>(
    () => null,
  )

  /**
   * Fetch inspection sheet for the selected project and inspection area.
   */
  const fetchInspectionSheet = async (prj: Project, ia: InspectionArea, fetchInspectionAreas: boolean) => {
    const token = await getAccessToken()
    if (!token) return

    const [is, ias] = await Promise.all([
      getInspectionSheets(token, prj.project_id, ia.inspection_area_id, showErrorModal),
      fetchInspectionAreas ? getInspectionAreas(token, prj.project_id, showErrorModal) : Promise.resolve(null),
    ])
    if (!is) return

    setInspectionSheet(is[0])
    setIsLoading(false)
    if (fetchInspectionAreas && ias) {
      setInspectionAreasForDuplicationCheck(ias)
    }
  }

  // close modal
  const onModalClose = useCallback(() => {
    // Reset first
    setInspectionAreaName('')
    setFileName('')
    setUploadFile(null)
    setSelectedProject(null)
    setSelectedInspectionArea(null)
    setInspectionSheet(null)
    setIsSaving(false)
    setUploadPercent(0)
    setIsEditingCreatorDate(false)
    setIsEditingObserverDate(false)
    if (uploadFileRef && uploadFileRef.current) uploadFileRef.current.value = ''

    // Close modal
    onClose()
  }, [onClose])

  /**
   * Open the modal to create or edit an inspection area.
   * @param project The project to which the inspection area will be added.
   * @param inspectionArea The inspection area to be edited. If this is provided, the modal will be in edit mode.
   * @param inspectionAreas The list of inspection areas in the project. This is used to check for duplicate names.
   * @param onSavedFn Callback function to be called after the inspection area is saved. Will provide the inspection area object that was saved.
   */
  const openModal = (
    project: Project,
    area?: InspectionArea,
    areas?: InspectionArea[] | null,
    sheet?: InspectionSheet | null,
    onSavedFn?: (area: InspectionArea, inspectionSheet: InspectionSheet) => Promise<void> | void,
  ) => {
    setSelectedProject(project)

    if (area) {
      setSelectedInspectionArea(area)
      setInspectionAreaName(area.inspection_area_name)

      if (sheet) {
        setInspectionSheet(sheet)
      } else {
        setIsLoading(true)
        void fetchInspectionSheet(project, area, areas === null)
      }
    }

    if (onSavedFn) {
      setOnSaved(() => onSavedFn)
    }

    if (areas) {
      setInspectionAreasForDuplicationCheck(areas)
    }

    onOpen()
    setTimeout(() => inspectionAreaInputRef.current?.focus())
  }

  /**
   * Close the modal.
   */
  const closeModal = useCallback(() => {
    onModalClose()
  }, [onModalClose])

  //* 親コンポーネントから呼び出される関数定義
  useImperativeHandle(ref, () => ({ openModal, closeModal }))

  //* ファイル選択エクスプローラー表示
  const handleFileClick = () => {
    uploadFileRef.current?.click()
  }

  //* ファイル選択時処理
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const file = processSelectedFile(e)
    if (!file) {
      setFileName('')
      if (uploadFileRef?.current) uploadFileRef.current.value = ''
      return
    }

    if (!inspectionAreaName || inspectionAreaName === upload_file?.name.split('.')[0]) {
      setInspectionAreaName(file.name.split('.')[0].replace(/\t/g, ' '))
    }
    setUploadFile(file.file)
    setFileName(file.name)
    setShouldShowWarningMissingPCD(false)
  }

  //* 工事作成処理進捗初期化
  const resetSavingStates = useCallback(
    (needReload?: boolean, project?: Project, area?: InspectionArea, sheet?: InspectionSheet) => {
      setUploadPercent(0)
      setIsSaving(false)

      if (needReload && project && area) {
        if (onSaved) onSaved(area, sheet)
      }
    },
    [onSaved],
  )

  //* If there is any error on uploading file, delete the inspection area that was created along with that file
  const revertCreatingInspectionArea = useCallback(
    (token: string, project: Project, area: InspectionArea) => {
      setSelectedInspectionArea(null)
      return deleteInspectionArea(token, project.project_id, area.inspection_area_id, showErrorModal)
    },
    [showErrorModal],
  )

  //* 工事作成
  const handleSubmit = useCallback(async () => {
    if (requireFile && !upload_file) return false

    //* 入力値チェック
    if ((!selectedInspectionArea && !inspectionAreaName) || !selectedProject) {
      showErrorModal('エリア名が未入力です。')
      return false
    }

    let uploadFileName = null
    let content_type = null

    //* Optional upload file
    if (file_name) {
      uploadFileName = dayjs().format('YYYYMMDDHHmmss_') + file_name.replace(/[^\p{L}\p{N}.-]/gu, '_') // replace all invalid characters with underscore
      content_type = returnContentType(file_name)

      if (!content_type) {
        showErrorModal('ファイル形式に誤りがあります。')
        return false
      }
      if (!upload_file) {
        showErrorModal('入力ファイルが不正です。')
        return false
      }
      const extension: string | undefined = file_name.split('.').pop()
      if (!checkSize(upload_file.size, extension === 'laz' ? UPLOAD_LIMIT_FILE_LAZ_BYTE : UPLOAD_LIMIT_FILE_BYTE)) {
        showErrorModal(
          `${UPLOAD_LIMIT_FILE_GIGABYTE}GB以下（LAZの場合${UPLOAD_LIMIT_FILE_LAZ_MEGABYTE}MB以下）のファイルのみアップロード可能です。`,
        )
        return false
      }
    }

    //* 工事作成処理開始
    setIsSaving(true)

    //* 署名付きURL取得
    const token = await getAccessToken()
    if (!token) {
      resetSavingStates()
      return false
    }

    let inspectionArea: InspectionArea | null
    if (selectedInspectionArea && inspectionSheet) {
      const [ia] = await Promise.all([
        patchInspectionArea(
          token,
          selectedProject.project_id,
          {
            inspection_area_id: selectedInspectionArea.inspection_area_id,
            inspection_area_name: inspectionAreaName,
          } as InspectionArea,
          showErrorModal,
        ),
        updateInspectionSheet(
          token,
          selectedProject.project_id,
          selectedInspectionArea.inspection_area_id,
          inspectionSheet,
          showErrorModal,
        ),
      ])
      inspectionArea = ia
    } else {
      inspectionArea = await postInspectionArea(
        token,
        selectedProject.project_id,
        inspectionAreaName,
        inspectionSheet,
        showErrorModal,
      )
    }

    if (!inspectionArea) {
      resetSavingStates()
      return false
    }

    const operation = selectedInspectionArea ? 'Update' : 'Create'

    //* Optional upload file
    if (file_name && uploadFileName && content_type && upload_file) {
      //* If file already exists, check if shapes have been created
      if (selectedInspectionArea?.down_sampled_file?.name) {
        const shapes = await getShapes(
          token,
          selectedProject.project_id,
          selectedInspectionArea.inspection_area_id,
          showErrorModal,
        )
        if (shapes && shapesExist(shapes)) {
          showModal({
            body: '既に検出された鉄筋または平面があります。まず削除してください。',
            confirmText: '了解',
            modalType: MODAL_TYPES.ALERT,
          })
          resetSavingStates()
          return false
        }
      }

      try {
        inspectionArea = await uploadPcd(
          selectedProject,
          inspectionArea,
          { name: uploadFileName, content_type, file: upload_file },
          setUploadPercent,
        )
      } catch (err) {
        if (!selectedInspectionArea) {
          await revertCreatingInspectionArea(token, selectedProject, inspectionArea)
        }
        return false
      } finally {
        resetSavingStates()
      }
    }

    // analytics
    mixpanel.track(`${operation} Inspection Area - Saved`, {
      'Project ID': selectedProject.project_id,
      'Inspection Area ID': inspectionArea.inspection_area_id,
      'Has File': !!inspectionArea.down_sampled_file?.name,
      'Workflow ID': getWorkflowTrackingId(`${operation.toLowerCase()}_inspection_area`),
      'Original File Name': inspectionArea?.origin_file?.name,
    })

    //* 入力値、進捗管理変数の初期化
    setFileName('')
    setInspectionAreaName('')
    setUploadFile(null)
    resetSavingStates(true, selectedProject, inspectionArea, inspectionSheet || undefined)
    if (uploadFileRef && uploadFileRef.current) uploadFileRef.current.value = ''

    toast({
      ...TOAST_CONFIG,
      title: operation === 'Update' ? 'エリアを変更しました' : 'エリアを作成しました',
      containerStyle: { top: '120px', position: 'relative' }, // push it down to a more visible position
    })

    //* モーダルを閉じる
    onModalClose()
    return true
  }, [
    file_name,
    getAccessToken,
    inspectionAreaName,
    resetSavingStates,
    revertCreatingInspectionArea,
    selectedInspectionArea,
    selectedProject,
    showErrorModal,
    showModal,
    upload_file,
    onModalClose,
    toast,
    inspectionSheet,
    requireFile,
    uploadPcd,
  ])

  const getHeader = () => {
    if (!selectedInspectionArea) return 'エリアを追加'
    return 'エリアを編集'
  }

  const confirmLabel = useMemo(() => {
    if (!selectedInspectionArea) return '作成'
    return '変更'
  }, [selectedInspectionArea])

  const isDuplicateName = !!inspectionAreasForDuplicationCheck?.filter(
    (ia) =>
      ia.inspection_area_name === inspectionAreaName &&
      selectedInspectionArea?.inspection_area_name !== inspectionAreaName,
  ).length

  return (
    <Modal
      closeOnOverlayClick={!isCreating && allowClose}
      closeOnEsc={!isCreating && allowClose}
      isOpen={isOpen}
      onClose={onModalClose}
      trapFocus={false}
    >
      {useOverlay && <ModalOverlay />}
      <ModalContent className={!useOverlay ? 'no-overlay' : ''}>
        <ModalHeader>{getHeader()}</ModalHeader>
        {allowClose && <ModalCloseButton hidden={isCreating} />}
        {isLoading ? (
          <ModalBody mb={5}>
            <HStack>
              <Spinner />
              <Text>読み込み中...</Text>
            </HStack>
          </ModalBody>
        ) : (
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor="project_name">工事</FormLabel>
              <Input id="project_name" type="text" value={selectedProject?.project_name} readOnly />
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={
                (shouldShowWarningMissingPCD && !selectedInspectionArea?.down_sampled_file?.name) ||
                selectedInspectionArea?.downsample_status?.status === InspectionAreaDownSampleStatus.FAILED
              }
              isRequired={requireFile}
            >
              <FormLabel htmlFor="file">las, laz, ply, pts, xyz, xyzrgb ファイル選択</FormLabel>
              <InputGroup size="md">
                <Input
                  data-testid="file-upload"
                  hidden
                  ref={uploadFileRef}
                  type="file"
                  accept={isMobile ? '' : '.las,.laz,.ply,.pts,.xyz,.xyzrgb'}
                  onChange={handleFileChange}
                />
                <Input isReadOnly value={file_name} />
                <InputRightElement w={14}>
                  <IconButton
                    aria-label="Upload file"
                    borderColor="inherit"
                    borderWidth="1px"
                    className="upload-file-button"
                    isDisabled={isCreating}
                    fontSize="lg"
                    icon={<FolderIcon />}
                    onClick={handleFileClick}
                    w="100%"
                  />
                </InputRightElement>
              </InputGroup>
              {shouldShowWarningMissingPCD && (
                <FormErrorMessage color="red">3D画面を利用するためには点群ファイルを追加してください</FormErrorMessage>
              )}
              {selectedInspectionArea?.downsample_status?.status_id && (
                <FormErrorMessage>
                  {
                    getErrorForToast(
                      ERROR_PROCESS.CREATE_INSPECTION_AREA,
                      selectedInspectionArea.downsample_status.status_id,
                    ).description
                  }
                </FormErrorMessage>
              )}
              {selectedInspectionArea?.down_sampled_file?.name && (
                <FormHelperText>
                  <b>現在のファイル:</b> <br />
                  {selectedInspectionArea?.down_sampled_file?.name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={isDuplicateName} isRequired>
              <FormLabel htmlFor="inspection_area_name">エリア名</FormLabel>
              <Input
                id="inspection_area_name"
                type="text"
                value={inspectionAreaName}
                onChange={(e) => setInspectionAreaName(e.target.value)}
                ref={inspectionAreaInputRef}
              />
              <FormErrorMessage>同じエリア名が既に存在します</FormErrorMessage>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="construction_type">工種名</FormLabel>
              <Input
                id="construction_type"
                type="text"
                defaultValue={
                  !selectedInspectionArea
                    ? selectedProject?.default_construction_properties?.construction_type
                    : inspectionSheet?.construction_properties.construction_type
                }
                onChange={(e) =>
                  setInspectionSheet(
                    (prev) =>
                      ({
                        ...prev,
                        construction_properties: {
                          ...prev?.construction_properties,
                          construction_type: e.target.value,
                        },
                      }) as InspectionSheet,
                  )
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="construction_type_detailed">種別</FormLabel>
              <Input
                id="construction_type_detailed"
                type="text"
                defaultValue={
                  !selectedInspectionArea
                    ? selectedProject?.default_construction_properties?.construction_type_detailed
                    : inspectionSheet?.construction_properties.construction_type_detailed
                }
                onChange={(e) =>
                  setInspectionSheet(
                    (prev) =>
                      ({
                        ...prev,
                        construction_properties: {
                          ...prev?.construction_properties,
                          construction_type_detailed: e.target.value,
                        },
                      }) as InspectionSheet,
                  )
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="creator_name">作成者</FormLabel>
              <Input
                id="creator_name"
                type="text"
                defaultValue={inspectionSheet?.creator_name}
                onChange={(e) =>
                  setInspectionSheet((prev) => ({ ...prev, creator_name: e.target.value }) as InspectionSheet)
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="construction_date">作成日</FormLabel>
              <Input
                id="construction_date"
                type="text"
                value={
                  inspectionSheet?.create_time_user_specified
                    ? dayjs(inspectionSheet?.create_time_user_specified).format('YYYY/MM/DD')
                    : undefined
                }
                readOnly
                onClick={() => setIsEditingCreatorDate(true)}
              />
              {isEditingCreatorDate && (
                <Box position="relative">
                  <Box
                    fontSize="sm"
                    position="absolute"
                    boxShadow="md"
                    borderColor="secondary.200"
                    backgroundColor="white"
                    borderWidth={1}
                    rounded={8}
                    left={0}
                    top={0}
                    zIndex={10}
                  >
                    <DayPicker
                      mode="single"
                      locale={ja}
                      selected={
                        inspectionSheet?.create_time_user_specified
                          ? dayjs(inspectionSheet?.create_time_user_specified).toDate()
                          : undefined
                      }
                      defaultMonth={
                        inspectionSheet?.create_time_user_specified
                          ? dayjs(inspectionSheet?.create_time_user_specified).toDate()
                          : undefined
                      }
                      onSelect={(date) => {
                        if (date) {
                          setInspectionSheet(
                            (prev) =>
                              ({
                                ...prev,
                                create_time_user_specified: dayjs.utc(date).toISOString(),
                              }) as InspectionSheet,
                          )
                        }
                        setIsEditingCreatorDate(false)
                      }}
                    />
                  </Box>
                </Box>
              )}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="observer_name">立会者</FormLabel>
              <Input
                id="observer_name"
                type="text"
                defaultValue={inspectionSheet?.observer_name}
                onChange={(e) =>
                  setInspectionSheet((prev) => ({ ...prev, observer_name: e.target.value }) as InspectionSheet)
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel htmlFor="construction_date">立会日</FormLabel>
              <Input
                id="construction_date"
                type="text"
                value={
                  inspectionSheet?.observe_time_user_specified
                    ? dayjs(inspectionSheet?.observe_time_user_specified).format('YYYY/MM/DD')
                    : undefined
                }
                readOnly
                onClick={() => setIsEditingObserverDate(true)}
              />
              {isEditingObserverDate && (
                <Box position="relative">
                  <Box
                    fontSize="sm"
                    position="absolute"
                    boxShadow="md"
                    borderColor="secondary.200"
                    backgroundColor="white"
                    borderWidth={1}
                    rounded={8}
                    left={0}
                    top={0}
                    zIndex={10}
                  >
                    <DayPicker
                      mode="single"
                      locale={ja}
                      selected={
                        inspectionSheet?.observe_time_user_specified
                          ? dayjs(inspectionSheet?.observe_time_user_specified).toDate()
                          : undefined
                      }
                      defaultMonth={
                        inspectionSheet?.observe_time_user_specified
                          ? dayjs(inspectionSheet?.observe_time_user_specified).toDate()
                          : undefined
                      }
                      onSelect={(date) => {
                        if (date) {
                          setInspectionSheet(
                            (prev) =>
                              ({
                                ...prev,
                                observe_time_user_specified: dayjs.utc(date).toISOString(),
                              }) as InspectionSheet,
                          )
                        }
                        setIsEditingObserverDate(false)
                      }}
                    />
                  </Box>
                </Box>
              )}
            </FormControl>
          </ModalBody>
        )}

        {!isLoading && (
          <ModalFooter mt={8}>
            {!isEditorPage && (
              <Button isDisabled={isCreating} me={3} py={2} minW="100px" onClick={onModalClose}>
                キャンセル
              </Button>
            )}

            <Button
              colorScheme="primary"
              isDisabled={(!selectedInspectionArea && !inspectionAreaName) || isCreating || isDuplicateName}
              isLoading={isCreating}
              loadingText={file_name ? `アップロード中...${uploadPercent}%` : `${confirmLabel}中...`}
              minW="100px"
              onClick={handleSubmit}
              py={2}
            >
              {confirmLabel}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

const InspectionAreaFormModal = forwardRef(InspectionAreaFormModalFunc)
export default InspectionAreaFormModal
