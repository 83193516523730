import './styles.css'

import { PropsWithChildren } from 'react'

import { Box } from '@chakra-ui/react'

export const Pill = ({ children, colorScheme }: PropsWithChildren<{ colorScheme?: string }>) => (
  <Box>
    <span className={['pill', colorScheme].join(' ')}>{children}</span>
  </Box>
)
