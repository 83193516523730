/* istanbul ignore file */
export interface ProjectStats {
  total_size: number
}

export type ProjectConstructionProperties =
  | 'construction_project_name'
  | 'construction_type'
  | 'construction_type_detailed'

export type ProjectSheetRowVisibility =
  | 'polyon_length_1'
  | 'polyon_length_2'
  | 'polygon_perimeter'
  | 'polygon_area'
  | 'volume'
  | 'grid'
  | 'min_grid_depth'
  | 'max_grid_depth'
  | 'length_with_distance_tool'
  | 'upper_plane_to_cylinders_distance'
  | 'lower_plane_to_cylinders_distance'
  | 'polyline_length'

export enum ProjectSheetToleranceType {
  WithinTolerance = 'within_tolerance',
  GteDesignedValue = 'gte_designed_value',
  LteDesignedValue = 'lte_designed_value',
  AsymmetricTolerance = 'asymmetric_tolerance',
}

export type ProjectSheetColVisibility = 'estimated_value' | 'designed_value' | 'tolerance'

export type ProjectSheetDiagramVisibility = 'plane_diagram' | 'grid_diagram' | 'polyline_diagram'

export type ProjectSheetModelVisibility = 'volume' | 'plane' | 'length_with_distance_tool' | 'polyline'

export interface ProjectSheetSettings {
  default_construction_properties?: Record<ProjectConstructionProperties, string>
  sheet_rows_visibility?: Record<ProjectSheetRowVisibility, boolean>
  sheet_cols_visibility?: Record<ProjectSheetColVisibility, boolean>
  sheet_models_visibility?: Record<ProjectSheetModelVisibility, boolean>
  sheet_diagram_visibility?: Record<ProjectSheetDiagramVisibility, boolean>
  sheet_rows_tolerance_type?: Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>
}

export interface Project extends ProjectSheetSettings {
  project_id: string
  project_name: string
  user_id: string
  stats?: ProjectStats
  shared_user?: Record<string, string>
  created_at: number
  updated_at: number
  deleted_at?: number | null
}

export interface GetProjectsAxiosResponse {
  projects: Project[]
}

export interface UserContextProps {
  getAccessToken: () => Promise<string>
}
