import { EDITOR_DIAMETER_INPUT_MAX, EDITOR_DIAMETER_INPUT_MIN, USER_TYPES } from 'config/constants'

import { Project } from 'interfaces/project'
import { UserInfo } from 'interfaces/user'
import { PermissionSets } from 'interfaces/validation'

/**
 * サイズが最大サイズ以下であることをチェックする関数
 * @param {number} size チェック対象サイズ
 * @param {number} maxSize 最大サイズ
 * @return {boolean} 適合: true, 不適合:false
 */
export const checkSize = (size: number, maxSize: number) => size <= maxSize

const regex = /^(1?[0-9]{0,2}|0)(\.[0-9]{1,2})?$/
/**
 * 直径フォーマットチェック、正数かつ小数点2桁かつ0.01~100mmであること
 * @param {string} diameter 直径
 * @return {boolean} 適合: true, 不適合:false
 */
export const checkDiameterFormat = (diameter: string) => {
  //* 必須チェック
  if (!diameter) return false
  //* 桁数チェック
  if (!regex.test(diameter)) return false
  //* 境界値チェック
  const floatDiameter = parseFloat(diameter)
  if (floatDiameter < EDITOR_DIAMETER_INPUT_MIN || floatDiameter > EDITOR_DIAMETER_INPUT_MAX) return false
  return true
}

/**
 * Decide the permission for some actions based on the user type
 * @param owner is the owner of the project/project group
 * @param invited is invited to the project/project group
 * @returns array of user types that allowed for some permissions
 */
export const decideActionPermission = (owner?: boolean, invited?: boolean): PermissionSets => {
  // pattern 1 (view/comment)
  const viewPattern = [
    ...(owner || invited ? [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER, USER_TYPES.BASE_USER] : []),
    ...(invited ? [USER_TYPES.NON_PAYING_USER] : []),
    USER_TYPES.ADMIN,
  ]

  // pattern 2 (edit)
  const modifyPattern = owner ? [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER, USER_TYPES.ADMIN] : []

  // pattern 3 (edit comment)
  const editPattern = [
    ...(owner || invited ? [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER, USER_TYPES.BASE_USER] : []),
    ...(invited ? [USER_TYPES.NON_PAYING_USER] : []),
    ...(owner ? [USER_TYPES.ADMIN] : []),
  ]

  return {
    PROJECT_DASHBOARD: {
      ACTIVATE_FEATURES: [USER_TYPES.NON_PAYING_USER, USER_TYPES.BASE_USER],
      CREATE_PROJECT_GROUP: [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER],
      INVITE_USER_TO_PROJECT: modifyPattern,
      INVITE_TO_CREATE_ACCOUNT: [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER],
      BROWSE: viewPattern,
      MODIFY: modifyPattern,
    },
    MAIN_CANVAS: {
      BROWSE: viewPattern,
      MEASURE: viewPattern,
      DETECT: modifyPattern,
      MODIFY: modifyPattern,
    },
    INSPECTION_SHEET: {
      BROWSE: viewPattern,
      DOWNLOAD: viewPattern,
      MODIFY: modifyPattern,
    },
    BLUEPRINT_VIEWER: {
      BROWSE: viewPattern,
      MODIFY: modifyPattern,
    },
    COMMENT_THREAD: {
      BROWSE: viewPattern,
      CREATE: viewPattern,
      EDIT: editPattern,
    },
  }
}

/**
 * Check if the user is the owner of the project
 *
 * @param project Project to check
 * @param userProfile User profile
 */
export const isOwner = (project: Project, userProfile: UserInfo): boolean => project.user_id === userProfile.user_id

/**
 * Check if the user is invited to the project
 *
 * @param project Project to check
 * @param userProfile User profile
 */
export const isInvited = (project: Project, userProfile: UserInfo): boolean =>
  project.shared_user ? !!project.shared_user[userProfile.user_id] : false
