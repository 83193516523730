import axios from 'axios'

import { API_GATEWAY_URL } from 'config/environments'

import { Prices } from 'interfaces/subscription'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

export const PRICES_API_URL = `${API_GATEWAY_URL}/prices`
export const ACTIVATE_BASE_FEATURE_API_URL = `${API_GATEWAY_URL}/base-feature-activation-records`
export const ACTIVATE_ADDITIONAL_FEATURE_API_URL = `${API_GATEWAY_URL}/additional-feature-activation-records`
export const PATCH_AUTO_BASE_FEATURE_RENEWAL_API_URL = `${API_GATEWAY_URL}/automatic-base-feature-renewal`

/**
 * Get prices
 * @param {string} access_token
 * @param {function} showErrorModal
 * @return {Prices}
 * */
export const getPrices = async (
  access_token: string,
  showErrorModal: (message: string) => void,
): Promise<Prices | null> => {
  const prices = await axios
    .get<Prices>(`${PRICES_API_URL}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PRICES, showErrorModal)
      return null
    })

  return prices
}

/**
 * Activate base feature
 * @param {string} access_token
 * @param {function} showErrorModal
 * @return {boolean}
 * */
export const activateBaseFeature = async (
  access_token: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .post<boolean>(
      `${ACTIVATE_BASE_FEATURE_API_URL}`,
      {},
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ACTIVATE_BASE_FEATURE, showErrorModal)
      return false
    })

  return result
}

/**
 * Activate additional feature
 * @param {string} access_token
 * @param {function} showErrorModal
 * @return {boolean}
 * */
export const activateAdditionalFeature = async (
  access_token: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .post<boolean>(
      `${ACTIVATE_ADDITIONAL_FEATURE_API_URL}`,
      {},
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ACTIVATE_ADDITIONAL_FEATURE, showErrorModal)
      return false
    })

  return result
}

/**
 * Patch auto base feature renewal
 * @param {string} access_token
 * @param {function} showErrorModal
 * @return {boolean}
 * */
export const patchAutoBaseFeatureRenewal = async (
  access_token: string,
  auto_base_feature_renewal: 'Y' | 'N',
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .patch<boolean>(
      `${PATCH_AUTO_BASE_FEATURE_RENEWAL_API_URL}`,
      { auto_base_feature_renewal },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ACTIVATE_BASE_FEATURE, showErrorModal)
      return false
    })

  return result
}
