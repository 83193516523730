import { useCallback, useContext, useState } from 'react'

import {
  Button,
  Checkbox,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useAppDispatch } from 'store/app'
import { setUserProfile } from 'store/user'

import { PaymentIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { useUserContext } from 'contexts/Users'

import { patchAutoBaseFeatureRenewal } from 'services/Subscriptions'
import { getUser } from 'services/Users'
import { withPromiseContained } from 'services/Util'

interface AgreeConfirmationModalProps {
  disclosure: ReturnType<typeof useDisclosure>
  active: boolean
  name: string
}

const BaseFeatureAutoRenewalConfirmationModal = ({ disclosure, active, name }: AgreeConfirmationModalProps) => {
  // Context
  const { getAccessToken } = useUserContext()
  const { showErrorModal } = useContext(GlobalModalContext)

  // States
  const [isLoading, setIsLoading] = useState(false)
  const [agreeUpdate, setAgreeUpdate] = useState(false)

  // Store
  const dispatch = useAppDispatch()

  // Disclosure
  const { isOpen, onClose } = disclosure

  /**
   * Handle cancellation.
   */
  const handleClose = useCallback(() => {
    if (isLoading) return
    setAgreeUpdate(false)
    onClose()
  }, [onClose, isLoading])

  /**
   * Handle confirmation.
   */
  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    await withPromiseContained(async () => {
      const token = await getAccessToken()
      if (!token) return
      if (active) {
        // Disable auto renewal
        await patchAutoBaseFeatureRenewal(token, 'N', showErrorModal)
        // Update the user profile
        const userInfo = await getUser(token, showErrorModal)
        if (!userInfo) return
        dispatch(setUserProfile(userInfo))
      } else {
        // Enable auto renewal
        await patchAutoBaseFeatureRenewal(token, 'Y', showErrorModal)
        // Update the user profile
        const userInfo = await getUser(token, showErrorModal)
        if (!userInfo) return
        dispatch(setUserProfile(userInfo))
      }
    }).finally(() => {
      setIsLoading(false)
      handleClose()
    })
  }, [getAccessToken, showErrorModal, dispatch, active, handleClose])

  return (
    <Modal closeOnEsc closeOnOverlayClick isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <HStack gap={4} alignItems="start" w="100%">
          <Circle bg="orange.100" color="orange.600" size="2rem" position="relative" top="1rem" left="1.5rem">
            <PaymentIcon size="1.2rem" />
          </Circle>
          <VStack alignItems="start" w="100%" gap={0}>
            <ModalHeader>{name}の自動更新</ModalHeader>
            <ModalBody whiteSpace="pre-wrap">
              <VStack alignItems="start">
                <Spacer m={2} />

                <Checkbox isChecked={agreeUpdate} onChange={() => setAgreeUpdate(!agreeUpdate)}>
                  (必須){' '}
                  {active
                    ? '自動更新しないまま有効期限を過ぎるとデータは削除されます。'
                    : `${name}は自動的に更新されます。後で自動更新を解除することもできます。`}
                </Checkbox>
              </VStack>
            </ModalBody>
          </VStack>
        </HStack>
        <ModalFooter>
          <Button isDisabled={isLoading} mr={3} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            colorScheme={active ? 'red' : 'primary'}
            onClick={handleConfirm}
            isDisabled={!agreeUpdate || isLoading}
            isLoading={isLoading}
          >
            {active ? '自動更新解除' : '自動更新する'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BaseFeatureAutoRenewalConfirmationModal
