import { useFrame, useThree } from '@react-three/fiber'
import { PointCloudOctree, Potree } from 'three-loader'

import { useEditorContext } from 'contexts/Editor'

const PotreePCD = ({ pointCloud, potree }: { pointCloud: PointCloudOctree | undefined; potree: Potree }) => {
  const { camera } = useThree()
  const { isPointCloudInvisible } = useEditorContext()

  useFrame((state) => {
    if (!pointCloud) return
    potree.updatePointClouds([pointCloud], camera, state.gl)
  })

  if (!pointCloud || isPointCloudInvisible) return null

  return (
    <group>
      <primitive object={pointCloud} />
    </group>
  )
}

export default PotreePCD
