import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { uniqueId } from 'lodash'
import { CursorState, setCursor, setIsDragging } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { DistanceLabelProps } from 'interfaces/canvas'
import { CanvasConfig, CanvasEventsExtra } from 'interfaces/editor'
import { InspectionItem } from 'interfaces/inspection'

import { addInspectionItem, updateInspectionItem } from 'services/InspectionSheet'
import { pointArrayToVector3 } from 'services/Points'
import { getDistanceLabel, zeroPad } from 'services/Util'

import {
  CustomMeasurement,
  setCustomMeasurements,
  setTempMeasurements,
  updateCustomMeasurement,
  updateTempMeasurement,
} from '../store'
import {
  DISTANCE_LABEL_STYLES,
  DISTANCE_LABEL_STYLES_EDITABLE,
  DISTANCE_LABEL_STYLES_EDITABLE_DRAGGING,
  DISTANCE_LABEL_STYLES_WORKING,
} from '../styles'
import { WORKING_ID_PREFIX, ZERO_PLACES, isWorking } from '../utils'

let lastTouchPoint: PointArray | undefined
let mouseDown = false
const saved = new Set<string>()

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { selectedTool, isPreviousTool, inspectionSheet, inspectionItems, setInspectionItems } =
    useContext(EditorContext)
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const customMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.measurements)
  const tempMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.tempMeasurements)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const hiddenElementIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const hoveredElementId = useSelector((state: RootState) => state.editor.hoveredElementId)

  // State
  const [firstPointPlaced, setFirstPointPlaced] = useState(false)
  const [draggingLabelAnchor, setDraggingLabelAnchor] = useState<{ id: string; anchorIndex: number } | null>(null)
  const [axiosUpdateController, setAxiosUpdateController] = useState<Record<string, AbortController>>({})
  const [workingLabel, setWorkingLabel] = useState<CustomMeasurement | null>(null)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CUSTOM_MEASUREMENT, [selectedTool])
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)

  // Functions/Data to be used depending on user permssion
  const measurements = isAllowedToDetect ? customMeasurements : tempMeasurements
  const setMeasurementFn = isAllowedToDetect ? setCustomMeasurements : setTempMeasurements
  const updateMeasurementFn = isAllowedToDetect ? updateCustomMeasurement : updateTempMeasurement

  /**
   * Save temporary measurement
   * - This is for users without permission to save.
   * - This will be saved in the store only.
   *
   * @param measurement Measurement to save
   */
  const saveTempMeasurement = useCallback(
    (measurement: CustomMeasurement) => {
      dispatch(
        updateTempMeasurement({
          ...measurement,
          part_name: `距離`,
          item_type: 'length_with_distance_tool',
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        }),
      )
    },
    [dispatch],
  )

  /**
   * Save measurement to DB
   *
   * @param measurement Measurement to save
   */
  const saveCustomMeasurement = useCallback(
    async (measurement: CustomMeasurement) => {
      if (!project || !inspectionArea || !inspectionSheet) return

      const token = await getAccessToken()
      if (!token) return

      const item = await addInspectionItem(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        {
          part_name: '', // leave empty for generic name on display until user changes it
          item_type: 'length_with_distance_tool',
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        },
        showErrorModal,
      )

      // update the label with the inspection item
      if (item) {
        saved.add(measurement.inspection_item_id!)

        // gotta add the inspection item to the inspection items list as well
        setInspectionItems((items) => items.concat(item))
      }
    },
    [getAccessToken, inspectionArea, inspectionSheet, project, setInspectionItems, showErrorModal],
  )

  /**
   * Update measurement to DB
   *
   * @param measurementId Measurement ID to update
   */
  const updateMeasurement = useCallback(
    async (measurementId: string) => {
      if (!project || !inspectionArea || !inspectionSheet) return

      const measurement = measurements.find((measure) => measure.inspection_item_id === measurementId)
      if (!measurement || isWorking(measurement?.inspection_item_id)) return

      // get original, unmodified inspection item
      const inspectionItem = inspectionItems.find((item) => item.inspection_item_id === measurement?.inspection_item_id)
      if (!inspectionItem) return

      const token = await getAccessToken()
      if (!token) return

      // abort previous request if it's still running and create a new controller
      if (axiosUpdateController[measurementId]) axiosUpdateController[measurementId].abort()
      const abortController = new AbortController()
      setAxiosUpdateController((controllers) => ({ ...controllers, [measurementId]: abortController }))

      const result = await updateInspectionItem(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        {
          ...inspectionItem, // use the original inspection item, otherwise we may save some FE only data (eg: part_name that has not been defined yet)
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        } as InspectionItem,
        showErrorModal,
        abortController.signal,
      )

      // gotta udpate the inspection item in the inspection items list as well
      if (result) {
        setInspectionItems((items) =>
          items.map((item) => (item.inspection_item_id === result.inspection_item_id ? result : item)),
        )
      }
    },
    [
      inspectionArea,
      inspectionSheet,
      inspectionItems,
      measurements,
      project,
      axiosUpdateController,
      setInspectionItems,
      showErrorModal,
      getAccessToken,
    ],
  )

  const onDown = useCallback(
    (id: string, anchorIndex: number) => {
      dispatch(setIsDragging(true)) // toggling this back to false is managed by MainCanvas::onMouseUp
      setDraggingLabelAnchor({ id, anchorIndex })
      dispatch(setCursor(CursorState.GRABBING))
      mouseDown = true
    },
    [dispatch],
  )

  /**
   * When user changes to a different tool, remove working anchor
   */
  useEffect(
    () => {
      if (isToolSelected) {
        dispatch(setCursor(CursorState.CROSSHAIR))
      } else if (isPreviousTool(EDITOR_TOOLS.CUSTOM_MEASUREMENT) && selectedTool !== EDITOR_TOOLS.FOCUS) {
        dispatch(setCursor(CursorState.DEFAULT))
        setWorkingLabel(null)
        setFirstPointPlaced(false)
        setDraggingLabelAnchor(null)
        saved.clear()
      }
    },
    // We only care for change of tool, ignore the rest.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isToolSelected, isPreviousTool],
  )

  /**
   * Fetch measurement items from inspection items
   */
  useEffect(
    () => {
      const data = inspectionItems
        .filter((item) => item.item_type === 'length_with_distance_tool')
        .map<CustomMeasurement>((item, index) => {
          const id = item.inspection_item_id!
          return {
            ...item,
            part_name: item.part_name || `距離${zeroPad(index + 1, ZERO_PLACES)}`,
            distanceLabel: {
              ...DISTANCE_LABEL_STYLES,
              id,
              points: item.length_with_distance_tool!.positions_for_distance!,
              label: getDistanceLabel(item.length_with_distance_tool!.estimated_value!),
            },
          }
        })
        // append what is in customMeasurements but not in inspectionItems
        .concat(
          customMeasurements.filter(
            (measurement) => isWorking(measurement.inspection_item_id) && !saved.has(measurement.inspection_item_id!),
          ),
        )

      dispatch(setCustomMeasurements(data))
    },
    // Ignore adjustments to customMeasurements
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspectionItems, dispatch],
  )

  /**
   * Get distance label props, mainly for styling.
   */
  const getDistanceLabelProps = useCallback(
    (label: CustomMeasurement) => {
      let style = DISTANCE_LABEL_STYLES

      if (label.inspection_item_id === draggingLabelAnchor?.id) {
        style = DISTANCE_LABEL_STYLES_EDITABLE_DRAGGING
      } else if (isWorking(label?.inspection_item_id)) {
        style = DISTANCE_LABEL_STYLES_WORKING
      } else if (isToolSelected) {
        style = DISTANCE_LABEL_STYLES_EDITABLE
      }

      return {
        ...style,
        anchorProps: {
          ...style.anchorProps,
          scale: selectedElementIds.includes(label.inspection_item_id!) ? 1.5 : style.anchorProps?.scale || undefined,
          tooltipText: isToolSelected && !isWorking(label.inspection_item_id) ? '点を移動' : undefined,
        },
      }
    },
    [isToolSelected, selectedElementIds, draggingLabelAnchor],
  )

  /**
   * Generate a new distance label.
   */
  const generateWorkingLabel = useCallback(
    (points: PointArray[], styles: Partial<DistanceLabelProps>): CustomMeasurement => {
      const workingId = workingLabel?.inspection_item_id || uniqueId(WORKING_ID_PREFIX)
      return {
        inspection_item_id: workingId,
        distanceLabel: {
          id: workingId,
          ...styles,
          points,
          label:
            points.length === 2
              ? getDistanceLabel(pointArrayToVector3(points[0]).distanceTo(pointArrayToVector3(points[1])))
              : undefined,
        },
      }
    },
    [workingLabel],
  )

  /**
   * Handle dragging operation on moving operation.
   */
  const handleDraggingOnMove = useCallback(
    (point: PointArray) => {
      if (!draggingLabelAnchor) return

      const label = measurements.find((measure) => measure.inspection_item_id === draggingLabelAnchor.id)
      if (label) {
        const updatedPoints = [...label.distanceLabel.points]
        updatedPoints[draggingLabelAnchor.anchorIndex] = point

        dispatch(
          updateMeasurementFn({
            ...label,
            distanceLabel: {
              ...label.distanceLabel,
              label: getDistanceLabel(
                pointArrayToVector3(updatedPoints[0]).distanceTo(pointArrayToVector3(updatedPoints[1])),
              ),
              points: updatedPoints,
            },
          }),
        )
      }
    },
    [measurements, draggingLabelAnchor, dispatch, updateMeasurementFn],
  )

  /**
   * Handle dragging operation on end of interaction.
   */
  const handleDraggingOnEnd = useCallback(() => {
    if (!draggingLabelAnchor) return

    void updateMeasurement(draggingLabelAnchor.id)
    setDraggingLabelAnchor(null)
    dispatch(setCursor(CursorState.CROSSHAIR))
  }, [draggingLabelAnchor, dispatch, updateMeasurement])

  /**
   * Handle mouse up and touch end events.
   */
  const onMouseUp = useCallback(
    (point: PointArray, { isDragged }: CanvasEventsExtra) => {
      if (draggingLabelAnchor) {
        handleDraggingOnEnd()
      } else if (!isDragged) {
        let newLabel: CustomMeasurement | null = null
        const updatedLabels = [...measurements]

        if (workingLabel && firstPointPlaced && !lastTouchPoint) {
          newLabel = {
            ...workingLabel,
            distanceLabel: {
              ...workingLabel.distanceLabel,
              ...DISTANCE_LABEL_STYLES,
            },
          }
        } else if (lastTouchPoint) {
          if (workingLabel && firstPointPlaced) {
            newLabel = generateWorkingLabel([workingLabel.distanceLabel.points[0], point], DISTANCE_LABEL_STYLES)
          } else {
            setWorkingLabel(generateWorkingLabel([point], DISTANCE_LABEL_STYLES_WORKING))
          }
        }

        if (newLabel) {
          updatedLabels.push(newLabel)
          dispatch(setMeasurementFn(updatedLabels))
          if (isAllowedToDetect) {
            void saveCustomMeasurement(newLabel)
          } else {
            saveTempMeasurement(newLabel)
          }
          setWorkingLabel(null)
        }

        setFirstPointPlaced(!firstPointPlaced)
      }

      setTimeout(() => {
        lastTouchPoint = undefined
      }, 100)
    },
    [
      dispatch,
      draggingLabelAnchor,
      firstPointPlaced,
      generateWorkingLabel,
      handleDraggingOnEnd,
      isAllowedToDetect,
      measurements,
      saveCustomMeasurement,
      saveTempMeasurement,
      setMeasurementFn,
      workingLabel,
    ],
  )

  return {
    objects: {
      oldDistanceLabels: useMemo(
        () =>
          [
            // ## Existing measurements
            ...customMeasurements
              .concat(tempMeasurements)
              // When dragging, hide working label if there's only 1 anchor
              .filter(
                (label) =>
                  !hiddenElementIds.includes(label.inspection_item_id!) ||
                  hoveredElementId === label.inspection_item_id,
              )
              .map<DistanceLabelProps>((label, index) => ({
                ...label.distanceLabel,
                ...getDistanceLabelProps(label),
                label:
                  selectedElementIds.includes(label.inspection_item_id!) ||
                  hoveredElementId === label.inspection_item_id
                    ? label.distanceLabel.label
                    : undefined,
                id: `custom-measurement-${label?.inspection_item_id || index}`,
                labelOutlineColor:
                  selectedElementIds.includes(label.inspection_item_id!) ||
                  hoveredElementId === label.inspection_item_id
                    ? '#fff'
                    : undefined,
                onDown:
                  isToolSelected &&
                  ((label?.inspection_item_id && isAllowedToDetect) ||
                    (!label?.inspection_item_id && !isAllowedToDetect))
                    ? (anchorIndex: number) => {
                        onDown(label.inspection_item_id!, anchorIndex)
                      }
                    : undefined,
                onEnter: () => {
                  if (
                    isToolSelected &&
                    !draggingLabelAnchor &&
                    ((label?.inspection_item_id && isAllowedToDetect) ||
                      (!label?.inspection_item_id && !isAllowedToDetect))
                  )
                    dispatch(setCursor(CursorState.GRAB))
                },
                onLeave: () => {
                  if (!mouseDown) dispatch(setCursor(CursorState.CROSSHAIR))
                },
              })),

            // ## Working measurement
            workingLabel &&
            !hiddenElementIds.includes(workingLabel.inspection_item_id!) &&
            (!draggingLabelAnchor || (draggingLabelAnchor && workingLabel.distanceLabel.points.length > 1))
              ? workingLabel.distanceLabel
              : null,
          ].filter(Boolean) as DistanceLabelProps[],
        [
          isAllowedToDetect,
          customMeasurements,
          tempMeasurements,
          draggingLabelAnchor,
          isToolSelected,
          hiddenElementIds,
          selectedElementIds,
          workingLabel,
          hoveredElementId,
          getDistanceLabelProps,
          onDown,
          dispatch,
        ],
      ),
    },
    events: {
      onTouchStart: (ev, point) => {
        if (!isToolSelected) return
        lastTouchPoint = point
      },
      onTouchEnd: (ev, extra) => {
        if (!isToolSelected || !lastTouchPoint) return

        onMouseUp(lastTouchPoint, extra)
      },
      /**
       * User clicks on the canvas to confirm the anchor.
       * - If the latest distance label has only 1 anchor, just flag it. No need to do anything else
       *   as onMove will handle the rest.
       * - If the latest distance label already has 2 anchor, create a new working distance label with 1 anchor.
       *
       * @param e Mouse event
       * @param point Clicked point in 3D Space
       */
      onMouseUp(e, point, extra) {
        if (!isToolSelected || !point || lastTouchPoint) return
        mouseDown = false

        onMouseUp(point, extra)
      },
      /**
       * On movement of the mouse, add/update the working distance label.
       * - If there's no working distance label, an anchor will be added and always follow mouse movement.
       * - If there's a working distance label, the second anchor will be added to it and follows mouse movement,
       *   plus a complete distance label will be displayed.
       *
       * @param point Point in 3D Space
       */
      onMove(point, mouseEvent, touchEvent) {
        if (!point || !isToolSelected || touchEvent || lastTouchPoint) return // ignore touch events

        if (draggingLabelAnchor) {
          handleDraggingOnMove(point)
        } else {
          const workingId = uniqueId(WORKING_ID_PREFIX)
          let label: CustomMeasurement

          // if first point has been placed, add/edit second point
          if (workingLabel && firstPointPlaced) {
            label = {
              ...workingLabel,
              distanceLabel: {
                ...DISTANCE_LABEL_STYLES_WORKING,
                ...workingLabel.distanceLabel,
                points: [workingLabel.distanceLabel.points[0], point],
                label: getDistanceLabel(
                  pointArrayToVector3(workingLabel.distanceLabel.points[0]).distanceTo(pointArrayToVector3(point)),
                ),
              },
            }
          } else {
            // add/edit first point
            label = {
              inspection_item_id: workingLabel?.inspection_item_id || workingId,
              distanceLabel: {
                id: workingLabel?.inspection_item_id || workingId,
                ...DISTANCE_LABEL_STYLES_WORKING,
                points: [point],
              },
            }
          }

          setWorkingLabel(label)
        }
      },
      /**
       * Touch-version of onMove
       * @param ev
       */
      onTouchMoveCapture: (ev, point) => {
        if (!point || !isToolSelected) return

        lastTouchPoint = point

        if (draggingLabelAnchor) {
          handleDraggingOnMove(point)
        }
      },
    },
  }
}

export default useMainCanvas
