import { useMemo } from 'react'

import { cloneDeep } from 'lodash'
import { GRID_POINT_MINIMUM_INTERVAL } from 'pages/projects/editor/tools/Grid/constants'
import { generateGridPoints, mergeIntervalsWithSavedConfig } from 'pages/projects/editor/tools/Grid/utils'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Matrix4, Vector2 } from 'three'

import { PointArray } from 'interfaces/attribute'
import { PolygonDiagramVertice } from 'interfaces/inspection'
import { GridOrderDirection, GridType, IntervalsConfig } from 'interfaces/inspectionItemGrid'
import { ShapeKeyType } from 'interfaces/shape'

import { alignMinimumAreaBoundary, minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import {
  getDiagramScaledVertices,
  getGridForScaledDiagram,
  getOrientation,
  getProjectedVertices,
  orientGridBasedOnProjectedDiagram,
} from 'services/PlaneDiagram'
import { meterToMillimeter } from 'services/Util'

import { DEFAULT_TOLERANCE_TYPE, GRID_UNIT, GRID_UNIT_PRECISION } from '../constants'
import { GridPointLabel, UseDiagramProps } from '../interfaces'
import { getDifferenceValueAndIsPassingThresholds } from '../utils'

const useDiagram = ({
  width,
  height,
  upperPolygon,
  lowerPolygon,
  cameraProfile,
  settings,
  inspectionItem,
}: UseDiagramProps) => {
  // Data for use later.
  const { grid } = inspectionItem

  // Store
  const editedInspectionItems = useSelector((state: RootState) => state.inspectionSheet.editedInspectionItems)

  /*
   * Get threshold with priority for edited inspection items
   * for real-time updates.
   */
  const threshold = useMemo(
    () => ({
      ...inspectionItem.pre_defined_thresholds,
      ...editedInspectionItems.find((item) => item.inspection_item_id === inspectionItem.inspection_item_id)
        ?.pre_defined_thresholds,
    }),
    [inspectionItem, editedInspectionItems],
  )

  // Calculate the minimum rectangle boundary of the polygon.
  const mainPlane = useMemo(() => upperPolygon || lowerPolygon, [upperPolygon, lowerPolygon])
  const minimumRectangleBoundary = useMemo(() => {
    const boundary = minAreaRectangleOfPolygon(mainPlane.vertices, mainPlane.positions)
    return boundary ? alignMinimumAreaBoundary(boundary, mainPlane, lowerPolygon) : null
  }, [mainPlane, lowerPolygon])

  // Data is in meters, but we want it in millimeters.
  const length1 = minimumRectangleBoundary ? meterToMillimeter(minimumRectangleBoundary.extent[0]) : 0
  const length2 = minimumRectangleBoundary ? meterToMillimeter(minimumRectangleBoundary.extent[1]) : 0

  // The plane is drawn with the longest side as the length and the shortest side as the width.
  const planeWidth = Math.max(length1, length2)
  const planeHeight = Math.min(length1, length2)

  // Scale the plane to fit the canvas.
  const scale = Math.min(width / planeWidth, height / planeHeight) * 0.7 // leave some margin for labels
  const scaledPlaneWidth = planeWidth * scale
  const scaledPlaneHeight = planeHeight * scale

  // Derive certain properties from the polygon to be used later.
  const matrix4 = useMemo(() => new Matrix4().set(...mainPlane.transformation), [mainPlane.transformation])
  const matrixInverse = useMemo(() => matrix4.clone().invert(), [matrix4])

  /**
   * Projected diagram based on camera profile.
   * Might look unnecessary to have a separate state for this, but it's handy for debugging.
   */
  const projectedPolygon: ReturnType<typeof getProjectedVertices> | null = useMemo(() => {
    if (!minimumRectangleBoundary || !cameraProfile) return null

    // Diagram based on projected diagram through saved camera profile
    const result = getProjectedVertices(
      cameraProfile,
      minimumRectangleBoundary,
      scaledPlaneWidth,
      scaledPlaneHeight,
      mainPlane,
      scale,
    )

    return result
  }, [minimumRectangleBoundary, cameraProfile, mainPlane, scaledPlaneHeight, scaledPlaneWidth, scale])

  /**
   * Generate polygon diagram.
   * If there's a camera profile, it will orient the diagram based on the camera profile.
   * If there's no camera profile, it will orient the diagram based on the minimum rectangle boundary axis alignment.
   */
  const diagramPre: {
    vertices: PolygonDiagramVertice[]
    orientation?: ReturnType<typeof getOrientation>
    boundary?: Vector2[]
    grid: GridPointLabel[]
  } | null = useMemo(() => {
    if (!minimumRectangleBoundary) return null

    // ## Camera profile oriented diagram
    if (projectedPolygon) {
      const vertMM = mainPlane.vertices.map((v) => new Vector2(meterToMillimeter(v[0]), meterToMillimeter(v[1])))

      return {
        vertices: projectedPolygon.vertices.map((point, index) => {
          const nextPoint = projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length]
          const center = new Vector2().addVectors(point, nextPoint).multiplyScalar(0.5)

          const pointIn2d = vertMM[index]
          const nextPointIn2d = vertMM[(index + 1) % vertMM.length]

          return {
            label: {
              text: pointIn2d.distanceTo(nextPointIn2d).toFixed(0),
              point: center,
            },
            point,
          }
        }),
        orientation: projectedPolygon.orientation,
        boundary: projectedPolygon.boundary,
        grid: grid?.list_distances
          ? orientGridBasedOnProjectedDiagram(projectedPolygon.operations, mainPlane, grid, scale, matrixInverse)
          : [],
      }
    }

    // ## X-Axis aligned minimum rectangle boundary oriented diagram
    const pivot = new Vector2(
      meterToMillimeter(minimumRectangleBoundary.center[0] * scale),
      meterToMillimeter(minimumRectangleBoundary.center[1] * scale),
    )

    return {
      vertices: getDiagramScaledVertices(
        mainPlane.vertices as unknown as PointArray[],
        minimumRectangleBoundary,
        scale,
        pivot,
      ),
      boundary: minimumRectangleBoundary.boundaryAxisAlignment.vertices.map(
        (v) => new Vector2(v.x * scale, v.y * scale),
      ),
      orientation: 'horizontal',
      grid: grid ? getGridForScaledDiagram(minimumRectangleBoundary, grid, scale, matrixInverse, pivot) : [],
    }
  }, [projectedPolygon, mainPlane, scale, minimumRectangleBoundary, grid, matrixInverse])

  /**
   * Validate individual grid point distance thresholds.
   */
  const diagram = useMemo(() => {
    if (!diagramPre) return null

    const updated = cloneDeep(diagramPre)
    updated.grid = updated.grid.map((point) => ({
      ...point,
      thresholdValidation: getDifferenceValueAndIsPassingThresholds(
        {
          estimated_value: point.gridDistance.distance,
        },
        threshold,
        settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
        GRID_UNIT,
        GRID_UNIT_PRECISION,
      ),
    }))

    return updated
  }, [diagramPre, settings, threshold])

  /**
   * Generate grid points based on intervals so we can draw the grid lines.
   */
  const intervalGridPoints = useMemo(() => {
    if (!minimumRectangleBoundary || !grid || !diagram) return []

    const [wd, hg] = minimumRectangleBoundary.extent
    const longAxisInt = Math.max(wd, hg)
    const shortAxisInt = Math.min(wd, hg)

    let interval: IntervalsConfig = {
      topPlaneId: '',
      bottomPlaneId: '',
      shapeKey: ShapeKeyType.POLYGON,
      orderDirection: GridOrderDirection.Horizontal,
      type: GridType.CenterBased,
      longAxis: {
        total: meterToMillimeter(longAxisInt),
        interval: {
          min: 0,
          max: Math.round(meterToMillimeter(longAxisInt / 2)),
          value: Math.round((planeWidth / 2 + GRID_POINT_MINIMUM_INTERVAL) / 2),
        },
        offset: 0,
        edgeDistance: {
          min: 0,
          max: 0,
          value: 0,
        },
        pointCount: {
          min: 0,
          max: 0,
          value: 0,
        },
      },
      shortAxis: {
        total: meterToMillimeter(shortAxisInt),
        interval: {
          min: 0,
          max: Math.round(meterToMillimeter(shortAxisInt / 2)),
          value: Math.round((planeHeight / 2 + GRID_POINT_MINIMUM_INTERVAL) / 2),
        },
        offset: 0,
        edgeDistance: {
          min: 0,
          max: 0,
          value: 0,
        },
        pointCount: {
          min: 0,
          max: 0,
          value: 0,
        },
      },
      // 2D boundary is already pre-aligned to x-axis
      angle: 0,
    }

    // Overwrite with saved config
    if (inspectionItem.grid) {
      if (inspectionItem.grid.intervals) {
        interval = mergeIntervalsWithSavedConfig(interval, inspectionItem.grid)
      } else {
        interval.shortAxis.interval.value = 0
        interval.longAxis.interval.value = 0
      }
    }

    return generateGridPoints(interval).map((point) => point.map(Math.round))
  }, [grid, planeWidth, planeHeight, minimumRectangleBoundary, inspectionItem.grid, diagram])

  return {
    scale,
    scaledPlaneWidth,
    scaledPlaneHeight,
    mainPlane,
    diagram,
    projectedPolygon,
    intervalGridPoints,
    inspectionItem,
  }
}

export default useDiagram
