import { useCallback, useContext, useState } from 'react'

import {
  Button,
  Checkbox,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useAppDispatch } from 'store/app'
import { setUserProfile } from 'store/user'

import { PaymentIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { useUserContext } from 'contexts/Users'

import { activateAdditionalFeature } from 'services/Subscriptions'
import { getUser } from 'services/Users'
import { withPromiseContained } from 'services/Util'

interface AgreeConfirmationModalProps {
  disclosure: ReturnType<typeof useDisclosure>
  price: number
  name: string
}

const UpgradeConfirmationModal = ({ disclosure, price, name }: AgreeConfirmationModalProps) => {
  // Context
  const { getAccessToken } = useUserContext()
  const { showErrorModal } = useContext(GlobalModalContext)

  // States
  const [isLoading, setIsLoading] = useState(false)
  const [agreeUpgrade, setAgreeUpgrade] = useState(false)
  // const [agreeUpdate, setAgreeUpdate] = useState(false)

  // Store
  const dispatch = useAppDispatch()

  // Disclosure
  const { isOpen, onClose } = disclosure

  /**
   * Handle cancellation.
   */
  const handleClose = useCallback(() => {
    if (isLoading) return
    setAgreeUpgrade(false)
    onClose()
  }, [onClose, isLoading])

  /**
   * Handle confirmation.
   */
  const handleConfirm = useCallback(async () => {
    setIsLoading(true)

    await withPromiseContained(async () => {
      const token = await getAccessToken()
      if (!token) return

      await activateAdditionalFeature(token, showErrorModal)
      // Update the user profile
      const userInfo = await getUser(token, showErrorModal)
      if (!userInfo) return
      dispatch(setUserProfile(userInfo))
    }).finally(() => {
      setIsLoading(false)
      handleClose()
    })
  }, [getAccessToken, handleClose, showErrorModal, dispatch])

  return (
    <Modal closeOnEsc closeOnOverlayClick isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <HStack gap={4} alignItems="start" w="100%">
          <Circle bg="orange.100" color="orange.600" size="2rem" position="relative" top="1rem" left="1.5rem">
            <PaymentIcon size="1.2rem" />
          </Circle>
          <VStack alignItems="start" w="100%" gap={0}>
            <ModalHeader>{name}の有効化</ModalHeader>
            <ModalBody whiteSpace="pre-wrap">
              <VStack alignItems="start">
                <Spacer m={2} />
                <Checkbox isChecked={agreeUpgrade} onChange={() => setAgreeUpgrade(!agreeUpgrade)}>
                  (必須) {name}を有効化し、今月の料金として¥{price.toLocaleString()}を支払うことに同意します。
                </Checkbox>
                {/* <Checkbox isChecked={agreeUpdate} onChange={() => setAgreeUpdate(!agreeUpdate)}>
                  (任意) 毎月自動的に{name}を更新し、月額¥{price.toLocaleString()}を支払うことに同意します。
                </Checkbox> */}
              </VStack>
            </ModalBody>
          </VStack>
        </HStack>
        <ModalFooter>
          <Button mr={3} onClick={handleClose} isDisabled={isLoading}>
            キャンセル
          </Button>
          <Button
            colorScheme="primary"
            onClick={handleConfirm}
            isDisabled={!agreeUpgrade || isLoading}
            isLoading={isLoading}
          >
            有効化する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpgradeConfirmationModal
