/* istanbul ignore file */
import dayjs from 'dayjs'
import { BorderStyle, CellHyperlinkValue, CellValue, Color, Style, Worksheet } from 'exceljs'

import { APP_URL } from 'config/environments'

import { InspectionArea, InspectionItem, InspectionSheet, InspectionSheetDiagram } from 'interfaces/inspection'
import { Project, ProjectSheetDiagramVisibility, ProjectSheetSettings } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { calculateRebarSurfaceArea, meterToMillimeter } from 'services/Util'

import { planeHeaderFill, summaryTitleStyle, underlineStyle, verticalMiddleStyle } from './styles'

export const DIAGRAM_SHEET_NAME = '2D図面'
export const DATA_SHEET_NAME = '検査帳票'
export const GRID_VALUES_SHEET_NAME = '深さグリッド点'

const isHyperlinkValue = (value: CellValue): value is CellHyperlinkValue =>
  !!value && typeof value === 'object' && 'text' in value

/**
 * Create surrounding border from a range of cells.
 * https://github.com/exceljs/exceljs/issues/333#issuecomment-1591185638
 *
 * @param worksheet
 * @param startCell
 * @param endCell
 * @param borderStyle
 */
export const createOuterBorder = (
  worksheet: Worksheet,
  [startCol, startRow]: [number, number],
  [endCol, endRow]: [number, number],
  style: BorderStyle = 'thin',
  color: Partial<Color> = { argb: '00000000' },
) => {
  for (let i = startRow; i <= endRow; i += 1) {
    const leftBorderCell = worksheet.getCell(i, startCol)
    const rightBorderCell = worksheet.getCell(i, endCol)

    leftBorderCell.border = {
      ...leftBorderCell.border,
      left: {
        style,
        color,
      },
    }

    rightBorderCell.border = {
      ...rightBorderCell.border,
      right: {
        style,
        color,
      },
    }
  }

  for (let i = startCol; i <= endCol; i += 1) {
    const topBorderCell = worksheet.getCell(startRow, i)
    const bottomBorderCell = worksheet.getCell(endRow, i)

    topBorderCell.border = {
      ...topBorderCell.border,
      top: {
        style,
        color,
      },
    }

    bottomBorderCell.border = {
      ...bottomBorderCell.border,
      bottom: {
        style,
        color,
      },
    }
  }
}

/**
 * Set a title row.
 *
 * @param sheet Worksheet
 * @param bodyRow Current row
 * @param title Title
 * @returns New body row
 */
export const setTitle = (sheet: Worksheet, bodyRow: number, title: string): number => {
  sheet.getRow(bodyRow).getCell(1).value = title
  sheet.getRow(bodyRow).getCell(1).font = { bold: true, size: 20 }

  return bodyRow + 1
}

/**
 * Set profile section.
 *
 * @param sheet ExcelJS Worksheet
 * @param inspectionSheet Inspection Sheet
 * @param maxColumn Maximum number of columns to be used
 */
export const setProfileGroup = (
  sheet: Worksheet,
  bodyRow: number,
  project: Project,
  inspectionArea: InspectionArea | undefined,
  inspectionSheet: InspectionSheet | null,
  forProject = false,
) => {
  let updatedRow = bodyRow
  let maxRow = bodyRow

  sheet.getCell(updatedRow, 1).value = '工事名'
  sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
  sheet.getCell(updatedRow, 2).value = {
    text: project.project_name,
    hyperlink: `${APP_URL}/dashboard#${project.project_id}`,
  }
  sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle, ...underlineStyle }
  updatedRow += 1

  if (!forProject && inspectionSheet) {
    maxRow = Math.max(maxRow, updatedRow)

    sheet.getCell(updatedRow, 1).value = '工種名'
    sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
    sheet.getCell(updatedRow, 2).value = inspectionSheet.construction_properties.construction_type || ''
    sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }
    updatedRow += 1
    maxRow = Math.max(maxRow, updatedRow)

    sheet.getCell(updatedRow, 1).value = '種別'
    sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
    sheet.getCell(updatedRow, 2).value = inspectionSheet.construction_properties.construction_type_detailed || ''
    sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }

    // start from the top again
    updatedRow = bodyRow
    sheet.getCell(updatedRow, 4).value = '作成者'
    sheet.getCell(updatedRow, 4).style = { ...summaryTitleStyle }
    sheet.getCell(updatedRow, 5).value = inspectionSheet.creator_name || ''
    sheet.getCell(updatedRow, 5).style = { ...verticalMiddleStyle }
    updatedRow += 1
    maxRow = Math.max(maxRow, updatedRow)

    sheet.getCell(updatedRow, 4).value = '作成日'
    sheet.getCell(updatedRow, 4).style = { ...summaryTitleStyle }
    sheet.getCell(updatedRow, 5).value = inspectionSheet.create_time_user_specified
      ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
      : ''
    sheet.getCell(updatedRow, 5).style = { ...verticalMiddleStyle }

    // start from the lowest row plus padding
    updatedRow = maxRow + 2
  }

  // set inspection area name if available
  if (!forProject && inspectionArea) {
    sheet.getCell(updatedRow, 1).value = 'エリア名'
    sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
    sheet.getCell(updatedRow, 2).value = {
      text: inspectionArea.inspection_area_name,
      hyperlink: `${APP_URL}/projects/${project.project_id}/editor?area=${inspectionArea.inspection_area_id}`,
    }
    sheet.getCell(updatedRow, 2).style = { ...underlineStyle, ...verticalMiddleStyle } as Style
    updatedRow += 1
  }

  return updatedRow
}

/**
 * Set the summary section.
 *
 * @param sheet Current worksheet
 * @param project Project
 * @param inspectionArea Inspection Area
 * @param inspectionItems Inspection Items for the project
 * @param shapes Shapes
 * @param bodyRow Current row
 */
export const setSummary = (
  sheet: Worksheet,
  inspectionItems: InspectionItem[],
  shapes: Shapes,
  bodyRow: number,
): number => {
  let updatedRow = bodyRow

  // set total volume
  const totalVolume = inspectionItems
    .filter((item) => item.item_type === 'volume')
    .reduce((acc, item) => acc + (item.volume?.estimated_value || 0), 0)
  sheet.getCell(updatedRow, 1).value = '体積の合計'
  sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
  sheet.getCell(updatedRow, 2).value = totalVolume
    ? `${totalVolume.toLocaleString(undefined, { maximumFractionDigits: 4 })}m³`
    : ''
  sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }
  updatedRow += 1

  // set total area
  const totalArea = inspectionItems
    .filter((item) => item.item_type === 'polygon_area' && !item.volume_id)
    .reduce((total, item) => total + (item.polygon_area?.estimated_value || 0), 0)
  sheet.getCell(updatedRow, 1).value = '面積の合計'
  sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
  sheet.getCell(updatedRow, 2).value = totalArea
    ? `${totalArea.toLocaleString(undefined, { maximumFractionDigits: 4 })}m³`
    : ''
  sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }
  updatedRow += 1

  // set total rebar surface area
  const rebarsSurfaceArea = shapes.cylinders.reduce((sum, cylinder) => sum + calculateRebarSurfaceArea(cylinder), 0)
  sheet.getCell(updatedRow, 1).value = '鉄筋の表面積の合計'
  sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
  sheet.getCell(updatedRow, 2).value = rebarsSurfaceArea
    ? `${rebarsSurfaceArea.toLocaleString(undefined, { maximumFractionDigits: 4 })}m²`
    : ''
  sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }
  updatedRow += 1

  // set total polyline
  const totalPolyline = meterToMillimeter(
    inspectionItems
      .filter((item) => item.item_type === 'polyline_length')
      .reduce((total, item) => total + (item.polyline_length?.estimated_value || 0), 0),
  )
  sheet.getCell(updatedRow, 1).value = '延長の合計'
  sheet.getCell(updatedRow, 1).style = { ...summaryTitleStyle }
  sheet.getCell(updatedRow, 2).value = totalPolyline
    ? `${totalPolyline.toLocaleString(undefined, { maximumFractionDigits: 0 })}mm`
    : ''
  sheet.getCell(updatedRow, 2).style = { ...verticalMiddleStyle }

  return updatedRow + 1
}

/**
 * Fit column widths and row heights to the content.
 *
 * @param sheet Worksheet
 */
export const fitColumns = (sheet: Worksheet, forProject = false) => {
  // Adjust column widths to fit the longest text in each column
  sheet.columns.slice(0, 1).forEach((column) => {
    if (!column.eachCell) return

    let maxLength = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value) {
        if (isHyperlinkValue(cell.value)) {
          maxLength = Math.max(maxLength, cell.value.text.toString().length)
        } else if (typeof cell.value === 'string') {
          maxLength = Math.max(maxLength, cell.value ? cell.value.toString().length : 10)
        } else {
          maxLength = Math.max(maxLength, 10)
        }
      }
    })

    column.width = Math.max(20, maxLength) + 2
  })

  // 6 to account for the 4 columns of the profile section
  sheet.columns.slice(1, forProject ? 6 : 2).forEach((column) => {
    if (!column.eachCell) return

    let maxLength = 10
    column.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value) {
        if (isHyperlinkValue(cell.value)) {
          maxLength = Math.max(maxLength, cell.value.text.toString().length)
        } else if (typeof cell.value === 'string') {
          maxLength = Math.max(maxLength, cell.value ? cell.value.toString().length : 10)
        } else {
          maxLength = Math.max(maxLength, 10)
        }
      }
    })
    column.width = maxLength + 2
  })
}

/**
 * Set a header cell.
 *
 * @param sheet Current worksheet
 * @param bodyRow Current row
 * @param bodyCol Current column
 * @param title Title
 * @param size Text size
 */
export const setHeaderCell = (
  sheet: Worksheet,
  bodyRow: number,
  bodyCol: number,
  title: string,
  size: 'md' | 'lg' = 'md',
) => {
  sheet.getCell(bodyRow, bodyCol).value = title
  sheet.getCell(bodyRow, bodyCol).fill = planeHeaderFill
  sheet.getCell(bodyRow, bodyCol).alignment = { horizontal: 'center', vertical: 'middle' }

  if (size === 'lg') {
    sheet.getCell(bodyRow, bodyCol).font = { bold: true, size: 12 }
  }
}

/**
 * Get all diagrams by inspection item.
 * @param volumeSet
 * @param planeDiagrams
 * @param settings
 * @returns
 */
export const getDiagramsByInspectionItem = (
  volumeSet: InspectionItem,
  planeDiagrams: Record<string, InspectionSheetDiagram>,
  settings: ProjectSheetSettings,
): Record<string, InspectionSheetDiagram> => {
  const id = volumeSet.inspection_item_id!
  const diagramIds = Object.keys(planeDiagrams)
  return Object.values(planeDiagrams).reduce<Record<string, InspectionSheetDiagram>>((acc, diagram, index) => {
    const [, diagramType] = diagramIds[index].split('--') as [string, ProjectSheetDiagramVisibility]
    if (
      settings.sheet_diagram_visibility &&
      settings.sheet_diagram_visibility[diagramType] &&
      diagramIds[index].startsWith(id)
    ) {
      acc[diagramIds[index]] = diagram
    }
    return acc
  }, {})
}

/**
 * Check if any diagrams is shown in the project settings.
 *
 * @param settings Project settings
 * @returns
 */
export const isDiagramShown = (settings: ProjectSheetSettings): boolean =>
  !!settings.sheet_diagram_visibility?.grid_diagram ||
  !!settings.sheet_diagram_visibility?.plane_diagram ||
  !!settings.sheet_diagram_visibility?.polyline_diagram

/**
 * Generate a cell value to link to an another cell within the same workbook.
 *
 * @param sheetName Worksheet name
 * @param cellId Cell ID
 * @param label Text label to display
 */
export const makeInternalLinkValue = (sheetName: string, cellId: string, label: string) => ({
  formula: `HYPERLINK("#'${sheetName}'!"&ADDRESS(CELL("row", ${cellId}), CELL("col",${cellId})), "${label}")`,
  date1904: false,
})

export const makeExternalLinkValue = (label: string, url: string) => ({
  text: label,
  hyperlink: url,
})
