import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { merge } from 'lodash'

import { IntervalsConfig } from 'interfaces/inspectionItemGrid'
import { InspectionItem, PointArray } from 'interfaces/interfaces'

import { generateEdgePlanes } from './utils'

export interface GridState {
  /**
   * Grid being processed.
   */
  isLoading: boolean

  /**
   * Flag to indicate if a volume has been selected.
   */
  selectedVolumeId: string

  /**
   * Intervals config.
   */
  intervals: Record<string, IntervalsConfig>

  /**
   * Working grid points.
   */
  workingGridPoints: Record<string, PointArray[][]>

  /**
   * Working edge planes (for edge-based grids).
   * They're always generated but hidden, only shown as necessary.
   */
  workingEdgePlanes: Record<string, ReturnType<typeof generateEdgePlanes>>

  /**
   * Flag to indicate which working edge planes are to be shown.
   */
  shownWorkingEdgePlanes?: {
    id: string
    axis: 'longAxis' | 'shortAxis'
  }

  /**
   * Highlighted working grid.
   */
  highlightedWorkingGrid: string

  /**
   * Hovered grid point
   */
  hoveredGridPoint?: {
    gridId: string
    pointIndex: number
  }

  /**
   * Grid data.
   */
  grids: InspectionItem[]
}

const initialState: GridState = {
  isLoading: false,
  selectedVolumeId: '',
  grids: [],
  intervals: {},
  workingGridPoints: {},
  workingEdgePlanes: {},
  highlightedWorkingGrid: '',
}

export const slice = createSlice({
  name: 'editor/tools/grid',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSelectedVolumeId: (state, action: PayloadAction<string>) => {
      state.selectedVolumeId = action.payload
    },
    updateInterval: (state, action: PayloadAction<{ id: string; interval: IntervalsConfig }>) => {
      state.intervals[action.payload.id] = action.payload.interval
    },
    /**
     * Do a deep merge of the interval config.
     */
    patchInterval: (state, action: PayloadAction<{ id: string; interval: Partial<IntervalsConfig> }>) => {
      state.intervals[action.payload.id] = merge(state.intervals[action.payload.id], action.payload.interval)
    },
    setWorkingGridPoints: (state, action: PayloadAction<Record<string, PointArray[][]>>) => {
      state.workingGridPoints = action.payload
    },
    updateWorkingGridPoints: (state, action: PayloadAction<{ id: string; points: PointArray[][] }>) => {
      state.workingGridPoints[action.payload.id] = action.payload.points
    },
    removeWorkingGridPoint: (state, action: PayloadAction<{ id: string; gridPointIndex: number }>) => {
      state.workingGridPoints[action.payload.id].splice(action.payload.gridPointIndex, 1)
    },
    setGrids: (state, action: PayloadAction<InspectionItem[]>) => {
      state.grids = action.payload
    },
    updateGrid: (state, action: PayloadAction<InspectionItem>) => {
      state.grids = state.grids.map((grid) => {
        if (grid.inspection_item_id === action.payload.inspection_item_id) {
          return action.payload
        }
        return grid
      })
    },
    setHighlightedWorkingGrid: (state, action: PayloadAction<string>) => {
      state.highlightedWorkingGrid = action.payload
    },
    setHoveredGridPoint: (state, action: PayloadAction<{ gridId: string; pointIndex: number } | undefined>) => {
      state.hoveredGridPoint = action.payload
    },
    updateWorkingEdgePlanes: (
      state,
      action: PayloadAction<{ id: string; edgePlanes: ReturnType<typeof generateEdgePlanes> }>,
    ) => {
      state.workingEdgePlanes[action.payload.id] = action.payload.edgePlanes
    },
    setShownWorkingEdgePlanes: (state, action: PayloadAction<{ id: string; axis: 'longAxis' | 'shortAxis' }>) => {
      state.shownWorkingEdgePlanes = action.payload
    },
    resetShownWorkingEdgePlanes: (
      state,
      { payload: { id, axis } }: PayloadAction<{ id: string; axis: 'longAxis' | 'shortAxis' }>,
    ) => {
      // Reset only if the shown working edge planes are the same as the one to be reset.
      if (state.shownWorkingEdgePlanes?.id === id && state.shownWorkingEdgePlanes.axis === axis) {
        state.shownWorkingEdgePlanes = undefined
      }
    },
    resetWorking: (state) => ({ ...initialState, grids: state.grids }),
    resetPerVolumeId: (state, action: PayloadAction<string>) => {
      delete state.intervals[action.payload]
      delete state.workingGridPoints[action.payload]
      delete state.workingEdgePlanes[action.payload]
      if (state.shownWorkingEdgePlanes?.id === action.payload) {
        state.shownWorkingEdgePlanes = undefined
      }
    },
    reset: () => initialState,
  },
})

export const {
  setIsLoading,
  setSelectedVolumeId,

  updateInterval,
  patchInterval,

  setWorkingGridPoints,
  updateWorkingGridPoints,
  removeWorkingGridPoint,
  setGrids,
  updateGrid,

  setHighlightedWorkingGrid,
  setHoveredGridPoint,

  reset,
  resetWorking,
  resetPerVolumeId,

  updateWorkingEdgePlanes,
  setShownWorkingEdgePlanes,
  resetShownWorkingEdgePlanes,
} = slice.actions

export default slice.reducer
