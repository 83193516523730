import { ProjectSheetToleranceType } from 'interfaces/project'

export const FIRST_COLUMN_WIDTH = 130
export const COLUMN_WIDTH = 100
export const DIAGRAM_WIDTH = 550
export const PLUS_MINS_PADDING = 24

export const DEFAULT_TOLERANCE_TYPE = ProjectSheetToleranceType.WithinTolerance

export const UNIT_PRECISION_DEFAULT = '0.0001'
export const UNIT_PRECISION_MM = '0.001'

export const GRID_UNIT = 'mm'
export const GRID_UNIT_PRECISION = UNIT_PRECISION_MM
