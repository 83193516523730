import { FC, memo, useCallback, useContext } from 'react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { useDebouncedCallback } from 'use-debounce'

import { ResetGridLockIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, PanelType } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { Timeout } from 'interfaces/attribute'
import { GridOrderDirection, GridType, IntervalsAxisConfig, IntervalsConfig } from 'interfaces/inspectionItemGrid'

import { patchInterval, resetShownWorkingEdgePlanes, setShownWorkingEdgePlanes, updateInterval } from '../../store'
import { calculateInterval } from '../../utils'
import CenterBasedAxisConfig from './CenterBasedAxisConfig'
import EdgeBasedAxisConfig from './EdgeBasedAxisConfig'

let mouseoverTimeout: Timeout | null = null

const GridIntervalPanel: FC = () => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const selectedVolumeId = useSelector((state: RootState) => state.toolGrid.selectedVolumeId)
  const shownWorkingEdgePlanes = useSelector((state: RootState) => state.toolGrid.shownWorkingEdgePlanes)
  const interval = intervals[selectedVolumeId] || null

  /**
   * Reset interval lock so it would be editable again.
   * Will remove any arbitrary placed grid points.
   */
  const resetIntervalLock = useCallback(() => {
    dispatch(updateInterval({ id: selectedVolumeId, interval: { ...interval, locked: false } }))
  }, [dispatch, interval, selectedVolumeId])

  const patch = useDebouncedCallback((volumeId: string, newData: Partial<IntervalsConfig>) => {
    dispatch(patchInterval({ id: volumeId, interval: newData }))
  }, 200)

  const resetWorkingEdgePlanes = useCallback(
    (id: string, axis: 'shortAxis' | 'longAxis') => {
      if (mouseoverTimeout) {
        clearTimeout(mouseoverTimeout)
      }

      mouseoverTimeout = setTimeout(() => {
        dispatch(resetShownWorkingEdgePlanes({ id, axis }))
      }, 3000)
    },
    [dispatch],
  )

  const patchLongAxisInterval = (config: Partial<IntervalsAxisConfig>) => {
    patch(selectedVolumeId, { longAxis: { ...interval.longAxis, ...config } })
  }
  const patchShortAxisInterval = (config: Partial<IntervalsAxisConfig>) => {
    patch(selectedVolumeId, { shortAxis: { ...interval.shortAxis, ...config } })
  }

  const handleOnMouseIn = useCallback(
    (axis: 'shortAxis' | 'longAxis') => {
      if (
        mouseoverTimeout &&
        shownWorkingEdgePlanes?.id === selectedVolumeId &&
        shownWorkingEdgePlanes?.axis === axis
      ) {
        clearTimeout(mouseoverTimeout)
      }

      dispatch(setShownWorkingEdgePlanes({ id: selectedVolumeId, axis }))
    },
    [dispatch, shownWorkingEdgePlanes, selectedVolumeId],
  )

  const handleOnMouseOut = useCallback(
    (axis: 'shortAxis' | 'longAxis') => {
      resetWorkingEdgePlanes(selectedVolumeId, axis)
    },
    [resetWorkingEdgePlanes, selectedVolumeId],
  )

  const setGridOrder = (orderDirection: GridOrderDirection) => patch(selectedVolumeId, { orderDirection })
  const setGridType = (type: GridType) => patch(selectedVolumeId, { type })

  if (selectedTool !== EDITOR_TOOLS.GRID && Object.keys(intervals).length === 0) {
    return null
  }

  return (
    <CollapsePanel title="グリッド間隔" type={PanelType.Tool} onChange={() => null} data-testid="grid-interval-panel">
      {!interval && (
        <Box textAlign="center" py={5}>
          <Text>グリッドを作成する領域の平面を選んでください</Text>
        </Box>
      )}

      {interval?.locked && (
        <Box textAlign="center" py={5}>
          <Text>手動で点を移動したグリッドの間隔は変更できません</Text>
          <Button
            colorScheme="secondary"
            fontSize="xs"
            variant="toolbar"
            size={isTablet ? 'lg' : 'sm'}
            mt={5}
            rightIcon={<ResetGridLockIcon size={18} />}
            onClick={resetIntervalLock}
          >
            リセット
          </Button>
        </Box>
      )}

      {interval && !interval.locked && (
        <>
          <FormControl px={INFO_PANEL_PADDING} pt={INFO_PANEL_PADDING}>
            <Text>番号付けの方向</Text>
            <Menu variant="panel" placement="bottom-start" gutter={4} matchWidth>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="panel-dropdown">
                {interval.orderDirection === GridOrderDirection.Vertical ? '縦方向' : '横方向'}
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem onClick={() => setGridOrder(GridOrderDirection.Horizontal)}>横方向</MenuItem>
                  <MenuItem onClick={() => setGridOrder(GridOrderDirection.Vertical)}>縦方向</MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </FormControl>

          <FormControl px={INFO_PANEL_PADDING} py={INFO_PANEL_PADDING}>
            <Text>点の配置方法</Text>
            <Menu variant="panel" placement="bottom-start" gutter={4} matchWidth>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="panel-dropdown">
                {interval.type === GridType.CenterBased ? '中心から等間隔に配置' : '端から指定数で配置'}
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem onClick={() => setGridType(GridType.CenterBased)}>中心から等間隔に配置</MenuItem>
                  <MenuItem
                    onClick={() =>
                      // recalculate intervals for edge-based
                      patch(selectedVolumeId, {
                        type: GridType.EdgeBased,
                        longAxis: {
                          ...interval.longAxis,
                          interval: {
                            ...interval.longAxis.interval,
                            value: calculateInterval(
                              interval.longAxis.total,
                              interval.longAxis.edgeDistance.value,
                              interval.longAxis.pointCount.value,
                            ),
                          },
                        },
                        shortAxis: {
                          ...interval.shortAxis,
                          interval: {
                            ...interval.shortAxis.interval,
                            value: calculateInterval(
                              interval.shortAxis.total,
                              interval.shortAxis.edgeDistance.value,
                              interval.shortAxis.pointCount.value,
                            ),
                          },
                        },
                      })
                    }
                  >
                    端から指定数で配置
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </FormControl>

          <Divider borderColor="gray.600" />

          {interval?.type === GridType.CenterBased && (
            <>
              {/* Longer axis */}
              <CenterBasedAxisConfig
                side="長辺"
                selectedVolumeId={selectedVolumeId}
                axisConfig={interval.longAxis}
                patchAxisConfig={patchLongAxisInterval}
              />

              <Flex justifyContent="center" px={INFO_PANEL_PADDING}>
                <Divider borderColor="gray.700" />
              </Flex>

              {/* Shorter axis */}
              <CenterBasedAxisConfig
                side="短辺"
                selectedVolumeId={selectedVolumeId}
                axisConfig={interval.shortAxis}
                patchAxisConfig={patchShortAxisInterval}
              />
            </>
          )}

          {/* ## Edge-based grid config */}
          {interval.type === GridType.EdgeBased && (
            <>
              {/* Edge-based - Longer axis edge distance */}
              <EdgeBasedAxisConfig
                side="長辺"
                selectedVolumeId={selectedVolumeId}
                axisConfig={interval.longAxis}
                patchAxisConfig={patchLongAxisInterval}
                onMouseIn={() => handleOnMouseIn('longAxis')}
                onMouseOut={() => handleOnMouseOut('longAxis')}
              />

              <Flex justifyContent="center" px={INFO_PANEL_PADDING}>
                <Divider borderColor="gray.700" />
              </Flex>

              {/* Edge-based - Shorter axis edge distance */}
              <EdgeBasedAxisConfig
                side="短辺"
                selectedVolumeId={selectedVolumeId}
                axisConfig={interval.shortAxis}
                patchAxisConfig={patchShortAxisInterval}
                onMouseIn={() => handleOnMouseIn('shortAxis')}
                onMouseOut={() => handleOnMouseOut('shortAxis')}
              />
            </>
          )}
        </>
      )}
    </CollapsePanel>
  )
}

export default memo(GridIntervalPanel)
