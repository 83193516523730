import { FC } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Divider, Flex, HStack, Spacer, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store/app'

import { LogoIcon } from 'assets/icons'

import { HELP_CENTER_URL } from 'config/constants'

const PageFooter: FC = () => {
  const { user } = useAuth0()
  // store
  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const hasDeprecatedSubscription = useSelector((state: RootState) => state.user.hasDeprecatedSubscription)

  return (
    <Box mt={5}>
      <Divider />
      <HStack my={5}>
        <LogoIcon />
        <Text fontSize="xs" color="gray.500">
          &copy; 2024 DataLabs株式会社
        </Text>
        <Spacer />
        <Flex gap={10}>
          {!!user && (
            <Link to={HELP_CENTER_URL} target="_blank">
              ユーザーマニュアル
            </Link>
          )}
          {userLoaded &&
            userProfile &&
            featureFlags?.selfManagedSubscriptions.enabled &&
            hasDeprecatedSubscription === false && (
              <Button
                variant="link"
                color="primary.600"
                fontWeight="400"
                _hover={{ textDecoration: 'none' }}
                onClick={() => document.dispatchEvent(new Event('open-tos'))}
              >
                利用規約
              </Button>
            )}
          <Link to="https://share.hsforms.com/11_hEhYjCR0CBEe1gX9adVQddydr" target="_blank">
            お問い合わせ
          </Link>
        </Flex>
      </HStack>
    </Box>
  )
}

export default PageFooter
