import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Container,
  Flex,
  Input,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { useSelector } from 'react-redux'
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom'
import { RootState } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { USER_TYPES } from 'config/constants'

import { UserProfile } from 'interfaces/user'

import { getUserList, patchUsers } from 'services/Users'

interface UserListProps {
  hubspotOnly?: boolean
}

const UserList: FC<UserListProps> = ({ hubspotOnly = true }) => {
  const navigate = useNavigate()

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userType = useSelector((state: RootState) => state.user.userType)

  // State
  const [users, setUsers] = useState<UserProfile[]>([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [changedUserHubspotIds, setChangedUserHubspotIds] = useState<Record<string, string>>({})
  const [updatedUserIds, setUpdatedUserIds] = useState<string[]>([])

  const fetchData = useCallback(async () => {
    if (userType !== USER_TYPES.ADMIN) {
      return null
    }

    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    setLoading(true)
    setUsers([])
    const result = await getUserList(token, hubspotOnly, true, showErrorModal)
    if (result) setUsers(result)
    setLoading(false)

    return Promise.resolve()
  }, [userType, getAccessToken, showErrorModal, hubspotOnly])

  const usersMap = useMemo(() => {
    const map = new Map<string, UserProfile>()
    users.forEach((user) => {
      map.set(user.user_id, user)
    })
    return map
  }, [users])

  const handleSave = useCallback(async () => {
    setLoading(true)
    setUsers([])
    setSaving(true)

    const token = await getAccessToken()
    if (!token) return

    const request = Object.entries(changedUserHubspotIds).map(([user_id, hubspot_id]) => ({
      user_id,
      hubspot_id,
    }))
    await patchUsers(token, request, showErrorModal)
    await fetchData()
    setSaving(false)
    setUpdatedUserIds(Object.keys(changedUserHubspotIds))
    setChangedUserHubspotIds({})
  }, [changedUserHubspotIds, getAccessToken, showErrorModal, fetchData])

  useEffect(() => {
    if (!userLoaded) return

    if (userType !== USER_TYPES.ADMIN) {
      navigate('/')
    }
  }, [userType, userLoaded, navigate])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  if (userType !== USER_TYPES.ADMIN) {
    return null
  }

  return (
    <>
      <Navbar />

      <Container maxW="100%">
        <PageHeading>ユーザーリスト</PageHeading>
        {loading && <Spinner />}
        {!loading && (
          <Flex>
            <Button
              colorScheme="primary"
              onClick={handleSave}
              isLoading={saving}
              marginEnd="auto"
              disabled={!Object.keys(changedUserHubspotIds).length}
            >
              変更を保存
            </Button>
            <Button
              colorScheme="yellow"
              onClick={() => setChangedUserHubspotIds({})}
              isLoading={saving}
              disabled={!Object.keys(changedUserHubspotIds).length}
            >
              変更をクリア
            </Button>
          </Flex>
        )}
        <TableContainer mb={10}>
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>Auth0 email</Th>
                <Th>Hubspot email</Th>
                <Th textAlign="right">Hubspot ID</Th>
                <Th textAlign="right">氏名</Th>
                <Th textAlign="right">会社名</Th>
                <Th textAlign="right">所属部署</Th>
                <Th textAlign="right">user type</Th>
                <Th textAlign="right">Stripe ID</Th>
                <Th textAlign="right">Subscription</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.user_id} bg={updatedUserIds.includes(user.user_id) ? 'green.100' : ''}>
                  <Td>
                    <Link as={RouterNavLink} variant="underline" to={`/dashboard?user_id=${user.user_id}`}>
                      {user.auth0_email}
                    </Link>
                  </Td>
                  <Td>
                    <Link
                      as={RouterNavLink}
                      variant="underline"
                      to={`/dashboard?user_id=${user.user_id}&user_email=${user.hubspot_properties?.email}`}
                    >
                      {user.hubspot_properties?.email}
                    </Link>
                  </Td>
                  <Td textAlign="right">
                    <Input
                      defaultValue={user.hubspot_id}
                      value={
                        user.user_id in changedUserHubspotIds
                          ? changedUserHubspotIds[user.user_id]
                          : user.hubspot_id || ''
                      }
                      onChange={(e) => {
                        const originalId = usersMap.get(user.user_id)?.hubspot_id
                        if (originalId !== e.target.value) {
                          setChangedUserHubspotIds((prev) => ({ ...prev, [user.user_id]: e.target.value }))
                        } else {
                          setChangedUserHubspotIds((prev) => {
                            const newPrev = { ...prev }
                            delete newPrev[user.user_id]
                            return newPrev
                          })
                        }
                      }}
                      outlineColor={user.user_id in changedUserHubspotIds ? 'orange' : 'transparent'}
                    />
                  </Td>
                  <Td textAlign="right">
                    {user.hubspot_properties?.last_name} {user.hubspot_properties?.first_name}
                  </Td>
                  <Td textAlign="right">{user.hubspot_properties?.company}</Td>
                  <Td textAlign="right">{user.hubspot_properties?.department}</Td>
                  <Td textAlign="right">{user.user_type}</Td>
                  <Td textAlign="right">
                    <Link
                      href={`https://dashboard.stripe.com/customers/${user.stripe_id}`}
                      isExternal
                      variant="underline"
                    >
                      {user.stripe_id}
                    </Link>
                  </Td>
                  <Td textAlign="right">{user.subscriptions?.type}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  )
}

export default UserList
