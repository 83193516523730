import { ReactElement } from 'react'

import { Box2, Vector2 } from 'three'

import { InspectionItemNumberValues, InspectionItemPredefinedThresholds } from 'interfaces/inspection'
import { ProjectSheetSettings, ProjectSheetToleranceType } from 'interfaces/project'

import { isPointInsidePolygon } from 'services/Points'
import { isValueDefined, roundNumber, roundVolume } from 'services/Util'

import { COLUMN_WIDTH, DIAGRAM_WIDTH, FIRST_COLUMN_WIDTH } from './constants'
import { GridBox, GridPointLabel, SortingDataPoint, ValuePassingThreshold } from './interfaces'

/**
 * Given grid coordinates, orientation, and scale,
 * return the number of rows and columns.
 *
 * @param gridCoordinates Coordinates of the grid.
 * @param orientation Orientation of the grid.
 * @param scale Scale of the grid.
 */
function getRowsAndCols(gridCoordinates: Vector2[], orientation: 'vertical' | 'horizontal' | undefined, scale: number) {
  const rows = new Set<number>()
  const cols = new Set<number>()

  if (orientation === 'horizontal') {
    gridCoordinates.forEach((point) => {
      rows.add(Math.floor(point.x * scale))
      cols.add(Math.floor(point.y * scale))
    })
  } else {
    gridCoordinates.forEach((point) => {
      rows.add(Math.floor(point.y * scale))
      cols.add(Math.floor(point.x * scale))
    })
  }

  return { rows: Array.from(rows), cols: Array.from(cols) }
}

/**
 * Given a center point, width, and height, create a bounding box.
 *
 * @param center Center point.
 * @param width Width of the bounding box.
 * @param height Height of the bounding box.
 */
function makeBoundingBox(center: Vector2, width: number, height: number): Box2 {
  return new Box2(
    new Vector2(center.x - width / 2, center.y - height / 2),
    new Vector2(center.x + width / 2, center.y + height / 2),
  )
}

/**
 * Given a set of Vector2 coordinates, flip its y-axis.
 */
export function flipYAxis(coordinates: Vector2[]): Vector2[] {
  const flipped = coordinates.map((point) => new Vector2(point.x, -point.y))
  return flipped
}

/**
 * Generate a grid box for visualization of grid values.
 *
 * This function uses the interval grid as bounding box and find any data within it.
 * If there's no data, it will find the closest point to the grid point.
 *
 * It uses bounding box as primary method due to optimization to reduce the number of calculations.
 *
 * @param gridBoxCoordinates Initial grid point values generated from intervals.
 * @param orientation Orientation of the polygon.
 * @param scale Scale reference.
 * @param dataPoints Grid points.
 * @param polygon Polygon of the grid.
 */
export function generateGridBox(
  gridBoxCoordinates: Vector2[],
  orientation: 'vertical' | 'horizontal' | undefined,
  scale: number,
  dataPoints: GridPointLabel[],
  polygon: Vector2[],
): GridBox[][] {
  const vectorDataPoints = dataPoints.map((point) => new Vector2(point.x, point.y))
  const nestedArray: GridBox[][] = []
  const { rows, cols } = getRowsAndCols(gridBoxCoordinates, orientation, scale)
  const intervalX = Math.abs(rows[0] - rows[1])
  const intervalY = Math.abs(cols[0] - cols[1])
  const placedDataPoints: number[] = []

  for (let colIndex = 0; colIndex < cols.length; colIndex += 1) {
    const row: GridBox[] = []

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
      const gridBoxCoord = new Vector2(rows[rowIndex], cols[colIndex])
      const boundingBox = makeBoundingBox(gridBoxCoord, intervalX, intervalY)
      const isInside = isPointInsidePolygon(gridBoxCoord, polygon)

      let cellContent: GridBox | null = null

      // Get all points within the bounding box, and find the closest point.
      // There could be multiple points due to user moving them around.
      let point: SortingDataPoint = {
        distance: undefined,
        point: null,
        index: null,
      }
      const sortedPoints = vectorDataPoints
        .map<SortingDataPoint | false>((pt, index) => {
          const inside = boundingBox.containsPoint(pt)
          if (inside) {
            return {
              point: pt,
              index,
              distance: 0, // Dummy to mark as placed
            }
          }

          return false
        })
        .filter(Boolean) as SortingDataPoint[]

      // If there's only one point, use that. Otherwise, find the closest point.
      if (sortedPoints.length === 1) {
        ;[point] = sortedPoints
      } else {
        point = sortedPoints.reduce<SortingDataPoint>(
          (closest, current) => {
            const distance = current.point!.distanceTo(gridBoxCoord)

            if (closest.distance === undefined || (closest.distance && distance < closest.distance)) {
              return {
                ...current,
                distance,
              }
            }

            return closest
          },
          { distance: undefined, point: null, index: null },
        )
      }

      if (point.point && point.index !== null) {
        cellContent = {
          ...dataPoints[point.index],
          inside: isInside,
          distance: 0, // Dummy to mark as placed
          coordinate: new Vector2(gridBoxCoord.x, gridBoxCoord.y),
        }
        placedDataPoints.push(point.index)
      }

      if (!cellContent) {
        cellContent = {
          inside: isInside,
          label: `r${rowIndex}c${colIndex}`,
          coordinate: new Vector2(gridBoxCoord.x, gridBoxCoord.y),
          isMax: false,
          isMin: false,
        }
      }

      row.push(cellContent)
    }

    nestedArray.push(row)
  }

  // Loop through the grid again to fill in empty boxes
  for (let colIndex = 0; colIndex < cols.length; colIndex += 1) {
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
      const gridIndex = rowIndex * cols.length + colIndex // Calculate grid index
      const gridBoxContent = nestedArray[colIndex][rowIndex]
      const gridBoxCoord = gridBoxCoordinates[gridIndex]

      // For any empty box that _should_ have data, use closest data point.
      if (gridBoxContent.inside && gridBoxContent.distance === undefined) {
        let foundIndex = null
        const found = dataPoints.reduce<GridBox>((closest, current, i) => {
          if (placedDataPoints.includes(i) || closest.distance !== undefined) {
            return closest
          }

          const currentPoint = new Vector2(current.x, current.y)
          const distance = currentPoint.distanceTo(gridBoxCoord)

          if (closest.distance === undefined || (closest.distance && distance < closest.distance)) {
            foundIndex = i
            return {
              ...gridBoxContent,
              ...current,
              distance,
            }
          }

          return closest
        }, gridBoxContent)

        if (foundIndex !== null) {
          placedDataPoints.push(foundIndex)
          nestedArray[colIndex][rowIndex] = found
        }
      }
    }
  }

  return nestedArray
}

/**
 * Max width is determined by the number of columns.
 *
 * @param allAdditionalMetricsToggle All additional metrics' toggle state.
 * @param settings Project sheet settings.
 */
export const getTableWidths = (allAdditionalMetricsToggle: boolean[], settings: ProjectSheetSettings) => {
  let colCount = allAdditionalMetricsToggle.filter((hidden) => !hidden).length
  let diagCount = 0
  const { sheet_cols_visibility: colVisibility, sheet_diagram_visibility: diagVisibility } = settings

  if (colVisibility?.estimated_value) {
    colCount += 1
  }

  if (colVisibility?.tolerance) {
    colCount += 1
  }

  if (colVisibility?.designed_value) {
    colCount += 2
  }

  if (diagVisibility?.plane_diagram || diagVisibility?.polyline_diagram) {
    diagCount += 1
  }

  const left = colCount * COLUMN_WIDTH + FIRST_COLUMN_WIDTH
  const right = diagCount * DIAGRAM_WIDTH

  return {
    left,
    right,
    max: left + right,
  }
}

/**
 * Checks if the designed value and tolerance can be evaluated.
 *
 * @param settings Project sheet settings.
 * @param toleranceType Tolerance type.
 * @param designedValue Designed value.
 * @param tolerance Tolerance.
 */
export const canBeEvaluated = (
  settings: ProjectSheetSettings,
  toleranceType: ProjectSheetToleranceType,
  threshold: InspectionItemPredefinedThresholds | undefined,
) => {
  const isDesignedValueDefined = isValueDefined(threshold?.designed_value)
  const isToleranceDefined = isValueDefined(threshold?.tolerance)
  const isUpperToleranceDefined = isValueDefined(threshold?.upper_tolerance)
  const isLowerToleranceDefined = isValueDefined(threshold?.lower_tolerance)
  const isEvaluated =
    ((isToleranceDefined && toleranceType === ProjectSheetToleranceType.WithinTolerance) || toleranceType) &&
    isDesignedValueDefined &&
    settings.sheet_cols_visibility?.designed_value &&
    settings.sheet_cols_visibility?.tolerance

  return {
    isDesignedValueDefined,
    isToleranceDefined,
    isUpperToleranceDefined,
    isLowerToleranceDefined,
    isEvaluated,
  }
}

/**
 * Calculates the difference value and checks if it passes the pre-defined thresholds for an inspection item.
 * @param inspectionItemValue The inspection item value to compare.
 * @param inspectionItem The inspection item to compare.
 * @param unit The unit of measurement for the difference value.
 * @returns An object containing the difference value and a boolean indicating if it passes the pre-defined thresholds.
 */
export const getDifferenceValueAndIsPassingThresholds = (
  inspectionItemValue: InspectionItemNumberValues | undefined,
  threshold: InspectionItemPredefinedThresholds | undefined,
  toleranceType: ProjectSheetToleranceType,
  unit: string | ReactElement,
  rounding: '0.0000001' | '0.000001' | '0.00001' | '0.0001' | '0.001' | '0.01' | '0.1' | '1' = '0.0001',
): ValuePassingThreshold => {
  let differenceValue: number | undefined
  let isPassingThresholds: boolean | undefined

  if (typeof inspectionItemValue?.estimated_value === 'number' && typeof threshold?.designed_value === 'number') {
    differenceValue = inspectionItemValue.estimated_value - threshold.designed_value
    differenceValue = unit === 'mm' ? roundNumber(differenceValue, rounding) : roundVolume(differenceValue)
    const estimatedValue =
      unit === 'mm'
        ? roundNumber(inspectionItemValue.estimated_value, rounding)
        : roundVolume(inspectionItemValue.estimated_value)
    const designedValue =
      unit === 'mm' ? roundNumber(threshold.designed_value || 0, rounding) : roundVolume(threshold.designed_value || 0)

    if (toleranceType === ProjectSheetToleranceType.WithinTolerance) {
      if (typeof threshold.tolerance === 'number') {
        isPassingThresholds = Math.abs(differenceValue) <= threshold.tolerance
      }
    } else if (toleranceType === ProjectSheetToleranceType.GteDesignedValue) {
      isPassingThresholds = estimatedValue >= designedValue
    } else if (toleranceType === ProjectSheetToleranceType.LteDesignedValue) {
      isPassingThresholds = estimatedValue <= designedValue
    } else if (
      toleranceType === ProjectSheetToleranceType.AsymmetricTolerance &&
      (typeof threshold.upper_tolerance === 'number' || typeof threshold.lower_tolerance === 'number')
    ) {
      const upperTolerance =
        unit === 'mm'
          ? roundNumber(threshold.upper_tolerance || 0, rounding)
          : roundVolume(threshold.upper_tolerance || 0)
      const lowerTolerance =
        unit === 'mm'
          ? roundNumber(threshold.lower_tolerance || 0, rounding)
          : roundVolume(threshold.lower_tolerance || 0)

      isPassingThresholds =
        estimatedValue >= designedValue - lowerTolerance && estimatedValue <= designedValue + upperTolerance
    }
  }

  return { differenceValue, isPassingThresholds, threshold }
}
