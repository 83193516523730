import './styles.css'

import { FC, useEffect } from 'react'

import { Flex, Grid, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import LoadingPage from 'components/LoadingPage'
import PageErrorHandler from 'components/PageErrorHandler'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { Project } from 'interfaces/project'

import Navbar from '../common/Navbar'
import DataSaver from './components/DataSaver'
import InspectionAreaRow from './components/InspectionAreaRow'
import PopoverEditing from './components/PopoverEditing'
import SettingsDrawer from './components/SettingsDrawer'
import XLSXExportButton from './components/XLSXExportButton'
import { COLUMN_WIDTH, DIAGRAM_WIDTH, FIRST_COLUMN_WIDTH } from './constants'
import useInspectionSheet from './hooks/useInspectionSheet'
import { patchEditedProject } from './store'

const debouncedPatchEditedProject = debounce((dispatch: ReturnType<typeof useAppDispatch>, data: Partial<Project>) => {
  dispatch(patchEditedProject(data))
}, 200)

const InspectionSheet: FC = () => {
  const {
    handleGenerateXLSX,
    isLoading,
    shapes,
    isAllowedToModifyProject,
    isAllowedToModifySheet,
    allAdditionalMetricsToggle,
    sortedInspectionItems,
    rebarsSurfaceArea,
    totalVolume,
    totalArea,
    totalPolyline,
    settingsDrawerRef,
  } = useInspectionSheet()

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editedProject = useSelector((state: RootState) => state.inspectionSheet.editedProject)

  const rootStyleVars = `
  :root {
    --inspection-sheet-first-column-width: ${FIRST_COLUMN_WIDTH}px;
    --inspection-sheet-column-width: ${COLUMN_WIDTH}px;
    --inspection-sheet-diagram-width: ${DIAGRAM_WIDTH}px;
  }
  `

  /**
   * Listen to actions triggered on navbar.
   */
  useEffect(() => {
    const handleEditProject = () => {
      settingsDrawerRef.current?.openDrawer()
    }

    window.addEventListener('edit-project', handleEditProject)

    return () => {
      window.removeEventListener('edit-project', handleEditProject)
    }
  }, [settingsDrawerRef])

  return (
    <PageErrorHandler>
      <style>{rootStyleVars}</style>

      <DataSaver />
      <XLSXExportButton handleGenerateXLSX={handleGenerateXLSX} />

      <VStack id="inspection-sheet" className={[isAllowedToModifySheet ? 'editable' : ''].join(' ')}>
        <HStack zIndex={160} position="relative" w="100%">
          <Navbar />
        </HStack>

        {isLoading ? (
          <LoadingPage text="読み込み中…" />
        ) : (
          <Flex id="inspection-sheet-container">
            <Grid className="scrollable-container">
              {/* Profile group */}
              <HStack borderTop="1px solid var(--chakra-colors-gray-300)" w="100%" p={8} pl={24} gap={6}>
                {/* page title */}
                <Flex color="primary.700" alignItems="end" flexDirection="column">
                  <Text fontSize="4xl" fontWeight="bold" whiteSpace="nowrap">
                    検査帳票
                  </Text>
                  <Text fontSize="xl">出来形表</Text>
                </Flex>

                {/* construction properties */}
                <Flex className="construction-properties">
                  <HStack>
                    <Text width="70px" color="primary.700">
                      工事名
                    </Text>
                    {isAllowedToModifySheet && project ? (
                      <PopoverEditing
                        allowEmpty={false}
                        value={editedProject?.project_name || project.project_name}
                        onChange={(value) => {
                          debouncedPatchEditedProject(dispatch, { project_id: project.project_id, project_name: value })
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </HStack>
                </Flex>

                {/* Summary */}
                <Flex py={4} flexDirection="column">
                  <HStack>
                    <Text width="150px" color="primary.700">
                      体積の合計
                    </Text>
                    <Text>
                      {totalVolume ? `${totalVolume.toLocaleString(undefined, { maximumFractionDigits: 4 })}m³` : '-'}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text width="150px" color="primary.700">
                      面積の合計
                    </Text>
                    <Text>
                      {totalArea ? `${totalArea.toLocaleString(undefined, { maximumFractionDigits: 4 })}m²` : '-'}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text width="150px" color="primary.700">
                      鉄筋の表面積の合計
                    </Text>
                    <Text>
                      {rebarsSurfaceArea
                        ? `${rebarsSurfaceArea.toLocaleString(undefined, { maximumFractionDigits: 4 })}m²`
                        : '-'}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text width="150px" color="primary.700">
                      延長の合計
                    </Text>
                    <Text>
                      {totalPolyline
                        ? `${totalPolyline.toLocaleString(undefined, { maximumFractionDigits: 0 })}mm`
                        : '-'}
                    </Text>
                  </HStack>
                </Flex>
              </HStack>

              {/* Table */}
              {sortedInspectionItems.map(({ area: ar, items: its }) => (
                <InspectionAreaRow
                  key={ar.inspection_area_id}
                  inspectionArea={ar}
                  inspectionItems={its}
                  shapes={shapes}
                  allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                />
              ))}

              <Spacer />
            </Grid>
          </Flex>
        )}
      </VStack>
      {isAllowedToModifyProject && <SettingsDrawer ref={settingsDrawerRef} />}
    </PageErrorHandler>
  )
}

export default InspectionSheet
