import axios, { CanceledError } from 'axios'
import { debounce } from 'lodash'

import { EDITOR_DECIMAL_BASE } from 'config/constants'
import {
  INSPECTION_CONTRACTEE_ERROR_COLOR,
  INSPECTION_CONTRACTOR_ERROR_COLOR,
  INSPECTION_NORMAL_COLOR,
} from 'config/styles'

import {
  InspectionItem,
  InspectionItemNumberValues,
  InspectionItemPreDefinedThresholdsKey,
  InspectionItemPredefinedThresholds,
  InspectionItemUserDefinedThresholds,
  InspectionSheet,
  PlaneInspectionItems,
  ProjectSheetSettings,
} from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_INSPECTION_AREA_API_URL, parseInspectionItemPartitionKey } from './InspectionArea'
import { GET_RPOJECTS_API_URL } from './Projects'
import { meterToMillimeter, millimeterToMeter, roundNumber } from './Util'

export const GET_INSPECTION_SHEET_API_URL = (projectId: string, inspectionAreaId: string, inspectionSheetId?: string) =>
  [GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId), 'inspection-sheets', inspectionSheetId]
    .filter((v) => v !== undefined)
    .join('/')

/**
 * Get inspection sheets for an inspection area.
 * @param access_token
 * @param projectId
 * @param inspectionAreaId
 * @param showErrorModal
 * @returns
 */
export const getInspectionSheets = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  showErrorModal: (message: string) => void,
): Promise<InspectionSheet[]> => {
  const sheets = await axios
    .get<{ results: InspectionSheet[] }>(`${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId)}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_SHEET, showErrorModal)
      return []
    })

  return sheets
}

/**
 * Get all inspection items of an inspection sheet.
 *
 * @param access_token Auth0 access token
 * @param projectId Projekct ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param showErrorModal Function to show error modal
 * @returns
 */
export const getInspectionItems = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  showErrorModal: (message: string) => void,
): Promise<InspectionItem[]> => {
  const sheets = await axios
    .get<{ results: InspectionItem[] }>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/inspection-items`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => {
      let volumeIndex = 1

      return response.data.results.map((row) => {
        const updated = { ...row }
        // generate ID for later use
        if (updated.user_defined_thresholds) {
          updated.user_defined_thresholds.map((obj) => ({ ...obj, key: Date.now() }))
        }

        // generate volume labelling
        if (updated.item_type === 'volume') {
          if (!updated.part_name) updated.part_name = `体積 ${volumeIndex}`
          volumeIndex += 1
        }

        return updated
      })
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_SHEET, showErrorModal)
      return []
    })

  return sheets
}

/**
 * Update an inspection item.
 *
 * @param access_token Access token
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param item Inspection Item to be updated
 * @param showErrorModal
 */
export const updateInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  item: InspectionItem,
  showErrorModal: (message: string) => void,
  signal?: AbortSignal,
): Promise<InspectionItem | null> => {
  if (!item.inspection_item_id) {
    return null
  }

  // Remove any null values as API will reject them
  const preDefinedThresholds: InspectionItemPredefinedThresholds = {}
  if (item.pre_defined_thresholds) {
    ;(Object.keys(item.pre_defined_thresholds) as InspectionItemPreDefinedThresholdsKey[]).forEach((key) => {
      if (typeof item.pre_defined_thresholds![key] === 'number') {
        preDefinedThresholds[key] = item.pre_defined_thresholds![key]
      }
    })
  }

  // Remove id from user-defined fields
  let userDefinedThresholds: InspectionItemUserDefinedThresholds[] = []
  if (item.user_defined_thresholds) {
    userDefinedThresholds = item.user_defined_thresholds.map((row) => ({ name: row.name, value: row.value }))
  }

  return axios
    .patch<InspectionItem>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/inspection-items/${
        item.inspection_item_id
      }`,
      // only send specific keys, not entire inspection item object
      {
        part_name: item.part_name,
        need_update: item.need_update,
        pre_defined_thresholds: preDefinedThresholds,
        user_defined_thresholds: userDefinedThresholds,
        is_shown_on_final_sheet: item.is_shown_on_final_sheet,
        length_with_distance_tool: item.length_with_distance_tool,
      },
      {
        signal,
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (signal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return null
      }

      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return null
    })
}

/**
 * Update inspection item in batches.They must all belong to the same project.
 * They can belong to different inspection areas and inspection sheets.
 *
 * @param access_token Access token
 * @param projectId Project ID
 * @param item Inspection Item to be updated
 * @param showErrorModal
 */
export const updateInspectionItems = async (
  access_token: string,
  projectId: string,
  items: Partial<InspectionItem>[],
  showErrorModal: (message: string) => void,
  signal?: AbortSignal,
): Promise<InspectionItem[] | null> => {
  // Remove any null values as API will reject them
  const data = items
    .map((item) => {
      if (!item.inspection_item_id) {
        return null
      }

      // Extract inspection area and inspection sheet ids, first directly from itself,
      // then if not available from partition_key.
      let ids = { inspectionAreaId: item.inspection_area_id, inspectionSheetId: item.inspection_sheet_id }
      if ((!ids.inspectionAreaId || !ids.inspectionSheetId) && item.partition_key) {
        ids = parseInspectionItemPartitionKey(item.partition_key)
        if (!ids.inspectionAreaId || !ids.inspectionSheetId) {
          return null
        }
      }

      // if we still don't have ids, skip this item
      if (!ids.inspectionAreaId || !ids.inspectionSheetId) {
        return null
      }

      const preDefinedThresholds: InspectionItemPredefinedThresholds = {}
      if (item.pre_defined_thresholds) {
        ;(Object.keys(item.pre_defined_thresholds) as InspectionItemPreDefinedThresholdsKey[]).forEach((key) => {
          if (typeof item.pre_defined_thresholds![key] === 'number') {
            preDefinedThresholds[key] = item.pre_defined_thresholds![key]
          }
        })
      }

      // Remove id from user-defined fields
      let userDefinedThresholds: InspectionItemUserDefinedThresholds[] = []
      if (item.user_defined_thresholds) {
        userDefinedThresholds = item.user_defined_thresholds.map((row) => ({ name: row.name, value: row.value }))
      }

      // only send specific keys, not entire inspection item object
      return {
        inspection_item_id: item.inspection_item_id,
        inspection_area_id: ids.inspectionAreaId,
        inspection_sheet_id: ids.inspectionSheetId,
        params: {
          part_name: item.part_name,
          pre_defined_thresholds: preDefinedThresholds,
          user_defined_thresholds: userDefinedThresholds,
          is_shown_on_final_sheet: item.is_shown_on_final_sheet,
          length_with_distance_tool: item.length_with_distance_tool,
        },
      }
    })
    .filter(Boolean)

  return axios
    .patch<InspectionItem[]>(`${GET_RPOJECTS_API_URL(projectId)}/inspection-items`, data, {
      signal,
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((res) => res.data)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (signal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return null
      }

      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return null
    })
}

/**
 * Debounced version of updateInspectionItems.
 */
export const debouncedUpdateInspectionItems = debounce(
  async (
    getAccessToken: () => Promise<string>,
    project_id: string,
    inspectionItems: Partial<InspectionItem>[],
    showErrorModal: (message: string) => void,
    beforeSaving: () => void,
    onSaved: (result: InspectionItem[]) => void,
    setIsSavingCb: (isSaving: boolean) => void,
  ) => {
    beforeSaving()
    setIsSavingCb(true)
    const token = await getAccessToken()
    if (!token) {
      return
    }

    const result = await updateInspectionItems(token, project_id, inspectionItems, showErrorModal)
    if (!result) {
      return
    }

    onSaved(result)
    setIsSavingCb(false)
  },
  4000,
)

/**
 * Add an inspection item.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param item Inspection Item to be added
 * @param showErrorModal Function to show error modal
 */
export const addInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  item: Partial<InspectionItem>,
  showErrorModal: (message: string) => void,
): Promise<InspectionItem | null> => {
  const result = await addInspectionItems(
    access_token,
    projectId,
    inspectionAreaId,
    inspectionSheetId,
    [item],
    showErrorModal,
  )
  return result ? result[0] : null
}

/**
 * Batch add inspection items.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param items Inspection Items to be added
 * @param showErrorModal Function to show error modal
 */
export const addInspectionItems = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  items: Partial<InspectionItem>[],
  showErrorModal: (message: string) => void,
): Promise<InspectionItem[] | null> => {
  const result = await axios
    .post<InspectionItem[]>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/inspection-items:batchCreate`,
      items,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return null
    })

  return result
}

/**
 * Delete an inspection item.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID of the inspection item
 * @param inspectionAreaId Inspection Area ID of the inspection item
 * @param inspectionSheetId Inspection Sheet ID of the inspection item
 * @param inspectionItemId Inspection Item ID to be deleted
 * @param showErrorModal Function to show error modal
 */
export const deleteInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  inspectionItemId: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete(
      `${GET_INSPECTION_SHEET_API_URL(
        projectId,
        inspectionAreaId,
        inspectionSheetId,
      )}/inspection-items/${inspectionItemId}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return false
    })

  return result
}

/**
 * Delete an inspection item.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID of the inspection item
 * @param inspectionAreaId Inspection Area ID of the inspection item
 * @param inspectionSheetId Inspection Sheet ID of the inspection item
 * @param inspectionItemId Inspection Item ID to be deleted
 * @param showErrorModal Function to show error modal
 */
export const batchDeleteInspectionItem = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  inspectionItemIds: string[],
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .post(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/inspection-items:batchDelete`,
      { inspection_item_ids: inspectionItemIds },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return false
    })

  return result
}

/**
 * Update an inspection sheet.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheet Inspection Sheet to be updated
 * @param showErrorModal Function to show error modal
 */
export const updateInspectionSheet = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheet: InspectionSheet,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  // partion_key is not allowed to be updated
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { inspection_sheet_id, partition_key, ...values } = inspectionSheet

  if (!inspection_sheet_id) {
    return false
  }

  const result = await axios
    .patch<{ results: InspectionSheet[] }>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspection_sheet_id)}`,
      { ...values },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return false
    })

  return result
}

/**
 * Update inspection sheet in batches.
 *
 * @param access_token Auth0 access token
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheets Inspection Sheets to be updated. Must contain inspection_area_id.
 * @param showErrorModal Function to show error modal
 * @returns Updated inspection sheets
 */
export const updateInspectionSheets = async (
  access_token: string,
  projectId: string,
  inspectionSheets: InspectionSheet[],
  showErrorModal?: (message: string) => void,
): Promise<InspectionSheet[] | null> => {
  // Filter out invalid inspection sheets
  const validSheets = inspectionSheets.filter((sheet) => sheet.inspection_sheet_id && sheet.inspection_area_id)
  if (!validSheets.length) {
    return null
  }

  // Prepare data
  const data = validSheets.map(
    ({
      inspection_sheet_id,
      inspection_area_id,
      creator_name,
      create_time_user_specified,
      construction_properties,
      observer_name,
      observe_time_user_specified,
    }) => ({
      inspection_sheet_id,
      inspection_area_id,
      params: {
        creator_name,
        create_time_user_specified,
        observer_name,
        observe_time_user_specified,
        construction_properties,
      },
    }),
  )

  const request = axios
    .patch<InspectionSheet[]>(`${GET_RPOJECTS_API_URL(projectId)}/inspection-sheets`, data, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((res) => res.data)

  if (showErrorModal) {
    return request.catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.MODIFY_INSPECTION_SHEET, showErrorModal)
      return null
    })
  }

  return request
}

// https://github.com/DataLabs-Japan/tekkin-app/issues/26#issuecomment-1309844610
/**
 *
 * @param values
 * @param criterion
 * @param referValue
 * @param needScaleValueUp need to convert meter to millimeter
 * @param emptyIsValid if both estimated and specified values are null, return valid
 * @returns
 *   - null: values are not valid (not exists)
 *   - true: passed
 *   - false: not passed
 */
export const evaluateInspectionItemValue = (
  values: InspectionItemNumberValues | undefined,
  criterion: number,
  referValue?: number | null,
  needScaleValueUp?: boolean,
  emptyIsValid?: boolean,
): { result: boolean | null; description: string } => {
  if (values && emptyIsValid && values.estimated_value === null && values.specified_value === null) {
    return {
      result: true,
      description: '',
    }
  }

  if (values === undefined || values.estimated_value === null || values.specified_value === null) {
    return {
      result: null,
      description: '',
    }
  }

  const result = {
    result: null,
    description: '',
  }

  // values are not valid (not exists)
  if (values.estimated_value === undefined || values.specified_value === undefined) {
    return result
  }

  // Computes criterion_contractee/criterion_contractor using designed value of diameter:
  const criterionReference = referValue !== undefined && referValue !== null ? referValue * criterion : criterion
  // Computes difference of designed/estimated values.
  // The order is estimated value - designed value:
  const difference = values.estimated_value - values.specified_value

  // For description
  const scaledUpDifference = needScaleValueUp ? meterToMillimeter(difference) : difference
  const differenceDescription = `${roundNumber(scaledUpDifference, EDITOR_DECIMAL_BASE)}`

  const scaledUpReferValue = !referValue ? referValue : meterToMillimeter(referValue)
  const scaledUpCriterionReference = needScaleValueUp ? meterToMillimeter(criterionReference) : criterionReference
  const referenceDescription =
    scaledUpReferValue !== undefined && scaledUpReferValue !== null && criterion !== 0
      ? `${roundNumber(scaledUpReferValue, EDITOR_DECIMAL_BASE)} * ${roundNumber(
          criterion,
          EDITOR_DECIMAL_BASE,
        )} = ${roundNumber(scaledUpCriterionReference, EDITOR_DECIMAL_BASE)}`
      : `${criterion}`

  // Check whether the difference is within the criterion
  if (Math.abs(difference) <= criterionReference) {
    return {
      result: true,
      description: `(${differenceDescription}) <= ±(${referenceDescription})`,
    }
  }
  return {
    result: false,
    description: `(${differenceDescription}) > ±(${referenceDescription})`,
  }
}

export const scaleUpValues = (
  values: InspectionItemNumberValues | undefined,
  fallbackSpecifiedValue: number | undefined,
  fallbackEstimatedValue: number | undefined,
) => ({
  specified_value:
    values?.specified_value !== undefined && values.specified_value !== null
      ? meterToMillimeter(values.specified_value)
      : fallbackSpecifiedValue,
  estimated_value:
    values?.estimated_value !== undefined && values.estimated_value !== null
      ? meterToMillimeter(values.estimated_value)
      : fallbackEstimatedValue,
})
export const scaleDownValues = (values: InspectionItemNumberValues) => ({
  specified_value:
    values.specified_value !== undefined && values.specified_value !== null
      ? millimeterToMeter(values.specified_value)
      : undefined,
  estimated_value:
    values.estimated_value !== undefined && values.estimated_value !== null
      ? millimeterToMeter(values.estimated_value)
      : undefined,
})

export const evaluateValues = (
  values: InspectionItemNumberValues | null | undefined,
  contracteeCriterion: number,
  contractorCriterion: number,
  contracteeReferValue?: number | null,
  contractorReferValue?: number | null,
  needScaleValueUp?: boolean,
  emptyIsValid?: boolean,
): { color: string; description: string } => {
  if (values === null || values === undefined) {
    return { color: INSPECTION_NORMAL_COLOR, description: '' }
  }

  const contracteeResult = evaluateInspectionItemValue(
    values,
    contracteeCriterion,
    contracteeReferValue,
    needScaleValueUp,
    emptyIsValid,
  )
  if (!contracteeResult.result) {
    return { color: INSPECTION_CONTRACTEE_ERROR_COLOR, description: contracteeResult.description }
  }

  const contractorResult = evaluateInspectionItemValue(
    values,
    contractorCriterion,
    contractorReferValue,
    needScaleValueUp,
    emptyIsValid,
  )
  if (!contractorResult.result) {
    return { color: INSPECTION_CONTRACTOR_ERROR_COLOR, description: contractorResult.description }
  }

  return { color: INSPECTION_NORMAL_COLOR, description: contracteeResult.description || contractorResult.description }
}

/**
 * Checks if any of the plane item is shown on the final sheet.
 */
export const isAnyPlaneItemShownOnFinalSheet = (plane: PlaneInspectionItems): boolean =>
  plane.area?.is_shown_on_final_sheet ||
  plane.perimeter?.is_shown_on_final_sheet ||
  plane.length1?.is_shown_on_final_sheet ||
  plane.length2?.is_shown_on_final_sheet ||
  false

/**
 * Find an inspection sheet by inspection area ID from a list of inspection sheets.
 * The inspection sheet list must contain partition_key.
 *
 * @param sheets Inspection sheets
 * @param inspectionAreaId Inspection area ID
 * @returns Inspection sheet or null if not found
 */
export const findInspectionAreaSheet = (sheets: InspectionSheet[], inspectionAreaId: string): InspectionSheet | null =>
  sheets.find((sheet) => {
    if (!sheet.partition_key) return null

    const ids = parseInspectionItemPartitionKey(sheet.partition_key)
    return ids.inspectionAreaId === inspectionAreaId
  }) || null

/**
 * Get headers for diagram section of the inspection sheet.
 *
 * @param settings Inspection sheet settings
 */
export const getDiagramHeaders = (
  settings: ProjectSheetSettings,
  hasGrid = true,
): { planeHeader: string | null; gridHeader: string | null } => {
  // Combined header for Plane + Polyline can change depending on settings
  const planeHeader = (): string | null => {
    if (
      !settings.sheet_diagram_visibility ||
      (settings.sheet_diagram_visibility.plane_diagram && settings.sheet_diagram_visibility.polyline_diagram)
    ) {
      return '平面図・延長図'
    }

    if (
      hasGrid &&
      settings.sheet_diagram_visibility.grid_diagram &&
      settings.sheet_diagram_visibility.polyline_diagram
    ) {
      return 'グリッド図面・延長図'
    }

    if (settings.sheet_diagram_visibility.polyline_diagram) {
      return '延長図'
    }

    if (settings.sheet_diagram_visibility.plane_diagram) {
      return '平面図'
    }

    return null
  }

  // Combined header for Grid + Polyline can change depending on settings
  const gridHeader = (): string | null => {
    // if there's only grid left
    if (
      !settings.sheet_diagram_visibility ||
      (settings.sheet_diagram_visibility.grid_diagram && !settings.sheet_diagram_visibility.polyline_diagram)
    ) {
      return 'グリッド図面'
    }

    // if plane is hidden, grid gets 'moved' to plane header
    // and of course if grid is hidden, header should be hidden too
    if (!settings.sheet_diagram_visibility.plane_diagram || !settings.sheet_diagram_visibility.grid_diagram) {
      return null
    }

    return 'グリッド図面'
  }

  return { planeHeader: planeHeader(), gridHeader: gridHeader() }
}
