import { useRef, useState } from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'
import { clamp } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import { INFO_PANEL_PADDING } from 'config/styles'

import { IntervalsAxisConfig } from 'interfaces/inspectionItemGrid'

import { calculateInterval, calculateMaxPointCount, validateAxisConfigValue } from '../../utils'

let beforeChange: IntervalsAxisConfig | null = null

const EdgeBasedAxisConfig = ({
  side,
  axisConfig,
  selectedVolumeId,
  patchAxisConfig,
  onMouseIn,
  onMouseOut,
}: {
  side: string
  axisConfig: IntervalsAxisConfig
  selectedVolumeId: string
  patchAxisConfig: (axisConfig: Partial<IntervalsAxisConfig>) => void
  onMouseIn?: () => void
  onMouseOut?: () => void
}) => {
  // Refs
  const edgeDistanceInputRef = useRef<HTMLInputElement>(null)
  const edgeDistanceSliderTrackRef = useRef<HTMLDivElement>(null)
  const edgeDistanceSliderThumbRef = useRef<HTMLDivElement>(null)

  const pointCountInputRef = useRef<HTMLInputElement>(null)
  const pointCountSliderTrackRef = useRef<HTMLDivElement>(null)
  const pointCountSliderThumbRef = useRef<HTMLDivElement>(null)

  // States
  // max point count is managed separately as well due to side-effect of debounced patchAxisConfig.
  const [pointCountMax, setPointCountMax] = useState(axisConfig.pointCount.max)

  /**
   * Manually update point count input value.
   * @param value - New value.
   */
  const updatePointCountInput = (value: number) => {
    if (pointCountInputRef.current) {
      pointCountInputRef.current.value = value.toString()
    }
  }

  /**
   * Manually update point count slider.
   *
   * @param value - New value.
   */
  const updatePointCountSlider = (value: number, max?: number) => {
    const maxValue = max !== undefined ? max : axisConfig.pointCount.max
    if (
      pointCountSliderTrackRef.current?.children[0] instanceof HTMLDivElement &&
      pointCountSliderThumbRef.current instanceof HTMLDivElement
    ) {
      const perc = (value / maxValue) * 100
      pointCountSliderTrackRef.current.children[0].style.width = `${perc}%`
      pointCountSliderThumbRef.current.style.left = `calc(${perc}% - var(--slider-thumb-size) / 2)`
    }

    if (max !== undefined) {
      setPointCountMax(max)
    }
  }

  /**
   * Update point count input fields.
   *
   * @param value - New value.
   */
  const updatePointCount = useDebouncedCallback((value: number, max?: number) => {
    updatePointCountInput(value)
    updatePointCountSlider(value, max)
  }, 200)

  return (
    <>
      <Flex gap={0} px={INFO_PANEL_PADDING} pt={INFO_PANEL_PADDING} onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}>
        <FormControl w="50%" isInvalid={!validateAxisConfigValue(axisConfig.edgeDistance)}>
          <Text>{side}の端からの距離</Text>
          <InputGroup size="sm">
            <Input
              ref={edgeDistanceInputRef}
              type="number"
              size="sm"
              defaultValue={axisConfig.edgeDistance.value}
              min={axisConfig.edgeDistance.min}
              max={axisConfig.edgeDistance.max}
              background="gray.700"
              borderColor="whiteAlpha.200"
              borderRadius="5px 0 0 5px"
              isDisabled={!selectedVolumeId}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10)
                const maxPointCount = calculateMaxPointCount(axisConfig.total, value)
                const pointCount = clamp(axisConfig.pointCount.value, axisConfig.pointCount.min, maxPointCount)
                patchAxisConfig({
                  edgeDistance: {
                    ...axisConfig.edgeDistance,
                    value,
                  },
                  pointCount: {
                    ...axisConfig.pointCount,
                    max: maxPointCount,
                    value: pointCount,
                  },
                  interval: {
                    ...axisConfig.interval,
                    value: calculateInterval(axisConfig.total, value, axisConfig.pointCount.value),
                  },
                })

                // We're not using controlled component so update sliders manually
                if (
                  edgeDistanceSliderTrackRef.current?.children[0] instanceof HTMLDivElement &&
                  edgeDistanceSliderThumbRef.current instanceof HTMLDivElement
                ) {
                  const perc = (value / axisConfig.edgeDistance.max) * 100
                  edgeDistanceSliderTrackRef.current.children[0].style.width = `${perc}%`
                  edgeDistanceSliderThumbRef.current.style.left = `calc(${perc}% - var(--slider-thumb-size) / 2)`
                }

                updatePointCount(pointCount, maxPointCount)
              }}
            />
            <InputRightElement fontSize="sm" px={5}>
              mm
            </InputRightElement>
          </InputGroup>
          <Box mt={2} mx={2}>
            <Slider
              defaultValue={axisConfig.edgeDistance.value}
              min={axisConfig.edgeDistance.min}
              max={axisConfig.edgeDistance.max}
              step={1}
              isDisabled={!selectedVolumeId}
              onChange={(value) => {
                const maxPointCount = calculateMaxPointCount(axisConfig.total, value)
                const pointCount = clamp(
                  Math.max(axisConfig.pointCount.value, beforeChange?.pointCount.value || 0),
                  axisConfig.pointCount.min,
                  maxPointCount,
                )
                patchAxisConfig({
                  edgeDistance: {
                    ...axisConfig.edgeDistance,
                    value,
                  },
                  pointCount: {
                    ...axisConfig.pointCount,
                    max: maxPointCount,
                    value: pointCount,
                  },
                  interval: {
                    ...axisConfig.interval,
                    value: calculateInterval(axisConfig.total, value, axisConfig.pointCount.value),
                  },
                })

                if (edgeDistanceInputRef.current) {
                  edgeDistanceInputRef.current.value = value.toString()
                }

                updatePointCount(pointCount, maxPointCount)
              }}
              onChangeStart={() => {
                beforeChange = { ...axisConfig }
              }}
              focusThumbOnChange={false}
            >
              <SliderTrack ref={edgeDistanceSliderTrackRef}>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb ref={edgeDistanceSliderThumbRef} />
            </Slider>
          </Box>
          <FormErrorMessage fontSize="xs">
            {axisConfig.edgeDistance.min}~{axisConfig.edgeDistance.max}
            の間の値を設定してください
          </FormErrorMessage>
        </FormControl>

        <FormControl w="50%" isInvalid={!validateAxisConfigValue(axisConfig.pointCount)}>
          <Text textAlign="right">{side}の点の数</Text>
          <InputGroup size="sm">
            <Input
              ref={pointCountInputRef}
              type="number"
              size="sm"
              defaultValue={axisConfig.pointCount.value}
              min={axisConfig.pointCount.min}
              max={pointCountMax}
              background="gray.700"
              borderColor="whiteAlpha.200"
              borderRadius="0 5px 5px 0"
              isDisabled={!selectedVolumeId}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10)
                patchAxisConfig({
                  pointCount: {
                    ...axisConfig.pointCount,
                    value,
                  },
                  interval: {
                    ...axisConfig.interval,
                    value: calculateInterval(axisConfig.total, axisConfig.edgeDistance.value, value),
                  },
                })

                // We're not using controlled component so update sliders manually
                updatePointCountSlider(value)
              }}
            />
            <InputRightElement fontSize="sm" px={5}>
              点
            </InputRightElement>
          </InputGroup>
          <Box mt={2} mx={2}>
            <Slider
              defaultValue={axisConfig.pointCount.value}
              min={axisConfig.pointCount.min}
              max={pointCountMax}
              step={1}
              isDisabled={!selectedVolumeId}
              onChange={(value) => {
                patchAxisConfig({
                  pointCount: {
                    ...axisConfig.pointCount,
                    value,
                  },
                  interval: {
                    ...axisConfig.interval,
                    value: calculateInterval(axisConfig.total, axisConfig.edgeDistance.value, value),
                  },
                })

                updatePointCountInput(value)
              }}
              focusThumbOnChange={false}
            >
              <SliderTrack ref={pointCountSliderTrackRef}>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb ref={pointCountSliderThumbRef} />
            </Slider>
          </Box>
          <FormErrorMessage fontSize="xs">
            {axisConfig.pointCount.min}~{axisConfig.pointCount.max}
            の間の値を設定してください
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Box px={INFO_PANEL_PADDING} pb={INFO_PANEL_PADDING}>
        <Flex justifyContent="space-between">
          <Text>点の間隔</Text>
          <Text>{axisConfig.interval.value}mm</Text>
        </Flex>
      </Box>
    </>
  )
}

export default EdgeBasedAxisConfig
