import { User } from '@auth0/auth0-react'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setUser as setSentryUser } from '@sentry/react'

import { USER_TYPES } from 'config/constants'

import { UserInfo } from 'interfaces/user'

export interface UserState {
  /**
   * Whether the user has been loaded (both from Auth0 and from the DB).
   */
  userLoaded: boolean

  /**
   * Auth0 user object.
   */
  user: User

  /**
   * User profile from DB.
   */
  userProfile: UserInfo | null

  /**
   * User type from DB.
   */
  userType: string

  /**
   * Whether the user has a deprecated subscription.
   */
  hasDeprecatedSubscription: boolean | null
}

const initialState: UserState = {
  userLoaded: false,
  user: {},
  userProfile: null,
  userType: USER_TYPES.NON_PAYING_USER,
  hasDeprecatedSubscription: null,
}

export const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      setSentryUser({
        id: action.payload.sub,
        email: action.payload.email,
        'Email Domain': action.payload.email?.split('@')[1],
      })
    },
    setUserProfile: (state, action: PayloadAction<UserInfo>) => {
      state.userProfile = action.payload
      state.userType = action.payload.user_type
    },
    setUserLoaded: (state) => {
      state.userLoaded = true
    },
    setHasDeprecatedSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasDeprecatedSubscription = action.payload
    },
  },
})

export const { setUser, setUserProfile, setUserLoaded, setHasDeprecatedSubscription } = slice.actions

export default slice.reducer
