import { FC, useEffect } from 'react'

import { merge } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { InspectionItem } from 'interfaces/inspection'

/**
 * A component where its sole purpose is to generate an XLSX file.
 * This is separated from the main component to avoid the main component
 * from subscribing to the store of edited and latest inspection items.
 * Pulling this data on the parent component would cause re-render on every child,
 * causing severe performance issue.
 *
 * @param handleGenerateXLSX Function to generate XLSX file
 * @returns
 */
const XLSXExportButton: FC<{
  handleGenerateXLSX: (bleedingEdgeItems: InspectionItem[]) => Promise<void>
}> = ({ handleGenerateXLSX }) => {
  const inspectionItems = useSelector((state: RootState) => state.inspectionSheet.inspectionItems)
  const latestEditedValues = useSelector((state: RootState) => state.inspectionSheet.latestInspectionItems)
  const editedInspectionItems = useSelector((state: RootState) => state.inspectionSheet.editedInspectionItems)

  useEffect(() => {
    const handler = () =>
      handleGenerateXLSX(
        inspectionItems.map((item) =>
          merge(
            {},
            item,
            latestEditedValues.find((latestItem) => latestItem.inspection_item_id === item.inspection_item_id),
            editedInspectionItems.find((editedItem) => editedItem.inspection_item_id === item.inspection_item_id),
          ),
        ),
      )

    window.addEventListener('export-xlsx', handler)

    return () => {
      window.removeEventListener('export-xlsx', handler)
    }
  }, [inspectionItems, latestEditedValues, editedInspectionItems, handleGenerateXLSX])

  return null
}

export default XLSXExportButton
