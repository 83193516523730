import './Editor.css'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Button, Flex, Input, Text, VStack } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import LoadingPage from 'components/LoadingPage'
import PageErrorHandler from 'components/PageErrorHandler'
import { difference, uniq } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'
import { IoWarning } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { patchInspectionAreas, setInspectionArea } from 'store/page'
import { PerspectiveCamera, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { AddIcon } from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { useUploadPcd } from 'hooks/uploadPcd'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

import {
  COOKIE_EXPIRE,
  DEFAULT_EDITOR_TOOL,
  EDITOR_BACKGROUND_COOKIE_NAME,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_TOOLS,
  EDITOR_TOOL_KEYS,
  MODAL_TYPES,
  USER_TYPES,
} from 'config/constants'
import { EDITOR_DEFAULT_BACKGROUND } from 'config/styles'

import { Editor as EditorInterface } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import {
  AnchorPoints,
  Anchors,
  CuboidDirection,
  InspectionItem,
  InspectionSheet,
  MeshPoints,
  MeshRefs,
  PointArray,
  Polygon,
  Shapes,
} from 'interfaces/interfaces'

import { getShapes } from 'services/InspectionArea'
import { getInspectionItems, getInspectionSheets } from 'services/InspectionSheet'
import { getMaskingRegions } from 'services/MaskingRegion'
import { findNormalTowardsB } from 'services/Shape'
import { needClearAnchorFrames } from 'services/Util'
import { decideActionPermission } from 'services/Validation'
import {
  findCylindersWithinVolumePolygons,
  getVolumeEstimationItem,
  getVolumeEstimationItems,
} from 'services/VolumeEstimation'

import AttentionText from '../common/AttentionText/AttentionText'
import { setAttentionText } from '../common/AttentionText/store/attentionText'
import { useComments } from '../common/Comments/hooks/comments'
import Navbar from '../common/Navbar'
import { useEditor } from './hooks/editor'
import SidePanels from './infoPanels/InfoPanels'
import MainCanvas from './mainCanvas/MainCanvas'
import { setCuboidAnchor, setEditingCuboid } from './shapes/cuboid/store'
import {
  CursorState,
  forceOpenToolPanel,
  setCursor,
  setHidePlaneLabel,
  setIsDragging,
  setJobRunning,
  setSelectedElementIds,
} from './store/editor'
import SubToolbar from './toolbar/SubToolbar'
import Toolbar from './toolbar/Toolbar'
import TransformControlsToolbar from './toolbar/TransformControls'
import { resetWorking as resetWorkingGrid } from './tools/Grid/store'
import { setMaskRegions } from './tools/MaskPCD/store'
import { reset as resetPlaneDetection } from './tools/PlaneDetection/store'
import { resetWorkingPolylines } from './tools/Polyline/store'
import { reset as resetRebarDetection } from './tools/RebarDetection/store'
import { reset as resetVolumeEstimation } from './tools/VolumeEstimation/Polygon/store'
import { Tools, getCurrentEditorTool } from './tools/setup'

const Editor: FC = () => {
  // Router
  const location = useLocation()
  const navigate = useNavigate()
  const { project_id } = useParams<{ project_id: string }>()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Hooks
  useComments()
  useEditor()
  const { processSelectedFile, uploadPcd } = useUploadPcd()

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const userType = useSelector((state: RootState) => state.user.userType) as keyof typeof USER_TYPES
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const isJobRunning = useSelector((state: RootState) => state.editor.isJobRunning)

  // Permissions
  const isAllowedToModify = decideActionPermission(isOwner, isInvited).PROJECT_DASHBOARD.MODIFY.includes(userType)

  // Page title
  useDocumentTitle(
    `${inspectionArea?.inspection_area_name || 'Hatsuly'}${project ? ` · ${project?.project_name}` : ''}`,
  )

  // Tool Data
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const toolVolumePolygonIsDirty = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isDirty)
  const toolRebarDetectionIsDirty = useSelector((state: RootState) => state.toolRebarDetection.isDirty)
  const toolPlaneDetectionIsDirty = useSelector((state: RootState) => state.toolPlaneDetection.isDirty)
  const toolPolylineIsDirty = useSelector((state: RootState) => state.toolPolyline.isDirty)
  const toolCameraProfileIsDirty = useSelector((state: RootState) => state.toolCameraProfile.isDirty)

  const editorRef = useRef<HTMLDivElement>(null)
  const cameraRef = useRef<PerspectiveCamera>(null)
  const arcballControlsRef = useRef<ArcballControls>(null)

  // File upload
  const uploadFileRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  //* 工事データアクセス用
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const [cookies, setCookie] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])

  // Editor
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const [initCompleted, setInitCompleted] = useState(false)

  // Toolbar
  const [selectedTool, setSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [selectedSubTool, setSelectedSubTool] = useState<string>()
  const [prevSelectedTool, setPrevSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [prevPrevSelectedTool, setPrevPrevSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [isPointCloudInvisible, setIsPointCloudInvisible] = useState(false)
  const [pointSize, setPointSize] = useState<number>()
  const [cuboidDirection, setCuboidDirection] = useState<CuboidDirection>()
  const [collidingShapeIds, setCollidingShapeIds] = useState<string[]>([])

  // Project
  const [inspectionSheet, setInspectionSheet] = useState<InspectionSheet>()
  const [inspectionItems, setInspectionItems] = useState<InspectionItem[]>([])

  // Data states
  const [anchors, setAnchors] = useState<Anchors>(INITIAL_SHAPE_STATE())
  const [distanceAnchors, setDistanceAnchors] = useState<AnchorPoints[]>([])
  const [shapes, setShapes] = useState<Shapes>(INITIAL_SHAPE_STATE())
  const [processingAnchor, setProcessingAnchor] = useState<PointArray>()
  const [isToolProcessing, setIsToolProcessing] = useState(false)
  const [meshRefs, setMeshRefs] = useState<MeshRefs>()
  const [meshPoints, setMeshPoints] = useState<MeshPoints>({})
  const [selectionBoundaryBoxVectors] = useState<Vector3[][]>([])

  //* Prefixed cube positions
  const [movingPrefixedPosition, setMovingPrefixedPosition] = useState('')

  const fetchInspectionSheet = useCallback(async () => {
    if (!project_id || !inspection_area_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const inspectionSheets = await getInspectionSheets(token, project_id, inspection_area_id, showErrorModal)

    if (!inspectionSheets.length || !inspectionSheets[0].inspection_sheet_id) {
      showErrorModal('帳票が存在しません。')
      return false
    }

    setInspectionSheet(inspectionSheets[0])

    return true
  }, [project_id, inspection_area_id, getAccessToken, showErrorModal])

  const fetchInspectionItems = useCallback(async () => {
    if (!project_id || !inspection_area_id || !inspectionSheet) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const items = await getInspectionItems(
      token,
      project_id,
      inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      showErrorModal,
    )

    setInspectionItems(items)

    return true
  }, [project_id, inspection_area_id, inspectionSheet, getAccessToken, showErrorModal])

  useEffect(() => {
    if (inspectionSheet) void fetchInspectionItems()
  }, [inspectionSheet, fetchInspectionItems])

  /**
   * Given 2 planes, find all cylinders that are within the volume of the 2 planes
   * @param planes Array of plane shape ids
   */
  const findCylindersWithinVolumePlanes = useCallback(
    (planes: string[]): string[] => {
      const polygons = shapes.polygons.filter((shape) => planes.includes(shape.shape_id))
      const cylinders = findCylindersWithinVolumePolygons(polygons as [Polygon, Polygon], shapes.cylinders)
      return cylinders.map((cylinder) => cylinder.shape_id)
    },
    [shapes],
  )

  /**
   * Add or remove additional shape ids when selecting a shape
   * TODO: Figure out how to do in modules.
   * @param shapeIds All shapeIDs to be selected including current ones
   */
  const addAdditionalSelectedShapeIds = useCallback(
    (shapeIds: string[], newTool?: string): string[] => {
      let newCollection = [...shapeIds]
      const volumeItems = getVolumeEstimationItems(inspectionItems)
      const currentTool = getCurrentEditorTool(newTool || selectedTool)

      if (volumeItems.length) {
        // If tool is configured only for single selectable volume,
        // when there's more than 1 volume selected, deselect all except for one.
        if (currentTool?.config?.volume?.onlyOneSelectable) {
          const selectedVolumesByPlanes = newCollection.reduce<Record<string, number>>((collection, id) => {
            const selectedVolume = volumeItems.find((item) => (item.shape_ids.polygons || []).includes(id))
            if (selectedVolume) {
              if (collection[selectedVolume.inspection_item_id!]) {
                collection[selectedVolume.inspection_item_id!] += 1
              } else {
                collection[selectedVolume.inspection_item_id!] = 1
              }
            }

            return collection
          }, {})

          // Filter shape ids to last selected volume
          if (Object.keys(selectedVolumesByPlanes).length > 1) {
            const selectedVolumeIds = Object.keys(selectedVolumesByPlanes)
            const lastSelectedVolumeId = selectedVolumeIds[selectedVolumeIds.length - 1]
            newCollection = newCollection.filter((id) => {
              const selectedVolume = volumeItems.find((item) => (item.shape_ids.polygons || []).includes(id))
              return selectedVolume?.inspection_item_id === lastSelectedVolumeId
            })
          }
        }

        // ## Add both planes of volume estimation planes if one is selected
        const missingPlanes = volumeItems
          .map((item) => (item.shape_ids.polygons || [])?.filter((value) => !shapeIds.includes(value)))
          .filter((arr) => arr.length === 1)
          .flat()
        const toRemove = !!missingPlanes.filter((value) => selectedElementIds.includes(value)).length

        if (missingPlanes.length === 1) {
          // add all missing plane ids to the collection
          newCollection = newCollection.concat(missingPlanes)

          // user is un-selecting a volume plane
          if (toRemove) {
            const toRemoveIds = volumeItems
              .filter((item) => (item.shape_ids.polygons || [])?.some((value) => missingPlanes.includes(value)))
              .map((item) => item.shape_ids.polygons || [])
              .flat()
            newCollection = newCollection.filter((value) => !toRemoveIds.includes(value))
          }
        }

        // If we're on depth estimation, add or remove cylinders located within the selected volume
        if (selectedTool === EDITOR_TOOLS.DEPTH || newTool === EDITOR_TOOLS.DEPTH) {
          // find out if all new selected shapes are volume planes
          const newShapes = difference(newCollection, selectedElementIds)
          const areAllNewVolumePlanes =
            newShapes.length > 0 &&
            newShapes.every((id) => volumeItems.some((item) => (item.shape_ids.polygons || []).includes(id)))

          // find out if all deselected shapes are volume planes
          const removedShapes = difference(selectedElementIds, newCollection)
          const areAllRemovedVolumePlanes =
            removedShapes.length > 0 &&
            removedShapes.every((id) => volumeItems.some((item) => (item.shape_ids.polygons || []).includes(id)))

          // we need to group it by volume so we don't accidentally merge planes of different volume.
          if (areAllRemovedVolumePlanes) {
            const deselectedVolumes = volumeItems
              .filter((item) => (item.shape_ids.polygons || [])?.some((value) => removedShapes.includes(value)))
              .map((item) => item.shape_ids.polygons || [])
            const deselectedCylinders = deselectedVolumes.map((vol) => findCylindersWithinVolumePlanes(vol))
            newCollection = newCollection.filter((id) => !deselectedCylinders.flat().includes(id))
          }

          // If we're on depth estimation, add all cylinders located within the selected plane
          // this only applies when changing tool or selecting a plane
          if (areAllNewVolumePlanes) {
            // we need to group it by volume so we don't accidentally merge planes of different volume.
            const selectedVolumes = volumeItems
              .filter((item) => (item.shape_ids.polygons || [])?.some((value) => newShapes.includes(value)))
              .map((item) => item.shape_ids.polygons || [])

            newCollection = uniq(
              newCollection.concat(selectedVolumes.map((vol) => findCylindersWithinVolumePlanes(vol)).flat()),
            )
          }
        }
      }

      return newCollection
    },
    [inspectionItems, selectedElementIds, selectedTool, findCylindersWithinVolumePlanes],
  )

  /**
   * Set shape IDs, optionally adding any related shapes as well.
   */
  const changeSelectedShapeIds = useCallback(
    (shapeIds: string[]) => {
      dispatch(setSelectedElementIds(addAdditionalSelectedShapeIds(shapeIds)))
    },
    [addAdditionalSelectedShapeIds, dispatch],
  )

  /**
   * Filters or add shapes based on currently selected shapes.
   */
  const filterOrAddSelectedShapeIds = useCallback(
    (newTool?: string) => {
      dispatch(setSelectedElementIds(addAdditionalSelectedShapeIds(selectedElementIds, newTool)))
    },
    [selectedElementIds, addAdditionalSelectedShapeIds, dispatch],
  )

  /**
   * Fetch all shapes
   */
  const fetchShapes = useCallback(async () => {
    if (!project_id || !inspection_area_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const newShapes = await getShapes(token, project_id, inspection_area_id, showErrorModal)
    if (!newShapes) {
      return false
    }

    setShapes(newShapes)

    return true
  }, [project_id, inspection_area_id, setShapes, getAccessToken, showErrorModal])

  //* Clean the anchor picking status
  //* Should be called before changing tool
  const resetState = useCallback(() => {
    const shapeKey = EDITOR_TOOL_KEYS[selectedTool]

    if (shapeKey) {
      const requiredAnchors = EDITOR_REQUIRED_ANCHORS[shapeKey]
      const anchorPoints = [...anchors[shapeKey]]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setAnchors({ ...anchors, [shapeKey]: anchorPoints })
      }
    } else if (selectedTool === EDITOR_TOOLS.DISTANCE) {
      const requiredAnchors = 2
      const anchorPoints = [...distanceAnchors]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setDistanceAnchors(anchorPoints)
      }
    }

    setIsToolProcessing(false)
    dispatch(setIsDragging(false))
    dispatch(setAttentionText({ message: '' }))
  }, [selectedTool, anchors, processingAnchor, distanceAnchors, dispatch])

  /**
   * Check if the tool is the previous tool, or the tool before the previous tool
   * if the previous tool is FOCUS.
   */
  const isPreviousTool = useCallback(
    (tool: string) =>
      prevSelectedTool === tool || (prevSelectedTool === EDITOR_TOOLS.FOCUS && prevPrevSelectedTool === tool),
    [prevSelectedTool, prevPrevSelectedTool],
  )

  const finalizeChangingTool = useCallback(
    (tool: string) => {
      // TODO: Move to tool module
      if (tool === EDITOR_TOOLS.COMMENT) {
        dispatch(setCursor(CursorState.CROSSHAIR))
      } else if (selectedTool === EDITOR_TOOLS.COMMENT) {
        dispatch(setCursor(CursorState.DEFAULT))
      }

      if (tool !== EDITOR_TOOLS.MOVE) {
        dispatch(forceOpenToolPanel())
      }

      setPrevPrevSelectedTool(prevSelectedTool)
      setPrevSelectedTool(selectedTool)
      setSelectedTool(tool)
      const currentTool = getCurrentEditorTool(tool)

      if (currentTool?.config?.volume?.onlyOneSelectable) {
        filterOrAddSelectedShapeIds(tool)
      }

      mixpanel.track('Change tool', {
        'Tool (new)': tool,
        'Tool (old)': selectedTool,
      })
    },
    [selectedTool, prevSelectedTool, filterOrAddSelectedShapeIds, dispatch],
  )

  /**
   * Reset all tools and states
   */
  const resetTools = useCallback(() => {
    setAnchors(INITIAL_SHAPE_STATE())
    dispatch(setCuboidAnchor(undefined))
    dispatch(setEditingCuboid(undefined))
    dispatch(setHidePlaneLabel(false))

    // TODO: Some of this is already done in their respective useEditor hooks.
    //       Move the rest of the logic there and remove this.
    dispatch(resetWorkingGrid())
    dispatch(resetVolumeEstimation())
    dispatch(resetRebarDetection())
    dispatch(resetPlaneDetection())
    dispatch(resetWorkingPolylines())
  }, [dispatch])

  const changeTool = useCallback(
    (tool: string, skipClearAnchorFramesChecking?: boolean) => {
      resetState()

      if (
        isJobRunning &&
        // TODO: to be replaced with non-blocking operations
        [
          EDITOR_TOOLS.MOVE,
          EDITOR_TOOLS.VOLUME_POLYGON,
          EDITOR_TOOLS.PLANE,
          EDITOR_TOOLS.POLYLINE,
          EDITOR_TOOLS.CYLINDER,
          EDITOR_TOOLS.PLANE,
          EDITOR_TOOLS.DEPTH,
          EDITOR_TOOLS.GRID,
          EDITOR_TOOLS.PCD_TRIM_CUBOID,
        ].includes(tool)
      ) {
        showErrorModal('データを更新中です。完了後に再度実行してください。')
        return
      }

      // need volume to use tool
      const volumeInspectionItem = getVolumeEstimationItem(inspectionItems)
      const toolRequireVolume = Tools.find((t) => t.key === tool)?.config?.volume?.required || false
      if (toolRequireVolume && !volumeInspectionItem) {
        showErrorModal('体積測定を先に実施してください。')
        return
      }

      // need cylinder to use tool
      const hasCylinders = shapes.cylinders?.length > 0
      const toolRequireCylinder = Tools.find((t) => t.key === tool)?.config?.cylinder?.required || false
      if (toolRequireCylinder && !hasCylinders) {
        showErrorModal('先に鉄筋を検出してください。')
        return
      }

      // clear guidelines if any
      if (
        !skipClearAnchorFramesChecking &&
        needClearAnchorFrames(
          tool,
          cuboidAnchor,
          intervals,
          toolVolumePolygonIsDirty,
          toolRebarDetectionIsDirty,
          toolPlaneDetectionIsDirty,
          toolPolylineIsDirty,
          toolCameraProfileIsDirty,
        )
      ) {
        showModal({
          modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
          body: '検出を破棄しますか？',
          confirmText: '破棄',
          cancelText: 'キャンセル',
          onConfirm: () => {
            resetTools()
            finalizeChangingTool(tool)
            mixpanel.track('Reset tool', {
              Tool: tool,
              Reason: 'Change tool',
            })
            return true
          },
        })
      } else {
        finalizeChangingTool(tool)
      }
    },
    [
      cuboidAnchor,
      inspectionItems,
      isJobRunning,
      intervals,
      shapes,
      toolRebarDetectionIsDirty,
      toolVolumePolygonIsDirty,
      toolPlaneDetectionIsDirty,
      toolPolylineIsDirty,
      toolCameraProfileIsDirty,
      resetTools,
      finalizeChangingTool,
      resetState,
      showErrorModal,
      showModal,
    ],
  )

  /**
   * Focus the camera on the anchor point
   */
  const focusCamera = useCallback(
    (anchorPoint: PointArray | undefined) => {
      if (!anchorPoint || !cameraRef.current || !arcballControlsRef.current) return

      // Save the current position and up vector of the camera
      const currentCameraPosition = cameraRef.current.position.clone()
      const up = cameraRef.current.up.clone()

      // distance from the camera to the anchor point
      const cameraToAnchor = new Vector3(...anchorPoint).sub(currentCameraPosition.clone())

      // angle between the camera's forward vector and the vector from the camera to the anchor point
      const angle = cameraToAnchor.angleTo(new Vector3(0, 0, -1).applyQuaternion(cameraRef.current.quaternion.clone()))
      // center position of viewing plane calculated from the camera's position and the distance to the anchor point
      const centerOfTheClickedViewingPlane = currentCameraPosition
        .clone()
        .sub(
          new Vector3(0, 0, cameraToAnchor.length() * Math.cos(angle)).applyQuaternion(
            cameraRef.current.quaternion.clone(),
          ),
        )

      // Set the target of the camera to the clicked point
      const newCameraPosition = currentCameraPosition
        .clone()
        .add(new Vector3(...anchorPoint))
        .sub(centerOfTheClickedViewingPlane)
      arcballControlsRef.current.setTarget(...anchorPoint)
      arcballControlsRef.current.reset()
      // Set the camera position and up vector back to the original
      cameraRef.current.position.set(newCameraPosition.x, newCameraPosition.y, newCameraPosition.z)
      cameraRef.current.up.set(up.x, up.y, up.z)
    },
    [cameraRef, arcballControlsRef],
  )

  /**
   * Focus the camera on the anchor point based on a specified normal vector.
   *
   * @param point The point to focus the camera on
   * @param normal The normal vector to point the camera towards
   * @param right The right vector of the camera
   * @param distance The distance from the target point
   */
  const focusCameraDirectional = useCallback(
    (point: PointArray, normal: Vector3, right: Vector3, distance?: number) => {
      if (!point || !cameraRef.current || !arcballControlsRef.current) return

      // Save the current position and up vector of the camera
      const point3 = new Vector3(...point)
      const currentCameraPosition = cameraRef.current.position.clone()

      // Get forward vector of the camera
      const cameraForwardVector = findNormalTowardsB(currentCameraPosition, point3, normal)

      // Calculate the new camera position based on the current distance
      const dist = distance || currentCameraPosition.distanceTo(point3)
      const newCameraPosition = point3.clone().add(cameraForwardVector.clone().negate().multiplyScalar(dist))

      arcballControlsRef.current.setTarget(...point)
      arcballControlsRef.current.reset()

      // Set the camera position
      cameraRef.current.position.set(newCameraPosition.x, newCameraPosition.y, newCameraPosition.z)

      // Update camera orientation
      const cameraUpVector = new Vector3().crossVectors(right, normal).normalize()
      cameraRef.current.up.set(cameraUpVector.x, cameraUpVector.y, cameraUpVector.z)
    },
    [cameraRef, arcballControlsRef],
  )

  const changeSubTool = useCallback((subTool: string) => {
    setSelectedSubTool(subTool)
  }, [])
  const changeBackgroundColor = useCallback(
    (color: string) => {
      setCookie(EDITOR_BACKGROUND_COOKIE_NAME, color, { expires: COOKIE_EXPIRE })
    },
    [setCookie],
  )
  const changeCuboidDirection = useCallback((direction: CuboidDirection) => {
    setCuboidDirection(direction)
  }, [])
  const changeIsJobRunning = useCallback(
    (running: boolean) => {
      dispatch(setJobRunning({ running, tool: selectedTool }))
      if (running && selectedTool !== EDITOR_TOOLS.DISTANCE) {
        changeTool(EDITOR_TOOLS.MOVE, true)
      }
    },
    [changeTool, selectedTool, dispatch],
  )
  const changeIsToolProcessing = useCallback((processing: boolean) => {
    setIsToolProcessing(processing)
  }, [])
  const changePointSize = useCallback((size?: number) => {
    setPointSize(size)
  }, [])
  const updatePointCloudVisibility = useCallback((invisible: boolean) => {
    setIsPointCloudInvisible(invisible)
  }, [])
  const updateMeshRefs = useCallback((ref: MeshRefs) => {
    setMeshRefs((refs) => ({ ...refs, ...ref }))
  }, [])
  const setMeshPointsFn = useCallback((pts: MeshPoints) => {
    setMeshPoints((mp) => ({ ...mp, ...pts }))
  }, [])
  const changeCollidingShapeIds = useCallback((shapeIds: string[]) => {
    setCollidingShapeIds(shapeIds)
  }, [])

  const contextValue: EditorInterface = useMemo(
    () => ({
      backgroundColor: (cookies as Record<string, string>)[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND,
      cuboidDirection,
      changeBackgroundColor,
      changeCuboidDirection,
      changeIsJobRunning,
      changeIsToolProcessing,
      changePointSize,
      changeSelectedShapeIds,
      changeTool,
      changeSubTool,
      setPrevSelectedTool,
      initCompleted,
      isJobRunning,
      isPointCloudInvisible,
      isToolProcessing,
      meshRefs,
      pointSize,
      inspectionSheet,
      setInspectionSheet,
      selectedTool,
      selectedSubTool,
      prevSelectedTool,
      prevPrevSelectedTool,
      isPreviousTool,
      shapes,
      setShapes,
      updatePointCloudVisibility,
      updateMeshRefs,
      inspectionItems,
      setInspectionItems,
      fetchInspectionSheet,
      fetchInspectionItems,
      fetchShapes,
      changeCollidingShapeIds,
      collidingShapeIds,
      selectionBoundaryBoxVectors,
      meshPoints,
      setMeshPoints: setMeshPointsFn,
      cameraRef,
      arcballControlsRef,
      focusCamera,
      focusCameraDirectional,
    }),
    [
      cookies,
      cuboidDirection,
      changeBackgroundColor,
      changeCuboidDirection,
      changeIsJobRunning,
      changeIsToolProcessing,
      changePointSize,
      changeSelectedShapeIds,
      changeTool,
      changeSubTool,
      setPrevSelectedTool,
      initCompleted,
      isJobRunning,
      isPointCloudInvisible,
      isToolProcessing,
      meshRefs,
      pointSize,
      inspectionSheet,
      setInspectionSheet,
      selectedTool,
      selectedSubTool,
      prevSelectedTool,
      prevPrevSelectedTool,
      isPreviousTool,
      shapes,
      setShapes,
      updatePointCloudVisibility,
      updateMeshRefs,
      inspectionItems,
      setInspectionItems,
      fetchInspectionSheet,
      fetchInspectionItems,
      fetchShapes,
      changeCollidingShapeIds,
      collidingShapeIds,
      selectionBoundaryBoxVectors,
      meshPoints,
      setMeshPointsFn,
      cameraRef,
      arcballControlsRef,
      focusCamera,
      focusCameraDirectional,
    ],
  )

  useEffect(() => {
    if (cookies) {
      // update the cookie expiration date
      setCookie(EDITOR_BACKGROUND_COOKIE_NAME, cookies[EDITOR_BACKGROUND_COOKIE_NAME] || '#000', {
        expires: COOKIE_EXPIRE,
      })
    }
  }, [cookies, setCookie])

  const handleResize = useCallback(() => {
    const canvasBox = document.getElementById('canvasBox') as HTMLElement
    if (!canvasBox) return

    setWindowSize({
      width: window.innerWidth,
      height: canvasBox.clientHeight,
    })
  }, [])

  /**
   * Mixpanel
   */
  // Super properties to register
  const mixpanelSuperPropsRef = useRef({
    Page: 'Editor',
    'Project ID': project_id,
    'Inspection Area ID': inspection_area_id,
    'Original File Name': inspectionArea?.origin_file?.name,
  })

  // Cleanup super properties
  useEffect(() => () => Object.keys(mixpanelSuperPropsRef.current).forEach((prop) => mixpanel.unregister(prop)), [])

  /**
   * If area has already been set and inspection_area_id is different, reset store/states
   */
  useEffect(() => {
    if (initCompleted && inspection_area_id && inspectionArea?.inspection_area_id !== inspection_area_id) {
      setShapes(INITIAL_SHAPE_STATE())
      setInspectionSheet(undefined)
      setInspectionItems([])
      dispatch(setSelectedElementIds([]))
      dispatch(setMaskRegions([]))
      setInitCompleted(false)
      resetTools()
      changeTool(EDITOR_TOOLS.MOVE, true)
    }
  }, [initCompleted, inspection_area_id, inspectionArea, resetTools, changeTool, dispatch])

  /**
   * Load everything needed for Editor page.
   */
  useEffect(() => {
    void (async () => {
      if (
        !initCompleted &&
        project &&
        inspectionArea &&
        userLoaded &&
        project_id &&
        inspection_area_id &&
        inspectionArea.inspection_area_id === inspection_area_id
      ) {
        const token = await getAccessToken()
        if (!token) {
          setInitCompleted(true)
          return
        }

        const allData = await Promise.all([
          getShapes(token, project_id, inspection_area_id, showErrorModal),
          fetchInspectionSheet(),
          getMaskingRegions(token, project_id, inspection_area_id, showErrorModal),
        ])

        if (allData[0]) {
          setShapes(allData[0])
        } else {
          setShapes(INITIAL_SHAPE_STATE())
        }

        if (allData[2]) {
          dispatch(setMaskRegions(allData[2]))
        }

        setInitCompleted(true)

        // analytics
        mixpanel.register(mixpanelSuperPropsRef.current)
        mixpanel.track('Page View')
      }
    })()
  }, [
    initCompleted,
    userLoaded,
    project,
    project_id,
    inspectionArea,
    inspection_area_id,
    getAccessToken,
    showErrorModal,
    dispatch,
    fetchInspectionSheet,
  ])

  /**
   * Prevent iPad from pulling to refresh
   * canvasがリサイズされるたびに再計算
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    document.body.classList.add('no-overflow')

    return () => {
      window.removeEventListener('resize', handleResize)
      document.body.classList.remove('no-overflow')
    }
  }, [handleResize])

  /**
   * Hacky way to use router from within ThreeJS
   */
  useEffect(() => {
    const goto = (ev: Event) => {
      const {
        detail: { url, replace },
      } = ev as CustomEvent<{ url: string; replace: boolean }>
      navigate(url, { replace })
    }

    document.addEventListener('app:navigate', goto)

    return () => {
      document.removeEventListener('app:navigate', goto)
    }
  }, [navigate])

  // Tools activation by running hook
  // Regardless if user can't use it, the hook must run.
  const toolsConfig = Tools.map((tool) => {
    const tools: (
      | {
          key: string
          auth: boolean
          hook: EditorConfig
        }
      | undefined
    )[] = []
    if (tool.hooks?.useEditor) {
      tools.push({
        key: tool.key,
        auth: tool.authCheck(decideActionPermission(isOwner, isInvited), userType),
        hook: tool.hooks?.useEditor(contextValue),
      })
    }

    if (tool.toolbar?.variants) {
      tools.push(
        ...tool.toolbar.variants.map(
          (variant) =>
            variant.hooks?.useEditor && {
              key: variant.key,
              auth: variant.authCheck(decideActionPermission(isOwner, isInvited), userType),
              hook: variant.hooks?.useEditor(contextValue),
            },
        ),
      )
    }

    return tools
  })
    .filter(Boolean)
    .flat()
  const toolsComponents = Tools.map((tool) => tool.components?.editor)
    .flat()
    .filter((component) => !!component) as FC[]

  // No inspection area
  if (!isPageLoading && !inspectionArea) {
    return (
      <PageErrorHandler>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Flex
            bg="black"
            flex={1}
            w="100%"
            color="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={3}
          >
            <IoWarning size="7rem" color="yellow" />
            <Text fontSize="lg" color="gray.300">
              エリアが見つかりませんでした。
            </Text>
          </Flex>
        </VStack>
      </PageErrorHandler>
    )
  }

  // Down-sampling is still running
  if (inspectionArea && inspectionArea.downsample_status?.status === InspectionAreaDownSampleStatus.RUNNING) {
    return (
      <PageErrorHandler>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Flex
            bg="black"
            flex={1}
            w="100%"
            color="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={3}
          >
            <LoadingPage text="ファイルを変換中..." />
          </Flex>
        </VStack>
      </PageErrorHandler>
    )
  }

  // PCD file is not uploaded yet
  if (inspectionArea && !inspectionArea.down_sampled_file?.name) {
    const getText = () => {
      if (!isAllowedToModify) {
        return '点群ファイルがまだ追加されていません。'
      }

      return isUploading
        ? `アップロード中...${uploadProgress !== null ? `${uploadProgress}%` : ''}`
        : '3D画面を利用するためには点群ファイルを追加してください'
    }

    return (
      <PageErrorHandler>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Flex
            bg="black"
            flex={1}
            w="100%"
            color="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={3}
          >
            <Text fontSize="lg" color="gray.300">
              {getText()}
            </Text>
            {isAllowedToModify && (
              <>
                <Button
                  variant="outline"
                  color="gray.100"
                  borderColor="gray.300"
                  _hover={{ color: 'gray.800', backgroundColor: 'white' }}
                  size="lg"
                  onClick={() => uploadFileRef?.current?.click()}
                  leftIcon={<AddIcon />}
                  isDisabled={isUploading}
                >
                  点群ファイルをアップロード
                </Button>
                <Input
                  data-testid="file-upload"
                  hidden
                  ref={uploadFileRef}
                  type="file"
                  accept={isMobile ? '' : '.las,.laz,.ply,.pts,.xyz,.xyzrgb'}
                  onChange={async (e) => {
                    e.preventDefault()
                    if (!project || !inspectionArea) return

                    const file = processSelectedFile(e)
                    if (!file) return

                    // start uploading
                    setIsUploading(true)
                    try {
                      const area = await uploadPcd(project, inspectionArea, file, setUploadProgress)

                      // need to update both unfortunately
                      dispatch(setInspectionArea(area))
                      dispatch(patchInspectionAreas([area]))
                    } catch (err) {
                      Sentry.captureException(err)
                    } finally {
                      setIsUploading(false)
                      setUploadProgress(null)
                    }
                  }}
                />
              </>
            )}
          </Flex>
        </VStack>
      </PageErrorHandler>
    )
  }

  return (
    <PageErrorHandler>
      <EditorContext.Provider value={contextValue}>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Box
            w="100svw"
            h="100%"
            className="editor"
            ref={editorRef}
            position="relative"
            flex="1"
            marginTop="0 !important"
          >
            <Flex justify="space-between" align="start" h="100%">
              <Box id="canvasBox" flex={1} h="100%">
                <MainCanvas
                  windowSize={windowSize}
                  setMovingPrefixedPosition={setMovingPrefixedPosition}
                  movingPrefixedPosition={movingPrefixedPosition}
                />
              </Box>

              {initCompleted && (
                <>
                  <Toolbar />
                  {!isJobRunning &&
                    Tools.find((tool) => selectedTool === tool.key)?.config?.additionalToolbars?.cuboidControls &&
                    cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid && <SubToolbar />}
                  <TransformControlsToolbar />

                  <SidePanels toolsConfig={toolsConfig} />

                  {/* Message box at the top of screen */}
                  <AttentionText />
                </>
              )}
            </Flex>
          </Box>

          {initCompleted &&
            toolsComponents.map((ToolComponent) => (
              <ToolComponent key={`editor-tool-component-${ToolComponent.name}`} />
            ))}
        </VStack>
      </EditorContext.Provider>
      {/* <DebugPanel /> */}
    </PageErrorHandler>
  )
}

export default Editor
