/* istanbul ignore file */
import { useCallback, useContext, useMemo, useState } from 'react'

import { ThreeEvent } from '@react-three/fiber'
import { last, uniqueId } from 'lodash'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'

import { EDITOR_ANCHOR_CLOSING_PROXIMITY, EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorIcon, DistanceLabelProps, LabelProps, LineStyle } from 'interfaces/canvas'
import { CanvasConfig, CanvasEventsExtra } from 'interfaces/editor'

import { pointArrayToVector3 } from 'services/Points'
import { getDistanceLabel, zeroPad } from 'services/Util'

import {
  Polyline,
  WORKING_ID_PREFIX,
  addWorkingPolyline,
  patchWorkingPolyline,
  setFirstPointPlaced,
  setIsDirty,
  setWorkingLabel,
  undoLastWorkingPolyline,
} from '../store'

const DISTANCE_LABEL_STYLES: Partial<DistanceLabelProps> = {
  anchorProps: {
    color: '#f00',
    icon: undefined,
    outlineOpacity: 1,
    scale: 1,
  },
  lineColor: '#000',
  lineOutline: true,
  lineOutlineColor: 'lightgreen',
  labelBgColor: 'lightgreen',
  labelTextColor: '#000',
}

const DISTANCE_LABEL_STYLES_HOVERED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineColor: '#fff',
}

const DISTANCE_LABEL_STYLES_SELECTED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineColor: '#4cb364',
  lineOutlineColor: 'yellow',
}

// Working label when there's only 1 point
const DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineOutlineColor: 'yellow',
}

// Working label when there's no polyline yet (only 2 point)
const DISTANCE_LABEL_STYLES_WORKING_LABEL_NO_POLY: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE,
  topAnchorProps: DISTANCE_LABEL_STYLES.anchorProps,
}

// Working label
const DISTANCE_LABEL_STYLES_WORKING_LABEL: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE,
  topAnchorProps: {
    outlineOpacity: 0,
    color: '#248CE4',
    scale: 1.3,
    icon: CircleAnchorIcon.CHECK,
    iconScale: 1.1,
    iconColor: 'white',
  },
  bottomAnchorProps: DISTANCE_LABEL_STYLES.anchorProps,
}

// For working label when it's being closed (not polyline)
const DISTANCE_LABEL_STYLES_WORKING_LABEL_COMPLETING: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE,
  topAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE.bottomAnchorProps,
    color: '#f00',
  },
}

// For working polyline itself (not working label)
const DISTANCE_LABEL_STYLES_WORKING_POLY: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
  topAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_LABEL.topAnchorProps,
    color: '#f00',
    outlineOpacity: 1,
    icon: undefined,
    scale: 0.9,
  },
  bottomAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_LABEL.bottomAnchorProps,
    color: '#f00',
    outlineOpacity: undefined,
    icon: undefined,
    scale: undefined,
  },
}

// For working polyline when it's being completed (the anchor is being hovered)
const DISTANCE_LABEL_WORKING_COMPLETING: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_POLY,
  lineColor: '#f00',
  topAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_POLY.topAnchorProps,
    color: '#f00',
  },
}

const DISTANCE_LABEL_COMPLETED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
  topAnchorProps: undefined,
  bottomAnchorProps: undefined,
  anchorProps: {
    color: '#555',
    outlineOpacity: 1,
    scale: 1,
  },
  lineColor: '#000',
  lineOutlineColor: 'pink',
}

let lastTouchPoint: PointArray | undefined
let lastTouchClient: { x: number; y: number } | undefined

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const polylines = useSelector((state: RootState) => state.toolPolyline.polylines)
  const workingPolylines = useSelector((state: RootState) => state.toolPolyline.workingPolylines)
  const workingLabel = useSelector((state: RootState) => state.toolPolyline.workingLabel)
  const firstPointPlaced = useSelector((state: RootState) => state.toolPolyline.firstPointPlaced)
  const hoveredElementId = useSelector((state: RootState) => state.editor.hoveredElementId)
  const hiddenElementIds = useSelector((state: RootState) => state.editor.hiddenElementIds)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)

  // State
  const [isClosing, setIsClosing] = useState(false)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.POLYLINE, [selectedTool])
  const currentWorkingPolyline: Polyline | undefined = useMemo(() => workingPolylines.slice(-1)[0], [workingPolylines])
  const hideWorkingLabel = useMemo(() => {
    const workPoly = workingPolylines.filter((p) => !p.completed).slice(-1)[0]
    if (workPoly) {
      return hiddenElementIds.includes(workPoly.inspection_item_id!)
    }

    return false
  }, [hiddenElementIds, workingPolylines])

  /**
   * Add a working polyline from the working label.
   */
  const addPolyline = useCallback(
    (points: PointArray[], extra?: Partial<Polyline>) => {
      dispatch(
        addWorkingPolyline({
          ...extra,
          inspection_item_id: uniqueId(WORKING_ID_PREFIX),
          part_name: `延長${zeroPad(workingPolylines.length + polylines.length + 1, 3)}`,
          shape_ids: INITIAL_SHAPE_STATE(),
          polyline_length: {
            positions_for_distance: points,
          },
        }),
      )
    },
    [dispatch, polylines, workingPolylines],
  )

  /**
   * Check if the working label points are at the same place.
   */
  const isWorkingLabelPointsSamePlace = useMemo(() => {
    if (!workingLabel || workingLabel.points.length !== 2) return false

    return workingLabel.points[0].join(',') === workingLabel.points[1].join(',')
  }, [workingLabel])

  /**
   * End drawing of polyline
   */
  const endPolyline = useCallback(
    (addFinalPoint = true) => {
      if (!workingLabel) return

      if (!currentWorkingPolyline || currentWorkingPolyline.completed) {
        addPolyline(workingLabel.points, {
          completed: true,
        })
      } else {
        dispatch(
          patchWorkingPolyline({
            ...currentWorkingPolyline,
            polyline_length:
              workingLabel.points[1] && addFinalPoint
                ? {
                    positions_for_distance: [
                      ...currentWorkingPolyline.polyline_length!.positions_for_distance!,
                      workingLabel.points[1],
                    ],
                  }
                : currentWorkingPolyline.polyline_length,
            completed: true,
          }),
        )
      }
      dispatch(setCursor(CursorState.CROSSHAIR))
      dispatch(setFirstPointPlaced(false))
      dispatch(
        setWorkingLabel({
          ...workingLabel,
          points: [workingLabel.points[1] ?? workingLabel.points[0]],
        }),
      )
    },
    [currentWorkingPolyline, workingLabel, addPolyline, dispatch],
  )

  /**
   * Get anchor props for the polyline.
   * This is separated from the function that defines working label to ensure
   * it will always refer to the latest state of the polyline since the working
   * label is re-used through re-renders.
   */
  const getWorkingLabelProps = useCallback(
    (prevLabel: DistanceLabelProps): DistanceLabelProps => {
      // if there's already a working polyline, use the last point as the first point.
      // otherwise, use current point.
      const isInteractable =
        (isToolSelected || selectedTool === EDITOR_TOOLS.MOVE) &&
        currentWorkingPolyline &&
        (currentWorkingPolyline.polyline_length?.positions_for_distance?.length || 0) > 1 &&
        !currentWorkingPolyline.completed

      let style = isInteractable ? DISTANCE_LABEL_STYLES_WORKING_LABEL : DISTANCE_LABEL_STYLES_WORKING_LABEL_NO_POLY
      if (isClosing) {
        style = DISTANCE_LABEL_STYLES_WORKING_LABEL_COMPLETING
      }

      return {
        ...prevLabel,
        ...style,
        topAnchorProps: {
          ...prevLabel.topAnchorProps,
          ...style.topAnchorProps,
          tooltipText: isInteractable ? 'この延長の作成を完了' : undefined,
          onMove: isInteractable
            ? (event: ThreeEvent<PointerEvent>) => {
                event.stopPropagation()
                dispatch(setCursor(CursorState.POINTER))
                setIsClosing(true)
              }
            : undefined,
          onLeave: isInteractable
            ? (event: ThreeEvent<PointerEvent>) => {
                event.stopPropagation()
                dispatch(setCursor(selectedTool === EDITOR_TOOLS.MOVE ? CursorState.DEFAULT : CursorState.CROSSHAIR))
                setIsClosing(false)
              }
            : undefined,
          onUp: isInteractable
            ? (event: ThreeEvent<PointerEvent>) => {
                event.stopPropagation()
                setIsClosing(true)
                endPolyline(false)
              }
            : undefined,
        },
      }
    },
    [isToolSelected, selectedTool, currentWorkingPolyline, isClosing, endPolyline, dispatch],
  )

  /**
   * Get working label definition with only the first point.
   */
  const getWorkingLabelOnlyFirstPoint = useCallback(
    (points: PointArray) =>
      ({
        ...DISTANCE_LABEL_STYLES_WORKING_LABEL_SINGLE,
        id: `working-label-${WORKING_ID_PREFIX}`,
        points: [points],
        label: '',
      }) as DistanceLabelProps,
    [],
  )

  /**
   * Get the total distance label of the polyline.
   */
  const getTotalDistanceLabel = useCallback((allPoints: PointArray[]): LabelProps => {
    // Go through each section of the polyline and calculate the distance between them, then the total distance.
    const totalDistance = allPoints.reduce<number>((collection, point, index) => {
      const labelPoints = [point, allPoints[index + 1]]
      if (!labelPoints[1]) return collection
      return collection + pointArrayToVector3(labelPoints[0]).distanceTo(pointArrayToVector3(labelPoints[1]))
    }, 0)

    // Figure out the mid-point of the polyline.
    const midPointDistance = totalDistance / 2
    const midPoint = allPoints.reduce<{ point: PointArray; totalDistance: number }>(
      (collection, point, index) => {
        if (collection.totalDistance >= midPointDistance) return collection

        const labelPoints = [point, allPoints[index + 1]]
        if (!labelPoints[1]) return collection

        const distance = pointArrayToVector3(labelPoints[0]).distanceTo(pointArrayToVector3(labelPoints[1]))
        if (collection.totalDistance + distance < midPointDistance) {
          collection.totalDistance += distance
          return collection
        }

        const remainingDistance = midPointDistance - collection.totalDistance
        const result = pointArrayToVector3(labelPoints[0])
          .lerp(pointArrayToVector3(labelPoints[1]), remainingDistance / distance)
          .toArray()

        return { point: result, totalDistance: midPointDistance }
      },
      {
        point: [0, 0, 0],
        totalDistance: 0,
      },
    ).point

    return {
      id: uniqueId('polyline-label-'),
      point: midPoint,
      message: '', // this is tooltip, not using it
      label: getDistanceLabel(totalDistance),
      labelPrefix: '',
      backgroundColor: 'lightgreen',
      color: '#333333',
      padding: [5, 2],
    }
  }, [])

  /**
   * Main action to draw the polyline.
   * - If there's only 1 point on the distance label, commit the point to the working label as its first point.
   * - If there are 2 points;
   *   - If the polyline does not have any points yet, commit both points (first in working label and current point) to the polyline.
   *   - If the polyline already has points, only commit the current point to the working label.
   *   - Whichever the case, the working label will be reset.
   * - On double click, end the polyline drawing.
   *
   * @param points Clicked point in 3D Space.
   * @param extra Extra events data.
   */
  const onMouseUp = useCallback(
    (points: PointArray | undefined, { isDragged, isDoubleClicked }: CanvasEventsExtra, isTouch = false) => {
      if (!points || isDragged) return

      if (isToolSelected) {
        let currentPoly: Polyline | undefined = workingPolylines.slice(-1)[0]
        if (currentPoly?.completed) {
          currentPoly = undefined
        }

        // Double clicked with more than 2 points or current point is near the last point.
        // This is to help touch user to close without having to be too precise.
        const lastWorkingPolyline = workingPolylines.slice(-1)[0]
        const firstPoint = lastWorkingPolyline?.polyline_length?.positions_for_distance
          ? last(lastWorkingPolyline.polyline_length?.positions_for_distance)
          : null
        const isCloseProximityClose = firstPoint
          ? new Vector3(...points).distanceTo(new Vector3(...firstPoint)) < EDITOR_ANCHOR_CLOSING_PROXIMITY
          : false

        // Double click to end the polyline
        // The nested is to ignore double-click even if the polyline is not to be completed yet.
        if (isDoubleClicked || isCloseProximityClose) {
          if (lastWorkingPolyline && !lastWorkingPolyline.completed) {
            endPolyline(!isWorkingLabelPointsSamePlace)
            setIsClosing(false)
            return
          }
        }

        // Disable adding points if the polyline is being ended.
        if (isClosing) {
          setIsClosing(false)
          return
        }

        // Placing down the first point.
        // For mouse interactions, no need to do anything other than the flag. onMove will handle the rest.
        // For touch interactions, we need to add the first point to the working label.
        if (!firstPointPlaced) {
          if (!workingLabel) {
            dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(points)))
          }

          if (isTouch && workingLabel) {
            dispatch(
              setWorkingLabel({
                ...workingLabel,
                points: [points],
              }),
            )
          }

          dispatch(setIsDirty(true))
          dispatch(setFirstPointPlaced(true))
          return
        }

        // Further processing are to complete the label and add it to the polyline.
        // If working label is missing for some reason, there's nothing that can be done.
        // Can happen if the user clicks too fast.
        // Check of count can only happen on mouse interacctions since touch can't have the second, mouse-following anchor.
        if (!workingLabel || (!isTouch && workingLabel.points.length < 2) || workingLabel.points.some((p) => !p)) return

        // Get current working polyline. If there's none yet, create a new one.
        if (!currentPoly) {
          addPolyline(workingLabel.points.length === 2 ? workingLabel.points : [workingLabel.points[0], points])
          dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(workingLabel.points[1] || points)))
          return
        }

        // If there's already a polyline, add the points to the working polyline.
        dispatch(
          patchWorkingPolyline({
            ...currentPoly,
            polyline_length: {
              positions_for_distance: [
                ...currentPoly.polyline_length!.positions_for_distance!,
                workingLabel.points[1] || points,
              ],
            },
          }),
        )
        dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(workingLabel.points[1] || points)))
      }
    },
    [
      isClosing,
      isWorkingLabelPointsSamePlace,
      firstPointPlaced,
      workingLabel,
      workingPolylines,
      isToolSelected,
      addPolyline,
      endPolyline,
      getWorkingLabelOnlyFirstPoint,
      dispatch,
    ],
  )

  /**
   * On movement of the mouse, add/update the working distance label.
   * - If there's no working distance label, an anchor will be added and always follow mouse movement.
   * - If there's a working distance label, the second anchor will be added to it and follows mouse movement,
   *   plus a complete distance label will be displayed.
   *
   * The working label points are not part of the polyline state until the second anchor is placed.
   *
   * @param points Point in 3D Space
   */
  const onMouseMove = useCallback(
    (points: PointArray | undefined) => {
      if (!points) return

      if (isToolSelected) {
        let updatedWorkingLabel = { ...workingLabel } as DistanceLabelProps | undefined

        if ((!currentWorkingPolyline || currentWorkingPolyline.completed) && !firstPointPlaced) {
          updatedWorkingLabel = getWorkingLabelOnlyFirstPoint(points)
        } else if (updatedWorkingLabel?.points[0]) {
          const distance = pointArrayToVector3(updatedWorkingLabel.points[0]).distanceTo(pointArrayToVector3(points))
          const canHaveLabel =
            ((!currentWorkingPolyline?.completed &&
              currentWorkingPolyline?.polyline_length?.positions_for_distance?.length) ||
              0) < 2
          updatedWorkingLabel = {
            ...updatedWorkingLabel,
            ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
            hideBottomAnchor: isClosing,
            points: [updatedWorkingLabel.points[0], points],
            label: !isClosing && distance && canHaveLabel ? getDistanceLabel(distance) : undefined,
            labelBgColor: 'white',
            labelTextColor: '#333333',
          }
        }

        dispatch(setWorkingLabel(updatedWorkingLabel))
      }
    },
    [
      isToolSelected,
      currentWorkingPolyline,
      workingLabel,
      firstPointPlaced,
      isClosing,
      getWorkingLabelOnlyFirstPoint,
      dispatch,
    ],
  )

  return {
    objects: {
      /**
       * Labels of total distance of the polyline.
       */
      labels: [
        // Generate labels for working polylines.
        ...useMemo(
          () =>
            workingPolylines
              .filter(
                (polyline) =>
                  !hiddenElementIds.includes(polyline.inspection_item_id!) ||
                  hoveredElementId === polyline.inspection_item_id,
              )
              .map<LabelProps | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance

                if (!points || points.length < 2) return null

                // Collect all points of this polyline, and if it's not completed, add the working label points.
                const allPoints = polyline.completed ? points : [...points, ...(workingLabel?.points || [])]

                return {
                  ...getTotalDistanceLabel(allPoints),
                  backgroundColor: polyline.completed ? 'lightgreen' : 'white',
                }
              }),
          [workingPolylines, workingLabel?.points, hiddenElementIds, hoveredElementId, getTotalDistanceLabel],
        ),

        // Generate labels for saved polylines.
        ...useMemo(
          () =>
            polylines.map<LabelProps | null>((polyline) => {
              const points = polyline.polyline_length?.positions_for_distance
              const isHovered = hoveredElementId === polyline.inspection_item_id

              if (
                !points ||
                points.length < 2 ||
                (!isHovered && !selectedElementIds.includes(polyline.inspection_item_id!))
              )
                return null

              return getTotalDistanceLabel(points)
            }),
          [polylines, hoveredElementId, selectedElementIds, getTotalDistanceLabel],
        ),
      ].filter(Boolean) as LabelProps[],

      oldDistanceLabels: [
        /**
         * Generate distance labels for saved polyline.
         */
        ...useMemo(
          () =>
            polylines
              .filter(
                (polyline) =>
                  !hiddenElementIds.includes(polyline.inspection_item_id!) ||
                  hoveredElementId === polyline.inspection_item_id,
              )
              .map<DistanceLabelProps[] | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance
                if (!points) return null

                let style = DISTANCE_LABEL_STYLES
                const isHovered = hoveredElementId === polyline.inspection_item_id
                if (isHovered) {
                  style = DISTANCE_LABEL_STYLES_HOVERED
                } else if (selectedElementIds.includes(polyline.inspection_item_id!)) {
                  style = DISTANCE_LABEL_STYLES_SELECTED
                }

                // Each Polyline has multiple distance labels, each with 2 anchors where
                // the following point is the end of the previous label.
                // eg: [0, 1, 2, 3, 4] => [[0, 1], [1, 2], [2, 3], [3, 4]]
                return points
                  .reduce<DistanceLabelProps[]>((collection, point, index) => {
                    if (index === points.length - 1) return collection

                    const labelPoints = [point, points[index + 1]]
                    const label: DistanceLabelProps = {
                      ...style,
                      id: `polyline-${polyline.inspection_item_id}-${index}`,
                      points: labelPoints,
                      hideTopAnchor: true,
                      hideBottomAnchor: true,
                      lineStyle: LineStyle.Dashed,
                    }

                    return collection.concat(label)
                  }, [])
                  .flat()
              })
              .flat(),
          [polylines, hoveredElementId, hiddenElementIds, selectedElementIds],
        ),

        /**
         * Generate distance labels for working polyline.
         */
        ...useMemo(
          () =>
            workingPolylines
              .filter(
                (polyline) =>
                  !hiddenElementIds.includes(polyline.inspection_item_id!) ||
                  hoveredElementId === polyline.inspection_item_id,
              )
              .map<DistanceLabelProps[] | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance

                if (!points) return null

                // Each Polyline has multiple distance labels, each with 2 anchors where
                // the following point is the end of the previous label.
                // eg: [0, 1, 2, 3, 4] => [[0, 1], [1, 2], [2, 3], [3, 4]]
                return points
                  .reduce<DistanceLabelProps[]>((collection, point, index) => {
                    if (index === points.length - 1) return collection

                    // Only the first label will have the first anchor as the start point
                    // bottom is always hidden since the working label will continue after it
                    const labelPoints = [point, points[index + 1]]
                    const hideBottomAnchor =
                      !polyline.completed || index !== points.length - 2 || (points.length > 2 && index === 0)
                    const hideTopAnchor = index !== 0

                    if (labelPoints.length < 2 || labelPoints.some((p) => !p)) return collection

                    // the colors need to change based on its state (selected, hover)
                    let style = DISTANCE_LABEL_STYLES_WORKING_POLY
                    if (isClosing && currentWorkingPolyline?.inspection_item_id === polyline.inspection_item_id) {
                      style = DISTANCE_LABEL_WORKING_COMPLETING
                    } else if (polyline.completed) {
                      style = DISTANCE_LABEL_COMPLETED
                    }

                    const label: DistanceLabelProps = {
                      ...style,
                      id: `working-polyline-${polyline.inspection_item_id}-${index}`,
                      points: labelPoints,
                      hideTopAnchor,
                      hideBottomAnchor,
                      lineStyle: LineStyle.Dashed,
                    }

                    return collection.concat(label)
                  }, [])
                  .flat()
              })
              .flat(),
          [workingPolylines, currentWorkingPolyline, isClosing, hiddenElementIds, hoveredElementId],
        ),

        /**
         * Working distance label.
         * - This label will be displayed when the user is drawing a polyline.
         * - Once user completes the distance label (both point placed) it will be added to the polyline state.
         * - It will follow current polyline visibility state.
         * No point of useMemo here since it updates on mouse move.
         */
        workingLabel?.points.length && !hideWorkingLabel ? getWorkingLabelProps(workingLabel) : null,
      ].filter(Boolean) as DistanceLabelProps[],
    },
    events: {
      onTouchStart: (ev, point) => {
        lastTouchPoint = point
        lastTouchClient = { x: ev.touches[0].clientX, y: ev.touches[0].clientY }
      },
      onTouchEnd: (ev, extra) => {
        if (!isToolSelected) return

        // Last touch point and last touch client are obtained from touchstart and touchstart because touchmove does not have `touches` data.
        if (lastTouchPoint && lastTouchClient) {
          onMouseUp(lastTouchPoint, extra, true)
        }

        lastTouchPoint = undefined
      },
      onTouchMoveCapture: (ev, point) => {
        onMouseMove(point)
      },
      onMouseUp(e, points, extra) {
        if (!isToolSelected || lastTouchClient) {
          lastTouchClient = undefined
          return
        }

        onMouseUp(points, extra)
      },
      onMove(points) {
        if (!isToolSelected || lastTouchClient) return

        onMouseMove(points)
      },

      /**
       * Undo the last point of the working polyline.
       * If the last polyline is completed, it will remove the last point and make it the drawing stage.
       */
      onUndo() {
        if (!isToolSelected) return

        dispatch(undoLastWorkingPolyline())
        setIsClosing(false)
      },
    },
  }
}

export default useMainCanvas
