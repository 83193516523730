/* istanbul ignore file */
import dayjs from 'dayjs'
import { Style, Workbook, Worksheet } from 'exceljs'
import { DEFAULT_TOLERANCE_TYPE, GRID_UNIT, GRID_UNIT_PRECISION } from 'pages/projects/inspection-sheet/constants'
import { ValuePassingThreshold } from 'pages/projects/inspection-sheet/interfaces'
import { canBeEvaluated, getDifferenceValueAndIsPassingThresholds } from 'pages/projects/inspection-sheet/utils'

import { APP_URL } from 'config/environments'

import { InspectionArea, InspectionItem, InspectionSheet } from 'interfaces/inspection'
import { Project, ProjectSheetRowVisibility, ProjectSheetSettings, ProjectSheetToleranceType } from 'interfaces/project'
import { PlaneSide, Shapes } from 'interfaces/shape'

import { parseInspectionItemPartitionKey } from 'services/InspectionArea'
import { findInspectionAreaSheet } from 'services/InspectionSheet'
import { isValueDefined, meterRounded, meterToMilimeterRounded, meterToMillimeter, zeroPad } from 'services/Util'

import {
  DATA_SHEET_NAME,
  DIAGRAM_SHEET_NAME,
  GRID_VALUES_SHEET_NAME,
  createOuterBorder,
  fitColumns,
  isDiagramShown,
  makeInternalLinkValue,
  setHeaderCell,
  setProfileGroup,
  setSummary,
  setTitle,
} from './common'
import {
  centerCenterStyle,
  nameStyle,
  nameStyleCenter,
  plusMinusValueStyleMeter,
  plusMinusValueStyleMili,
  underlineStyle,
  valueFailed,
  valueStyleMeter,
  valueStyleMili,
} from './styles'

const DATA_COLUMNS: [ProjectSheetRowVisibility, [string, string]][] = [
  ['polyon_length_1', ['辺の長さ1', '(mm)']],
  ['polyon_length_2', ['辺の長さ2', '(mm)']],
  ['polygon_perimeter', ['外周長', '(mm)']],
  ['polygon_area', ['面積', '(m²)']],
  ['volume', ['体積', '(m³)']],
  ['grid', ['深さ', '(mm)']],
  ['upper_plane_to_cylinders_distance', ['表かぶり厚', '(mm)']],
  ['lower_plane_to_cylinders_distance', ['裏かぶり厚', '(mm)']],
]

/**
 * Filter the data columns based on the project settings.
 *
 * @param settings Project settings
 * @returns Filtered data columns
 */
const filterDataColumns = (settings: ProjectSheetSettings) =>
  DATA_COLUMNS.filter(([key]) => (settings.sheet_rows_visibility ? settings.sheet_rows_visibility[key] : false))

/**
 * Set the headers for the volume table.
 *
 * @param sheet Worksheet
 * @param bodyRow Current row
 * @param settings Project settings
 * @param forProject If the row is for the project
 * @returns [New body row, number of columns per exported metric, total columns]
 */
const setTableHeaders = (
  sheet: Worksheet,
  bodyRow: number,
  settings: ProjectSheetSettings,
  forProject = false,
): [number, [ProjectSheetRowVisibility, number][], number] => {
  let bodyCol = 1

  // inspection area
  if (forProject) {
    // name
    sheet.mergeCells(bodyRow, bodyCol, bodyRow + 2, bodyCol)
    setHeaderCell(sheet, bodyRow, bodyCol, 'エリア名', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow + 2])
    bodyCol += 1

    // props
    sheet.mergeCells(bodyRow, bodyCol, bodyRow + 2, bodyCol)
    setHeaderCell(sheet, bodyRow, bodyCol, '工種名', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow + 2])
    bodyCol += 1

    sheet.mergeCells(bodyRow, bodyCol, bodyRow + 2, bodyCol)
    setHeaderCell(sheet, bodyRow, bodyCol, '種別', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow + 2])
    bodyCol += 1

    sheet.mergeCells(bodyRow, bodyCol, bodyRow + 2, bodyCol)
    setHeaderCell(sheet, bodyRow, bodyCol, '作成者', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow + 2])
    bodyCol += 1

    sheet.mergeCells(bodyRow, bodyCol, bodyRow + 2, bodyCol)
    setHeaderCell(sheet, bodyRow, bodyCol, '作成日', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow + 2])
    bodyCol += 1
  }

  // item group
  sheet.mergeCells(bodyRow, bodyCol, bodyRow + 1, bodyCol + 1)
  setHeaderCell(sheet, bodyRow, bodyCol, '測点', 'lg')

  // item name and type
  setHeaderCell(sheet, bodyRow + 2, bodyCol, '名称')
  setHeaderCell(sheet, bodyRow + 2, bodyCol + 1, '種類')

  createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol + 1, bodyRow + 2])
  bodyCol += 2

  // // Add data columns
  let curCol = bodyCol // the current column
  let startCol = 0 // the starting column for each metric
  const colCountPerMetric: [ProjectSheetRowVisibility, number][] = []
  filterDataColumns(settings).forEach(([row, [name, unit]]) => {
    startCol = curCol
    let colCount: [ProjectSheetRowVisibility, number] = [row, 0]

    // ## data values
    // actual
    if (settings.sheet_cols_visibility?.estimated_value) {
      setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '実測値')
      colCount = [row, colCount[1] + 1]
    }

    // design
    if (settings.sheet_cols_visibility?.designed_value) {
      setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '設計値')
      colCount = [row, colCount[1] + 1]
    }

    // tolerance
    if (settings.sheet_cols_visibility?.tolerance) {
      setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '規格値')
      colCount = [row, colCount[1] + 1]
    }

    // diff
    if (settings.sheet_cols_visibility?.designed_value) {
      setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '差')
      colCount = [row, colCount[1] + 1]
    }

    if (row === 'grid') {
      if (settings.sheet_rows_visibility?.min_grid_depth) {
        setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '最小')
        colCount = [row, colCount[1] + 1]
      }

      if (settings.sheet_rows_visibility?.max_grid_depth) {
        setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '最大')
        colCount = [row, colCount[1] + 1]
      }

      // link to grid values
      if (settings.sheet_diagram_visibility?.grid_diagram) {
        setHeaderCell(sheet, bodyRow + 2, curCol + colCount[1], '')
        sheet.getColumn(curCol + colCount[1]).width = 15
        sheet.getColumn(curCol + colCount[1]).alignment = { vertical: 'middle', horizontal: 'center' }
        colCount = [row, colCount[1] + 1]
      }
    }

    colCountPerMetric.push(colCount)

    curCol += colCount[1]

    // data type
    sheet.mergeCells(bodyRow, startCol, bodyRow, curCol - 1)
    setHeaderCell(sheet, bodyRow, startCol, name, 'lg')

    // data unit
    sheet.mergeCells(bodyRow + 1, startCol, bodyRow + 1, curCol - 1)
    setHeaderCell(sheet, bodyRow + 1, startCol, unit, 'lg')

    createOuterBorder(sheet, [startCol, bodyRow], [curCol - 1, bodyRow + 2])
  })

  return [bodyRow + 3, colCountPerMetric, curCol]
}

/**
 * Sets the standard values for an inspection item.
 * - Design value, actual value, difference, and tolerance.
 *
 * @param sheet Worksheet
 * @param bodyRow Current row
 * @param bodyCol Current column
 * @param measuredValue Actual measured value
 * @param inspectionItem Inspection item
 * @param valueConverter Optional value converter
 * @param style Optional style
 * @param plusMinusStyle Optional style for plus/minus values
 * @returns New body column
 */
const setCategoryValues = (
  sheet: Worksheet,
  bodyRow: number,
  bodyCol: number,
  measuredValue: number | undefined | null,
  inspectionItem: InspectionItem | undefined,
  settings: ProjectSheetSettings,
  toleranceType: ProjectSheetToleranceType,
  valueConverter?: (value: number) => number,
  style?: Style,
  plusMinusStyle?: Style,

  /**
   * Extra data points to be displayed.
   */
  additionalMetrics: {
    title: string
    value: number
    isHidden: boolean
  }[] = [],

  /**
   * Optional link column.
   * Used to link to another cell in the same or different sheet.
   *
   * (Can be extended to a URL link in the future)
   */
  link:
    | {
        /**
         * Name of the link.
         */
        name: string

        /**
         * Sheet name to link to.
         */
        sheetName: string

        /**
         * ID of the cell to link to.
         */
        cellId: string
      }
    | undefined = undefined,
): number => {
  let validation: ValuePassingThreshold | undefined
  let evaluation: ReturnType<typeof canBeEvaluated> | undefined
  let colNum = bodyCol

  if (inspectionItem) {
    validation = getDifferenceValueAndIsPassingThresholds(
      {
        estimated_value: measuredValue,
      },
      inspectionItem.pre_defined_thresholds,
      settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
      GRID_UNIT,
      GRID_UNIT_PRECISION,
    )
    evaluation = canBeEvaluated(
      settings,
      settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
      inspectionItem.pre_defined_thresholds,
    )
  }

  const { differenceValue, isPassingThresholds } = validation || {}
  const { isEvaluated, isUpperToleranceDefined, isLowerToleranceDefined } = evaluation || {}

  const isFailingThresholds = (isEvaluated && !isPassingThresholds) || false
  let updatedStyles = { ...style }
  if (isFailingThresholds) {
    updatedStyles = { ...updatedStyles, ...valueFailed }
  }

  // actual value
  if (settings.sheet_cols_visibility?.estimated_value) {
    if (measuredValue) {
      sheet.getCell(bodyRow, colNum).value = valueConverter ? valueConverter(measuredValue) : measuredValue
      if (style) {
        sheet.getCell(bodyRow, colNum).style = { ...style } // exceljs will continue using the same object reference. copy to avoid overwriting.
      }
    }
    colNum += 1
  }

  // design value
  if (settings.sheet_cols_visibility?.designed_value) {
    if (inspectionItem && isValueDefined(inspectionItem.pre_defined_thresholds?.designed_value)) {
      sheet.getCell(bodyRow, colNum).value = valueConverter
        ? valueConverter(inspectionItem.pre_defined_thresholds.designed_value)
        : inspectionItem.pre_defined_thresholds.designed_value
      sheet.getCell(bodyRow, colNum).style = { ...updatedStyles }
    }
    colNum += 1
  }

  // tolerance
  if (settings.sheet_cols_visibility?.tolerance) {
    if (inspectionItem) {
      let tolerance: number | string | null | undefined =
        isValueDefined(inspectionItem.pre_defined_thresholds?.tolerance) && valueConverter
          ? valueConverter(inspectionItem.pre_defined_thresholds.tolerance)
          : inspectionItem.pre_defined_thresholds?.tolerance

      if (isPassingThresholds !== undefined) {
        if (toleranceType === ProjectSheetToleranceType.GteDesignedValue) {
          tolerance = isPassingThresholds ? '以上' : '以下'
        } else if (toleranceType === ProjectSheetToleranceType.LteDesignedValue) {
          tolerance = isPassingThresholds ? '以下' : '以上'
        }
      }

      if (isValueDefined<string | number>(tolerance)) {
        sheet.getCell(bodyRow, colNum).value = tolerance
        if (typeof tolerance === 'string')
          sheet.getCell(bodyRow, colNum).style = { ...centerCenterStyle, ...updatedStyles }
        else if (typeof tolerance === 'number')
          sheet.getCell(bodyRow, colNum).style = { ...plusMinusStyle, ...updatedStyles }
        else sheet.getCell(bodyRow, colNum).style = { ...updatedStyles }
      }

      if (isUpperToleranceDefined || isLowerToleranceDefined) {
        sheet.getCell(bodyRow, colNum).style = { ...centerCenterStyle, ...updatedStyles }

        const str = []

        if (isValueDefined(inspectionItem.pre_defined_thresholds?.lower_tolerance)) {
          const value = valueConverter
            ? valueConverter(inspectionItem.pre_defined_thresholds.lower_tolerance)
            : inspectionItem.pre_defined_thresholds?.lower_tolerance
          str.push(`-${value}`)
        }

        if (isValueDefined(inspectionItem.pre_defined_thresholds?.upper_tolerance)) {
          const value = valueConverter
            ? valueConverter(inspectionItem.pre_defined_thresholds.upper_tolerance)
            : inspectionItem.pre_defined_thresholds?.upper_tolerance
          str.push(`+${value}`)
        }

        tolerance = str.join(' ')
      }

      sheet.getCell(bodyRow, colNum).value = tolerance
    }
    colNum += 1
  }

  // difference
  if (settings.sheet_cols_visibility?.designed_value) {
    if (differenceValue !== undefined) {
      sheet.getCell(bodyRow, colNum).value = valueConverter ? valueConverter(differenceValue) : differenceValue
      sheet.getCell(bodyRow, colNum).style = { ...updatedStyles }
    }
    colNum += 1
  }

  // additional metrics
  additionalMetrics.forEach((metric) => {
    if (!metric.isHidden) {
      sheet.getCell(bodyRow, colNum).value = valueConverter ? valueConverter(metric.value) : metric.value
      if (style) sheet.getCell(bodyRow, colNum).style = { ...updatedStyles }
      colNum += 1
    }
  })

  // optional link column
  if (link) {
    sheet.getCell(bodyRow, colNum).value = makeInternalLinkValue(link.sheetName, link.cellId, link.name)
    sheet.getCell(bodyRow, colNum).style = { ...centerCenterStyle, ...updatedStyles, ...underlineStyle }

    colNum += 1
  }

  return colNum
}

/**
 * Set the columns for plane inspection items.
 *
 * @param sheet Worksheet
 * @param bodyCol Current column
 * @param bodyRow Current row
 * @param volume Volume inspection item
 * @param inspectionItems Inspection items related to the volume
 * @returns New body column
 */
const setPlaneColumns = (
  cellId: string,
  sheet: Worksheet,
  bodyCol: number,
  bodyRow: number,
  name: string,
  type: string,
  inspectionItems: InspectionItem[],
  settings: ProjectSheetSettings,
): number => {
  let updatedCol = bodyCol

  // set volume name
  if (isDiagramShown(settings)) {
    sheet.getCell(bodyRow, updatedCol).value = {
      formula: `HYPERLINK("#'${DIAGRAM_SHEET_NAME}'!"&ADDRESS(CELL("row", diagram_${cellId}), CELL("col",diagram_${cellId})), "${name}")`,
      date1904: false,
    }
    sheet.getCell(bodyRow, updatedCol).style = { ...nameStyleCenter, ...underlineStyle } // copy to avoid overwriting
    sheet.getCell(bodyRow, updatedCol).name = `data_${cellId}`
  } else {
    sheet.getCell(bodyRow, updatedCol).value = name
    sheet.getCell(bodyRow, updatedCol).style = { ...nameStyleCenter }
  }
  updatedCol += 1

  // set type
  sheet.getCell(bodyRow, updatedCol).value = type
  sheet.getCell(bodyRow, updatedCol).style = { ...nameStyleCenter }
  updatedCol += 1

  // Length 1 (Polygon)
  if (settings.sheet_rows_visibility?.polyon_length_1) {
    const polyLength1 = inspectionItems.find((it) => it.item_type === 'polygon_length_1')
    updatedCol = setCategoryValues(
      sheet,
      bodyRow,
      updatedCol,
      polyLength1?.polygon_length_1?.estimated_value,
      polyLength1,
      settings,
      settings.sheet_rows_tolerance_type?.polyon_length_1 || DEFAULT_TOLERANCE_TYPE,
      meterToMilimeterRounded,
      valueStyleMili,
      plusMinusValueStyleMili,
    )
  }

  // Length 2 (Polygon)
  if (settings.sheet_rows_visibility?.polyon_length_2) {
    const polyLength2 = inspectionItems.find((it) => it.item_type === 'polygon_length_2')
    updatedCol = setCategoryValues(
      sheet,
      bodyRow,
      updatedCol,
      polyLength2?.polygon_length_2?.estimated_value,
      polyLength2,
      settings,
      settings.sheet_rows_tolerance_type?.polyon_length_2 || DEFAULT_TOLERANCE_TYPE,
      meterToMilimeterRounded,
      valueStyleMili,
      plusMinusValueStyleMili,
    )
  }

  // Perimeter (Polygon)
  if (settings.sheet_rows_visibility?.polygon_perimeter) {
    const polyPerimeter = inspectionItems.find((it) => it.item_type === 'polygon_perimeter')
    updatedCol = setCategoryValues(
      sheet,
      bodyRow,
      updatedCol,
      polyPerimeter?.polygon_perimeter?.estimated_value,
      polyPerimeter,
      settings,
      settings.sheet_rows_tolerance_type?.polygon_perimeter || DEFAULT_TOLERANCE_TYPE,
      meterToMilimeterRounded,
      valueStyleMili,
      plusMinusValueStyleMili,
    )
  }

  // Area (Polygon)
  if (settings.sheet_rows_visibility?.polygon_area) {
    const polyArea = inspectionItems.find((it) => it.item_type === 'polygon_area')
    updatedCol = setCategoryValues(
      sheet,
      bodyRow,
      updatedCol,
      polyArea?.polygon_area?.estimated_value,
      polyArea,
      settings,
      settings.sheet_rows_tolerance_type?.polygon_area || DEFAULT_TOLERANCE_TYPE,
      meterRounded,
      valueStyleMeter,
      plusMinusValueStyleMeter,
    )
  }

  return updatedCol
}

/**
 * Set rows for an inspection area.
 *
 * @param sheet Current worksheet
 * @param startRow Current row
 * @param colCountPerMetric Number of columns per metric
 * @param totalCols Total columns
 * @param inspectionArea Inspection Area
 * @param inspectionItems Inspection Items for the project
 * @param shapes Shapes of the project
 * @param settings Project settings
 * @param forProject If the row is for the project
 * @returns New body row
 */
const setInspectionAreaRow = (
  sheet: Worksheet,
  startRow: number,
  colCountPerMetric: [ProjectSheetRowVisibility, number][],
  totalCols: number,
  project: Project,
  inspectionArea: InspectionArea,
  inspectionSheet: InspectionSheet,
  inspectionItems: InspectionItem[],
  shapes: Shapes,
  settings: ProjectSheetSettings,
  forProject = false,
): number => {
  let updatedRow = startRow
  const startingRow = updatedRow
  const beginningCol = forProject ? 1 : 0 // 1 is for area name
  const dataStartCol = forProject ? 1 + 4 : 0 // 1 is for area name, 4 is for profile group

  // Figure out how many rows will we have. It will be a total of volumes + standalone planes
  const volumes = inspectionItems.filter((item) => item.item_type === 'volume')
  const standaloneShapes = inspectionItems.filter(
    (item) =>
      volumes.find((v) => v.inspection_item_id === item.volume_id) === undefined && item.item_type === 'polygon_area',
  )
  const customMeasurement = inspectionItems.filter((item) => item.item_type === 'length_with_distance_tool')

  // Get polylines
  const polylines = inspectionItems.filter((item) => item.item_type === 'polyline_length')

  // go through volumes first
  if (settings.sheet_models_visibility?.volume) {
    volumes.forEach((volume, index) => {
      let bodyCol = dataStartCol + 1
      const volumeShapes = {
        polygons: shapes.polygons?.filter((polygon) => volume.shape_ids.polygons?.includes(polygon.shape_id)) || [],
      }

      // Upper-plane-specific columns
      const upperPlaneId = volumeShapes.polygons.find((p) => p.plane_side === PlaneSide.UPPER)?.shape_id || ''
      bodyCol = setPlaneColumns(
        volume.inspection_item_id?.substring(volume.inspection_item_id.length - 8) || '',
        sheet,
        bodyCol,
        updatedRow,
        volume.part_name || `体積 ${index + 1}`,
        '体積',
        inspectionItems.filter((it) => it.shape_ids.polygons?.includes(upperPlaneId)),
        settings,
      )

      // Volume columns
      if (settings.sheet_rows_visibility?.volume) {
        bodyCol = setCategoryValues(
          sheet,
          updatedRow,
          bodyCol,
          volume.volume?.estimated_value || 0,
          volume,
          settings,
          settings.sheet_rows_tolerance_type?.volume || DEFAULT_TOLERANCE_TYPE,
          undefined,
          valueStyleMeter,
          plusMinusValueStyleMeter,
        )
      }

      // Grid depth
      if (settings.sheet_rows_visibility?.grid) {
        const gridDepth = inspectionItems.find(
          (it) => it.item_type === 'grid' && it.volume_id === volume.inspection_item_id,
        )
        bodyCol = setCategoryValues(
          sheet,
          updatedRow,
          bodyCol,
          gridDepth?.grid?.estimated_value,
          gridDepth,
          settings,
          settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
          meterToMillimeter,
          valueStyleMili,
          plusMinusValueStyleMili,
          gridDepth?.grid
            ? [
                {
                  title: '最小',
                  value: gridDepth?.grid?.min_grid_depth,
                  isHidden: !settings.sheet_rows_visibility?.min_grid_depth,
                },
                {
                  title: '最大',
                  value: gridDepth?.grid?.max_grid_depth,
                  isHidden: !settings.sheet_rows_visibility?.max_grid_depth,
                },
              ]
            : [],
          gridDepth?.grid && settings.sheet_diagram_visibility?.grid_diagram
            ? {
                name: 'グリッド点',
                sheetName: GRID_VALUES_SHEET_NAME,
                cellId: `grid_values_${gridDepth?.inspection_item_id?.substring(gridDepth.inspection_item_id.length - 8) || ''}`,
              }
            : undefined,
        )
      }

      // cylinder depth (upper)
      if (settings.sheet_rows_visibility?.upper_plane_to_cylinders_distance) {
        const upperDepth = inspectionItems.find(
          (item) =>
            item.item_type === `plane_to_cylinders_distance` &&
            shapes.polygons?.find(
              (shape) => shape.shape_id === item.shape_ids.polygons[0] && shape.plane_side === PlaneSide.UPPER,
            ) &&
            item.volume_id === volume.inspection_item_id,
        )
        bodyCol = setCategoryValues(
          sheet,
          updatedRow,
          bodyCol,
          upperDepth?.plane_to_cylinders_distance?.estimated_value,
          upperDepth,
          settings,
          settings.sheet_rows_tolerance_type?.upper_plane_to_cylinders_distance || DEFAULT_TOLERANCE_TYPE,
          meterToMillimeter,
          valueStyleMili,
          plusMinusValueStyleMili,
        )
      }

      // cylinder depth (lower)
      if (settings.sheet_rows_visibility?.lower_plane_to_cylinders_distance) {
        const lowerDepth = inspectionItems.find(
          (item) =>
            item.item_type === `plane_to_cylinders_distance` &&
            shapes.polygons?.find(
              (shape) => shape.shape_id === item.shape_ids.polygons[0] && shape.plane_side === PlaneSide.LOWER,
            ) &&
            item.volume_id === volume.inspection_item_id,
        )
        setCategoryValues(
          sheet,
          updatedRow,
          bodyCol,
          lowerDepth?.plane_to_cylinders_distance?.estimated_value,
          lowerDepth,
          settings,
          settings.sheet_rows_tolerance_type?.lower_plane_to_cylinders_distance || DEFAULT_TOLERANCE_TYPE,
          meterToMillimeter,
          valueStyleMili,
          plusMinusValueStyleMili,
        )
      }

      updatedRow += 1
    })
  }

  // standalone planes
  if (settings.sheet_models_visibility?.plane) {
    standaloneShapes.forEach((inspectionItem, index) => {
      const newColNumber = setPlaneColumns(
        inspectionItem.inspection_item_id?.substring(inspectionItem.inspection_item_id.length - 8) || '',
        sheet,
        dataStartCol + 1,
        updatedRow,
        inspectionItem.part_name || `面積${zeroPad(index + 1, 3)}`,
        '面積',
        inspectionItems.filter((it) => it.shape_ids.polygons?.includes(inspectionItem.shape_ids.polygons[0])),
        settings,
      )

      // no grid and volume for standalone shapes, so we fill with grey cells
      for (let i = newColNumber; i < totalCols; i += 1) {
        sheet.getCell(updatedRow, i).style = { ...nameStyle } // copy to avoid overwriting
      }

      updatedRow += 1
    })
  }

  // polylines
  if (settings.sheet_models_visibility?.polyline) {
    polylines.forEach((inspectionItem, index) => {
      // set name
      const cellId = inspectionItem.inspection_item_id?.substring(inspectionItem.inspection_item_id.length - 8) || ''
      const name = inspectionItem.part_name || `延長${zeroPad(index + 1, 3)}`
      if (settings.sheet_diagram_visibility?.polyline_diagram) {
        sheet.getCell(updatedRow, dataStartCol + 1).value = {
          formula: `HYPERLINK("#'${DIAGRAM_SHEET_NAME}'!"&ADDRESS(CELL("row", diagram_${cellId}), CELL("col",diagram_${cellId})), "${name}")`,
          date1904: false,
        }
        sheet.getCell(updatedRow, dataStartCol + 1).style = { ...nameStyleCenter, ...underlineStyle } // copy to avoid overwriting
        sheet.getCell(updatedRow, dataStartCol + 1).name = `data_${cellId}`
      } else {
        sheet.getCell(updatedRow, dataStartCol + 1).value = name
        sheet.getCell(updatedRow, dataStartCol + 1).style = { ...nameStyleCenter } // copy to avoid overwriting
      }

      // set type
      sheet.getCell(updatedRow, dataStartCol + 2).value = '延長'
      sheet.getCell(updatedRow, dataStartCol + 2).style = { ...nameStyleCenter } // copy to avoid overwriting

      setCategoryValues(
        sheet,
        updatedRow,
        dataStartCol + 3,
        inspectionItem.polyline_length?.estimated_value || 0,
        inspectionItem,
        settings,
        settings.sheet_rows_tolerance_type?.polyline_length || DEFAULT_TOLERANCE_TYPE,
        meterToMilimeterRounded,
        valueStyleMili,
        plusMinusValueStyleMili,
      )

      // using less columns for polyline, so we fill the unused ones with grey cells
      // 2 = types, 1 = start column for this shading, since this does not have it's own group, use col count of first metric
      for (let i = dataStartCol + 2 + (colCountPerMetric[0][1] || 0) + 1; i < totalCols; i += 1) {
        sheet.getCell(updatedRow, i).style = { ...nameStyle } // copy to avoid overwriting
      }

      updatedRow += 1
    })
  }

  // custom measurements
  if (settings.sheet_models_visibility?.length_with_distance_tool) {
    customMeasurement.forEach((inspectionItem, index) => {
      sheet.getCell(updatedRow, dataStartCol + 1).value = inspectionItem.part_name || `距離${zeroPad(index + 1, 3)}`
      sheet.getCell(updatedRow, dataStartCol + 1).style = { ...nameStyleCenter } // copy to avoid overwriting

      // set type
      sheet.getCell(updatedRow, dataStartCol + 2).value = '距離'
      sheet.getCell(updatedRow, dataStartCol + 2).style = { ...nameStyleCenter } // copy to avoid overwriting

      setCategoryValues(
        sheet,
        updatedRow,
        dataStartCol + 3,
        inspectionItem.length_with_distance_tool?.estimated_value || 0,
        inspectionItem,
        settings,
        settings.sheet_rows_tolerance_type?.length_with_distance_tool || DEFAULT_TOLERANCE_TYPE,
        meterToMilimeterRounded,
        valueStyleMili,
        plusMinusValueStyleMili,
      )

      // using less columns for custom measurement, so we fill the unused ones with grey cells
      // 2 = types, 1 = start column for this shading, since this does not have it's own group, use col count of first metric
      for (let i = dataStartCol + 2 + (colCountPerMetric[0][1] || 0) + 1; i < totalCols; i += 1) {
        sheet.getCell(updatedRow, i).style = { ...nameStyle } // copy to avoid overwriting
      }

      updatedRow += 1
    })
  }

  // end row for merge depends on whether there's data or not for this inspecteion area.
  // if there's data, we started with current row (updatedRow) minus 1 as updatedRow is already incremented for new data
  let endRow = updatedRow - 1
  let offset = 0
  if (startingRow === updatedRow) {
    endRow = startingRow
    offset = 1 // we need to increment the row for the next inspection area
  }

  // set inspection area name, only for project-level
  if (forProject) {
    sheet.mergeCells(startingRow, beginningCol, endRow, beginningCol)
    sheet.getCell(startingRow, beginningCol).value = {
      text: inspectionArea.inspection_area_name,
      hyperlink: `${APP_URL}/projects/${project.project_id}/editor?area=${inspectionArea.inspection_area_id}`,
    }
    sheet.getCell(startRow, beginningCol).style = { ...nameStyle, ...underlineStyle } // copy to avoid overwriting
    createOuterBorder(sheet, [beginningCol, startRow], [beginningCol, endRow])

    sheet.mergeCells(startingRow, beginningCol + 1, endRow, beginningCol + 1)
    sheet.getCell(startingRow, beginningCol + 1).value =
      inspectionSheet.construction_properties?.construction_type || '-'
    sheet.getCell(startRow, beginningCol + 1).style = { ...nameStyleCenter } // copy to avoid overwriting
    createOuterBorder(sheet, [beginningCol + 1, startRow], [beginningCol + 1, endRow])

    sheet.mergeCells(startingRow, beginningCol + 2, endRow, beginningCol + 2)
    sheet.getCell(startingRow, beginningCol + 2).value =
      inspectionSheet.construction_properties?.construction_type_detailed || '-'
    sheet.getCell(startRow, beginningCol + 2).style = { ...nameStyleCenter } // copy to avoid overwriting
    createOuterBorder(sheet, [beginningCol + 2, startRow], [beginningCol + 2, endRow])

    sheet.mergeCells(startingRow, beginningCol + 3, endRow, beginningCol + 3)
    sheet.getCell(startingRow, beginningCol + 3).value = inspectionSheet.creator_name || '-'
    sheet.getCell(startRow, beginningCol + 3).style = { ...nameStyleCenter } // copy to avoid overwriting
    createOuterBorder(sheet, [beginningCol + 3, startRow], [beginningCol + 3, endRow])

    sheet.mergeCells(startingRow, beginningCol + 4, endRow, beginningCol + 4)
    sheet.getCell(startingRow, beginningCol + 4).value = inspectionSheet?.create_time_user_specified
      ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
      : '-'
    sheet.getCell(startRow, beginningCol + 4).style = { ...nameStyleCenter } // copy to avoid overwriting
    createOuterBorder(sheet, [beginningCol + 4, startRow], [beginningCol + 4, endRow])
  }

  // border around type and name columns
  createOuterBorder(sheet, [dataStartCol + 1, startRow], [dataStartCol + 2, endRow])

  // border around data columns
  let i = dataStartCol + 3
  colCountPerMetric.forEach(([, count]) => {
    createOuterBorder(sheet, [i, startRow], [i + count - 1, endRow])
    i += count
  })

  // if there are no data, we still need to fill name/type columns
  if (volumes.length + standaloneShapes.length + customMeasurement.length + polylines.length === 0) {
    sheet.getCell(updatedRow, dataStartCol + 1).style = { ...nameStyle } // copy to avoid overwriting
    sheet.getCell(updatedRow, dataStartCol + 2).style = { ...nameStyle } // copy to avoid overwriting
    createOuterBorder(sheet, [dataStartCol + 1, updatedRow], [dataStartCol + 2, updatedRow])
  }

  return updatedRow + offset
}

/**
 * Add the data sheet to the workbook.
 *
 * @param workbook Workbook
 * @param project Current project
 * @param inspectionAreas All inspection areas of the project
 * @param inspectionSheet Inspection sheet
 * @param inspectionItems All inspection items of the project
 * @param shapes All shapes of the project
 */
export const addDataSheet = (
  workbook: Workbook,
  project: Project,
  inspectionAreas: InspectionArea[],
  inspectionSheets: InspectionSheet[],
  inspectionItems: InspectionItem[],
  shapes: Shapes,
  forProject = false,
) => {
  let inspectionSheet = findInspectionAreaSheet(inspectionSheets, inspectionAreas[0].inspection_area_id)
  const sheet = workbook.addWorksheet(DATA_SHEET_NAME, {
    pageSetup: { orientation: 'landscape', paperSize: 9 },
  })

  // start from 1st row
  let bodyRow = 1

  // ## worksheet title
  bodyRow = setTitle(sheet, bodyRow, DATA_SHEET_NAME) + 1 // +1 for padding

  // ## profile section
  bodyRow = setProfileGroup(sheet, bodyRow, project, inspectionAreas[0], inspectionSheet, forProject) + 1 // +1 for padding

  // ## Summary
  // inspection sheet is only available for inspection area export, hence the check below
  bodyRow = setSummary(sheet, inspectionItems, shapes, bodyRow) + 1 // +1 for padding

  // ## Data table
  const [newRow, colCountPerMetric, totalCols] = setTableHeaders(sheet, bodyRow, project, forProject)
  bodyRow = newRow

  // for every inspection area, set the values
  if (!forProject && inspectionSheet) {
    setInspectionAreaRow(
      sheet,
      bodyRow,
      colCountPerMetric,
      totalCols,
      project,
      inspectionAreas[0],
      inspectionSheet,
      inspectionItems,
      shapes,
      project,
      forProject,
    )
  } else {
    inspectionAreas.forEach((inspectionArea) => {
      inspectionSheet = findInspectionAreaSheet(inspectionSheets, inspectionArea.inspection_area_id)
      if (!inspectionSheet) return

      const items = inspectionItems.filter(
        (item) =>
          parseInspectionItemPartitionKey(item.partition_key!).inspectionAreaId === inspectionArea.inspection_area_id,
      )
      bodyRow = setInspectionAreaRow(
        sheet,
        bodyRow,
        colCountPerMetric,
        totalCols,
        project,
        inspectionArea,
        inspectionSheet,
        items,
        shapes,
        project,
        forProject,
      )
    })
  }

  fitColumns(sheet, forProject)
  sheet.eachRow((row, index) => {
    if (index > 9) {
      row.height = 25
    }
  })
}
