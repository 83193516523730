import { PointArray } from './attribute'
import { ShapeKeyType } from './shape'

export enum GridOrderDirection {
  /**
   * Top-to-bottom then left-to-right.
   */
  Vertical = 'vertical',

  /**
   * Left-to-right then top-to-bottom.
   */
  Horizontal = 'horizontal',
}

export enum GridType {
  /**
   * Grid is generated from the center of the volume.
   */
  CenterBased = 'center_based',

  /**
   * Grid is generated from the edge of the volume.
   */
  EdgeBased = 'edge_based',
}

export interface IntervalsAxisConfigValue {
  min: number
  max: number
  value: number
}

export interface IntervalsAxisConfig {
  /**
   * Total length of the axis.
   */
  total: number

  /**
   * Interval between grid points.
   */
  interval: IntervalsAxisConfigValue

  /**
   * Distance from the edge of the polygon.
   * Only for edge-based grid.
   */
  edgeDistance: IntervalsAxisConfigValue

  /**
   * Number of points to generate along the axis.
   * Only for edge-based grid.
   */
  pointCount: IntervalsAxisConfigValue

  /**
   * Offset  the origin point.
   * Only for center-based grid.
   */
  offset: number
}

export interface IntervalsConfig {
  shapeKey: ShapeKeyType
  topPlaneId: string
  bottomPlaneId: string
  longAxis: IntervalsAxisConfig
  shortAxis: IntervalsAxisConfig

  /**
   * Angle to rotate the grid.
   */
  angle: number

  /**
   * The direction to generate the grid points.
   * When using without camera profile this can be arbitrary but
   * when using with camera profile, this should be from the camera's perspective.
   */
  orderDirection: GridOrderDirection

  /**
   * The type of grid to generate.
   */
  type: GridType

  /**
   * Flag to indicate if the grid is locked.
   */
  locked?: boolean
}

export interface InspectionItemGridListDistance {
  name: string
  active: boolean
  distance: number | null
  position_on_grid_point: PointArray
  position_on_projected_point: PointArray
}

export interface InspectionItemGrid {
  estimated_value: number
  list_distances: InspectionItemGridListDistance[]
  min_grid_depth: number
  min_grid_index: number
  max_grid_depth: number
  max_grid_index: number
  intervals?: {
    long_axis: number
    short_axis: number
  }
  grid_type: GridType
  grid_order_direction: GridOrderDirection
  distances_from_edge?: {
    long_axis: number
    short_axis: number
  }
  number_of_points?: {
    long_axis: number
    short_axis: number
  }
}
