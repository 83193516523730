import './style.css'

import { Badge, Box, Button, Card, Divider, HStack, Heading, Spacer, Text, Tooltip } from '@chakra-ui/react'

import { GRACE_HOURS_BEFORE_RENEWAL } from 'config/constants'

interface SubscriptionPlanProps {
  active: boolean
  disabled?: boolean
  label?: string
  name: string
  renewalDate?: Date
  description: string
  price: number
  interval: 'year' | 'month'
  autoRenewal?: 'Y' | 'N'
  onToggleActivation?: () => void
  onToggleAutoRenewal?: () => void
}

const SubscriptionPlan = ({
  active,
  disabled,
  label,
  name,
  description,
  price,
  renewalDate,
  interval,
  autoRenewal,
  onToggleActivation,
  onToggleAutoRenewal,
}: SubscriptionPlanProps) => {
  // The auto-renewal setting can be changed up to two hours before the expiration date
  const now = new Date()
  const exceedsBaseFeatureRenewalDeadline =
    renewalDate && renewalDate.getTime() - now.getTime() < GRACE_HOURS_BEFORE_RENEWAL * 60 * 60 * 1000

  return (
    <Tooltip label={label} bg="red.600" hasArrow>
      <Card className={['subscription-plan', active ? 'active' : 'inactive'].join(' ')}>
        <HStack className="header">
          <Heading size="md">{name}</Heading>
          <Spacer />
          <Tooltip
            label={`自動更新設定の変更は有効期限最終日の${24 - GRACE_HOURS_BEFORE_RENEWAL}時までに実施してください`}
            hasArrow
          >
            {active && autoRenewal === 'Y' && (
              <Button disabled={exceedsBaseFeatureRenewalDeadline} colorScheme="red" onClick={onToggleAutoRenewal}>
                自動更新解除
              </Button>
            )}
            {active && autoRenewal === 'N' && (
              <Button disabled={exceedsBaseFeatureRenewalDeadline} colorScheme="primary" onClick={onToggleAutoRenewal}>
                自動更新する
              </Button>
            )}
          </Tooltip>
          {active ? (
            <Button colorScheme="primary" disabled onClick={onToggleActivation}>
              有効
            </Button>
          ) : (
            <Button colorScheme="primary" disabled={disabled} onClick={onToggleActivation}>
              有効化する
            </Button>
          )}
        </HStack>
        <HStack className="summary">
          {active ? <Badge colorScheme="primary">有効</Badge> : <Badge>無効</Badge>}
          {autoRenewal === 'Y' ? <Badge colorScheme="red">自動更新</Badge> : null}

          {active ? (
            <Text className="renewal-date">
              {autoRenewal === 'Y' ? '次回更新日:' : '有効期限:'}{' '}
              {renewalDate &&
                renewalDate.toLocaleDateString('ja-JP', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
            </Text>
          ) : null}
          {active && autoRenewal === 'N' && (
            <Text className="warning">自動更新しない場合は有効期限を過ぎるとデータが削除されます。 </Text>
          )}
          <Spacer />
          <Text className="price">
            ¥{price.toLocaleString()}/{interval === 'year' ? '年' : '月'}
          </Text>
        </HStack>
        <Divider />
        <Box className="description">{description}</Box>
      </Card>
    </Tooltip>
  )
}
export default SubscriptionPlan
