import './styles.css'

import { useMemo } from 'react'

import { Flex, Grid, Heading, VStack } from '@chakra-ui/react'
import { Vector2 } from 'three'

import { findLowerPlane, findUpperPlane } from 'services/Shape'

import { DIAGRAM_WIDTH, PLUS_MINS_PADDING } from '../../constants'
import useDiagram from '../../hooks/useDiagram'
import { GridRowProps } from '../../interfaces'
import { generateGridBox, getTableWidths } from '../../utils'
import NoData from '../NoData'
import PolygonDiagram from '../PolygonDiagram'
import GridValues from './GridValues'

const GridRow = ({
  inspectionArea,
  inspectionItem,
  shapes,
  settings,
  allAdditionalMetricsToggle,
  upperPolygon,
  lowerPolygon,
  cameraProfile,
  toleranceType,
  imageId,
}: GridRowProps) => {
  const topPlane = findUpperPlane(shapes, inspectionItem)
  const bottomPlane = findLowerPlane(shapes, inspectionItem)

  /**
   * Calculate the maximum width of the grid values box.
   */
  const tableWidths = useMemo(
    () => getTableWidths(allAdditionalMetricsToggle, settings),
    [settings, allAdditionalMetricsToggle],
  )

  const { diagram, scale, intervalGridPoints } = useDiagram({
    // Width and height are arbitrary values for the sake of
    // having a consistent guideline for the grid values vs polygon.
    width: 1000,
    height: 1000,
    upperPolygon,
    lowerPolygon,
    cameraProfile,
    settings,
    inspectionItem,
  })

  /**
   * Calculate label positions for grid intervals.
   */
  const gridValues = useMemo(() => {
    if (!diagram) return []

    return generateGridBox(
      intervalGridPoints.map((point) => new Vector2(point[0], point[1])),
      diagram.orientation,
      scale,
      diagram.grid,
      diagram?.vertices.map((v) => v.point),
    )
  }, [diagram, scale, intervalGridPoints])

  const diagramColWidth = Math.max(
    tableWidths.left + (settings.sheet_cols_visibility?.tolerance ? PLUS_MINS_PADDING : 0),
    DIAGRAM_WIDTH,
  )

  return (
    <VStack className="grid-values">
      <Flex className="inspection-sheet-table-header" width={`${tableWidths.max}px`}>
        <Heading size="sm" width={`${diagramColWidth}px`}>
          グリッド図面
        </Heading>
        <Heading size="sm" width={`${DIAGRAM_WIDTH}px`}>
          深さグリッド点
        </Heading>
      </Flex>
      <Flex className="grid-values-boxes-container">
        {bottomPlane && inspectionItem?.grid && (
          <Flex
            width={`${diagramColWidth}px`}
            borderRight="1px solid var(--chakra-colors-gray-300)"
            mx={7}
            justifyContent="center"
            alignItems="center"
            transition="width ease-in-out 0.2s"
          >
            <PolygonDiagram
              key={`${inspectionItem.inspection_item_id}--grid_diagram`}
              imageId={imageId}
              width={DIAGRAM_WIDTH}
              height={350}
              upperPolygon={topPlane}
              lowerPolygon={bottomPlane}
              cameraProfile={inspectionArea?.camera_profile}
              inspectionItem={inspectionItem}
              settings={settings}
            />
          </Flex>
        )}

        {topPlane && !inspectionItem?.grid && (
          <NoData
            message="この体積には深さグリッドが定義されていません"
            inspectionArea={inspectionArea}
            hidden={!settings.sheet_diagram_visibility?.grid_diagram}
          />
        )}

        <Flex
          minWidth={`calc(${tableWidths.right - PLUS_MINS_PADDING}px - (var(--chakra-space-7)*2))`}
          alignItems="center"
        >
          {gridValues?.length ? (
            <Grid templateColumns={`repeat(${gridValues[0].length}, 70px)`} className="grid-values-boxes">
              {gridValues.flat().map((point) => (
                <GridValues key={point?.label} value={point} toleranceType={toleranceType} settings={settings} />
              ))}
            </Grid>
          ) : null}
        </Flex>
      </Flex>
    </VStack>
  )
}

export default GridRow
