import { Anchor, Workbook, Worksheet } from 'exceljs'

import { APP_URL } from 'config/environments'

import { InspectionArea, InspectionItem, InspectionSheet, InspectionSheetDiagram } from 'interfaces/inspection'
import { Project, ProjectSheetSettings } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { parseInspectionItemPartitionKey } from 'services/InspectionArea'
import { getDiagramHeaders } from 'services/InspectionSheet'
import { getIdUniqueString, zeroPad } from 'services/Util'

import {
  DATA_SHEET_NAME,
  DIAGRAM_SHEET_NAME,
  GRID_VALUES_SHEET_NAME,
  createOuterBorder,
  fitColumns,
  getDiagramsByInspectionItem,
  makeInternalLinkValue,
  setHeaderCell,
  setProfileGroup,
  setSummary,
  setTitle,
} from './common'
import { centerCenterStyle, fillGrey50, nameStyle, underlineStyle } from './styles'

export const addDiagramSheet = (
  workbook: Workbook,
  project: Project,
  inspectionAreas: InspectionArea[],
  inspectionSheet: InspectionSheet | null,
  inspectionItems: InspectionItem[],
  shapes: Shapes,
  diagrams: Record<string, InspectionSheetDiagram>,
  forProject = false,
) => {
  const sheet = workbook.addWorksheet(DIAGRAM_SHEET_NAME, {
    pageSetup: { orientation: 'landscape', paperSize: 9 },
  })

  // start from 1st row
  let bodyRow = 1

  // ## worksheet title
  bodyRow = setTitle(sheet, bodyRow, DIAGRAM_SHEET_NAME) + 1 // +1 for padding

  // ## profile section
  if (inspectionSheet) {
    bodyRow =
      setProfileGroup(
        sheet,
        bodyRow,
        project,
        inspectionSheet ? inspectionAreas[0] : undefined,
        inspectionSheet,
        forProject,
      ) + 1 // +1 for padding
  }

  // ## Summary
  // inspection sheet is only available for inspection area export, hence the check below
  bodyRow = setSummary(sheet, inspectionItems, shapes, bodyRow) + 1 // +1 for padding

  // Set table headers
  const [newRow, totalCols] = setTableHeaders(sheet, bodyRow, project, forProject)
  bodyRow = newRow

  // Set table data
  if (inspectionAreas.length === 1) {
    bodyRow = setInspectionAreaRow(
      workbook,
      sheet,
      bodyRow,
      totalCols,
      project,
      inspectionAreas[0],
      inspectionItems,
      diagrams,
      project,
      forProject,
    )
  } else {
    inspectionAreas.forEach((inspectionArea) => {
      const items = inspectionItems.filter(
        (item) =>
          parseInspectionItemPartitionKey(item.partition_key!).inspectionAreaId === inspectionArea.inspection_area_id &&
          (item.item_type === 'volume' ||
            item.item_type === 'polygon_area' ||
            item.item_type === 'polyline_length' ||
            item.item_type === 'grid'),
      )

      if (items.length) {
        bodyRow = setInspectionAreaRow(
          workbook,
          sheet,
          bodyRow,
          totalCols,
          project,
          inspectionArea,
          items,
          diagrams,
          project,
          forProject,
        )
      }
    })
  }

  fitColumns(sheet)

  // set diagram column widths
  const diagCol = forProject ? 2 : 1
  sheet.columns[diagCol].width = 60

  // Only set the width if both diagrams are visible
  if (project.sheet_diagram_visibility?.plane_diagram && project.sheet_diagram_visibility?.grid_diagram) {
    sheet.columns[diagCol + 1].width = 60
  }
}

/**
 * Set table headers
 *
 * @param sheet Current worksheet
 * @param bodyRow Current row
 * @param forProject Whether the headers are for project-level export
 * @return [Next row, Total columns]
 */
const setTableHeaders = (
  sheet: Worksheet,
  bodyRow: number,
  settings: ProjectSheetSettings,
  forProject: boolean,
): [number, number] => {
  let bodyCol = 1
  const { planeHeader, gridHeader } = getDiagramHeaders(settings)

  // inspection area name
  if (forProject) {
    setHeaderCell(sheet, bodyRow, bodyCol, 'エリア名', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow])
    bodyCol += 1
  }

  // Volume name
  setHeaderCell(sheet, bodyRow, bodyCol, '名称', 'lg')
  createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow])
  bodyCol += 1

  // Volume diagram
  if (planeHeader) {
    setHeaderCell(sheet, bodyRow, bodyCol, planeHeader, 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow])
    bodyCol += 1
  }

  // Grid diagram
  if (gridHeader) {
    setHeaderCell(sheet, bodyRow, bodyCol, gridHeader, 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow])
    bodyCol += 1
  }

  if (settings.sheet_diagram_visibility?.grid_diagram) {
    setHeaderCell(sheet, bodyRow, bodyCol, 'グリッド点', 'lg')
    createOuterBorder(sheet, [bodyCol, bodyRow], [bodyCol, bodyRow])
    sheet.getColumn(bodyCol).width = 15
    bodyCol += 1
  }

  return [bodyRow + 1, bodyCol]
}

/**
 * Set inspection area row diagrams.
 *
 * @param workbook Current workbook
 * @param sheet Current worksheet
 * @param bodyRow Current row
 * @param project Project
 * @param inspectionArea Current inspection area
 * @param inspectionItems Inspection items for the inspection area
 * @param diagrams Plane diagrams for the inspection area
 * @param forProject Whether the row is for project-level export
 * @return Next row
 */
const setInspectionAreaRow = (
  workbook: Workbook,
  sheet: Worksheet,
  bodyRow: number,
  totalCols: number,
  project: Project,
  inspectionArea: InspectionArea,
  inspectionItems: InspectionItem[],
  diagrams: Record<string, InspectionSheetDiagram>,
  settings: ProjectSheetSettings,
  forProject = false,
): number => {
  let updatedRow = bodyRow
  const updatedCol = forProject ? 1 : 0

  // Figure out how many rows will we have. It will be a total of volumes + standalone planes
  const volumes =
    settings.sheet_diagram_visibility?.plane_diagram || settings.sheet_diagram_visibility?.grid_diagram
      ? inspectionItems.filter((item) => item.item_type === 'volume')
      : []
  const standaloneShapes = settings.sheet_diagram_visibility?.plane_diagram
    ? inspectionItems.filter(
        (item) =>
          volumes.find((v) => v.inspection_item_id === item.volume_id) === undefined &&
          item.item_type === 'polygon_area',
      )
    : []
  const polylines = settings.sheet_diagram_visibility?.polyline_diagram
    ? inspectionItems.filter((item) => item.item_type === 'polyline_length')
    : []
  const totalRows = Math.max(volumes.length + standaloneShapes.length + polylines.length, 1) // At least 1 row for the inspection area

  // set inspection area name, only for project-level
  if (forProject) {
    sheet.mergeCells(updatedRow, updatedCol, updatedRow + totalRows - 1, updatedCol)
    sheet.getCell(updatedRow, updatedCol).value = {
      text: inspectionArea.inspection_area_name,
      hyperlink: `${APP_URL}/projects/${project.project_id}/editor?area=${inspectionArea.inspection_area_id}`,
    }
    sheet.getCell(updatedRow, updatedCol).style = { ...nameStyle, ...underlineStyle } // copy to avoid overwriting
    createOuterBorder(sheet, [updatedCol, updatedRow], [updatedCol, updatedRow + totalRows - 1])
  }

  // go through volumes first
  volumes.forEach((volume, index) => {
    const cellId = volume.inspection_item_id?.substring(volume.inspection_item_id.length - 8)
    sheet.getCell(updatedRow, updatedCol + 1).style = { ...nameStyle, ...underlineStyle }
    sheet.getCell(updatedRow, updatedCol + 1).name = `diagram_${cellId}`
    sheet.getCell(updatedRow, updatedCol + 1).value = makeInternalLinkValue(
      DATA_SHEET_NAME,
      `data_${cellId}`,
      volume.part_name || `体積 ${index + 1}`,
    )

    // Plane diagram
    let diagramCol = updatedCol + 1
    let height = 0
    const planeDiagrams = getDiagramsByInspectionItem(volume, diagrams, settings)
    Object.values(planeDiagrams).forEach((diagram) => {
      const diagramImage = workbook.addImage({
        base64: diagram.image,
        extension: 'png',
      })

      const { width, height: dimHeight } = getImageDimensions(diagram.width, diagram.height, 405)
      height = dimHeight

      sheet.addImage(diagramImage, {
        tl: { col: diagramCol + 0.1, row: updatedRow - 1 + 0.1 } as Anchor, // -1 due to zero-index and 0.1 for padding
        ext: { width, height },
        editAs: 'oneCell',
      })

      diagramCol += 1
    })
    sheet.getRow(updatedRow).height = Math.max(height / 1.33 + 1.5, 20) // 1.33 is approximate height ratio of row height in Excel, 1.5 is padding

    // Link to grid values
    const grid = inspectionItems.find(
      (item) => item.item_type === 'grid' && item.volume_id === volume.inspection_item_id,
    )
    if (settings.sheet_diagram_visibility?.grid_diagram && grid) {
      sheet.getCell(updatedRow, totalCols - 1).value = makeInternalLinkValue(
        GRID_VALUES_SHEET_NAME,
        `grid_values_${getIdUniqueString(grid.inspection_item_id!)}`,
        'グリッド点',
      )
      sheet.getCell(updatedRow, totalCols - 1).style = { ...underlineStyle, ...centerCenterStyle }
    }

    createOuterBorder(sheet, [updatedCol + 1, updatedRow], [totalCols - 1, updatedRow]) // totalCols include name but this border does not include name
    updatedRow += 1
  })

  // standalone planes
  standaloneShapes.forEach((inspectionItem, index) => {
    const cellId = inspectionItem.inspection_item_id?.substring(inspectionItem.inspection_item_id.length - 8)
    sheet.getCell(updatedRow, updatedCol + 1).style = {
      ...nameStyle,
      ...underlineStyle,
    }
    sheet.getCell(updatedRow, updatedCol + 1).name = `diagram_${inspectionItem.inspection_item_id?.substring(
      inspectionItem.inspection_item_id.length - 8,
    )}`
    sheet.getCell(updatedRow, updatedCol + 1).value = makeInternalLinkValue(
      DATA_SHEET_NAME,
      `data_${cellId}`,
      inspectionItem.part_name || `面積${zeroPad(index + 1, 3)}`,
    )

    // Plane diagram
    let diagramCol = updatedCol + 1
    let height = 0
    const hasPlaneDiagram =
      Object.keys(diagrams).some((id) => id.includes('plane_diagram')) &&
      settings.sheet_diagram_visibility?.plane_diagram
    const planeDiagrams = getDiagramsByInspectionItem(inspectionItem, diagrams, settings)
    Object.values(planeDiagrams).forEach((diagram) => {
      const diagramImage = workbook.addImage({
        base64: diagram.image,
        extension: 'png',
      })

      const { width, height: dimHeight } = getImageDimensions(diagram.width, diagram.height, 405)
      height = dimHeight

      sheet.addImage(diagramImage, {
        tl: { col: diagramCol + 0.1, row: updatedRow - 1 + 0.1 } as Anchor, // -1 due to zero-index and 0.1 for padding
        ext: { width, height },
        editAs: 'oneCell',
      })

      diagramCol += 1
    })
    sheet.getRow(updatedRow).height = Math.max(height / 1.33 + 1.5, 20) // 1.33 is approximate height ratio of row height in Excel, 1.5 is padding

    // standalone planes does not have grid diagram so shade it out
    if (settings.sheet_diagram_visibility?.grid_diagram) {
      sheet.getCell(updatedRow, updatedCol + 1 + (hasPlaneDiagram ? 2 : 1)).fill = { ...fillGrey50 }
      sheet.getCell(updatedRow, updatedCol + 1 + (hasPlaneDiagram ? 3 : 2)).fill = { ...fillGrey50 }
    }

    createOuterBorder(sheet, [updatedCol + 1, updatedRow], [totalCols - 1, updatedRow]) // totalCols include name but this border does not include name
    updatedRow += 1
  })

  // Polyline diagrams
  polylines.forEach((inspectionItem, index) => {
    sheet.getCell(updatedRow, updatedCol + 1).style = {
      ...nameStyle,
      ...underlineStyle,
    }

    const cellId = inspectionItem.inspection_item_id?.substring(inspectionItem.inspection_item_id.length - 8)
    sheet.getCell(updatedRow, updatedCol + 1).name = `diagram_${getIdUniqueString(inspectionItem.inspection_item_id!)}`
    sheet.getCell(updatedRow, updatedCol + 1).value = makeInternalLinkValue(
      DATA_SHEET_NAME,
      `data_${cellId}`,
      inspectionItem.part_name || `延長${zeroPad(index + 1, 3)}`,
    )

    // Plane diagram
    let diagramCol = updatedCol + 1
    let height = 0
    const polylineDiagrams = getDiagramsByInspectionItem(inspectionItem, diagrams, settings)
    Object.values(polylineDiagrams).forEach((diagram) => {
      const diagramImage = workbook.addImage({
        base64: diagram.image,
        extension: 'png',
      })

      const { width, height: dimHeight } = getImageDimensions(diagram.width, diagram.height, 405)
      height = dimHeight

      sheet.addImage(diagramImage, {
        tl: { col: diagramCol + 0.1, row: updatedRow - 1 + 0.1 } as Anchor, // -1 due to zero-index and 0.1 for padding
        ext: { width, height },
        editAs: 'oneCell',
      })

      diagramCol += 1
    })
    sheet.getRow(updatedRow).height = Math.max(height / 1.33 + 1.5, 20) // 1.33 is approximate height ratio of row height in Excel, 1.5 is padding

    // standalone planes does not have grid diagram so shade it out
    if (settings.sheet_diagram_visibility?.grid_diagram && settings.sheet_diagram_visibility?.plane_diagram) {
      sheet.getCell(updatedRow, updatedCol + 3).fill = { ...fillGrey50 }
      sheet.getCell(updatedRow, updatedCol + 4).fill = { ...fillGrey50 }
    }

    createOuterBorder(sheet, [updatedCol + 1, updatedRow], [totalCols - 1, updatedRow]) // totalCols include name but this border does not include name
    updatedRow += 1
  })

  // border around type and name columns
  createOuterBorder(sheet, [updatedCol + 1, bodyRow], [updatedCol + 1, updatedRow - 1])

  // If there were no rows added, we still need to increment the row
  if (updatedRow === bodyRow) {
    sheet.getCell(updatedRow, updatedCol + 1).style = { ...nameStyle } // copy to avoid overwriting
    createOuterBorder(sheet, [updatedCol + 1, updatedRow], [updatedCol + 1, updatedRow]) // diagram name
    createOuterBorder(sheet, [updatedCol + 2, updatedRow], [totalCols - 1, updatedRow]) // diagrams
    updatedRow += 1
  }

  return updatedRow
}

/**
 * Get scaled image dimensions, scaling down to fit within the maxWidth, maintaining aspect ratio.
 * If the image is smaller than maxWidth, it will return the original dimensions.
 *
 * @param width
 * @param height
 */
const getImageDimensions = (width: number, height: number, maxWidth: number): { width: number; height: number } => {
  if (width <= maxWidth) {
    return { width, height }
  }

  const ratio = width / height
  return { width: maxWidth, height: maxWidth / ratio }
}
