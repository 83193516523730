import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Project } from 'interfaces/project'
import { ProjectDashboardPermissionSet } from 'interfaces/validation'

import { getInvitedProjects, getProjects } from 'services/Projects'

export interface DashboardState {
  /**
   * Permission sets to be used on the Dashboard
   */
  permissionSet: ProjectDashboardPermissionSet

  /**
   * User's projects.
   */
  projects: Project[]

  /**
   * User's invited projects.
   */
  invitedProjects: Project[]

  /**
   * Whether projects has been loaded.
   */
  projectsLoaded: boolean
}

/**
 * Get the user's projects.
 *
 * @param {string} access_token Access token provided by Auth0
 * @param {string} user_id User ID
 * @param {function} showErrorModal Show error modal function
 */
export const fetchProjects = createAsyncThunk(
  'dashboard/fetchProjects',
  ({
    access_token,
    user_id,
    showErrorModal,
  }: {
    access_token: string
    user_id?: string
    showErrorModal: (message: string) => void
  }) => getProjects(access_token, user_id, showErrorModal),
)

/**
 * Get the user's invited projects.
 *
 * @param {string} access_token Access token provided by Auth0
 * @param {string} user_id User ID
 * @param {function} showErrorModal Show error modal function
 */
export const fetchInvitedProjects = createAsyncThunk(
  'dashboard/fetchInvitedProjects',
  ({
    access_token,
    user_id,
    showErrorModal,
  }: {
    access_token: string
    user_id?: string
    showErrorModal: (message: string) => void
  }) => getInvitedProjects(access_token, user_id, showErrorModal),
)

const initialState: DashboardState = {
  projects: [],
  invitedProjects: [],
  projectsLoaded: false,
  permissionSet: {
    ACTIVATE_FEATURES: [],
    CREATE_PROJECT_GROUP: [],
    INVITE_USER_TO_PROJECT: [],
    INVITE_TO_CREATE_ACCOUNT: [],
    BROWSE: [],
    MODIFY: [],
  },
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setProjectsLoaded: (state, action: PayloadAction<boolean>) => {
      state.projectsLoaded = action.payload
    },
    setPermissionSet: (state, action: PayloadAction<ProjectDashboardPermissionSet>) => {
      state.permissionSet = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.fulfilled, (state, action) => {
        if (action.payload) state.projects = action.payload.projects
      })
      .addCase(fetchInvitedProjects.fulfilled, (state, action) => {
        if (action.payload) state.invitedProjects = action.payload.projects
      })
  },
})

export const { setProjectsLoaded, setPermissionSet, reset } = dashboardSlice.actions

export default dashboardSlice.reducer
