import './styles.css'

import { PropsWithChildren } from 'react'

import { Divider, HStack, Text } from '@chakra-ui/react'

interface SectionTitleProps extends PropsWithChildren {
  fontSize?: string
}

const SectionTitle = ({ children, fontSize = '2xl' }: SectionTitleProps) => (
  <HStack className="section-title">
    <Text fontSize={fontSize}>{children}</Text>
    <Divider />
  </HStack>
)

export default SectionTitle
