import { useCallback } from 'react'

import { setSelectedElementIds } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useEditorContext } from 'contexts/Editor'

import { EditorPanelItem, ElementsPanelConfig } from 'interfaces/editor'

import { resetPerVolumeId, setHoveredGridPoint } from '../store'

const useElementsPanel = (): ElementsPanelConfig => {
  // Context
  const { inspectionItems } = useEditorContext()

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const workingGridPoints = useSelector((state: RootState) => state.toolGrid.workingGridPoints)
  const selectedElementIds = useSelector((state: RootState) => state.editor.selectedElementIds)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  return {
    getPanelItems: useCallback(
      (parent?: EditorPanelItem['item']): EditorPanelItem[] => {
        // Grid falls under a volume
        if (!parent || !('item_type' in parent) || parent.item_type !== 'volume') return []

        const grid = inspectionItems.find(
          (item) => item.item_type === 'grid' && item.volume_id === parent.inspection_item_id,
        )
        const workingGrid = workingGridPoints[parent.inspection_item_id!]
        if (grid && !workingGrid) {
          return [
            {
              key: `panel-grid-${grid.inspection_item_id!}`,
              label: 'グリッド点',
              item: grid,
              defaultCollapsed: true,
              isCountable: true,
              onDelete: isAllowedToModify,
              children: grid.grid?.list_distances?.map((distance, index) => ({
                key: `panel-grid-point-${grid.inspection_item_id}-${index}`,
                label: distance.name,
                toggleVisibility: true,
                onSelect: false,
                onHover(flag) {
                  dispatch(
                    setHoveredGridPoint(flag ? { gridId: grid.inspection_item_id!, pointIndex: index } : undefined),
                  )
                },
              })),
            },
          ]
        }

        if (workingGrid) {
          return [
            {
              key: `panel-grid-${parent.inspection_item_id!}`,
              label: 'グリッド点',
              defaultCollapsed: true,
              isCountable: true,
              toggleVisibility: true,
              isWorking: true,
              onSelect: false,
              onReset() {
                dispatch(resetPerVolumeId(parent.inspection_item_id!))
                dispatch(
                  setSelectedElementIds(
                    selectedElementIds.filter(
                      (id) =>
                        !id.includes(parent.inspection_item_id!) &&
                        !id.includes(grid?.inspection_item_id || '---') &&
                        !parent.shape_ids.polygons.includes(id),
                    ),
                  ),
                )
              },
              children: workingGrid.map((point, index) => ({
                key: `panel-grid-point-${parent.inspection_item_id}-${index}`,
                label: `t${index}`,
                toggleVisibility: true,
                onSelect: false,
              })),
            },
          ]
        }

        return []
      },
      [inspectionItems, isAllowedToModify, workingGridPoints, selectedElementIds, dispatch],
    ),
  }
}

export default useElementsPanel
