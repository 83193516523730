import './CustomizableField.css'

import { FC, ReactElement, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'

import { ChevronDownIcon } from 'assets/icons'

import { ProjectSheetToleranceType as ToleranceType } from 'interfaces/project'

import ToggleField, { ToggleFieldProps } from '../ToggleField'

const ToleranceTypeLabel: Record<ToleranceType, string> = {
  [ToleranceType.WithinTolerance]: '設計値±規格値',
  [ToleranceType.GteDesignedValue]: '設計値以上',
  [ToleranceType.LteDesignedValue]: '設計値以下',
  [ToleranceType.AsymmetricTolerance]: '設計値±規格値(非対称)',
}

const CustomizableField: FC<{
  icon?: ReactElement
  title: string
  enabled: boolean
  expanded?: boolean
  expandable?: boolean
  additionalFields?: ToggleFieldProps[]
  toleranceType?: ToleranceType
  onToggle: (enabled: boolean) => void
  onChangeToleranceType?: (type: ToleranceType) => void
}> = ({
  icon,
  title,
  enabled,
  additionalFields = [],
  expanded = false,
  expandable = true,
  toleranceType,
  onToggle,
  onChangeToleranceType,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  return (
    <Box
      className={[
        'customizable-field',
        enabled ? 'enabled' : 'disabled',
        expandable ? 'expandable' : null,
        isExpanded ? 'opened' : null,
      ].join(' ')}
    >
      <Flex p={2} className="header" onClick={expandable ? () => setIsExpanded(!isExpanded) : undefined} gap={2}>
        {icon}
        <Text flex={1} fontSize="xl">
          {title}
        </Text>
        <Switch isChecked={enabled} onChange={() => onToggle(!enabled)} />
      </Flex>
      <Box bg="gray.50" px={4} py={2} borderBottomRadius={6}>
        {toleranceType !== undefined && (
          <HStack>
            <Text>許容設定</Text>
            <Spacer />
            <Menu isLazy placement="bottom-end">
              <MenuButton
                as={Button}
                fontWeight="normal"
                variant="outline"
                size="sm"
                bg="white"
                rightIcon={<ChevronDownIcon />}
              >
                {ToleranceTypeLabel[toleranceType]}
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={
                    onChangeToleranceType ? () => onChangeToleranceType(ToleranceType.WithinTolerance) : undefined
                  }
                >
                  {ToleranceTypeLabel.within_tolerance}
                </MenuItem>
                <MenuItem
                  onClick={
                    onChangeToleranceType ? () => onChangeToleranceType(ToleranceType.AsymmetricTolerance) : undefined
                  }
                >
                  {ToleranceTypeLabel.asymmetric_tolerance}
                </MenuItem>
                <MenuItem
                  onClick={
                    onChangeToleranceType ? () => onChangeToleranceType(ToleranceType.GteDesignedValue) : undefined
                  }
                >
                  {ToleranceTypeLabel.gte_designed_value}
                </MenuItem>
                <MenuItem
                  onClick={
                    onChangeToleranceType ? () => onChangeToleranceType(ToleranceType.LteDesignedValue) : undefined
                  }
                >
                  {ToleranceTypeLabel.lte_designed_value}
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        )}
        {additionalFields.length > 0 && (
          <>
            <Text my={2}>追加のデータポイント</Text>
            <VStack bg="white" py={3} px={5} borderColor="gray.300" borderWidth={1} borderRadius={6}>
              {additionalFields.map((field) => (
                <ToggleField {...field} disabled={!enabled} />
              ))}
            </VStack>
          </>
        )}
      </Box>
    </Box>
  )
}

export default CustomizableField
