import { useEffect, useMemo, useRef } from 'react'

import { useFrame, useThree } from '@react-three/fiber'
import { clamp, merge } from 'lodash'
import {
  Camera,
  CircleGeometry,
  InstancedBufferAttribute,
  InstancedMesh,
  Matrix4,
  PerspectiveCamera,
  Quaternion,
  ShaderMaterial,
  Vector3,
} from 'three'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorProps, DistanceLabelsProps } from 'interfaces/canvas'

import { colorToRGB } from 'services/Util'

/**
 * Maximum number of instances.
 */
const MAX_INSTANCE_COUNT = 1000

/**
 * Default anchor properties.
 */
const DEFAULT_ANCHOR_PROPS = {
  color: '#88DF41',
  outlineColor: '#FFFFFF',
  innerScale: 0.775,
} as Partial<CircleAnchorProps>

/**
 * Size of the anchor. This is a fixed value.
 * Larger anchors will be scaled up.
 */
const ANCHOR_SIZE = 0.0045

/**
 * Bunch of variables to avoid creating new instances in the render loop.
 */
const frameMatrix = new Matrix4()
const framePos = new Vector3()
const frameQuaternion = new Quaternion()
const frameScale = new Vector3()

/**
 * Calculate anchor's scale factor based on the camera's position and the anchor point.
 *
 * @param camera Camera
 * @param position Anchor position
 */
const calculateScaleFactor = (camera: Camera, position: Vector3) => {
  const distance = camera.position.distanceTo(position)
  const fov = (camera as PerspectiveCamera).fov * (Math.PI / 180) // Convert FOV to radians
  const scaleFactor = distance * Math.tan(fov / 2) * 2.5 // Adjust the 2 for the correct size.

  return clamp(scaleFactor, 0.5, 5)
}

/**
 * DistanceLabels generates multiple distance labels.
 */
const DistanceLabels = ({ labels }: DistanceLabelsProps) => {
  const instanceMeshRef = useRef<InstancedMesh>(null)
  const { camera } = useThree()

  const geometry = useMemo(() => new CircleGeometry(ANCHOR_SIZE, 20), []) // TODO: see if we can do LOD
  const material = useMemo(
    () =>
      new ShaderMaterial({
        vertexShader: `
          attribute vec3 instanceColor;
          attribute float instanceInnerScale;
          attribute float outlineThreshold;

          varying float vRadius;
          varying vec3 vColor;
          varying float vOutlineThreshold;

          void main() {
            vRadius = length(position.xy); // Calculate radius from center
            vColor = instanceColor;
            vOutlineThreshold = outlineThreshold;

            vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
          }
      `,
        fragmentShader: `
          precision mediump float;

          varying float vRadius;
          varying vec3 vColor;
          varying float vOutlineThreshold;

          void main() {
            vec3 color = vColor;

            if (vRadius > vOutlineThreshold) {
              color = vec3(1.0, 1.0, 1.0); 
            }

            gl_FragColor = vec4(color, 1.0);
          }
      `,
      }),
    [],
  )
  // const material = useMemo(() => new MeshBasicMaterial({ side: DoubleSide, wireframe: true  }), [])

  const attributes = useMemo(() => {
    // For the first half of the instances, it's the outer circle of the anchors.
    // For the second half, it's the inner circle of the anchors.
    const instancesColor = new Float32Array(labels.length * 3 * 2) // 3 values per color (RGB), 2 anchors per label multiplied by 2 (outer and inner circles)
    const instancesPosition: PointArray[] = []
    const instancesOutlineThreshold = new Float32Array(labels.length * 2) // 2 anchors per label

    // Outer circle of the anchors.
    labels.forEach((label, index) => {
      const { points, topAnchorProps, bottomAnchorProps, anchorProps } = label

      const topAnchorPropsConfig = merge({}, DEFAULT_ANCHOR_PROPS, anchorProps, topAnchorProps)
      const bottomAnchorPropsConfig = merge({}, DEFAULT_ANCHOR_PROPS, anchorProps, bottomAnchorProps)

      // top anchors
      instancesPosition.push(points[0])

      const [topR, topG, topB] = colorToRGB(topAnchorPropsConfig.color!)
      instancesColor[index * 6] = topR
      instancesColor[index * 6 + 1] = topG
      instancesColor[index * 6 + 2] = topB

      // bottom anchors
      instancesPosition.push(points[1])

      const [botR, botG, botB] = colorToRGB(bottomAnchorPropsConfig.color!)
      instancesColor[index * 6 + 3] = botR
      instancesColor[index * 6 + 4] = botG
      instancesColor[index * 6 + 5] = botB

      // inner scale. we're setting the value separately but it's the same for both top and bottom anchors
      instancesOutlineThreshold[index * 2] = topAnchorPropsConfig.innerScale! * ANCHOR_SIZE
      instancesOutlineThreshold[index * 2 + 1] = topAnchorPropsConfig.innerScale! * ANCHOR_SIZE
    })

    return { instancesColor, instancesPosition, instancesOutlineThreshold }
  }, [labels])
  // const lineConfig: DistanceLabelsProps['line'] = line
  //   ? {
  //       ...line,
  //       ...{
  //         outlineColor: '#FFFFFF',
  //         style: LineStyle.Dashed,
  //         color: '#333333',
  //         thickness: 0.001,
  //       },
  //     }
  //   : undefined
  // const labelConfig: DistanceLabelsProps['label'] =
  //   label instanceof Object
  //     ? { ...label, ...{ bgColor: '#88DF41', textColor: '#000', outlineColor: 'transparent', outlineWidth: 2 } }
  //     : undefined

  useEffect(() => {
    if (!instanceMeshRef.current) return

    const { instancesColor, instancesPosition, instancesOutlineThreshold } = attributes

    instanceMeshRef.current.count = instancesPosition.length
    instanceMeshRef.current.geometry.setAttribute('instanceColor', new InstancedBufferAttribute(instancesColor, 3))
    instanceMeshRef.current.geometry.setAttribute(
      'outlineThreshold',
      new InstancedBufferAttribute(instancesOutlineThreshold, 1),
    )

    // Update positions of each instance
    const matrix = new Matrix4()
    const vec3 = new Vector3()
    instancesPosition.forEach((position, index) => {
      matrix.makeTranslation(vec3.fromArray(position))
      instanceMeshRef.current!.setMatrixAt(index, matrix)
    })
  }, [attributes, camera])

  useFrame(() => {
    const { instancesPosition } = attributes

    if (!instanceMeshRef.current) return // 2 anchors per label multiplied by 2 (outer and inner circles)

    instancesPosition.forEach((position, index) => {
      instanceMeshRef.current!.getMatrixAt(index, frameMatrix)
      frameMatrix.lookAt(camera.position, framePos.fromArray(position), camera.up)
      frameMatrix.decompose(framePos, frameQuaternion, frameScale)

      frameMatrix.compose(framePos, frameQuaternion, frameScale.multiplyScalar(calculateScaleFactor(camera, framePos)))
      instanceMeshRef.current!.setMatrixAt(index, frameMatrix)
    })
    instanceMeshRef.current.instanceMatrix.needsUpdate = true
  })

  // The instance count here is the MAXIMUM possible instances. Actual instances are set in the useEffect above.
  return <instancedMesh ref={instanceMeshRef} args={[geometry, material, MAX_INSTANCE_COUNT]} renderOrder={10} />
}

export default DistanceLabels
