import { FC, useContext, useEffect, useState } from 'react'

import { Button, Container, Flex, FormControl, FormLabel, HStack, Input, Spacer, Text, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import PageFooter from 'pages/common/PageFooter'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { RootState } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { registerUser } from 'services/Users'
import { validateEmail } from 'services/Util'

const Register: FC = () => {
  const { search } = useLocation()

  const parameters = new URLSearchParams(search)
  const email_address = parameters.get('email_address')

  const { showErrorModal } = useContext(GlobalModalContext)

  const [emailAddress, setEmailAddress] = useState('')
  const [name, setName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCreated, setIsCreated] = useState(false)

  // Store
  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)

  useEffect(() => {
    setEmailAddress(email_address || '')
    setIsCreated(false)
  }, [email_address])

  const onCreateUser = async () => {
    setIsLoading(true)

    const result = await registerUser(emailAddress, showErrorModal, name, companyName)
    setIsCreated(result)
    setIsLoading(false)

    // track with mixpanel
    mixpanel.track('Create user account', {
      email: emailAddress,
    })

    return true
  }

  return (
    <Flex flex={1} bg="gray.50" flexDirection="column">
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH} id="inner-container" className="register" flex={1}>
        <PageHeading>新規閲覧用アカウント登録</PageHeading>
        {!isCreated && (
          <VStack w="50%" alignItems="flex-start" spacing={4}>
            <>
              <Text mt={-4} mb={4}>
                ここにメールアドレスを入力していただくと、工事の閲覧権限を持った閲覧ユーザー用アカウントが新規作成されます。
              </Text>
              <FormControl>
                <FormLabel>メールアドレス</FormLabel>
                <Input
                  id="email_address"
                  type="text"
                  value={emailAddress}
                  isDisabled={isLoading}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </FormControl>
              {featureFlags?.selfManagedSubscriptions.enabled && (
                <>
                  <FormControl>
                    <FormLabel>氏名</FormLabel>
                    <Input
                      id="name"
                      type="text"
                      value={name}
                      isDisabled={isLoading}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>会社名及び部署名</FormLabel>
                    <Input
                      id="company_name"
                      type="text"
                      value={companyName}
                      isDisabled={isLoading}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </FormControl>
                </>
              )}
              <Button
                colorScheme="primary"
                minW={20}
                isDisabled={
                  isLoading ||
                  !validateEmail(emailAddress) ||
                  (featureFlags?.selfManagedSubscriptions.enabled && (!name || !companyName))
                }
                onClick={() => {
                  void onCreateUser()
                }}
              >
                登録
              </Button>
            </>
          </VStack>
        )}
        {isCreated && (
          <VStack alignItems="flex-start" spacing={4}>
            <HStack color="primary.500" fontSize="2xl">
              <CheckCircleIcon />
              <Text fontSize="md" fontWeight="bold">
                ユーザーアカウント登録に成功しました。
              </Text>
            </HStack>
            <Text>
              メールの受信箱を確認し、パスワードを設定するためのリンクを取得してください。
              <Text color="gray.400">({emailAddress})</Text>
            </Text>
            <Button colorScheme="primary" as={RouterLink} to="/dashboard">
              ログイン
            </Button>
          </VStack>
        )}
        <Spacer />
        <PageFooter />
      </Container>
    </Flex>
  )
}

export default Register
