import axios from 'axios'

import { API_GATEWAY_URL, AUTH0_AUDIENCE } from 'config/environments'

import { Invitation, UserInfo, UserProfile } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

export const USER_API_URL = `${API_GATEWAY_URL}/user`
export const USERS_API_URL = `${API_GATEWAY_URL}/users`
const SEND_INVITATION_API_URL = `${API_GATEWAY_URL}/invitations`
const SEND_INVITATION_EMAIL_API_URL = `${API_GATEWAY_URL}/send-email`
const REGISTER_USER_API_URL = `${API_GATEWAY_URL}/register-auth0-account`
const AGREE_TO_TOS_API_URL = `${API_GATEWAY_URL}/agree-to-tos`

/**
 * Get user information
 * @param {string} access_token
 * @return {{isPaying: boolean}}
 */
export const getUser = async (
  access_token: string,
  showErrorModal: (message: string) => void,
): Promise<UserInfo | null> => {
  const user = await axios
    .get<UserInfo>(`${USER_API_URL}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_USER, showErrorModal)
      return null
    })

  return user
}

/**
 * 閲覧ユーザーを招待
 * @param {string} access_token アクセストークン
 * @param {string} email_address 閲覧ユーザーのメール
 * @return {Invitation}
 */
export const inviteUser = async (
  access_token: string,
  email_address: string,
  showErrorModal: (message: string) => void,
): Promise<Invitation | null> => {
  const user = await axios
    .post<Invitation>(
      `${SEND_INVITATION_API_URL}`,
      {
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.INVITE_USER, showErrorModal)
      return null
    })

  return user
}

/**
 * Send email notification
 * @param {string} access_token access token
 * @param {string} email_address email address
 * @return {boolean}
 */
export const sendInvitationEmail = async (
  access_token: string,
  email_address: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .post<boolean>(
      `${SEND_INVITATION_EMAIL_API_URL}`,
      {
        category: 'invite-to-hatsuly',
        info: {
          recipient: email_address,
          url: `${AUTH0_AUDIENCE}/register?email_address=${encodeURIComponent(email_address)}`,
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.INVITE_USER, showErrorModal)
      return false
    })

  return result
}

/**
 * Register a user
 * @param {string} email_address email address
 * @return {boolean}
 */
export const registerUser = async (
  email_address: string,
  showErrorModal: (message: string) => void,
  name?: string,
  company_name?: string,
): Promise<boolean> => {
  const result = await axios
    .post<boolean>(`${REGISTER_USER_API_URL}`, {
      email_address,
      name,
      company_name,
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.REGISTER_USER, showErrorModal)
      return false
    })

  return result
}

/**
 * Get user list, for admin account only
 * @param {string} access_token
 * @param {boolean} hubspotOnly
 * @param {boolean} hubspotProperties
 * @return {UserProfile[] | null}
 */
export const getUserList = (
  access_token: string,
  hubspotOnly: boolean,
  hubspotProperties: boolean,
  showErrorModal: (message: string) => void,
): Promise<UserProfile[] | null> =>
  axios
    .get<{ results: UserProfile[] }>(
      `${USERS_API_URL}?hubspot_only=${hubspotOnly}&hubspot_properties=${hubspotProperties}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_USER, showErrorModal)
      return null
    })

/**
 * Update user information
 *
 * @param access_token Access token
 * @param user User information
 * @param showErrorModal Error modal
 * @returns Updated user information
 */
export const patchUser = (access_token: string, user: UserInfo): Promise<UserInfo> =>
  axios
    .patch<UserInfo>(
      USER_API_URL,
      {
        sheet_rows_visibility: user.sheet_rows_visibility,
        sheet_cols_visibility: user.sheet_cols_visibility,
        sheet_models_visibility: user.sheet_models_visibility,
        sheet_diagram_visibility: user.sheet_diagram_visibility,
        sheet_rows_tolerance_type: user.sheet_rows_tolerance_type,
      },
      {
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)

/**
 * Agree to the terms of service
 * @param {string} access_token Access token
 * @param {number} agreed_tos_version Agreed ToS version
 * @param showErrorModal Error modal
 * @return {UserInfo}
 * */
export const agreeToTos = (
  access_token: string,
  agreed_tos_version: number,
  showErrorModal: (message: string) => void,
): Promise<UserInfo | null> =>
  axios
    .post<UserInfo>(
      AGREE_TO_TOS_API_URL,
      {
        agreed_tos_version,
      },
      {
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.AGREE_TO_TOS, showErrorModal)
      return null
    })

/**
 * Update user information
 *
 * @param access_token Access token
 * @param users User information
 * @param showErrorModal Error modal
 * @returns Updated user information
 */
export const patchUsers = (
  access_token: string,
  users: { user_id: string; hubspot_id: string }[],
  showErrorModal: (message: string) => void,
): Promise<UserInfo[] | null> =>
  axios
    .patch<UserInfo[]>(
      USERS_API_URL,
      users.map((user) => ({
        user_id: user.user_id,
        params: {
          hubspot_id: user.hubspot_id,
        },
      })),
      {
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.PATCH_USER, showErrorModal)
      return null
    })
