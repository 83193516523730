import axios, { CanceledError } from 'axios'
import mixpanel from 'mixpanel-browser'
import { Matrix3 } from 'three'

import { EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import {
  Anchors,
  CADFileType,
  Cuboid,
  CuboidDirection,
  Cylinder,
  CylinderDetectionResult,
  InspectionArea,
  InspectionAreaRebarSurface,
  InspectionAreaVolume,
  InspectionItem,
  InspectionSheet,
  PolygonDetectionResult,
  ShapeKey,
  Shapes,
  ShapesId,
} from 'interfaces/interfaces'
import { PendingAsyncJobToken } from 'interfaces/system'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { rotateCuboid } from './Points'
import { GET_RPOJECTS_API_URL } from './Projects'
import { getCylinderBoundingBox } from './Shape'
import { calculateRebarSurfaceArea, millimeterToMeter } from './Util'

export const GET_INSPECTION_AREAS_API_URL = (projectId: string) =>
  `${API_GATEWAY_URL}/projects/${projectId}/inspection-areas`
export const GET_INSPECTION_AREA_API_URL = (projectId: string, inspectionAreaId: string) =>
  `${GET_INSPECTION_AREAS_API_URL(projectId)}/${inspectionAreaId}`
export const GET_SHAPES_API_URL = (projectId: string, inspectionAreaId: string) =>
  `${GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId)}/shapes`
export const DELETE_SHAPES_API_URL = (projectId: string, inspectionAreaId: string) =>
  `${GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId)}/shapes:batchDelete`

export const DETECT_SHAPES_API_URL = `${API_GATEWAY_URL}/detect-shapes`
export const ORIGIN_FILE_API_URL = `${API_GATEWAY_URL}/origin-files`
export const DOWN_SAMPLED_FILES_API_URL = `${API_GATEWAY_URL}/down-sampled-files`
export const POTREE_FILES_API_URL = `${API_GATEWAY_URL}/potree-files`
export const CAD_FILES_API_URL = `${API_GATEWAY_URL}/cad-files`
export const CAD_2D_FILES_API_URL = `${API_GATEWAY_URL}/cad-files-2d`

/* --- 工事操作 --- */

/**
 * Get all inspection areas for a project.
 *
 * @param {string} access_token アクセストークン
 * @param {string} projectId Project ID
 * @return {{inspectionAreas: InspectionArea[], total_size: number}} {inspectionAreas: InspectionArea[], total_size: number}
 */
export const getInspectionAreas = async (
  access_token: string,
  projectId: string,
  showErrorModal?: (message: string) => void,
  abortSignal?: AbortSignal,
): Promise<InspectionArea[] | null | false> => {
  const inspectionAreas = await axios
    .get<{ inspection_areas: InspectionArea[] }>(`${GET_INSPECTION_AREAS_API_URL(projectId)}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
      signal: abortSignal,
    })
    .then((response) => response.data.inspection_areas)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (abortSignal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return false
      }

      if (showErrorModal) {
        processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
        return null
      }

      throw err
    })

  return inspectionAreas
}

/**
 * Get a single inspection area by Project ID + Inspection Area ID.
 *
 * @param {string} access_token アクセストークン
 * @param {string} projectId Project ID
 * @param {string} inspectionAreaId Inspection Area ID.
 * @return {{inspectionAreas: InspectionArea[], total_size: number}} {inspectionAreas: InspectionArea[], total_size: number}
 */
export const getInspectionArea = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  showErrorModal?: (message: string) => void,
): Promise<InspectionArea | null> => {
  const inspectionAreas = await axios
    .get<{ inspection_area: InspectionArea }>(`${GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId)}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.inspection_area)
    .catch((err) => {
      if (showErrorModal) {
        processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
        return null
      }

      throw err
    })

  return inspectionAreas
}

/**
 * Create a new inspection area.
 *
 * @param {string} access_token Access token
 * @param {string} inspection_area_name Name of inspection area
 * @return {InspectionArea} New inspection area object
 */
export const postInspectionArea = async (
  access_token: string,
  projectId: string,
  inspection_area_name: string,
  inspectionSheet: InspectionSheet | null,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> =>
  axios
    .post<{ message: string; inspection_area: InspectionArea }>(
      `${GET_INSPECTION_AREAS_API_URL(projectId)}`,
      {
        inspection_area_name,
        creator_name: inspectionSheet?.creator_name,
        create_time_user_specified: inspectionSheet?.create_time_user_specified,
        observer_name: inspectionSheet?.observer_name,
        observe_time_user_specified: inspectionSheet?.observe_time_user_specified,
        construction_properties: inspectionSheet?.construction_properties,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.inspection_area)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_INSPECTION_AREA, showErrorModal)
      return null
    })

/**
 * 工事名の変更
 * @param {string} access_token アクセストークン
 * @param {string} inspection_area_id ファイル名
 * @param {string} project_name 工事名
 * @return {InspectionArea} InspectionAreaオブジェクト
 * @deprecated use patchInspectionArea instead.
 */
export const updateInspectionAreaName = async (
  access_token: string,
  projectId: string,
  inspection_area_id: string,
  inspection_area_name: string,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> => {
  const inspectionArea = await axios
    .patch<InspectionArea>(
      `${GET_INSPECTION_AREAS_API_URL(projectId)}/${inspection_area_id}`,
      {
        inspection_area_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_INSPECTION_AREA, showErrorModal)
      return null
    })

  return inspectionArea
}

/**
 * 工事ファイルの変更
 * @param {string} access_token アクセストークン
 * @param {string} inspection_area_id Inspection Area ID
 * @param {string} filename ファイル名
 * @return {InspectionArea} InspectionAreaオブジェクト
 */
export const updateInspectionAreaFileName = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  filename: string,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> => {
  const inspectionArea = await axios
    .patch<InspectionArea>(
      `${GET_INSPECTION_AREAS_API_URL(project_id)}/${inspection_area_id}`,
      {
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_INSPECTION_AREA, showErrorModal)
      return null
    })

  return inspectionArea
}

/**
 * Patch inspection area details.
 *
 * @param access_token Access token
 * @param proejct_id Project ID
 * @param inspectionArea Inspection area object
 * @param showErrorModal Error modal
 * @returns Updated inspection area object
 */
export const patchInspectionArea = async (
  access_token: string,
  project_id: string,
  inspectionArea: InspectionArea,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> =>
  axios
    .patch<InspectionArea>(
      `${GET_INSPECTION_AREAS_API_URL(project_id)}/${inspectionArea.inspection_area_id}`,
      {
        inspection_area_name: inspectionArea.inspection_area_name,
        camera_profile: inspectionArea.camera_profile,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_INSPECTION_AREA, showErrorModal)
      return null
    })

/**
 * Patch inspection area details.
 *
 * @param access_token Access token
 * @param proejct_id Project ID
 * @param inspectionArea Inspection area object
 * @param showErrorModal Error modal
 * @returns Updated inspection area object
 */
export const updateInspectionAreas = async (
  access_token: string,
  project_id: string,
  inspectionArea: InspectionArea[],
  showErrorModal?: (message: string) => void,
): Promise<InspectionArea[] | null> => {
  if (!inspectionArea.length) {
    return null
  }

  // Prepare the request body
  const data = inspectionArea.map(({ inspection_area_id, inspection_area_name, order }) => ({
    inspection_area_id,
    params: {
      inspection_area_name,
      order,
    },
  }))

  const request = axios
    .patch<InspectionArea[]>(`${GET_RPOJECTS_API_URL(project_id)}/inspection-areas`, data, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)

  if (showErrorModal) {
    return request.catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_INSPECTION_AREA, showErrorModal)
      return null
    })
  }

  return request
}

/**
 * 工事群の取得
 * @param {string} access_token アクセストークン
 * @return {boolean}
 */
export const deleteInspectionArea = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete(`${GET_INSPECTION_AREAS_API_URL(projectId)}/${inspectionAreaId}`, {
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_PROJECT, showErrorModal)
      return false
    })
  return result
}

/* --- 鉄筋操作 --- */

/**
 * 鉄筋取得
 * @param {string} access_token Token
 * @param {string} inspection_area_id InspectionArea ID
 * @return {Shapes} Shapes object
 */
export const getShapes = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<Shapes | null> => {
  const shapes = await axios
    .get<{ results: Shapes }>(GET_SHAPES_API_URL(project_id, inspection_area_id), {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      const result = response.data.results

      // Create bbox for each shapes
      result.cylinders?.forEach((cylinder) => {
        const bbox = getCylinderBoundingBox(cylinder)
        cylinder.bbox = [bbox.min.toArray(), bbox.max.toArray()]
      })

      return result
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_SHAPES, showErrorModal)
      return null
    })

  return shapes
}

/**
 * 鉄筋削除
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {string} inspection_area_id inspectionArea id
 * @param {ShapesId} shapesId all ids of cylinders, tori, planes
 * @return {Boolean} success or failure
 */
export const deleteShapes = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  shapesId: ShapesId,
  showErrorModal: (message: string) => void,
): Promise<PendingAsyncJobToken[] | boolean> => {
  const filteredShapesId = {
    cylinders: shapesId.cylinders.filter((id) => id && !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    polygons: shapesId.polygons.filter((id) => id && !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
  }
  // No need to send request if deleting shapes are not saved yet (temporarily detected)
  if (!filteredShapesId.cylinders.length && !filteredShapesId.polygons.length) {
    return true
  }
  const result = await axios
    .post<PendingAsyncJobToken[] | boolean>(
      DELETE_SHAPES_API_URL(project_id, inspection_area_id),
      { ...filteredShapesId },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data || true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_SHAPES, showErrorModal)
      return false
    })

  return result
}

/**
 * Get the internal shape parameters for the shape.
 *
 * @param diameter Diameter of the shape
 * @param shapeKey Shape key
 */
export const getInternalShapeParameters = (diameter: number, shapeKey: ShapeKey) => {
  if (shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS) {
    return { diameter: millimeterToMeter(diameter) }
  }
  if (shapeKey === EDITOR_SHAPE_KEYS.TORI) {
    return { minor_diameter: millimeterToMeter(diameter) }
  }
  return null
}

/**
 * 鉄筋検出
 * @param {string} access_token token
 * @param {string} projectId project ID
 * @param {string} inspectionAreaId inspection area ID
 * @param {string} inspectionSheetId inspection sheet ID
 * @param {string} shape_type cylinders, tori, planes
 * @param {Anchors} anchors all points with diameter
 * @param {Cuboid[]} maskRegions mask regions
 * @return {string} job's token - used to retrieve job's status
 */
export const detectShapes = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  shape_type: ShapeKey,
  anchors: Anchors,
  maskRegions: Cuboid[],
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const token = await axios
    .post<{
      job_token: string
    }>(
      `${DETECT_SHAPES_API_URL}`,
      {
        project_id: projectId,
        inspection_area_id: inspectionAreaId,
        inspection_sheet_id: inspectionSheetId,
        shape_type,
        parameters_for_detecting_shapes: anchors[shape_type].map((anchor) => ({
          internal_shape_parameters: getInternalShapeParameters(anchor.diameter || 0, shape_type),
          positions: anchor.points.map((point) => ({ x: point[0], y: point[1], z: point[2] })),
          plane_side: anchor.plane_side,
          is_virtual: anchor.is_virtual,
        })),
        mask_region_ids: maskRegions.map((maskRegion) => maskRegion.region_id),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return null
    })

  return token
}

/**
 * 自動的に鉄筋検出
 * @param {string} shapeKey cylinders type
 * @param {string} access_token token
 * @param {string} downSampledFilePath file path
 * @param {string} situation cylinders_on_axis, tori_on_axis, cylinders_on_arc
 * @param {number} diameter diameter
 * @param {Cuboid} cuboid cuboid's size, position, rotation
 * @return {string} job's token - used to retrieve job's status
 */
export const autoDetectShapes = async (
  shapeKey: ShapeKey,
  access_token: string,
  downSampledFilePath: string,
  situation: string,
  diameter: number,
  cuboid: Cuboid,
  showErrorModal: (message: string) => void,
  cuboidDirection?: CuboidDirection,
): Promise<string | null> => {
  const bounds = rotateCuboid(cuboid, cuboidDirection)

  const token = await axios
    .post<{
      job_token: string
    }>(
      `${DETECT_SHAPES_API_URL}/bulk`,
      {
        path_pcd: downSampledFilePath,
        situation,
        internal_shape_parameters: getInternalShapeParameters(diameter, shapeKey),
        bounds: {
          ...bounds,
          rotation: new Matrix3().fromArray(bounds.rotation).invert().toArray(),
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return null
    })

  return token
}

/**
 * Check status of long-running jobs. By default will swallow failed jobs and not show error modal.
 * To show error modal, set throwError to true.
 *
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the detect shape API
 * @param {function} showErrorModal error modal
 * @param {boolean} throwError show error modal if job failed
 * @return {object | null} detection result
 */
export const checkJobStatusForDetectShapes = async (
  access_token: string,
  jobToken: string,
  showErrorModal: (message: string) => void,
): Promise<
  | {
      shape_type?: ShapeKey
      list_shapes: CylinderDetectionResult[] | PolygonDetectionResult[]
      reevaluate_volume_job_tokens?: PendingAsyncJobToken[]
    }
  | null
  | boolean
> => {
  const shapes = await axios
    .get<{
      job_status: string
      results: {
        error_object: string
        shape_type: ShapeKey
        list_shapes: CylinderDetectionResult[] | PolygonDetectionResult[]
        reevaluate_volume_job_tokens?: PendingAsyncJobToken[]
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { list_shapes: [] }
      }
      return null
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return false
    })

  return shapes
}

/* --- ファイル操作 --- */

/**
 * ファイルアップロード用のS3署名付きURLの発行
 * @param {string} access_token アクセストークン
 * @param {string} file_name ファイル名
 * @param {string} content_type コンテンツタイプ
 * @return {string} 署名付きURL
 */
export const getSignedUrlForUploadFile = async (
  access_token: string,
  file_name: string,
  content_type: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const url = await axios
    .post<{ url: string }>(
      `${ORIGIN_FILE_API_URL}`,
      {
        file_name,
        content_type,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_INSPECTION_AREA, showErrorModal)
      return null
    })

  return url
}

/**
 * Origin file download
 * @param {string} access_token
 * @param {string} inspection_area_id
 * @return {string} signed URL
 */
export const getSignedUrlForOriginFile = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${ORIGIN_FILE_API_URL}?project_id=${project_id}&inspection_area_id=${inspection_area_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return url
}

/**
 * ダウンサンプリング後ファイル取得用署名付きURLの発行
 * @param {string} access_token アクセストークン
 * @param {string} inspection_area_id 工事ID
 * @return {string} 署名付きURL
 */
export const getSignedUrlForGetDownSampledFile = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(
      `${DOWN_SAMPLED_FILES_API_URL}?project_id=${project_id}&inspection_area_id=${inspection_area_id}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_INSPECTION_AREA, showErrorModal)
      return null
    })

  return url
}

/**
 * Get signed URL for Potree files.
 *
 * @param access_token Auth0 Access token
 * @param project_id Project ID of the inspection area
 * @param inspection_area_id Inspection Area ID of the potree files
 * @param showErrorModal Function to show error modal
 * @returns
 */
export const getSignedUrlForPotreeFiles = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<{
  medata: string
  hierarchy: string
  octree: string
} | null> => {
  const url = await axios
    .get<{
      medata: string
      hierarchy: string
      octree: string
    }>(`${POTREE_FILES_API_URL}?project_id=${project_id}&inspection_area_id=${inspection_area_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_INSPECTION_AREA, showErrorModal)
      return null
    })

  return url
}

/**
 * CADファイル生成
 * @param {string} access_token アクセストークン
 * @param {string} inspection_area_id 工事ID
 * @param {FileType} file_type CAD生成対応ファイル拡張子
 * @return {InspectionArea} InspectionAreaオブジェクト
 */
export const postCadFiles = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  file_type: CADFileType,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> => {
  mixpanel.track('Export CAD file', { 'File Type': file_type })

  const inspectionArea = await axios
    .post<{ message: string; inspection_area: InspectionArea }>(
      CAD_FILES_API_URL,
      { project_id, inspection_area_id, file_type },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.inspection_area)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return inspectionArea
}

/**
 * Get signed URL for 2D CAD file.
 *
 * @param access_token Access token
 * @param project_id Project ID
 * @param inspection_area_id Inspection Area ID
 * @param showErrorModal Error modal
 * @returns
 */
export const post2dCadFiles = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<InspectionArea | null> => {
  mixpanel.track('Export 2D CAD file')

  const inspectionArea = await axios
    .post<{ message: string; inspection_area: InspectionArea }>(
      CAD_2D_FILES_API_URL,
      { project_id, inspection_area_id },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.inspection_area)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return inspectionArea
}

/**
 * CADファイル取得用署名付きURLの発行
 * @param {string} access_token アクセストークン
 * @param {string} inspection_area_id 工事ID
 * @return {string} 署名付きURL
 */
export const getSignedUrlForCadFile = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${CAD_FILES_API_URL}?project_id=${project_id}&inspection_area_id=${inspection_area_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return url
}

/**
 * Parses partitiion key to its components;
 * - userId
 * - projectId
 * - inspectionAreaId
 * - inspectionSheetId
 * @param partitionKey Inspection Item partition_key
 */
export const parseInspectionItemPartitionKey = (partitionKey: string) => {
  const split = partitionKey.split('#')
  return {
    userId: split[0],
    projectId: split[1],
    inspectionAreaId: split[2],
    inspectionSheetId: split[3],
  }
}

/**
 * Calculate total volume for each inspection areas.
 * This will limit decimal places to 4 digits to prevent too long of a number
 * and as part of safety for floating point error.
 *
 * @param inspectionAreas Inspection areas
 * @param inspectionItems Inspection sheet items (only items within the areas)
 * @returns
 */
export const calculateInspectionAreasTotalVolume = (
  inspectionAreas: InspectionArea[],
  inspectionItems: InspectionItem[],
): InspectionAreaVolume[] =>
  inspectionAreas.map((area) => {
    const volumeItems = inspectionItems.filter((item) => {
      if (!item.partition_key) {
        return false
      }

      const { inspectionAreaId } = parseInspectionItemPartitionKey(item.partition_key)
      return item.item_type === 'volume' && item.volume?.estimated_value && inspectionAreaId === area.inspection_area_id
    })

    return {
      key: area.inspection_area_id,
      name: area.inspection_area_name,
      volume: parseFloat(volumeItems.reduce((total, vi) => total + vi.volume!.estimated_value!, 0).toFixed(4)),
    }
  })

/**
 * Parses partitiion key to its components;
 * - userId
 * - inspectionAreaId
 * @param partitionKey Shape partition_key
 */
export const parseShapePartitionKey = (partitionKey: string) => {
  const split = partitionKey.split('#')
  return {
    userId: split[0],
    inspectionAreaId: split[1],
  }
}

/**
 * Calculate total rebars surface area for each inspection areas.
 * This will limit decimal places to 4 digits to prevent too long of a number
 * and as part of safety for floating point error.
 *
 * @param inspectionAreas Inspection areas
 * @param shapes Shape items (only items within the areas)
 * @returns
 */
export const calculateInspectionAreasTotalRebarSurfaceArea = (
  inspectionAreas: InspectionArea[],
  cylinders: Cylinder[],
): InspectionAreaRebarSurface[] =>
  inspectionAreas.map((area) => {
    const rebarItems = cylinders.filter((cylinder) => {
      if (!cylinder.partition_key) {
        return false
      }

      const { inspectionAreaId } = parseShapePartitionKey(cylinder.partition_key)
      return inspectionAreaId === area.inspection_area_id
    })

    return {
      key: area.inspection_area_id,
      name: area.inspection_area_name,
      surface: parseFloat(rebarItems.reduce((total, c) => total + calculateRebarSurfaceArea(c), 0).toFixed(4)),
    }
  })

/**
 * Set the order of inspection areas.
 *
 * @param inspectionAreas List of inspection areas
 * @param editedInspectionAreas List of edited inspection areas, used by inspection sheet.
 */
export const setInspectionAreasOrder = (
  inspectionAreas: InspectionArea[],
  editedInspectionAreas: InspectionArea[],
): InspectionArea[] => {
  const orderedAreas = inspectionAreas.filter((area) => area.order !== undefined)
  const unorderedAreas = inspectionAreas.filter((area) => area.order === undefined)
  const orderedUnorderedAreas = editedInspectionAreas.filter((area) =>
    unorderedAreas.some((a) => a.inspection_area_id === area.inspection_area_id && a.order !== undefined),
  )

  return orderedAreas
    .map((area) => {
      const editedArea = editedInspectionAreas.find((a) => a.inspection_area_id === area.inspection_area_id)
      let order = editedArea?.order
      if (order === undefined) {
        order = area.order
      }

      return {
        ...area,
        order,
      }
    })
    .concat(
      unorderedAreas.map((area, index) => {
        const editedArea = editedInspectionAreas.find((a) => a.inspection_area_id === area.inspection_area_id)
        let order = editedArea?.order
        if (order === undefined) {
          order = orderedAreas.length + orderedUnorderedAreas.length + index + (editedArea === undefined ? 1 : 0)
        }

        return {
          ...area,
          order,
        }
      }),
    )
}
