import { useMemo } from 'react'

import { Box, Text, Tooltip } from '@chakra-ui/react'
import { Pill } from 'components/Pill'

import { WarningTwoIcon } from 'assets/icons'

import { ProjectSheetSettings, ProjectSheetToleranceType } from 'interfaces/project'

import { meterToMilimeterWhole } from 'services/Util'

import { GridBox } from '../../interfaces'
import { canBeEvaluated } from '../../utils'

const GridValues = ({
  value,
  settings,
  toleranceType,
}: {
  /**
   * Value of the grid point.
   */
  value: GridBox

  /**
   * Type of tolerance for the inspection item.
   */
  toleranceType: ProjectSheetToleranceType

  /**
   * Project sheet settings.
   */
  settings: ProjectSheetSettings
}) => {
  const { isPassingThresholds, differenceValue, threshold } = value.thresholdValidation || {}
  const hasDistance = value?.gridDistance?.distance !== undefined && value?.gridDistance?.distance !== null
  const { isEvaluated } = canBeEvaluated(settings, toleranceType, threshold)

  /**
   * Determine pill color.
   * Min/max will always have the priority, if enabled in settings.
   * Otherwise, use threshold evaluation result.
   */
  const custom:
    | {
        color: string
        tooltip?: string
      }
    | undefined = useMemo(() => {
    if (value.isMax && settings.sheet_rows_visibility?.max_grid_depth)
      return {
        color: 'green',
        tooltip: '最大深さ',
      }
    if (value.isMin && settings.sheet_rows_visibility?.min_grid_depth)
      return {
        color: 'blue',
        tooltip: '最小深さ',
      }

    return hasDistance && isEvaluated && !isPassingThresholds
      ? {
          color: 'red',
        }
      : undefined
  }, [hasDistance, isEvaluated, isPassingThresholds, settings.sheet_rows_visibility, value.isMax, value.isMin])

  return (
    <Tooltip
      label={value?.inside && !hasDistance ? '底面に対応する点が見つかりません' : custom?.tooltip}
      hasArrow
      placement="top"
    >
      <Box
        className={[
          'grid-value-box',
          hasDistance && isEvaluated && !isPassingThresholds ? 'failed' : '',
          !hasDistance ? 'missing' : '',
        ]
          .filter(Boolean)
          .join(' ')}
        position="relative"
      >
        {value?.inside && (
          <>
            <Pill colorScheme={custom?.color}>
              {value?.label || '-'}

              {!hasDistance && <WarningTwoIcon color="yellow.400" />}
            </Pill>

            {hasDistance ? (
              <>
                <Text>{meterToMilimeterWhole(value.gridDistance!.distance!)}</Text>
                {differenceValue !== undefined ? (
                  <Text className="diff">
                    {differenceValue && differenceValue > 0 ? '+' : ''}
                    {meterToMilimeterWhole(differenceValue)}
                  </Text>
                ) : null}
              </>
            ) : (
              <Text>-</Text>
            )}
          </>
        )}
      </Box>
    </Tooltip>
  )
}

export default GridValues
