import { Style, Workbook, Worksheet } from 'exceljs'
import { DEFAULT_TOLERANCE_TYPE, GRID_UNIT, GRID_UNIT_PRECISION } from 'pages/projects/inspection-sheet/constants'
import { canBeEvaluated, getDifferenceValueAndIsPassingThresholds } from 'pages/projects/inspection-sheet/utils'

import { APP_URL } from 'config/environments'

import { InspectionArea, InspectionItem, InspectionSheet } from 'interfaces/inspection'
import { InspectionItemGridListDistance } from 'interfaces/inspectionItemGrid'
import { Project, ProjectSheetSettings, ProjectSheetToleranceType } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { parseInspectionItemPartitionKey } from 'services/InspectionArea'
import { getIdUniqueString, isValueDefined, meterToMilimeterWhole } from 'services/Util'

import {
  DATA_SHEET_NAME,
  DIAGRAM_SHEET_NAME,
  GRID_VALUES_SHEET_NAME,
  createOuterBorder,
  fitColumns,
  makeExternalLinkValue,
  makeInternalLinkValue,
  setProfileGroup,
  setSummary,
  setTitle,
} from './common'
import {
  bgGrey100,
  boldStyle,
  centerCenterStyle,
  fillGrey50,
  nameStyle,
  planeHeaderStyle,
  plusValueStyleWhole,
  underlineStyle,
  valueFailed,
  verticalMiddleStyle,
} from './styles'

/**
 * Set a header cell.
 *
 * @param sheet Current worksheet
 * @param bodyRow Current row
 * @param bodyCol Current column
 * @param title Title
 * @param size Text size
 */
const setHeaderCell = (sheet: Worksheet, bodyRow: number, bodyCol: number, title: string, style?: Style) => {
  sheet.getCell(bodyRow, bodyCol).value = title
  sheet.getCell(bodyRow, bodyCol).style = {
    ...centerCenterStyle,
    ...boldStyle,
    ...(style || {}),
  }
}

/**
 * Set a value cell.
 *
 * @param sheet Worksheet
 * @param row Current row
 * @param col Current column
 * @param value Value to set
 * @param isFailedThresholds If the value is failing the thresholds
 */
function setValueCell(
  sheet: Worksheet,
  row: number,
  col: number,
  value: number | string | null | undefined,
  isFailedThresholds: boolean,
  addPlusSign = false,
) {
  if (typeof value === 'number') {
    sheet.getCell(row, col).value = isValueDefined(value) ? meterToMilimeterWhole(value) : ''
    sheet.getCell(row, col).style = {
      ...verticalMiddleStyle,
      ...(addPlusSign && value && value > 0 ? plusValueStyleWhole : {}),
      ...(isFailedThresholds ? valueFailed : {}),
    }
  } else {
    sheet.getCell(row, col).value = value
    sheet.getCell(row, col).style = { ...centerCenterStyle, ...(isFailedThresholds ? valueFailed : {}) }
  }
}

/**
 * Set data row for a single grid.
 *
 * @param sheet Current worksheet
 * @param currentRow Current row
 * @param currentCol Current column
 * @param name Name of the grid
 * @param data Distance data
 * @param grid Grid data
 * @param settings Project sheet settings
 * @returns Next row
 */
function setDataRow(
  sheet: Worksheet,
  currentRow: number,
  currentCol: number,
  name: string,
  data: InspectionItemGridListDistance,
  grid: InspectionItem,
  settings: ProjectSheetSettings,
) {
  sheet.getCell(currentRow, currentCol).value = name

  // If there is no distance data, just set the designed value and tolerance
  if (!data?.distance) {
    sheet.getCell(currentRow, currentCol).style = { ...nameStyle, ...centerCenterStyle }
    setValueCell(sheet, currentRow, currentCol + 2, grid.pre_defined_thresholds?.designed_value, false)
    setValueCell(sheet, currentRow, currentCol + 3, grid.pre_defined_thresholds?.tolerance, false)
    return
  }

  const { differenceValue, isPassingThresholds } = getDifferenceValueAndIsPassingThresholds(
    {
      estimated_value: data.distance,
    },
    grid.pre_defined_thresholds,
    settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
    GRID_UNIT,
    GRID_UNIT_PRECISION,
  )
  const { isEvaluated, isUpperToleranceDefined, isLowerToleranceDefined } = canBeEvaluated(
    settings,
    settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE,
    grid.pre_defined_thresholds,
  )
  const isFailingThresholds = (isEvaluated && !isPassingThresholds) || false
  const toleranceType = settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE
  let tolerance: number | string | null = grid.pre_defined_thresholds?.tolerance || null

  if (toleranceType === ProjectSheetToleranceType.GteDesignedValue && isPassingThresholds !== undefined) {
    tolerance = isPassingThresholds ? '以上' : '以下'
  }

  if (toleranceType === ProjectSheetToleranceType.LteDesignedValue && isPassingThresholds !== undefined) {
    tolerance = isPassingThresholds ? '以下' : '以上'
  }

  if (isUpperToleranceDefined || isLowerToleranceDefined) {
    const str = []

    if (isValueDefined(grid.pre_defined_thresholds?.lower_tolerance)) {
      str.push(`-${meterToMilimeterWhole(grid.pre_defined_thresholds?.lower_tolerance)}`)
    }

    if (isValueDefined(grid.pre_defined_thresholds?.upper_tolerance)) {
      str.push(`+${meterToMilimeterWhole(grid.pre_defined_thresholds.upper_tolerance)}`)
    }

    tolerance = str.join(' ')
  }

  sheet.getCell(currentRow, currentCol).style = {
    ...nameStyle,
    ...centerCenterStyle,
    ...(isFailingThresholds ? valueFailed : {}),
  }
  setValueCell(sheet, currentRow, currentCol + 1, data.distance, isFailingThresholds)
  setValueCell(sheet, currentRow, currentCol + 2, grid.pre_defined_thresholds?.designed_value, isFailingThresholds)
  setValueCell(sheet, currentRow, currentCol + 3, tolerance, isFailingThresholds)
  setValueCell(sheet, currentRow, currentCol + 4, differenceValue, isFailingThresholds, true)
}

/**
 * Set data table for a single grid.
 */
function setDataTable(
  sheet: Worksheet,
  settings: ProjectSheetSettings,
  grid: InspectionItem,
  volume: InspectionItem,
  startRow: number,
): number {
  let currentRow = startRow
  let currentCol = 1

  // Grid name column
  sheet.getCell(currentRow, currentCol + 1).value = makeInternalLinkValue(
    DATA_SHEET_NAME,
    `data_${getIdUniqueString(volume.inspection_item_id!)}`,
    volume.part_name,
  )
  sheet.getCell(currentRow, currentCol + 1).style = { ...underlineStyle, ...bgGrey100, ...verticalMiddleStyle }
  sheet.getCell(currentRow, currentCol + 1).name = `grid_values_${getIdUniqueString(grid.inspection_item_id!)}`

  if (!grid.grid?.list_distances) return currentRow

  // List of values is split into 2, side-by-side (goes down first first side then the other)
  const totalRows = Math.ceil((grid.grid.list_distances.length || 0) / 2)

  // Merge all rows on grid name column
  sheet.mergeCells(currentRow, currentCol + 1, currentRow + totalRows - 1, currentCol + 1)
  createOuterBorder(sheet, [currentCol + 1, currentRow], [currentCol + 1, currentRow + totalRows - 1])
  currentCol += 2 // skip the first 2 columns due to names

  // Link to grid diagram
  if (settings.sheet_diagram_visibility?.grid_diagram) {
    const cellId = `diagram_${volume.inspection_item_id!.substring(volume.inspection_item_id!.length - 8)}`
    sheet.getCell(currentRow, currentCol).value = makeInternalLinkValue(DIAGRAM_SHEET_NAME, cellId, '図面')
    sheet.getCell(currentRow, currentCol).style = { ...centerCenterStyle, ...underlineStyle, ...bgGrey100 }
    sheet.mergeCells(currentRow, currentCol, currentRow + totalRows - 1, currentCol)
    createOuterBorder(sheet, [currentCol, currentRow], [currentCol, currentRow + totalRows - 1])

    currentCol += 1
  }

  for (let i = 0; i < totalRows; i += 1) {
    const left = grid.grid.list_distances[i]
    const right = grid.grid.list_distances[i + totalRows]

    setDataRow(sheet, currentRow, currentCol, `t${i + 1}`, left, grid, settings)

    // There might not be a right side due to odd number of points
    if (right) {
      setDataRow(sheet, currentRow, currentCol + 5, `t${i + totalRows + 1}`, right, grid, settings)
    } else {
      // grey it out
      sheet.getCell(currentRow, currentCol + 5).style = { fill: fillGrey50 }
      sheet.getCell(currentRow, currentCol + 6).style = { fill: fillGrey50 }
      sheet.mergeCells(currentRow, currentCol + 6, currentRow, currentCol + 9)
    }

    currentRow += 1
  }

  // Border surrounding each side
  createOuterBorder(sheet, [currentCol, startRow], [currentCol + 4, currentRow - 1])
  createOuterBorder(sheet, [currentCol + 5, startRow], [currentCol + 9, currentRow - 1])

  // Border around name column
  createOuterBorder(sheet, [currentCol, startRow], [currentCol, currentRow - 1])
  createOuterBorder(sheet, [currentCol + 5, startRow], [currentCol + 5, currentRow - 1])

  return currentRow
}

function setTableHaders(sheet: Worksheet, settings: ProjectSheetSettings, row: number): number {
  let currentCol = 1

  // Area title
  setHeaderCell(sheet, row, currentCol, '検査箇所名', planeHeaderStyle)
  createOuterBorder(sheet, [currentCol, row], [currentCol, row])
  currentCol += 1

  setHeaderCell(sheet, row, currentCol, '体積の名称', planeHeaderStyle)
  createOuterBorder(sheet, [currentCol, row], [currentCol, row])
  currentCol += 1

  if (settings.sheet_diagram_visibility?.grid_diagram) {
    setHeaderCell(sheet, row, currentCol, 'グリッド図面', planeHeaderStyle)
    createOuterBorder(sheet, [currentCol, row], [currentCol, row])
    currentCol += 1
  }

  setHeaderCell(sheet, row, currentCol, '点', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 1, '実測値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 2, '設計値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 3, '規格値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 4, '差', planeHeaderStyle)
  createOuterBorder(sheet, [currentCol, row], [currentCol + 4, row])
  currentCol += 5

  setHeaderCell(sheet, row, currentCol, '点', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 1, '実測値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 2, '設計値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 3, '規格値', planeHeaderStyle)
  setHeaderCell(sheet, row, currentCol + 4, '差', planeHeaderStyle)
  createOuterBorder(sheet, [currentCol, row], [currentCol + 4, row])

  return row + 1
}

function setInspectionAreaRow(
  sheet: Worksheet,
  startRow: number,
  project: Project,
  inspectionArea: InspectionArea,
  inspectionItems: InspectionItem[],
): number {
  let currentRow = startRow
  const grids = inspectionItems.filter((item) => item.item_type === 'grid')
  const volumes = inspectionItems
    .filter((item) => item.item_type === 'volume')
    .map((item, index) => ({
      ...item,
      part_name: item.part_name || `体積 ${index + 1}`,
    }))

  if (grids.length === 0) return currentRow

  // Individual grids
  const preRow = currentRow
  volumes.forEach((volume) => {
    const grid = grids.find((item) => item.volume_id === volume.inspection_item_id)
    if (grid) {
      currentRow = setDataTable(sheet, project, grid, volume, currentRow)
    }
  })

  // Set inspection area name
  sheet.getCell(preRow, 1).value = makeExternalLinkValue(
    inspectionArea.inspection_area_name,
    `${APP_URL}/projects/${project.project_id}/editor?area=${inspectionArea.inspection_area_id}`,
  )

  sheet.getCell(preRow, 1).style = { ...underlineStyle, ...bgGrey100, ...verticalMiddleStyle }
  sheet.mergeCells(preRow, 1, currentRow - 1, 1)
  createOuterBorder(sheet, [1, preRow], [1, currentRow - 1])

  return currentRow
}

export function addGridValuesSheet(
  workbook: Workbook,
  project: Project,
  inspectionAreas: InspectionArea[],
  inspectionSheet: InspectionSheet | null,
  inspectionItems: InspectionItem[],
  shapes: Shapes,
) {
  const sheet = workbook.addWorksheet(GRID_VALUES_SHEET_NAME, {
    pageSetup: { orientation: 'landscape', paperSize: 9 },
  })

  // start from 1st row
  let bodyRow = 1

  // ## worksheet title
  bodyRow = setTitle(sheet, bodyRow, GRID_VALUES_SHEET_NAME) + 1 // +1 for padding

  // ## profile section
  if (inspectionSheet) {
    bodyRow =
      setProfileGroup(
        sheet,
        bodyRow,
        project,
        inspectionSheet ? inspectionAreas[0] : undefined,
        inspectionSheet,
        true,
      ) + 1 // +1 for padding
  }

  // ## Summary
  bodyRow = setSummary(sheet, inspectionItems, shapes, bodyRow) + 1 // +1 for padding
  sheet.getCell(bodyRow - 2, 12).value = '単位: mm'

  // ## Table headers
  bodyRow = setTableHaders(sheet, project, bodyRow)

  // ## Data
  inspectionAreas.forEach((inspectionArea) => {
    const items = inspectionItems.filter(
      (item) =>
        parseInspectionItemPartitionKey(item.partition_key!).inspectionAreaId === inspectionArea.inspection_area_id,
    )
    bodyRow = setInspectionAreaRow(sheet, bodyRow, project, inspectionArea, items)
  })

  // Cell size adjustments
  fitColumns(sheet, true)
  sheet.getColumn(3).width = 16 // diagram column
  sheet.eachRow((row, index) => {
    if (index > 9) {
      row.height = 20
    }
  })
}
