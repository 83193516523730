import axios from 'axios'

import { PointArray } from 'interfaces/attribute'
import { GridType, IntervalsConfig } from 'interfaces/inspectionItemGrid'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_INSPECTION_SHEET_API_URL } from './InspectionSheet'

/**
 * Estimate depth based on a grid. No shapes are provided as back-end will figure
 * out what shapes to be used.
 *
 * @param accessToken Access token to access API
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param partName Name of this inspection item row.
 * @param planes Plane IDs
 * @param showErrorModal Function to show error modal
 * @returns Async job token
 */
export const estimateGridDepth = async (
  accessToken: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  part_name: string,
  volume_id: string,
  interval: IntervalsConfig,
  polygons: string[] | undefined,
  grid_points: PointArray[],
  showErrorModal: (message: string) => void,
): Promise<string | null> =>
  axios
    .post<{ job_token: string }>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/evaluate-grid`,
      {
        volume_id,
        part_name,
        polygons,
        grid_points,
        params_to_store: {
          intervals: {
            long_axis: interval.longAxis.interval.value,
            short_axis: interval.shortAxis.interval.value,
          },
          grid_type: interval.type,
          grid_order_direction: interval.orderDirection,
          ...(interval.type === GridType.EdgeBased
            ? {
                distances_from_edge: {
                  long_axis: interval.longAxis.edgeDistance.value,
                  short_axis: interval.shortAxis.edgeDistance.value,
                },
                number_of_points: {
                  long_axis: interval.longAxis.pointCount.value,
                  short_axis: interval.shortAxis.pointCount.value,
                },
              }
            : {}),
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${accessToken}` },
      },
    )
    .then((res) => res.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ESTIMATE_GRID, showErrorModal)
      return null
    })
