import { ForwardRefRenderFunction, useCallback, useImperativeHandle, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react'

export interface TermsOfServiceModalForwardRef {
  /**
   * Opens a modal to display ToS.
   *
   * @param title Modal title
   * @param url URL to the ToS
   * @param onAgree Callback when user agrees to the ToS. If this is not provided, there will be no 'Agree' button and user can freely close the modal.
   * @returns
   */
  openModal: (title: string, url: string, onAgree?: () => Promise<void>) => void
}

const TermsOfServiceModalFn: ForwardRefRenderFunction<TermsOfServiceModalForwardRef> = (props, ref) => {
  // ToS modal controls
  const disclosure = useDisclosure()
  const { onOpen, onClose } = disclosure

  // States
  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [onAgree, setOnAgree] = useState<() => Promise<void>>()

  const { logout } = useAuth0()

  // Derived
  const agreeable = !!onAgree

  /**
   * Handle user agreeing to the ToS.
   * Calls the callback, close the modal as well
   */
  const handleAgree = useCallback(async () => {
    if (onAgree) await onAgree()
    onClose()
  }, [onAgree, onClose])

  /**
   * Component imperative methods
   */
  useImperativeHandle(ref, () => ({
    openModal: (ttl: string, ul: string, onAgreeCb?: () => Promise<void>) => {
      setTitle(ttl)
      setUrl(ul)
      setOnAgree(onAgreeCb ? () => onAgreeCb : undefined)
      onOpen()
    },
  }))

  return (
    <Modal
      {...disclosure}
      closeOnEsc={!agreeable}
      closeOnOverlayClick={!agreeable}
      size={{
        sm: 'full',
        md: '5xl',
      }}
    >
      <ModalOverlay />
      <ModalContent
        maxHeight="1000px"
        height="80svh"
        mt={{
          md: 8,
          xl: 16,
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        {!agreeable && <ModalCloseButton />}
        <ModalBody>
          <iframe
            title={title}
            src={url}
            width="100%"
            height="100%"
            style={{ border: '1px solid var(--chakra-colors-gray-200)', borderRadius: 'var(--chakra-radii-sm)' }}
          />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            {agreeable ? (
              <>
                <Button colorScheme="primary" onClick={handleAgree}>
                  同意して続ける
                </Button>
                <Button
                  colorScheme="secondary"
                  onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                >
                  ログアウト
                </Button>
              </>
            ) : null}
            {!agreeable ? (
              <Button colorScheme="primary" onClick={onClose}>
                閉じる
              </Button>
            ) : null}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const TermsOfServiceModal = forwardRef(TermsOfServiceModalFn)
export default TermsOfServiceModal
