import './styles.css'

import { FC, useCallback, useRef } from 'react'

import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  Hide,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Square,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import InspectionAreaFormModal, {
  InspectionAreaFormModalForwardRef,
} from 'pages/dashboard/components/InspectionAreaFormModal'
import DownsampledPCDExportButton from 'pages/projects/editor/actions/DownsampledPCDExportButton'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation, useMatches, useNavigate, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { addInspectionArea, patchInspectionAreas } from 'store/page'
import { useDebouncedCallback } from 'use-debounce'

import {
  AddIcon,
  BackIcon,
  ChevronLeftIcon,
  CubeIcon,
  EditBoxIcon,
  ExcelIcon,
  ExternalLink,
  GridIcon,
  HelpCircleIcon,
  ListIcon,
  LogoLight,
  MenuIcon,
  PDFIcon,
  SettingIcon,
} from 'assets/icons'

import { useEditorContext } from 'contexts/Editor'
import { useGlobalModalContext } from 'contexts/GlobalModal'

import {
  BUTTON_FONT_SIZE,
  EDITOR_NAVBAR_HEIGHT,
  EDITOR_NAVBAR_HEIGHT_TABLET,
  EDITOR_NAVBAR_PADDING,
  HELP_CENTER_URL,
  MODAL_TYPES,
  USER_TYPES,
} from 'config/constants'

import { InspectionArea, InspectionSheet } from 'interfaces/inspection'

import { decideActionPermission } from 'services/Validation'

import CADExportButton from '../../editor/actions/CADExportButton'
import PCDExportButton from '../../editor/actions/PCDExportButton'
import AreaSwitcher from '../AreaSwitcher'
import NavbarPageButton from './components/NavbarPageButton'

const Navbar: FC = () => {
  // Context
  const { inspectionSheet, setInspectionSheet } = useEditorContext()
  const { showModal } = useGlobalModalContext()

  // Store
  const dispatch = useAppDispatch()
  const userType = useSelector((state: RootState) => state.user.userType)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const toolVolumePolygonIsDirty = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isDirty)
  const toolRebarDetectionIsDirty = useSelector((state: RootState) => state.toolRebarDetection.isDirty)
  const toolPlaneDetectionIsDirty = useSelector((state: RootState) => state.toolPlaneDetection.isDirty)
  const toolPolylineIsDirty = useSelector((state: RootState) => state.toolPolyline.isDirty)

  // Route matching to check current page
  const navigate = useNavigate()
  const matches = useMatches()
  const isBlueprintPage = matches.some((row) => row.id.includes('blueprint'))
  const isEditorPage = matches.some((row) => row.id.includes('editor'))
  const isInspectionSheetPage = matches.some((row) => row.id.includes('inspection-sheet'))

  // Get project ID from URL
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Handles sub-menu
  const { onClose, onOpen, isOpen } = useDisclosure()
  const debounceSubMenuClose = useDebouncedCallback(onClose, 200)
  const handleMouseEnter = () => {
    debounceSubMenuClose.cancel()
    onOpen()
  }

  // Refs
  const inspectionAreaFormModalRef = useRef<InspectionAreaFormModalForwardRef>(null)

  // Permissions
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isAllowedToModify = decideActionPermission(isOwner, isInvited).PROJECT_DASHBOARD.MODIFY.includes(userType)

  /**
   * Handles editing the current inspection area.
   */
  const handleEditArea = useCallback(() => {
    if (!project || !inspectionArea) return

    inspectionAreaFormModalRef.current?.openModal(
      project,
      inspectionArea,
      inspectionAreas,
      inspectionSheet,
      (updatedArea: InspectionArea, updatedSheet?: InspectionSheet) => {
        dispatch(patchInspectionAreas([updatedArea]))
        if (updatedSheet) setInspectionSheet(updatedSheet)
      },
    )
  }, [project, inspectionArea, inspectionAreas, inspectionSheet, dispatch, setInspectionSheet])

  /**
   * Handles creating a new inspection area
   */
  const handleCreateArea = useCallback(() => {
    if (!project || !inspectionArea) return

    const showCreateModal = () => {
      inspectionAreaFormModalRef.current?.openModal(
        project,
        undefined,
        inspectionAreas,
        inspectionSheet,
        (updatedArea: InspectionArea) => {
          dispatch(addInspectionArea(updatedArea))
          navigate(`/projects/${project.project_id}/editor?area=${updatedArea.inspection_area_id}`)
        },
      )
    }

    if (
      isEditorPage &&
      (cuboidAnchor ||
        Object.keys(intervals).length ||
        toolVolumePolygonIsDirty ||
        toolRebarDetectionIsDirty ||
        toolPlaneDetectionIsDirty ||
        toolPolylineIsDirty)
    ) {
      showModal({
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        body: '検出を破棄しますか？',
        confirmText: '破棄',
        cancelText: 'キャンセル',
        onConfirm: () => {
          showCreateModal()
          return true
        },
      })
      return
    }

    showCreateModal()
  }, [
    isEditorPage,
    cuboidAnchor,
    intervals,
    toolVolumePolygonIsDirty,
    toolRebarDetectionIsDirty,
    toolPlaneDetectionIsDirty,
    toolPolylineIsDirty,
    inspectionAreaFormModalRef,
    project,
    inspectionArea,
    inspectionAreas,
    inspectionSheet,
    showModal,
    dispatch,
    navigate,
  ])

  if (!project_id) {
    return null
  }

  return (
    <HStack background="gray.900" p={EDITOR_NAVBAR_PADDING} w="100%" m={0} id="project-navbar" zIndex={150}>
      <HStack
        w="100%"
        h={{ base: EDITOR_NAVBAR_HEIGHT_TABLET, xl: EDITOR_NAVBAR_HEIGHT }}
        justifyContent="start"
        gap={4}
      >
        <Button
          as={RouterLink}
          variant="ghostLink"
          leftIcon={<BackIcon />}
          to={`/dashboard#${project_id}`}
          size={{ base: 'lg', xl: 'xs' }}
          h="100%"
        >
          工事一覧
        </Button>
        <HStack
          spacing={0}
          backgroundColor="gray.800"
          overflow="hidden"
          borderRadius="md"
          divider={<StackDivider borderColor="whiteAlpha.200" />}
        >
          <>
            <NavbarPageButton
              id="editor-page-button"
              title="3D画面"
              tooltip={
                <Box>
                  <Text>3D画面に移動</Text>
                  {!inspection_area_id && <Text color="orange">エリアを選択してください</Text>}
                </Box>
              }
              icon={<CubeIcon />}
              path={`/projects/${project_id}/editor?area=${inspection_area_id}`}
              isActive={isEditorPage}
              isDisabled={!inspection_area_id || isPageLoading}
            />
            <NavbarPageButton
              id="blueprint-page-button"
              title="設計図面"
              tooltip="設計図面に移動"
              icon={<GridIcon />}
              path={
                !inspection_area_id
                  ? `/projects/${project_id}/blueprints`
                  : `/projects/${project_id}/blueprints?area=${inspection_area_id}`
              }
              isActive={isBlueprintPage}
              isDisabled={isPageLoading}
            />
            <NavbarPageButton
              id="inspection-sheet-page-button"
              title="帳票詳細"
              tooltip="帳票詳細に移動"
              icon={<ListIcon />}
              path={
                !inspection_area_id
                  ? `/projects/${project_id}/inspection-sheet`
                  : `/projects/${project_id}/inspection-sheet?area=${inspection_area_id}`
              }
              isActive={isInspectionSheetPage}
              isDisabled={isPageLoading}
            />
          </>
        </HStack>
      </HStack>

      <Hide below={isBlueprintPage ? 'xl' : 'md'}>
        <HStack>
          <LogoLight height="28" />
        </HStack>
      </Hide>

      <HStack w="100%" justifyContent="end" gap={2}>
        <AreaSwitcher />

        {(isEditorPage || isInspectionSheetPage) && (
          <Menu>
            <MenuButton
              data-testid="navbar-hamburger"
              bg="gray.800"
              color="gray.300"
              borderColor="gray.500"
              borderWidth={1}
              _hover={{ bg: 'gray.600' }}
              _active={{ bg: 'gray.600' }}
              as={IconButton}
              icon={
                <Square fontSize={BUTTON_FONT_SIZE}>
                  <MenuIcon />
                </Square>
              }
            />
            <MenuList
              className="navbar-hamburger"
              background="gray.600"
              border="1px solid var(--chakra-colors-gray-500)"
            >
              {isEditorPage && (
                <MenuGroup title="3D画面">
                  <Menu isOpen={isOpen} placement="right" offset={[0, 0]}>
                    <MenuButton
                      as={MenuItem}
                      onClick={onOpen}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={debounceSubMenuClose}
                      className="has-submenu"
                    >
                      <HStack>
                        <ChevronLeftIcon size="1.2rem" />
                        <Square fontSize={{ base: 'xl', xl: 'lg' }} className="chakra-menu__icon-wrapper">
                          <DownloadIcon />
                        </Square>
                        <Text>CAD出力</Text>
                      </HStack>
                    </MenuButton>
                    <MenuList
                      background="gray.600"
                      border="1px solid var(--chakra-colors-gray-500)"
                      width="210px"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={debounceSubMenuClose}
                    >
                      <CADExportButton />
                    </MenuList>
                  </Menu>
                  {userType === USER_TYPES.ADMIN && <PCDExportButton />}
                  {userType === USER_TYPES.ADMIN && <DownsampledPCDExportButton />}
                </MenuGroup>
              )}

              {isInspectionSheetPage && (
                <>
                  <MenuGroup title="帳票詳細">
                    <Menu isOpen={isOpen} placement="right" offset={[0, 0]}>
                      <MenuButton
                        as={MenuItem}
                        onClick={onOpen}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={debounceSubMenuClose}
                        className="has-submenu"
                      >
                        <HStack>
                          <ChevronLeftIcon size="1.2rem" />
                          <Square fontSize={{ base: 'xl', xl: 'lg' }} className="chakra-menu__icon-wrapper">
                            <DownloadIcon />
                          </Square>
                          <Text>ダウンロード</Text>
                        </HStack>
                      </MenuButton>
                      <MenuList
                        background="gray.600"
                        border="1px solid var(--chakra-colors-gray-500)"
                        width="210px"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={debounceSubMenuClose}
                      >
                        <MenuItem
                          icon={<PDFIcon size="1.2rem" />}
                          onClick={() => {
                            mixpanel.track('Export inspection sheet', { 'File format': 'PDF' })
                            window.print()
                          }}
                        >
                          印刷 (PDF)
                        </MenuItem>
                        <MenuItem
                          icon={<ExcelIcon size="1.2rem" />}
                          onClick={() => {
                            window.dispatchEvent(new Event('export-xlsx'))
                          }}
                        >
                          エクセル (XLSX)
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </MenuGroup>
                  <MenuItem
                    icon={<SettingIcon size="1.2rem" />}
                    onClick={() => {
                      window.dispatchEvent(new Event('edit-project'))
                    }}
                  >
                    設定
                  </MenuItem>
                </>
              )}

              <MenuDivider />

              {isAllowedToModify && (
                <>
                  {isEditorPage && (
                    <>
                      <MenuGroup title="エリア">
                        <MenuItem icon={<EditBoxIcon size="1.2rem" />} onClick={handleEditArea}>
                          エリアを編集
                        </MenuItem>
                      </MenuGroup>

                      <MenuDivider />
                    </>
                  )}

                  <MenuGroup title="工事">
                    <MenuItem icon={<AddIcon size="1.2rem" />} onClick={handleCreateArea}>
                      新しいエリアを作成
                    </MenuItem>
                  </MenuGroup>

                  <MenuDivider />
                </>
              )}

              <MenuGroup title="ヘルプ">
                <MenuItem icon={<HelpCircleIcon size="1.2rem" />} as={RouterLink} to={HELP_CENTER_URL} target="_blank">
                  ユーザーマニュアル
                </MenuItem>
                <MenuItem
                  icon={<ExternalLink size="0.9rem" />}
                  as={RouterLink}
                  to="https://share.hsforms.com/11_hEhYjCR0CBEe1gX9adVQddydr"
                  target="_blank"
                >
                  お問い合わせ
                </MenuItem>
              </MenuGroup>

              <MenuDivider />

              <MenuGroup>
                <MenuItem as={RouterLink} icon={<ListIcon size="1.4rem" />} to={`/dashboard#${project_id}`}>
                  工事一覧に戻る
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        )}
      </HStack>

      {isAllowedToModify && <InspectionAreaFormModal ref={inspectionAreaFormModalRef} />}
    </HStack>
  )
}

export default Navbar
