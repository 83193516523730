import { FC, useContext, useEffect, useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import LoadingPage from 'components/LoadingPage'
import mixpanel from 'mixpanel-browser'
import MaintenancePage from 'pages/maintenancePage/MaintenancePage'
import { DashboardProvider } from 'project-dashboard-library/dist/components/providers/DashboardProvider'
import {
  InspectionArea,
  InspectionAreaDownSampleStatus,
} from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { Project } from 'project-dashboard-library/dist/interfaces/project'
import { useSelector } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { setFeatureFlags, setFeatureFlagsLoaded } from 'store/featureFlags'
import { setServiceStatus } from 'store/system'
import { setVideos } from 'store/video'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { RouterContext } from 'contexts/Router'
import { UserContext, useUserContext } from 'contexts/Users'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import { MIXPANEL_TOKEN } from 'config/environments'

import { getFeatureFlags, getServiceStatus, getTutorialVideoConfig } from 'services/App'
import { ERROR_PROCESS, getErrorForToast } from 'services/ErrorHandler'
import { getInspectionArea, getInspectionAreas } from 'services/InspectionArea'
import { router } from 'services/Router'

const AppContent: FC = () => {
  useDocumentTitle('Hatsuly')
  const { isLoading, user, getAccessTokenSilently } = useAuth0()
  const routerContextValue = useMemo(
    () => ({
      // router: getRouter(),
      router,
    }),
    [],
  )

  //* アプリ全体で管理する項目、user,projects,videos
  const userContextVal = useUserContext()

  // Store
  const dispatch = useAppDispatch()
  const serviceStatus = useSelector((state: RootState) => state.system.serviceStatus)

  // Server status check
  const { showErrorModal } = useContext(GlobalModalContext)

  /**
   * Get service status
   */
  useEffect(() => {
    if (isLoading) return

    void (async () => {
      const token = user ? await getAccessTokenSilently() : undefined
      const status = await getServiceStatus(showErrorModal, token)

      if (status) dispatch(setServiceStatus(status))
    })()
  }, [showErrorModal, getAccessTokenSilently, user, isLoading, dispatch])

  /**
   * Get tutorial video config
   */
  useEffect(() => {
    if (!user || isLoading || !serviceStatus || serviceStatus.isDown.enabled) return

    void (async () => {
      const token = await getAccessTokenSilently()
      if (!token) return

      const videoConfig = await getTutorialVideoConfig(showErrorModal, token)
      if (videoConfig) {
        dispatch(setVideos(videoConfig))
      }
    })()
  }, [showErrorModal, getAccessTokenSilently, serviceStatus, user, isLoading, dispatch])

  /**
   * Get feature flags
   */
  useEffect(() => {
    if (isLoading || !serviceStatus || serviceStatus.isDown.enabled) return

    void (async () => {
      const featureFlags = await getFeatureFlags(showErrorModal)
      if (featureFlags) {
        dispatch(setFeatureFlags(featureFlags))
      }
      dispatch(setFeatureFlagsLoaded())
    })()
  }, [showErrorModal, getAccessTokenSilently, serviceStatus, user, isLoading, dispatch])

  // Init mixpanel
  mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true, persistence: 'localStorage' })
  if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_MIXPANEL) {
    mixpanel.disable()
  }

  if (isLoading || serviceStatus === null) {
    return <LoadingPage text="読み込み中…" />
  }

  if (serviceStatus.isDown.enabled) {
    return <MaintenancePage />
  }

  return (
    <UserContext.Provider value={userContextVal}>
      <DashboardProvider
        analytics={{
          track: (eventName: string, properties?: { [key: string]: unknown }): void =>
            mixpanel.track(eventName, properties),
        }}
        services={{
          inspectionArea: {
            get: async (projectId: string, inspectionAreaId: string): Promise<InspectionArea | null> => {
              const result = await getInspectionArea(
                await getAccessTokenSilently(),
                projectId,
                inspectionAreaId,
                showErrorModal,
              )
              if (result) {
                // replace downsample status message
                if (result.downsample_status?.status === InspectionAreaDownSampleStatus.FAILED) {
                  const err = getErrorForToast(ERROR_PROCESS.CREATE_INSPECTION_AREA, result.downsample_status.status_id)

                  result.downsample_status.status_message = err.description
                }

                return result
              }

              return null
            },
            getByProject: async (data: Project): Promise<InspectionArea[]> => {
              const results = await getInspectionAreas(await getAccessTokenSilently(), data.project_id, showErrorModal)

              if (!results) {
                return []
              }

              const processed = results.map((area) => {
                const updated = { ...area }

                // replace downsample status message
                if (updated.downsample_status?.status === InspectionAreaDownSampleStatus.FAILED) {
                  const err = getErrorForToast(
                    ERROR_PROCESS.CREATE_INSPECTION_AREA,
                    updated.downsample_status.status_id,
                  )

                  updated.downsample_status.status_message = err.description
                }

                return updated
              })

              if (processed) {
                return processed
              }

              return []
            },
            getViewRoute: (inspectionArea: InspectionArea) =>
              `/projects/${inspectionArea.project_id}/editor?area=${inspectionArea.inspection_area_id}`,
          },
          project: {},
        }}
      >
        <RouterContext.Provider value={routerContextValue}>
          <RouterProvider router={router} />
        </RouterContext.Provider>
      </DashboardProvider>
    </UserContext.Provider>
  )
}

export default AppContent
