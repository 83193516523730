import { FC, memo, useMemo } from 'react'

import { Flex } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'store/app'

import { InspectionArea, InspectionItem, InspectionItemNumberValues } from 'interfaces/inspection'
import { ProjectSheetToleranceType } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { zeroPad } from 'services/Util'
import { getVolumeEstimationItems } from 'services/VolumeEstimation'

import InspectionAreaRowHeader from './InspectionAreaRowHeader'
import MetricsGroup from './MetricsGroup'
import ModeledItem from './ModeledItem/ModeledItem'
import NoData from './NoData'

const InspectionAreaRow: FC<{
  inspectionArea: InspectionArea
  inspectionItems: InspectionItem[]
  shapes: Shapes

  /**
   * All additional metrics' toggle state, including ones not belonging to this metric.
   */
  allAdditionalMetricsToggle?: boolean[]
}> = ({ inspectionArea, inspectionItems, shapes, allAdditionalMetricsToggle = [] }) => {
  // URL Params
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Store
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)

  // Get volume estimation item and all related inspection items (grid, polygon inspection items)
  const volumeSets = useMemo(
    () =>
      getVolumeEstimationItems(inspectionItems).reduce<InspectionItem[][]>((collection, volumeItem) => {
        collection.push([
          volumeItem,
          ...inspectionItems.filter((item) => item.volume_id === volumeItem.inspection_item_id),
        ])
        return collection
      }, []),
    [inspectionItems],
  )

  // Get standalone plane inspection items
  const standalonePlaneItems = inspectionItems
    .filter((item) => item.item_type === 'polygon_area' && !item.volume_id)
    .reduce<InspectionItem[][]>((collection, item) => {
      collection.push([
        item,
        ...inspectionItems.filter((i) => i.shape_ids.polygons?.includes(item.shape_ids.polygons[0])),
      ])
      return collection
    }, [])

  // Get custom measurements
  const customMeasurements = inspectionItems
    .filter((item) => item.item_type === 'length_with_distance_tool')
    .map((item, index) => ({ ...item, part_name: `${item.part_name || `距離${zeroPad(index + 1, 3)}`}` }))

  // Get polylines
  const polylines = inspectionItems.filter((item) => item.item_type === 'polyline_length')

  return (
    <Flex
      className={['inspection-area-row', inspectionArea.inspection_area_id === inspection_area_id ? 'active' : '']
        .filter(Boolean)
        .join(' ')}
      id={`inspection-area-${inspectionArea.inspection_area_id}`}
    >
      <InspectionAreaRowHeader inspectionArea={inspectionArea} />

      {inspectionItems.length === 0 && (
        <NoData message="このエリアには表示する項目がありません" inspectionArea={inspectionArea} />
      )}

      {inspectionItems.length > 0 && (
        <Flex className="inspection-area-data-rows" flexDirection="column">
          {settings.sheet_models_visibility?.volume &&
            volumeSets.map((volumeSet, index) => (
              <ModeledItem
                key={volumeSet[0].inspection_item_id}
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                defaultTitle={`体積 ${index + 1}`}
                title={volumeSet[0].part_name}
                inspectionItems={volumeSet}
                inspectionArea={inspectionArea}
                shapes={shapes}
              />
            ))}
          {settings.sheet_models_visibility?.plane &&
            standalonePlaneItems.map((items, index) => (
              <ModeledItem
                key={items[0].inspection_item_id}
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                defaultTitle={`面積 ${zeroPad(index + 1, 3)}`}
                title={items[0].part_name}
                inspectionItems={items}
                inspectionArea={inspectionArea}
                shapes={shapes}
              />
            ))}
          {settings.sheet_models_visibility?.polyline &&
            polylines.map((item, index) => (
              <ModeledItem
                key={item.inspection_item_id}
                title={item.part_name}
                defaultTitle={`延長 ${zeroPad(index + 1, 3)}`}
                inspectionItems={[item]}
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionArea={inspectionArea}
                shapes={shapes}
              />
            ))}
          {customMeasurements.length && settings.sheet_models_visibility?.length_with_distance_tool ? (
            <MetricsGroup
              title="手動計測した距離"
              itemsDefaulTitle={(item, index) => `距離${zeroPad(index + 1, 3)}`}
              unit="mm"
              nameChangeable
              inspectionItems={customMeasurements as InspectionItem[]}
              inspectionItemValues={
                customMeasurements.map((item) => item.length_with_distance_tool) as InspectionItemNumberValues[]
              }
              isMetricsHidden={false}
              allAdditionalMetricsToggle={allAdditionalMetricsToggle}
              toleranceType={
                settings.sheet_rows_tolerance_type?.length_with_distance_tool ||
                ProjectSheetToleranceType.WithinTolerance
              }
            />
          ) : null}
        </Flex>
      )}
    </Flex>
  )
}

export default memo(InspectionAreaRow)
