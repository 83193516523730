import { FC, PropsWithChildren, useContext, useEffect, useRef } from 'react'

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Link, useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { setUserProfile } from 'store/user'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { GRACE_HOURS_BEFORE_RENEWAL } from 'config/constants'

import { agreeToTos } from 'services/Users'

import TermsOfServiceModal, { TermsOfServiceModalForwardRef } from './TermsOfServiceModal'

const SUBSCRIPTION_WARNING_TOAST_ID = 'subscription-warning'
const HIDE_SUBSCRIPTION_WARNING_LOCAL_STORAGE_KEY = 'hide-subscription-warning-until'

const RouteWrapperApp: FC<PropsWithChildren> = ({ children }) => {
  // Store
  const dispatch = useAppDispatch()
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)
  const hasDeprecatedSubscription = useSelector((state: RootState) => state.user.hasDeprecatedSubscription)

  // Refs
  const tosModalRef = useRef<TermsOfServiceModalForwardRef>(null)

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Toast
  const toast = useToast()

  /**
   * Trigger open ToS modal if the user needs to agree to it.
   * Generally after the initial sign-up or ToS has been updated.
   *
   * Also used to open the modal from other components via event dispatch.
   */
  useEffect(() => {
    if (!userProfile || !tosModalRef?.current || !featureFlags?.selfManagedSubscriptions) return () => null

    const agreedToCurrentTos = userProfile.agreed_tos_version === featureFlags.selfManagedSubscriptions.tos_version

    const openModal = () => {
      tosModalRef.current!.openModal(
        '利用規約',
        featureFlags.selfManagedSubscriptions.tos_url,
        !agreedToCurrentTos
          ? async () => {
              // Make sure the store has the latest data before closing the modal
              // otherwise, the modal will reopen on the next render.
              const token = await getAccessToken()
              if (!token) {
                return Promise.reject()
              }
              dispatch(
                setUserProfile({
                  ...userProfile,
                  agreed_tos_version: featureFlags.selfManagedSubscriptions.tos_version,
                }),
              )
              void agreeToTos(token, featureFlags.selfManagedSubscriptions.tos_version, showErrorModal)
              return Promise.resolve()
            }
          : undefined,
      )
    }

    // Open ToS modal if the user hasn't agreed to it yet.
    // Also, don't show the modal if the user has a deprecated subscription.
    if (featureFlags.selfManagedSubscriptions.enabled && hasDeprecatedSubscription === false && !agreedToCurrentTos) {
      openModal()
    }

    document.addEventListener('open-tos', openModal)

    return () => {
      document.removeEventListener('open-tos', openModal)
    }
  }, [tosModalRef, userProfile, featureFlags, hasDeprecatedSubscription, dispatch, getAccessToken, showErrorModal])

  /**
   * Subscription expiration warning
   */
  useEffect(() => {
    if (!userProfile || toast.isActive(SUBSCRIPTION_WARNING_TOAST_ID)) return
    // Subscription warning
    const isSubscriptionExpiringIn30Days =
      !!userProfile.base_feature_end_at &&
      new Date(userProfile.base_feature_end_at) > new Date(Date.now() + GRACE_HOURS_BEFORE_RENEWAL * 60 * 60 * 1000) &&
      new Date(userProfile.base_feature_end_at) < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    const isWarningHidden =
      !!localStorage.getItem(HIDE_SUBSCRIPTION_WARNING_LOCAL_STORAGE_KEY) &&
      new Date().getTime() < Number(localStorage.getItem(HIDE_SUBSCRIPTION_WARNING_LOCAL_STORAGE_KEY))
    const isAutoRenewEnabled = userProfile.auto_base_feature_renewal === 'Y'
    if (!isAutoRenewEnabled && isSubscriptionExpiringIn30Days && !isWarningHidden) {
      toast({
        id: SUBSCRIPTION_WARNING_TOAST_ID,
        title: '',
        status: 'warning',
        duration: null,
        position: 'bottom-right',

        isClosable: true,
        render: () => (
          <Alert status="warning" width={500}>
            <AlertIcon />
            <Box>
              <AlertTitle>サブスクリプションの期限切れ間近</AlertTitle>
              <AlertDescription>
                まもなく工事データは削除されます。データを保持するためには
                {new Date(userProfile.base_feature_end_at!).toLocaleDateString()} {24 - GRACE_HOURS_BEFORE_RENEWAL}
                時までに
                <Link href="/user#plan" variant="underline">
                  自動更新を有効
                </Link>
                にしてください。
              </AlertDescription>
            </Box>
            <Button
              onClick={() => {
                // hide until 1 day later
                localStorage.setItem(HIDE_SUBSCRIPTION_WARNING_LOCAL_STORAGE_KEY, `${new Date().getTime() + 86400000}`)
                toast.close(SUBSCRIPTION_WARNING_TOAST_ID)
              }}
            >
              閉じる
            </Button>
          </Alert>
        ),
      })
    }
  }, [userProfile, toast])

  return (
    <>
      <TermsOfServiceModal ref={tosModalRef} />

      {children ?? <Outlet />}
    </>
  )
}

export default RouteWrapperApp
