import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Flex, IconButton, Input, Text, useNumberInput, useOutsideClick } from '@chakra-ui/react'

import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'

const RangeInput: FC<{
  axis?: 'X' | 'Y' | 'Z' | undefined
  max?: number
  min?: number
  precision: number
  step: number
  unit?: string
  updateValue: (newValue: number) => void
  onManualEdit?: (isManual: boolean) => void
  value: number | null
  displayValue?: string
  disabled?: boolean
  isSmall?: boolean
  testId?: string
}> = ({
  axis,
  max,
  min,
  precision,
  step,
  unit,
  updateValue,
  onManualEdit,
  value,
  displayValue,
  disabled,
  isSmall,
  testId = '',
}) => {
  const { valueAsNumber, getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step,
    min,
    max,
    precision,
    defaultValue: value || 0,
  })

  const incrementButtonProps = getIncrementButtonProps()
  const decrementButtonProps = getDecrementButtonProps()
  const inputProps = getInputProps()

  const iconButtonProps = {
    disabled: value === null,
    fontSize: 'sm',
    size: 'xs',
    variant: 'toolbarIcon',
    minWidth: isSmall ? 4 : 6,
  }

  const [isManual, setIsManual] = useState(false)

  useEffect(() => {
    updateValue(valueAsNumber)
    // Do not watch updateValue function, it will be re-rendered every frame
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManual, valueAsNumber])

  const handleManualToggle = useCallback(
    (newState: boolean) => {
      if (onManualEdit) onManualEdit(newState)
      setIsManual(newState)
    },
    [onManualEdit],
  )

  useEffect(() => {
    if (isManual && ref.current) {
      ref.current.focus()
      ref.current.select()
    }
  }, [isManual])

  const ref = useRef<HTMLInputElement>(null)
  useOutsideClick({
    ref,
    handler: () => handleManualToggle(false),
  })

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      handleManualToggle(false)
    }
  }

  return (
    <Flex color="white" w="100%" alignItems="center" h="100%">
      <Input
        data-testid={`range-input-${testId}`}
        {...inputProps}
        onKeyDown={onKeyDown}
        size="xs"
        autoFocus
        ref={ref}
        display={isManual ? 'block' : 'none'}
      />
      {!isManual && (
        <>
          <IconButton {...decrementButtonProps} {...iconButtonProps} aria-label="decrease" icon={<CaretLeftIcon />} />
          <Flex flex="1" onClick={() => handleManualToggle(!disabled)}>
            {axis && <Text>{axis}:</Text>}
            <Text flex="1" textAlign={value === null || unit ? 'center' : 'right'} key={`asdferfg-${value}`}>
              {value === null ? '-' : displayValue || valueAsNumber}
              {value !== null && unit ? unit : ''}
            </Text>
          </Flex>
          <IconButton {...incrementButtonProps} {...iconButtonProps} aria-label="increase" icon={<CaretRightIcon />} />
        </>
      )}
    </Flex>
  )
}

export default RangeInput
