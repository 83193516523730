import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { FeatureFlags } from 'interfaces/interfaces'

export interface FeatureFlagsState {
  /**
   * Whether the feature flags has been loaded.
   */
  featureFlagsLoaded: boolean

  /**
   * Feature flags.
   */
  featureFlags?: FeatureFlags
}

const initialState: FeatureFlagsState = {
  featureFlagsLoaded: false,
  featureFlags: undefined,
}

export const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlagsLoaded: (state) => {
      state.featureFlagsLoaded = true
    },
    setFeatureFlags: (state, action: PayloadAction<FeatureFlags>) => {
      state.featureFlags = action.payload
    },
  },
})

export const { setFeatureFlagsLoaded, setFeatureFlags } = slice.actions

export default slice.reducer
