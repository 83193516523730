import { useRef } from 'react'

import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'

import { INFO_PANEL_PADDING } from 'config/styles'

import { IntervalsAxisConfig } from 'interfaces/inspectionItemGrid'

import { validateAxisConfigValue } from '../../utils'

const CenterBasedAxisConfig = ({
  side,
  axisConfig,
  selectedVolumeId,
  patchAxisConfig,
}: {
  side: string
  axisConfig: IntervalsAxisConfig
  selectedVolumeId: string
  patchAxisConfig: (axisConfig: Partial<IntervalsAxisConfig>) => void
}) => {
  // Refs
  const inputRef = useRef<HTMLInputElement>(null)
  const sliderTrackRef = useRef<HTMLDivElement>(null)
  const sliderThumbRef = useRef<HTMLDivElement>(null)

  return (
    <FormControl
      px={INFO_PANEL_PADDING}
      py={INFO_PANEL_PADDING}
      isInvalid={!validateAxisConfigValue(axisConfig.interval)}
    >
      <Text>{side}</Text>
      <InputGroup size="sm">
        <Input
          ref={inputRef}
          type="number"
          defaultValue={axisConfig.interval.value}
          size="sm"
          min={axisConfig.interval.min}
          max={axisConfig.interval.max}
          background="gray.700"
          borderColor="whiteAlpha.200"
          borderRadius={5}
          isDisabled={!selectedVolumeId}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10)
            patchAxisConfig({
              interval: { ...axisConfig.interval, value },
            })

            // We're not using controlled component so update sliders manually
            if (
              sliderTrackRef.current?.children[0] instanceof HTMLDivElement &&
              sliderThumbRef.current instanceof HTMLDivElement
            ) {
              const perc = (value / axisConfig.edgeDistance.max) * 100
              sliderTrackRef.current.children[0].style.width = `${perc}%`
              sliderThumbRef.current.style.left = `calc(${perc}% - var(--slider-thumb-size) / 2)`
            }
          }}
        />
        <InputRightElement fontSize="sm" px={5}>
          mm
        </InputRightElement>
      </InputGroup>
      <Box mt={2} mx={2}>
        <Slider
          defaultValue={axisConfig.interval.value}
          min={axisConfig.interval.min}
          max={axisConfig.interval.max}
          step={1}
          isDisabled={!selectedVolumeId}
          onChange={(value) => {
            patchAxisConfig({ interval: { ...axisConfig.interval, value } })

            if (inputRef.current) {
              inputRef.current.value = value.toString()
            }
          }}
          focusThumbOnChange={false}
        >
          <SliderTrack ref={sliderTrackRef}>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb ref={sliderThumbRef} />
        </Slider>
      </Box>
      <FormErrorMessage fontSize="xs">
        {axisConfig.interval.min}~{axisConfig.interval.max}の間の値を設定してください
      </FormErrorMessage>
    </FormControl>
  )
}

export default CenterBasedAxisConfig
