import './ModeledItem.css'

import { FC, useMemo } from 'react'

import { Box, Flex } from '@chakra-ui/react'
import { debounce } from 'lodash'
import PolygonDiagram from 'pages/projects/inspection-sheet/components/PolygonDiagram'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { InspectionArea, InspectionItem } from 'interfaces/inspection'
import { PlaneSide, Shapes } from 'interfaces/shape'

import { findLowerPlane, findUpperPlane } from 'services/Shape'

import { DEFAULT_TOLERANCE_TYPE, DIAGRAM_WIDTH, GRID_UNIT } from '../../constants'
import { patchEditedInspectionItems } from '../../store'
import { getTableWidths } from '../../utils'
import GridRow from '../GridRow'
import Metric from '../Metric/Metric'
import PolylineDiagram from '../PolylineDiagram'
import PopoverEditing from '../PopoverEditing'
import TableHeader from '../TableHeader'

/**
 * Check if the item is in the correct plane.
 * If volume, it should be in the upper plane.
 * If plane, it should be in the lower plane.
 *
 * @param item
 * @param shapes
 * @returns
 */
const isCorrectPlane = (item: InspectionItem, shapes: Shapes) =>
  shapes.polygons.find(
    (shape) =>
      shape.shape_id === item.shape_ids.polygons[0] &&
      ((item.volume_id && shape.plane_side === PlaneSide.UPPER) ||
        (!item.volume_id && shape.plane_side === PlaneSide.LOWER)),
  )

const debouncedPatchInspectionItem = debounce(
  (dispatch: ReturnType<typeof useAppDispatch>, inspectionItem: Partial<InspectionItem>) => {
    dispatch(patchEditedInspectionItems([inspectionItem]))
  },
  200,
)

const ModeledItem: FC<{
  /**
   * Title of the volume group
   */
  title: string

  /**
   * If title is empty, use this as the default title.
   */
  defaultTitle: string

  /**
   * Inspection area of these set of inspection items
   */
  inspectionArea: InspectionArea

  /**
   * All inspection items in the volume group
   */
  inspectionItems: InspectionItem[]

  /**
   * Shapes of the project
   */
  shapes: Shapes

  /**
   * All additional metrics' toggle state, including ones not belonging to this metric.
   */
  allAdditionalMetricsToggle?: boolean[]
}> = ({ title, defaultTitle, inspectionArea, inspectionItems, shapes, allAdditionalMetricsToggle = [] }) => {
  // Store
  const dispatch = useAppDispatch()
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)
  const isAllowedToModifySheet = useSelector((state: RootState) => state.inspectionSheet.isAllowedToModifySheet)

  // Derived values
  const volume = inspectionItems.find((item) => item.item_type === `volume`)
  const area = inspectionItems.find((item) => item.item_type === `polygon_area` && isCorrectPlane(item, shapes))
  const perimeter = inspectionItems.find(
    (item) => item.item_type === `polygon_perimeter` && isCorrectPlane(item, shapes),
  )
  const length1 = inspectionItems.find((item) => item.item_type === `polygon_length_1` && isCorrectPlane(item, shapes))
  const length2 = inspectionItems.find((item) => item.item_type === `polygon_length_2` && isCorrectPlane(item, shapes))
  const grid = inspectionItems.find((item) => item.item_type === `grid`)
  const upperDepth = inspectionItems.find(
    (item) =>
      item.item_type === `plane_to_cylinders_distance` &&
      shapes.polygons?.find(
        (shape) => shape.shape_id === item.shape_ids.polygons[0] && shape.plane_side === PlaneSide.UPPER,
      ),
  )
  const lowerDepth = inspectionItems.find(
    (item) =>
      item.item_type === `plane_to_cylinders_distance` &&
      shapes.polygons?.find(
        (shape) => shape.shape_id === item.shape_ids.polygons[0] && shape.plane_side === PlaneSide.LOWER,
      ),
  )
  const polyline = inspectionItems.find((item) => item.item_type === `polyline_length`)

  const topPlane = inspectionItems[0] ? findUpperPlane(shapes, inspectionItems[0]) : null
  const bottomPlane = inspectionItems[0] ? findLowerPlane(shapes, inspectionItems[0]) : null

  const maxWidth = useMemo(
    () => getTableWidths(allAdditionalMetricsToggle, settings),
    [settings, allAdditionalMetricsToggle],
  )

  return (
    <Flex className="modeled-items data-rows">
      <Flex className="title">
        {isAllowedToModifySheet && (
          <PopoverEditing
            value={title}
            defaultValue={defaultTitle}
            onChange={(value) => {
              debouncedPatchInspectionItem(dispatch, {
                inspection_item_id: inspectionItems[0].inspection_item_id,
                inspection_area_id: inspectionItems[0].inspection_area_id,
                inspection_sheet_id: inspectionItems[0].inspection_sheet_id,
                part_name: value,
              })
            }}
          />
        )}
      </Flex>

      <Flex flexDirection="column" w="100%">
        <TableHeader allAdditionalMetricsToggle={allAdditionalMetricsToggle} />

        <Flex width={`${maxWidth.max}px`} bg="white">
          {/* Data */}
          <Flex flexDirection="column" bg="white" alignSelf="stretch" borderRightWidth={1} borderRightColor="gray.300">
            {length1?.polygon_length_1 && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={length1}
                inspectionItemValue={length1.polygon_length_1}
                title="辺の長さ1"
                unit="mm"
                isHidden={!settings.sheet_rows_visibility?.polyon_length_1 || false}
                toleranceType={settings.sheet_rows_tolerance_type?.polyon_length_1 || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {length2?.polygon_length_2 && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={length2}
                inspectionItemValue={length2.polygon_length_2}
                title="辺の長さ2"
                unit="mm"
                isHidden={!settings.sheet_rows_visibility?.polyon_length_2 || false}
                toleranceType={settings.sheet_rows_tolerance_type?.polyon_length_2 || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {perimeter?.polygon_perimeter && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={perimeter}
                inspectionItemValue={perimeter.polygon_perimeter}
                title="外周長"
                unit="mm"
                isHidden={!settings.sheet_rows_visibility?.polygon_perimeter}
                toleranceType={settings.sheet_rows_tolerance_type?.polygon_perimeter || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {area?.polygon_area && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={area}
                inspectionItemValue={area.polygon_area}
                title="面積"
                unit="m²"
                isHidden={!settings.sheet_rows_visibility?.polygon_area}
                toleranceType={settings.sheet_rows_tolerance_type?.polygon_area || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {volume?.volume && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={volume}
                inspectionItemValue={volume.volume}
                title="体積"
                unit="m³"
                isHidden={!settings.sheet_rows_visibility?.volume}
                toleranceType={settings.sheet_rows_tolerance_type?.volume || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {grid?.grid && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={grid}
                inspectionItemValue={grid.grid}
                title="深さ"
                unit={GRID_UNIT}
                isHidden={!settings.sheet_rows_visibility?.grid}
                additionalMetrics={[
                  {
                    typeKey: 'min_grid_depth',
                    pillColor: 'blue',
                    title: '最小',
                    value: grid.grid.min_grid_depth,
                    isHidden: !settings.sheet_rows_visibility?.grid || !settings.sheet_rows_visibility?.min_grid_depth,
                  },
                  {
                    typeKey: 'max_grid_depth',
                    pillColor: 'green',
                    title: '最大',
                    value: grid.grid.max_grid_depth,
                    isHidden: !settings.sheet_rows_visibility?.grid || !settings.sheet_rows_visibility?.max_grid_depth,
                  },
                ]}
                toleranceType={settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE}
              />
            )}
            {upperDepth?.plane_to_cylinders_distance && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={upperDepth}
                inspectionItemValue={upperDepth.plane_to_cylinders_distance}
                title="表かぶり厚"
                unit="mm"
                isHidden={!settings.sheet_rows_visibility?.upper_plane_to_cylinders_distance}
                toleranceType={
                  settings.sheet_rows_tolerance_type?.upper_plane_to_cylinders_distance || DEFAULT_TOLERANCE_TYPE
                }
              />
            )}
            {lowerDepth?.plane_to_cylinders_distance && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={lowerDepth}
                inspectionItemValue={lowerDepth.plane_to_cylinders_distance}
                title="裏かぶり厚"
                unit="mm"
                isHidden={!settings.sheet_rows_visibility?.lower_plane_to_cylinders_distance}
                toleranceType={
                  settings.sheet_rows_tolerance_type?.lower_plane_to_cylinders_distance || DEFAULT_TOLERANCE_TYPE
                }
              />
            )}
            {polyline?.polyline_length && (
              <Metric
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                inspectionItem={polyline}
                inspectionItemValue={polyline.polyline_length}
                title={title || defaultTitle}
                unit="mm"
                isHidden={false}
                toleranceType={settings.sheet_rows_tolerance_type?.polyline_length || DEFAULT_TOLERANCE_TYPE}
              />
            )}
          </Flex>

          {/* Diagrams  */}
          {bottomPlane && inspectionItems[0]?.inspection_item_id && (
            <Flex justifyContent="center" bg="white" alignSelf="stretch">
              <Box
                className={[
                  'column',
                  'diagram',
                  !settings.sheet_diagram_visibility?.plane_diagram ? 'hidden' : '',
                ].join(' ')}
              >
                <PolygonDiagram
                  key={`${inspectionItems[0].inspection_item_id}--plane_diagram`}
                  imageId={`${inspectionItems[0].inspection_item_id}--plane_diagram`}
                  width={DIAGRAM_WIDTH}
                  height={350}
                  upperPolygon={topPlane || undefined}
                  lowerPolygon={bottomPlane}
                  cameraProfile={inspectionArea?.camera_profile}
                  inspectionItem={inspectionItems[0]}
                  settings={settings}
                  showEdgeMeasurement
                />
              </Box>
            </Flex>
          )}

          {/* Polyline diagram  */}
          {polyline?.polyline_length && (
            <Flex justifyContent="center" bg="white" alignSelf="stretch">
              <Box
                className={[
                  'column',
                  'diagram',
                  !settings.sheet_diagram_visibility?.polyline_diagram ? 'hidden' : '',
                ].join(' ')}
              >
                <PolylineDiagram
                  key={`${inspectionItems[0].inspection_item_id}--polyline_diagram`}
                  imageId={`${inspectionItems[0].inspection_item_id}--polyline_diagram`}
                  width={DIAGRAM_WIDTH}
                  height={350}
                  inspectionItem={polyline}
                  cameraProfile={inspectionArea?.camera_profile}
                />
              </Box>
            </Flex>
          )}

          {/* Spacer */}
          <Box flex={1} alignSelf="stretch" />
        </Flex>

        {/* Grid values */}
        {grid?.grid &&
        settings.sheet_diagram_visibility?.grid_diagram &&
        shapes.polygons.find(
          (shape) => grid.shape_ids.polygons.includes(shape.shape_id) && shape.plane_side === PlaneSide.LOWER,
        ) ? (
          <GridRow
            inspectionItem={grid}
            imageId={`${inspectionItems[0].inspection_item_id}--grid_diagram`}
            toleranceType={settings.sheet_rows_tolerance_type?.grid || DEFAULT_TOLERANCE_TYPE}
            settings={settings}
            allAdditionalMetricsToggle={allAdditionalMetricsToggle}
            cameraProfile={inspectionArea.camera_profile}
            lowerPolygon={
              shapes.polygons.find(
                (shape) => grid.shape_ids.polygons.includes(shape.shape_id) && shape.plane_side === PlaneSide.LOWER,
              )!
            }
            upperPolygon={shapes.polygons.find(
              (shape) => grid.shape_ids.polygons.includes(shape.shape_id) && shape.plane_side === PlaneSide.UPPER,
            )}
            inspectionArea={inspectionArea}
            shapes={shapes}
          />
        ) : null}
      </Flex>
    </Flex>
  )
}

export default ModeledItem
