import { Flex, Grid, HStack, Icon, Spinner, Square, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditBoxIcon } from 'assets/icons'

import { InspectionArea } from 'interfaces/inspection'

import { parseInspectionItemPartitionKey } from 'services/InspectionArea'

import { setInspectionAreaIdToEdit } from '../store'

const InspectionAreaRowHeader = ({ inspectionArea }: { inspectionArea: InspectionArea }) => {
  // Store
  const dispatch = useAppDispatch()
  const isSaving = useSelector((state: RootState) => state.inspectionSheet.isSaving)
  const isSavingSettings = useSelector((state: RootState) => state.inspectionSheet.isSavingSettings)

  const inspectionSheet = useSelector((state: RootState) => state.inspectionSheet.inspectionSheets).find((sheet) => {
    const ids = parseInspectionItemPartitionKey(sheet.partition_key || '') // items and sheet have same format so it can be re-used.
    return ids.inspectionAreaId === inspectionArea.inspection_area_id
  })

  const editedInspectionSheets = useSelector((state: RootState) => state.inspectionSheet.editedInspectionSheets)
  const editedInspectionSheet = editedInspectionSheets.find((sheet) => {
    const ids = parseInspectionItemPartitionKey(sheet.partition_key || '')
    return ids.inspectionAreaId === inspectionArea.inspection_area_id
  })

  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  const editedInspectionArea = editedInspectionAreas.find(
    (area) => area.inspection_area_id === inspectionArea.inspection_area_id,
  )

  return (
    <Flex className="inspection-area-header">
      <Flex className="inspection-area-title">
        <Square
          onClick={() => {
            dispatch(setInspectionAreaIdToEdit(inspectionArea.inspection_area_id))
          }}
        >
          <Icon as={EditBoxIcon} boxSize={5} />
        </Square>
        <span>{editedInspectionArea?.inspection_area_name || inspectionArea?.inspection_area_name}</span>
      </Flex>

      <Grid className="inspection-area-properties">
        <Text>
          <span>工種名</span>
          <span>
            {(editedInspectionSheet
              ? editedInspectionSheet.construction_properties.construction_type
              : inspectionSheet?.construction_properties.construction_type) || '-'}
          </span>
        </Text>
        <Text>
          <span>種別</span>
          <span>
            {(editedInspectionSheet
              ? editedInspectionSheet.construction_properties.construction_type_detailed
              : inspectionSheet?.construction_properties.construction_type_detailed) || '-'}
          </span>
        </Text>
        <Text>
          <span>作成者</span>
          <span>
            {(editedInspectionSheet ? editedInspectionSheet.creator_name : inspectionSheet?.creator_name) || '-'}
          </span>
        </Text>
        <Text>
          <span>作成日</span>
          <span>
            {editedInspectionSheet?.create_time_user_specified ||
            (!editedInspectionSheet && inspectionSheet?.create_time_user_specified)
              ? dayjs(
                  editedInspectionSheet?.create_time_user_specified || inspectionSheet?.create_time_user_specified,
                ).format('YYYY/MM/DD')
              : '-'}
          </span>
        </Text>
        <Text>
          <span>立会者</span>
          <span>{editedInspectionSheet?.observer_name || inspectionSheet?.observer_name || '-'}</span>
        </Text>
        <Text>
          <span>立会日</span>
          <span>
            {editedInspectionSheet?.observe_time_user_specified ||
            (!editedInspectionSheet && inspectionSheet?.observe_time_user_specified)
              ? dayjs(
                  editedInspectionSheet?.observe_time_user_specified || inspectionSheet?.observe_time_user_specified,
                ).format('YYYY/MM/DD')
              : '-'}
          </span>
        </Text>
      </Grid>

      <HStack
        color="gray.500"
        alignItems="center"
        justifyContent="end"
        pr={4}
        fontWeight={700}
        className="saving-indicator"
      >
        {(isSaving || isSavingSettings) && (
          <>
            <Spinner />
            <Text>保存中...</Text>
          </>
        )}
      </HStack>
    </Flex>
  )
}

export default InspectionAreaRowHeader
