/**
 * Minimum interval for in between grid points regardless of the grid type.
 * Value is in millimeters.
 */
export const GRID_POINT_MINIMUM_INTERVAL = 30

/**
 * Minimum point for edge-based grid
 */
export const GRID_EDGE_MINIMUM_POINT = 1

/**
 * Default point for edge-based grid.
 */
export const GRID_EDGE_DEFAULT_POINT = 3

/**
 * Default edge distance for edge-based grid.
 * Value is in millimeters.
 */
export const GRID_EDGE_DEFAULT_EDGE_DISTANCE = 100
