import { FC } from 'react'

import { Box, Spacer, Text, VStack } from '@chakra-ui/react'
import SectionTitle from 'components/SectionTitle'

import { ToolbarDistanceIcon, ToolbarPlaneIcon, ToolbarPolylineIcon, ToolbarVolumeIconPolygon } from 'assets/icons'

import {
  ProjectSheetColVisibility,
  ProjectSheetDiagramVisibility,
  ProjectSheetModelVisibility,
  ProjectSheetRowVisibility,
  ProjectSheetSettings,
  ProjectSheetToleranceType,
} from 'interfaces/project'

import CustomizableField from './components/CustomizableField/CustomizableField'
import ToggleField, { ToggleFieldProps } from './components/ToggleField'

const InspectionSheetSettings: FC<{
  settings: ProjectSheetSettings
  onChange: (settings: Partial<ProjectSheetSettings>) => void
}> = ({ settings, onChange }) => {
  /**
   * Predefined data points for depth.
   */
  const depthDataPoints: ToggleFieldProps[] = [
    {
      label: '最小',
      value: settings.sheet_rows_visibility?.min_grid_depth || false,
      onChange: () => {
        onChange({
          sheet_rows_visibility: { min_grid_depth: !settings.sheet_rows_visibility?.min_grid_depth } as Record<
            ProjectSheetRowVisibility,
            boolean
          >,
        })
      },
    },
    {
      label: '最大',
      value: settings.sheet_rows_visibility?.max_grid_depth || false,
      onChange: () => {
        onChange({
          sheet_rows_visibility: { max_grid_depth: !settings.sheet_rows_visibility?.max_grid_depth } as Record<
            ProjectSheetRowVisibility,
            boolean
          >,
        })
      },
    },
  ]

  return (
    <>
      {/* Modeled items */}
      <SectionTitle>検出した要素の表示/非表示</SectionTitle>
      <Text mt={2} fontSize="md">
        表示設定は出力されるファイルにも適用されます。
      </Text>
      <Box mt={3} className="setting-group">
        <CustomizableField
          icon={<ToolbarVolumeIconPolygon className="icon-black" height="1.4em" width="1.4em" />}
          expandable={false}
          title="体積モデル"
          enabled={settings.sheet_models_visibility?.volume || false}
          onToggle={() =>
            onChange({
              sheet_models_visibility: { volume: !settings.sheet_models_visibility?.volume } as Record<
                ProjectSheetModelVisibility,
                boolean
              >,
            })
          }
        />
        <CustomizableField
          icon={<ToolbarPlaneIcon className="icon-black" height="1.3em" width="1.3em" />}
          expandable={false}
          title="面積モデル"
          enabled={settings.sheet_models_visibility?.plane || false}
          onToggle={() =>
            onChange({
              sheet_models_visibility: { plane: !settings.sheet_models_visibility?.plane } as Record<
                ProjectSheetModelVisibility,
                boolean
              >,
            })
          }
        />
        <CustomizableField
          icon={<ToolbarDistanceIcon className="icon-black" height="50%" />}
          expandable={false}
          title="手動計測した距離"
          enabled={settings.sheet_models_visibility?.length_with_distance_tool || false}
          expanded={settings.sheet_models_visibility?.length_with_distance_tool || false}
          toleranceType={settings.sheet_rows_tolerance_type?.length_with_distance_tool}
          onToggle={() =>
            onChange({
              sheet_models_visibility: {
                length_with_distance_tool: !settings.sheet_models_visibility?.length_with_distance_tool,
              } as Record<ProjectSheetModelVisibility, boolean>,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                length_with_distance_tool: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          icon={<ToolbarPolylineIcon className="icon-black" height="1.3em" width="1.3em" />}
          expandable={false}
          title="延長"
          enabled={settings.sheet_models_visibility?.polyline || false}
          expanded={settings.sheet_models_visibility?.polyline || false}
          toleranceType={settings.sheet_rows_tolerance_type?.polyline_length}
          onToggle={() =>
            onChange({
              sheet_models_visibility: {
                polyline: !settings.sheet_models_visibility?.polyline,
              } as Record<ProjectSheetModelVisibility, boolean>,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                polyline_length: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
      </Box>

      <Spacer my={8} />

      {/* Data points */}
      <SectionTitle>列の表示/非表示</SectionTitle>
      <Text mt={2} fontSize="md">
        表示設定は出力されるファイルにも適用されます。
      </Text>

      <VStack borderColor="gray.300" borderWidth={1} borderRadius={6} mt={3} py={3} px={4} className="setting-group">
        <ToggleField
          label="実測値"
          value={settings.sheet_cols_visibility?.estimated_value || false}
          onChange={() =>
            onChange({
              sheet_cols_visibility: { estimated_value: !settings.sheet_cols_visibility?.estimated_value } as Record<
                ProjectSheetColVisibility,
                boolean
              >,
            })
          }
          tooltip="実測値は非表示にできません"
          disabled
        />
        <ToggleField
          label="規格値"
          value={settings.sheet_cols_visibility?.tolerance || false}
          onChange={() =>
            onChange({
              sheet_cols_visibility: { tolerance: !settings.sheet_cols_visibility?.tolerance } as Record<
                ProjectSheetColVisibility,
                boolean
              >,
            })
          }
        />
        <ToggleField
          label="設計値・差"
          value={settings.sheet_cols_visibility?.designed_value || false}
          onChange={() =>
            onChange({
              sheet_cols_visibility: { designed_value: !settings.sheet_cols_visibility?.designed_value } as Record<
                ProjectSheetColVisibility,
                boolean
              >,
            })
          }
        />
        <ToggleField
          label="平面図"
          value={settings.sheet_diagram_visibility?.plane_diagram || false}
          onChange={() =>
            onChange({
              sheet_diagram_visibility: { plane_diagram: !settings.sheet_diagram_visibility?.plane_diagram } as Record<
                ProjectSheetDiagramVisibility,
                boolean
              >,
            })
          }
        />
        <ToggleField
          label="深さグリッド"
          value={settings.sheet_diagram_visibility?.grid_diagram || false}
          onChange={() =>
            onChange({
              sheet_diagram_visibility: { grid_diagram: !settings.sheet_diagram_visibility?.grid_diagram } as Record<
                ProjectSheetDiagramVisibility,
                boolean
              >,
            })
          }
        />
        <ToggleField
          label="延長図"
          value={settings.sheet_diagram_visibility?.polyline_diagram || false}
          onChange={() =>
            onChange({
              sheet_diagram_visibility: {
                polyline_diagram: !settings.sheet_diagram_visibility?.polyline_diagram,
              } as Record<ProjectSheetDiagramVisibility, boolean>,
            })
          }
        />
      </VStack>

      <Spacer my={8} />

      {/* Metrics */}
      <SectionTitle>行の表示/非表示</SectionTitle>
      <Text mt={2} fontSize="md">
        表示設定は出力されるファイルにも適用されます。
      </Text>
      <Box mt={3} className="setting-group">
        <CustomizableField
          expandable={false}
          title="辺の長さ1"
          enabled={settings.sheet_rows_visibility?.polyon_length_1 || false}
          expanded={settings.sheet_rows_visibility?.polyon_length_1 || false}
          toleranceType={settings.sheet_rows_tolerance_type?.polyon_length_1}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: { polyon_length_1: !settings.sheet_rows_visibility?.polyon_length_1 } as Record<
                ProjectSheetRowVisibility,
                boolean
              >,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                polyon_length_1: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="辺の長さ2"
          enabled={settings.sheet_rows_visibility?.polyon_length_2 || false}
          expanded={settings.sheet_rows_visibility?.polyon_length_2 || false}
          toleranceType={settings.sheet_rows_tolerance_type?.polyon_length_2}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: { polyon_length_2: !settings.sheet_rows_visibility?.polyon_length_2 } as Record<
                ProjectSheetRowVisibility,
                boolean
              >,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                polyon_length_2: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="外周長"
          enabled={settings.sheet_rows_visibility?.polygon_perimeter || false}
          expanded={settings.sheet_rows_visibility?.polygon_perimeter || false}
          toleranceType={settings.sheet_rows_tolerance_type?.polygon_perimeter}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: {
                polygon_perimeter: !settings.sheet_rows_visibility?.polygon_perimeter,
              } as Record<ProjectSheetRowVisibility, boolean>,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                polygon_perimeter: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="面積値"
          enabled={settings.sheet_rows_visibility?.polygon_area || false}
          expanded={settings.sheet_rows_visibility?.polygon_area || false}
          toleranceType={settings.sheet_rows_tolerance_type?.polygon_area}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: { polygon_area: !settings.sheet_rows_visibility?.polygon_area } as Record<
                ProjectSheetRowVisibility,
                boolean
              >,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                polygon_area: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="体積値"
          enabled={settings.sheet_rows_visibility?.volume || false}
          expanded={settings.sheet_rows_visibility?.volume || false}
          toleranceType={settings.sheet_rows_tolerance_type?.volume}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: { volume: !settings.sheet_rows_visibility?.volume } as Record<
                ProjectSheetRowVisibility,
                boolean
              >,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                volume: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          additionalFields={depthDataPoints}
          title="深さ"
          enabled={settings.sheet_rows_visibility?.grid || false}
          expanded={settings.sheet_rows_visibility?.grid || false}
          toleranceType={settings.sheet_rows_tolerance_type?.grid}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: { grid: !settings.sheet_rows_visibility?.grid } as Record<
                ProjectSheetRowVisibility,
                boolean
              >,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                grid: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="表かぶり厚"
          enabled={settings.sheet_rows_visibility?.upper_plane_to_cylinders_distance || false}
          expanded={settings.sheet_rows_visibility?.upper_plane_to_cylinders_distance || false}
          toleranceType={settings.sheet_rows_tolerance_type?.upper_plane_to_cylinders_distance}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: {
                upper_plane_to_cylinders_distance: !settings.sheet_rows_visibility?.upper_plane_to_cylinders_distance,
              } as Record<ProjectSheetRowVisibility, boolean>,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                upper_plane_to_cylinders_distance: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
        <CustomizableField
          expandable={false}
          title="裏かぶり厚"
          enabled={settings.sheet_rows_visibility?.lower_plane_to_cylinders_distance || false}
          expanded={settings.sheet_rows_visibility?.lower_plane_to_cylinders_distance || false}
          toleranceType={settings.sheet_rows_tolerance_type?.lower_plane_to_cylinders_distance}
          onToggle={() =>
            onChange({
              sheet_rows_visibility: {
                lower_plane_to_cylinders_distance: !settings.sheet_rows_visibility?.lower_plane_to_cylinders_distance,
              } as Record<ProjectSheetRowVisibility, boolean>,
            })
          }
          onChangeToleranceType={(type) => {
            onChange({
              sheet_rows_tolerance_type: {
                lower_plane_to_cylinders_distance: type,
              } as Record<ProjectSheetRowVisibility, ProjectSheetToleranceType>,
            })
          }}
        />
      </Box>
    </>
  )
}

export default InspectionSheetSettings
