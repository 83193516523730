import { useCallback, useContext, useEffect, useState } from 'react'

import { Box, Button, Flex, Heading, Link, Spacer, Spinner, Text, VStack, useDisclosure } from '@chakra-ui/react'
import SectionTitle from 'components/SectionTitle'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { USER_TYPES } from 'config/constants'

import { Prices } from 'interfaces/subscription'

import { getPrices } from 'services/Subscriptions'

import BaseFeatureAutoRenewalConfirmationModal from './components/BaseFeatureAutoRenewalConfirmationModal'
import BaseFeatureConfirmationModal from './components/BaseFeatureConfirmationModal'
import SubscriptionPlan from './components/SubscriptionPlan'
import UpgradeConfirmationModal from './components/UpgradeConfirmationModal'

const Plan = () => {
  // Store
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const user = useSelector((state: RootState) => state.user.user)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // State
  const [prices, setPrices] = useState<Prices | null>(null)

  // Discolure to handle agreeing to upgrade modal
  const agreeToActivateBaseFeatureDisclosure = useDisclosure()
  const agreeToAutoRenewBaseFeatureDisclosure = useDisclosure()
  const agreeToUpgradeDisclosure = useDisclosure()

  const handleBaseFeatureToggle = useCallback(() => {
    agreeToActivateBaseFeatureDisclosure.onOpen()
  }, [agreeToActivateBaseFeatureDisclosure])
  const handleBaseFeatureAutoRenewalToggle = useCallback(() => {
    agreeToAutoRenewBaseFeatureDisclosure.onOpen()
  }, [agreeToAutoRenewBaseFeatureDisclosure])

  const handleFeatureToggle = useCallback(() => {
    agreeToUpgradeDisclosure.onOpen()
  }, [agreeToUpgradeDisclosure])

  // Fetch pricing information
  useEffect(() => {
    void (async () => {
      // Fetch pricing information
      const token = await getAccessToken()
      if (!token) {
        return
      }
      setPrices(await getPrices(token, showErrorModal))
    })()
  }, [getAccessToken, showErrorModal])

  return (
    <Box className="content-container">
      {!userLoaded || !prices || !userProfile ? (
        <Flex gap={4}>
          <Spinner />
          <Text fontWeight="bold">読み込み中…</Text>
        </Flex>
      ) : (
        <>
          <Heading fontSize="2xl">請求情報</Heading>

          <Spacer m={4} />

          <SectionTitle fontSize="xl">サブスクリプション</SectionTitle>

          <Spacer m={2} />

          {userProfile?.need_confirmation !== false ? (
            <VStack gap={4} alignItems="center">
              <Text>機能を有効化するには、 セールスまでお問い合わせください。</Text>
              <Button
                colorScheme="primary"
                as={Link}
                href={`https://share.hsforms.com/11_hEhYjCR0CBEe1gX9adVQddydr?kind=アカウント&message=${encodeURIComponent(`【${user.email}アカウント有効化について】`)}`}
                target="_blank"
              >
                お問い合わせ
              </Button>
            </VStack>
          ) : (
            <VStack gap={4} alignItems="start">
              <Text>ご利用中のサブスクリプションを以下に表示しています。</Text>

              <SubscriptionPlan
                active={userProfile?.user_type !== USER_TYPES.NON_PAYING_USER}
                disabled={userProfile?.agreed_tos_version !== featureFlags?.selfManagedSubscriptions.tos_version}
                name={prices.base_feature.name}
                price={prices.base_feature.price}
                renewalDate={new Date(userProfile.base_feature_end_at!)}
                interval="year"
                description={prices.base_feature.description}
                autoRenewal={userProfile.auto_base_feature_renewal}
                onToggleActivation={handleBaseFeatureToggle}
                onToggleAutoRenewal={handleBaseFeatureAutoRenewalToggle}
              />
              <SubscriptionPlan
                active={userProfile?.user_type === USER_TYPES.PAYING_USER}
                disabled={
                  userProfile?.user_type !== USER_TYPES.BASE_USER ||
                  userProfile?.agreed_tos_version !== featureFlags?.selfManagedSubscriptions.tos_version
                }
                label={
                  userProfile?.user_type === USER_TYPES.NON_PAYING_USER
                    ? `先に${prices.base_feature.name}を有効化する必要があります。`
                    : undefined
                }
                name={prices.additional_feature.name}
                price={prices.additional_feature.price}
                renewalDate={new Date(userProfile.additional_feature_end_at!)}
                interval="month"
                description={prices.additional_feature.description}
                onToggleActivation={handleFeatureToggle}
              />
            </VStack>
          )}
          <BaseFeatureConfirmationModal
            name={prices.base_feature.name}
            disclosure={agreeToActivateBaseFeatureDisclosure}
            price={prices.base_feature.price}
          />
          <BaseFeatureAutoRenewalConfirmationModal
            name={prices.base_feature.name}
            disclosure={agreeToAutoRenewBaseFeatureDisclosure}
            active={userProfile?.auto_base_feature_renewal === 'Y'}
          />
          <UpgradeConfirmationModal
            name={prices.additional_feature.name}
            disclosure={agreeToUpgradeDisclosure}
            price={prices.additional_feature.price}
          />
        </>
      )}
    </Box>
  )
}

export default Plan
