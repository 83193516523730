import './styles.css'

import { FC, MouseEvent, useCallback, useContext } from 'react'

import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react'
import { startCase } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { Link, useLocation, useMatches, useNavigate } from 'react-router-dom'
import { RootState } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { BUTTON_FONT_SIZE, MODAL_TYPES } from 'config/constants'

import { InspectionArea } from 'interfaces/inspection'

const AreaSwitcher: FC = () => {
  // Context
  const { showModal } = useContext(GlobalModalContext)

  // Router
  const navigate = useNavigate()
  const location = useLocation()
  const matches = useMatches()
  const isEditorPage = matches.some((row) => row.id.includes('editor'))
  const isInspectionSheetPage = matches.some((row) => row.id.includes('inspection-sheet'))

  // Store
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const isLoading = useSelector((state: RootState) => state.page.isLoading)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const toolVolumePolygonIsDirty = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isDirty)
  const toolRebarDetectionIsDirty = useSelector((state: RootState) => state.toolRebarDetection.isDirty)
  const toolPlaneDetectionIsDirty = useSelector((state: RootState) => state.toolPlaneDetection.isDirty)
  const toolPolylineIsDirty = useSelector((state: RootState) => state.toolPolyline.isDirty)

  // Derived
  const childPage = location.pathname.split('/').pop()

  const areas = [...inspectionAreas]
  // Re-sort inspection areas with editedInspectionAreas if we're on inspection sheet
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  if (isInspectionSheetPage) {
    areas.sort((a, b) => {
      const editedA = editedInspectionAreas.find((edited) => edited.inspection_area_id === a.inspection_area_id)
      const editedB = editedInspectionAreas.find((edited) => edited.inspection_area_id === b.inspection_area_id)
      if (editedA && editedB) {
        return editedA.order! - editedB.order!
      }
      if (editedA) {
        return -1
      }
      if (editedB) {
        return 1
      }
      return a.order! - b.order!
    })
  }

  /**
   * Get URL for an area.
   */
  const getUrl = useCallback(
    (area: InspectionArea) => `/projects/${area.project_id}/${childPage}?area=${area.inspection_area_id}`,
    [childPage],
  )

  /**
   * Navigate to an area and track the event.
   */
  const navigateToArea = useCallback(
    (area: InspectionArea) => {
      mixpanel.track('Switch area', {
        'Inspection Area ID': inspectionArea?.inspection_area_id,
        'Project ID': area.project_id,
        'Navigating To Area ID': area.inspection_area_id,
        Page: childPage ? startCase(childPage) : null,
      })
      navigate(getUrl(area))
    },
    [childPage, getUrl, navigate, inspectionArea],
  )

  /**
   * Handle switching area
   */
  const handleSwitchArea = useCallback(
    (event: MouseEvent<HTMLButtonElement>, area: InspectionArea) => {
      event.preventDefault()
      event.stopPropagation()

      // Check for dirty states before switching area.
      // Only applicable for editor page.
      if (
        isEditorPage &&
        (cuboidAnchor ||
          Object.keys(intervals).length ||
          toolVolumePolygonIsDirty ||
          toolRebarDetectionIsDirty ||
          toolPlaneDetectionIsDirty ||
          toolPolylineIsDirty)
      ) {
        showModal({
          modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
          body: '検出を破棄しますか？',
          confirmText: '破棄',
          cancelText: 'キャンセル',
          onConfirm: () => {
            navigateToArea(area)
            return true
          },
        })
        return
      }

      navigateToArea(area)
    },
    [
      isEditorPage,
      cuboidAnchor,
      intervals,
      toolVolumePolygonIsDirty,
      toolRebarDetectionIsDirty,
      toolPlaneDetectionIsDirty,
      toolPolylineIsDirty,
      showModal,
      navigateToArea,
    ],
  )

  return (
    <HStack id="area-switcher" gap={4}>
      <Text className="label" fontSize={BUTTON_FONT_SIZE}>
        エリア
      </Text>
      <Menu placement="bottom-end" isLazy>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} data-testid="area-switcher-button">
          {isLoading ? <Spinner size="sm" position="relative" top="2px" /> : inspectionArea?.inspection_area_name}
        </MenuButton>
        <MenuList className="menu" maxHeight={{ base: '90svh', xl: '50svh' }}>
          {areas.map((area) => (
            <MenuItem
              key={area.inspection_area_id}
              as={Link}
              to={getUrl(area)}
              onClick={(event) => handleSwitchArea(event, area)}
            >
              {area.inspection_area_name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default AreaSwitcher
