import { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { ThreeEvent, useFrame } from '@react-three/fiber'
import { clamp } from 'lodash'
import { FaCheck } from 'react-icons/fa6'
import { FiMove } from 'react-icons/fi'
import { SphereGeometry, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { CircleAnchorIcon, CircleAnchorProps } from 'interfaces/canvas'

/**
 * Available icons for the circle anchor.
 */
const Icons: {
  [key in CircleAnchorIcon]: JSX.Element | ReactElement
} = {
  [CircleAnchorIcon.CHECK]: <FaCheck pointerEvents="none" />,
  [CircleAnchorIcon.MOVE]: <FiMove pointerEvents="none" />,
}

/**
 * Max canvas height to keep the anchor size constant when resizing the window.
 */
const MAX_CANVAS_HEIGHT = 900
const MAX_CANVAS_HEIGHT_ICON = 1300

/**
 * Adds a circle to the canvas, generally used for interactable anchors.
 */
const CircleAnchor: FC<CircleAnchorProps> = ({
  groupRef,
  point,
  scale = 1,
  innerScale = 0.75,
  hoverScale = 1.1,
  icon,
  iconColor,
  iconScale = 0.8,
  color = 'red',
  outlineColor = 'white',
  outlineOpacity = 1,
  opacity = 1,
  tooltipText,
  renderOrder = 1,
  onDown,
  onUp,
  onEnter,
  onLeave,
  onMove,
}) => {
  // Context
  const { selectedTool, cameraRef } = useContext(EditorContext)

  // States
  const [isHovered, setIsHovered] = useState(false)
  const [scaleFactor, setScaleFactor] = useState(1)
  const [iconScaleFactor, setIconScaleFactor] = useState(1)
  const [windowHeightScaleFactor, setWindowHeightScaleFactor] = useState(1)
  const [iconWindowHeightScaleFactor, setIconWindowHeightScaleFactor] = useState(1)
  const [outerGeometry] = useState(new SphereGeometry(0.002, 32, 16))
  const [innerGeometry] = useState(new SphereGeometry(0.002 * innerScale, 32, 16))

  /**
   *  To keep the anchor size constant when zooming in/out.
   *  Use the camera's directional distance to prevent the anchor from appearing larger as it goes to the edge of the screen.
   *  NOTE: If this is heavy, consider using a distance from the camera to the ABControls' gizmo point for all anchors.
   *        The results will vary, but they look good.
   */
  useFrame(() => {
    if (cameraRef.current) {
      // distance from the camera to the anchor point
      const cameraToAnchor = new Vector3(...point).sub(cameraRef.current.position.clone())
      // angle between the camera's forward vector and the vector from the camera to the anchor point
      const angle = cameraToAnchor.angleTo(new Vector3(0, 0, -1).applyQuaternion(cameraRef.current.quaternion.clone()))
      // center position of viewing plane calculated from the camera's position and the distance to the anchor point
      const centerOfTheAnchorViewingPlane = cameraRef.current.position
        .clone()
        .sub(
          new Vector3(0, 0, cameraToAnchor.length() * Math.cos(angle)).applyQuaternion(
            cameraRef.current.quaternion.clone(),
          ),
        )

      const distance = cameraRef.current.position.distanceTo(centerOfTheAnchorViewingPlane)
      const factor = Math.min(Math.max(distance * 6, 1), 10)
      setScaleFactor(factor)

      // Icon needs to be scaled once the factor reaches 10
      if (factor >= 10) {
        setIconScaleFactor(Math.max(0, (10 - distance) / 10))
      } else {
        setIconScaleFactor(1)
      }
    }
  })

  /**
   * To keep the anchor size constant when resizing the window.
   */
  useEffect(() => {
    const canvas = document.querySelector('.editor-main-canvas canvas')
    if (!canvas) return () => null

    const onResize = () => {
      setWindowHeightScaleFactor(clamp(canvas.clientHeight / MAX_CANVAS_HEIGHT, 0, 1))
      setIconWindowHeightScaleFactor(clamp(canvas.clientHeight / MAX_CANVAS_HEIGHT_ICON, 0, 1))
    }

    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  /**
   * Event Handlers (pointer enter/hover)
   */
  const onPointerEnter = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      if (onEnter) onEnter(event)

      setIsHovered(true)
    },
    [onEnter],
  )

  /**
   * Event Handlers (pointer leave)
   */
  const onPointerLeave = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      if (onLeave) onLeave(event)

      setIsHovered(false)
    },
    [onLeave],
  )

  return (
    <group renderOrder={renderOrder} position={new Vector3(...point)} ref={groupRef} visible={opacity > 0}>
      <mesh
        scale={((isHovered ? hoverScale : scale) * scaleFactor) / windowHeightScaleFactor}
        onPointerDown={(e: ThreeEvent<PointerEvent>) => {
          if (selectedTool === EDITOR_TOOLS.FOCUS || e.nativeEvent.button !== 0) return
          if (onDown) onDown(e)
        }}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onPointerMove={onMove}
        onPointerUp={onUp}
        geometry={outerGeometry}
      >
        <meshStandardMaterial
          color={outlineColor}
          opacity={outlineOpacity}
          depthTest={false}
          depthWrite={false}
          transparent
        />
      </mesh>

      <mesh
        scale={((isHovered && onDown ? hoverScale : scale) * scaleFactor) / windowHeightScaleFactor}
        geometry={innerGeometry}
      >
        <meshStandardMaterial color={color} depthTest={false} depthWrite={false} opacity={opacity} transparent />
      </mesh>

      {/* Tooltip */}
      {icon !== undefined && (
        <Html style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }} zIndexRange={[1, 9]}>
          <Box
            color={iconColor}
            transform={`scale(${iconScale * iconScaleFactor * iconWindowHeightScaleFactor})`}
            pointerEvents="none"
          >
            {Icons[icon]}
          </Box>
        </Html>
      )}

      {/* Tooltip */}
      {!!tooltipText && isHovered && (
        <Html style={{ transform: 'translateX(-50%) translateY(-200%)' }} zIndexRange={[1, 9]}>
          <Box
            backgroundColor="yellow"
            py={0}
            px={4}
            opacity={opacity}
            fontSize="80%"
            fontWeight="bold"
            color="black"
            whiteSpace="nowrap"
          >
            {tooltipText}
          </Box>
        </Html>
      )}
    </group>
  )
}
export default CircleAnchor
