/* eslint-disable */
import { FC, useCallback, useMemo } from 'react'

import { merge } from 'lodash'
import { Group, Line, RegularPolygon, Text } from 'react-konva'

const AnchorLineDefaults = {
  type: 'line',
  size: 15,
}

const AnchorArrowDefaults = {
  type: 'arrow',
  size: 5,
}

interface LengthIndicatorProps {
  /**
   * Length of the line
   */
  length: number

  /**
   * Anchor to be shown at the beginning and end of the line.
   */
  anchor?: {
    /**
     * Type of the anchor.
     */
    type: 'arrow' | 'line'

    /**
     * Size of the anchor.
     */
    size?: number
  }

  /**
   * @deprecated Use `anchor.size` instead.
   */
  anchorWidth?: number

  /**
   * X coordinate of the line.
   */
  x: number

  /**
   * Y coordinate of the line.
   */
  y: number

  /**
   * Rotation of the line.
   * If not provided, the line will be vertical.
   */
  rotation?: number

  /**
   * Label of the line.
   */
  label?: string

  /**
   * If true, the position of the label will be inverted.
   * By default, the label is positioned to the left of the line (or bottom if flipped 270).
   * Inverting will place at the opposite side of the line.
   */
  invertLabelPosition?: boolean

  /**
   * Style of the line's dash.
   * Use [0, 0] for solid line.
   */
  lineDash?: number[]
}

/**
 * The origin is at the beginning of the line not offsetted by the anchor width.
 *           |                   |
 *   here -> |-------------------|
 *           |                   |
 */
const LengthIndicator: FC<LengthIndicatorProps> = ({
  length,
  anchorWidth,
  anchor,
  x,
  y,
  rotation = 0,
  label,
  invertLabelPosition,
  lineDash = [10, 5],
}) => {
  // Merge anchor with defaults and deprecate anchorWidth
  let anchorConfig = anchor
  if (anchor) {
    if (anchor.type === 'line') {
      anchorConfig = merge({}, AnchorLineDefaults, anchor)
    } else if (anchor.type === 'arrow') {
      anchorConfig = merge({}, AnchorArrowDefaults, anchor)
    }
  } else {
    anchorConfig = merge({}, AnchorLineDefaults, { size: anchorWidth }, anchor)
  }

  const labelY = useMemo(() => {
    if (length < 0) {
      return length
    }

    return rotation ? 0 : length
  }, [rotation, length])

  const labelX = useMemo(() => {
    let x = invertLabelPosition ? 18 : -5

    if (!rotation) {
      return x * -1
    }

    return x
  }, [rotation, invertLabelPosition])

  return (
    <Group x={x} y={y} rotation={rotation}>
      {/* Arrow anchor */}
      {anchorConfig?.type === 'arrow' ? (
        <>
          <RegularPolygon x={0} y={5} sides={3} radius={anchorConfig.size!} fill="black" />
          <RegularPolygon
            x={0}
            y={length - anchorConfig.size!}
            sides={3}
            rotation={180}
            radius={anchorConfig.size!}
            fill="black"
          />
        </>
      ) : null}

      {/* Line anchor */}
      {anchorConfig?.type === 'line' ? (
        <>
          {/* anchor 1 */}
          <Line
            stroke="#000"
            strokeWidth={1}
            points={[-(anchorConfig.size! / 2), 0, anchorConfig.size! / 2, 0]}
            x={0}
            y={0}
          />

          {/* anchor 2 */}
          <Line
            stroke="#000"
            strokeWidth={1}
            points={[-(anchorConfig.size! / 2), length, anchorConfig.size! / 2, length]}
            x={0}
            y={0}
          />
        </>
      ) : null}

      {/* body */}
      <Line stroke="#000" strokeWidth={1} points={[0, 0, 0, length]} dash={lineDash} />

      {/* label */}
      <Group>
        <Text
          text={label}
          y={labelY}
          x={labelX}
          rotation={rotation ? -rotation : -90}
          width={Math.abs(length)}
          align="center"
          verticalAlign="middle"
          fontSize={16}
          fontStyle="bold"
        />
      </Group>
    </Group>
  )
}

export default LengthIndicator
