import './Metric.css'

import React, { FC, useState } from 'react'

import { Box, Flex, Input, Spacer, Text } from '@chakra-ui/react'
import { Pill } from 'components/Pill'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { InspectionItem, InspectionItemNumberValues } from 'interfaces/inspection'
import { ProjectSheetToleranceType as ToleranceType } from 'interfaces/project'

import { meterRounded, meterToMilimeterWhole, millimeterToMeter, zeroPad } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import { UNIT_PRECISION_DEFAULT, UNIT_PRECISION_MM } from '../../constants'
import { patchEditedInspectionItems } from '../../store'
import { canBeEvaluated, getDifferenceValueAndIsPassingThresholds } from '../../utils'
import PopoverEditing from '../PopoverEditing'

const debouncedPatchInspectionItem = debounce(
  (dispatch: ReturnType<typeof useAppDispatch>, inspectionItem: Partial<InspectionItem>) => {
    dispatch(patchEditedInspectionItems([inspectionItem]))
  },
  200,
)

const Metric: FC<{
  /**
   * Title of the value row
   */
  title: string

  /**
   * If title is empty, use this as the default title.
   */
  defaulTitle?: string

  /**
   * Unit of the value to be displayed.
   */
  unit: string

  /**
   * Inspection item used for the row's values
   */
  inspectionItem: InspectionItem

  /**
   * Value to be displayed
   */
  inspectionItemValue: InspectionItemNumberValues

  /**
   * Whether the row is hidden or not.
   */
  isHidden: boolean

  /**
   * Extra data points to be displayed.
   */
  additionalMetrics?: {
    /**
     * Type key of the metric. This will be the same accross same type of metrics
     * but unique for different types.
     */
    typeKey: string
    pillColor: string
    title: string
    value: number
    isHidden: boolean
  }[]

  /**
   * All additional metrics' toggle state, including ones not belonging to this metric.
   */
  allAdditionalMetricsToggle?: boolean[]

  /**
   * Whether the name of the inspection item is changeable or not.
   */
  nameChangeable?: boolean

  /**
   * Type of tolerance for the inspection item.
   */
  toleranceType: ToleranceType
}> = ({
  title,
  defaulTitle,
  unit,
  inspectionItem,
  inspectionItemValue,
  isHidden,
  additionalMetrics = [],
  allAdditionalMetricsToggle = [],
  nameChangeable = false,
  toleranceType,
}) => {
  // Store
  const dispatch = useAppDispatch()
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const userType = useSelector((state: RootState) => state.user.userType)

  // States
  const [designedValue, setDesignedValue] = useState<number | null>(
    inspectionItem.pre_defined_thresholds?.designed_value || null,
  )
  const [tolerance, setTolerance] = useState<number | null>(inspectionItem.pre_defined_thresholds?.tolerance || null)
  const [upperTolerance, setUpperTolerance] = useState<number | null>(
    inspectionItem.pre_defined_thresholds?.upper_tolerance || null,
  )
  const [lowerTolerance, setLowerTolerance] = useState<number | null>(
    inspectionItem.pre_defined_thresholds?.lower_tolerance || null,
  )

  // Derived vars
  const valueConverter = unit === 'mm' ? meterToMilimeterWhole : meterRounded
  const inverseValueConverter = unit === 'mm' ? millimeterToMeter : null
  const { differenceValue, isPassingThresholds } = getDifferenceValueAndIsPassingThresholds(
    inspectionItemValue,
    {
      designed_value: designedValue,
      tolerance,
      upper_tolerance: upperTolerance,
      lower_tolerance: lowerTolerance,
    },
    toleranceType,
    unit,
    unit === 'mm' ? UNIT_PRECISION_MM : UNIT_PRECISION_DEFAULT,
  )

  const { isDesignedValueDefined, isToleranceDefined, isUpperToleranceDefined, isLowerToleranceDefined, isEvaluated } =
    canBeEvaluated(settings, toleranceType, {
      designed_value: designedValue,
      tolerance,
      upper_tolerance: upperTolerance,
      lower_tolerance: lowerTolerance,
    })

  // Permissions
  const permissionSet = decideActionPermission(isOwner, isInvited)
  const isAllowedToModifySheet = permissionSet.INSPECTION_SHEET.MODIFY.includes(userType)

  return (
    <Flex
      className={['sheet-metric', isEvaluated && !isPassingThresholds ? 'invalid' : '', isHidden ? 'hidden' : '']
        .filter(Boolean)
        .join(' ')}
      pr={4}
    >
      <Flex width="140px" pl={4} gap={2}>
        {nameChangeable ? (
          <PopoverEditing
            titlePostfix={`(${unit})`}
            value={title}
            defaultValue={defaulTitle}
            onChange={(value) => {
              debouncedPatchInspectionItem(dispatch, {
                inspection_item_id: inspectionItem.inspection_item_id,
                inspection_area_id: inspectionItem.inspection_area_id,
                inspection_sheet_id: inspectionItem.inspection_sheet_id,
                part_name: value,
              })
            }}
          />
        ) : (
          /* seems excessive but it generates better HTML */
          <Text>{`${title} (${unit})`}</Text>
        )}
      </Flex>

      {/* Estimated value */}
      <Box
        textAlign="center"
        className={['column', !settings.sheet_cols_visibility?.estimated_value ? 'hidden' : '']
          .filter(Boolean)
          .join(' ')}
      >
        {inspectionItemValue.estimated_value
          ? valueConverter(inspectionItemValue.estimated_value)
          : inspectionItemValue.estimated_value || ''}
      </Box>

      {/* Designed Value */}
      <Box
        className={['input-field', 'column', !settings.sheet_cols_visibility?.designed_value ? 'hidden' : '']
          .filter(Boolean)
          .join(' ')}
      >
        <Input
          type="number"
          readOnly={!isAllowedToModifySheet}
          defaultValue={isDesignedValueDefined ? valueConverter(designedValue!) : designedValue || ''}
          onChange={(event) => {
            event.preventDefault()
            event.stopPropagation()
            let val = event.target.value === '' ? null : parseFloat(event.target.value)
            if (inverseValueConverter && val !== null) {
              val = inverseValueConverter(val)
            }

            setDesignedValue(val)
            debouncedPatchInspectionItem(dispatch, {
              inspection_item_id: inspectionItem.inspection_item_id,
              inspection_area_id: inspectionItem.inspection_area_id,
              inspection_sheet_id: inspectionItem.inspection_sheet_id,
              pre_defined_thresholds: {
                designed_value: val,
              },
            })
          }}
        />
      </Box>

      {/* Threshold */}
      <Flex
        alignItems="center"
        className={['input-field', 'column', !settings.sheet_cols_visibility?.tolerance ? 'hidden' : '']
          .filter(Boolean)
          .join(' ')}
      >
        {toleranceType === ToleranceType.GteDesignedValue && isPassingThresholds !== undefined && (
          <Box>{isPassingThresholds ? '以上' : '以下'}</Box>
        )}

        {toleranceType === ToleranceType.LteDesignedValue && isPassingThresholds !== undefined && (
          <Box>{isPassingThresholds ? '以下' : '以上'}</Box>
        )}

        {toleranceType === ToleranceType.WithinTolerance && (
          <>
            <Text>&plusmn; </Text>
            <Input
              type="number"
              readOnly={!isAllowedToModifySheet}
              defaultValue={isToleranceDefined ? valueConverter(tolerance!) : tolerance || ''}
              onChange={(event) => {
                event.preventDefault()
                event.stopPropagation()
                let val = event.target.value === '' ? null : parseFloat(event.target.value)
                if (inverseValueConverter && val !== null) {
                  val = inverseValueConverter(val)
                }

                setTolerance(val)
                debouncedPatchInspectionItem(dispatch, {
                  inspection_item_id: inspectionItem.inspection_item_id,
                  inspection_area_id: inspectionItem.inspection_area_id,
                  inspection_sheet_id: inspectionItem.inspection_sheet_id,
                  pre_defined_thresholds: {
                    tolerance: val,
                  },
                })
              }}
            />
          </>
        )}

        {toleranceType === ToleranceType.AsymmetricTolerance && (
          <Flex flexDirection="column" gap={1} className="double-deck">
            <Flex alignItems="center">
              <Text>+ </Text>
              <Input
                type="number"
                readOnly={!isAllowedToModifySheet}
                defaultValue={isUpperToleranceDefined ? valueConverter(upperTolerance!) : upperTolerance || ''}
                min={0}
                onChange={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  let val = event.target.value === '' ? null : parseFloat(event.target.value)
                  if (inverseValueConverter && val !== null) {
                    val = inverseValueConverter(val)
                  }

                  setUpperTolerance(val)
                  debouncedPatchInspectionItem(dispatch, {
                    inspection_item_id: inspectionItem.inspection_item_id,
                    inspection_area_id: inspectionItem.inspection_area_id,
                    inspection_sheet_id: inspectionItem.inspection_sheet_id,
                    pre_defined_thresholds: {
                      upper_tolerance: val,
                    },
                  })
                }}
              />
            </Flex>
            <Flex alignItems="center">
              <Text>&minus; </Text>
              <Input
                type="number"
                readOnly={!isAllowedToModifySheet}
                defaultValue={isLowerToleranceDefined ? valueConverter(lowerTolerance!) : lowerTolerance || ''}
                min={0}
                onChange={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  let val = event.target.value === '' ? null : parseFloat(event.target.value)
                  if (inverseValueConverter && val !== null) {
                    val = inverseValueConverter(val)
                  }

                  setLowerTolerance(val)
                  debouncedPatchInspectionItem(dispatch, {
                    inspection_item_id: inspectionItem.inspection_item_id,
                    inspection_area_id: inspectionItem.inspection_area_id,
                    inspection_sheet_id: inspectionItem.inspection_sheet_id,
                    pre_defined_thresholds: {
                      lower_tolerance: val,
                    },
                  })
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      {/* Difference */}
      <Flex
        flexDirection="column"
        textAlign="center"
        className={[
          'column',
          !settings.sheet_cols_visibility?.designed_value ? 'hidden' : '',
          isEvaluated && !isPassingThresholds ? 'failed' : '',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {isEvaluated && !isPassingThresholds && <Pill colorScheme="red">不合格</Pill>}
        {isEvaluated && isPassingThresholds && <Pill>合格</Pill>}
        {isDesignedValueDefined && (
          <Text>
            {differenceValue && differenceValue > 0 ? '+' : ''}
            {(differenceValue ? valueConverter(differenceValue) : differenceValue)?.toFixed(unit === 'mm' ? 0 : 4)}
          </Text>
        )}
      </Flex>

      {/* Extra data points */}
      {additionalMetrics.map((additionalMetric) => (
        <Flex
          key={additionalMetric.title}
          flexDirection="column"
          textAlign="center"
          className={['column', additionalMetric.typeKey, additionalMetric.isHidden ? 'hidden' : '']
            .filter(Boolean)
            .join(' ')}
        >
          <Pill colorScheme={additionalMetric.pillColor}>{additionalMetric.title}</Pill>
          <Text>{valueConverter(additionalMetric.value)}</Text>
        </Flex>
      ))}

      {allAdditionalMetricsToggle
        .slice(0, allAdditionalMetricsToggle.length - additionalMetrics.length)
        .map((hidden, index) => (
          <Spacer
            key={`metrics-additional-column-${allAdditionalMetricsToggle.length}-${zeroPad(index, 2)}`}
            className={['column', hidden ? 'hidden' : ''].filter(Boolean).join(' ')}
          />
        ))}
    </Flex>
  )
}

export default Metric
