/* eslint-disable prefer-const */
import { FC, useEffect, useMemo, useRef } from 'react'

import Konva from 'konva'
import {
  calculateColumnDiff,
  findAllCorners,
  findGridDirection,
  generateGridLinesFromIntervalGridPoints,
  getSingleColOffset,
  getSingleRowOffset,
  mostCommonValue,
} from 'pages/projects/editor/tools/Grid/utils'
import { Circle, Group, Layer, Line, Rect, Stage, Text } from 'react-konva'
import { useAppDispatch } from 'store/app'
import { setDiagram } from 'store/diagram'

import { GridType } from 'interfaces/inspectionItemGrid'
import { ProjectSheetSettings } from 'interfaces/project'

import useDiagram from '../../hooks/useDiagram'
import { GridPointLabel, UseDiagramProps } from '../../interfaces'
import LengthIndicator from './components/LengthIndicator'

/**
 * Get color for a specific point.
 * Unless forceFailure is set to true, the color will be determined by the point's min/max status.
 * Min/max coloring is subjected to min/max visibility settings.
 */
function getPointColor(settings: ProjectSheetSettings, point: GridPointLabel, forceFailureCheck = false): string {
  if (forceFailureCheck && point.thresholdValidation?.isPassingThresholds === false) return '#F56565'
  if (point.isMax && settings.sheet_rows_visibility?.max_grid_depth) return '#38A169'
  if (point.isMin && settings.sheet_rows_visibility?.min_grid_depth) return '#4299e1'

  return 'black'
}

const PolygonDiagram: FC<
  {
    /**
     * ID to be used for the diagram.
     * This is used to store the diagram in the store.
     */
    imageId: string

    /**
     * Whether to show edge measurements.
     * Will not be shown by default
     */
    showEdgeMeasurement?: boolean
  } & UseDiagramProps
> = ({
  imageId,
  width,
  height,
  upperPolygon,
  lowerPolygon,
  inspectionItem,
  cameraProfile,
  showEdgeMeasurement = false,
  settings,
}) => {
  // Data for use later
  const { grid } = inspectionItem

  // Store
  const dispatch = useAppDispatch()

  // Refs
  const stageRef = useRef<Konva.Stage>(null)

  // Calculate diagram properties
  const { scale, scaledPlaneWidth, scaledPlaneHeight, diagram, projectedPolygon, intervalGridPoints } = useDiagram({
    width,
    height,
    upperPolygon,
    lowerPolygon,
    inspectionItem,
    cameraProfile,
    settings,
  })

  // Scale the plane to fit the canvas.
  const canvasWidth = scaledPlaneWidth + 140
  const canvasHeight = scaledPlaneHeight + 120

  /**
   * Calculate label positions for grid intervals.
   */
  const gridIntervalLines = useMemo(() => {
    if (!diagram?.grid.length || !diagram.boundary) return { rows: [], cols: [] }

    // ## If the intervals can be generated, use that.
    if (intervalGridPoints.length) {
      return generateGridLinesFromIntervalGridPoints(intervalGridPoints, diagram.orientation, scale)
    }

    // Don't even try to derive intervals if the grid is edge-based.
    // They're supposed to have saved config. If not, nothing can be done.
    if (grid?.grid_type === GridType.EdgeBased) return { rows: [], cols: [] }

    // ## If the intervals could not be generated, derive from the grid points.
    const cols: number[] = []
    const rows: number[] = []

    const corners = findAllCorners(diagram.grid)
    if (!corners) return { rows: [], cols: [] }

    const {
      topLeft: topLeftCorner,
      topRight: topRightCorner,
      bottomLeft: bottomLeftCorner,
      bottomRight: bottomRightCorner,
    } = corners

    // If we can't determine each of the corners, the grid is too confusing to derive intervals.
    const isSingleCol =
      topRightCorner.index === topLeftCorner.index && bottomRightCorner.index === bottomLeftCorner.index
    const isSingleRow =
      topRightCorner.index === bottomRightCorner.index && topLeftCorner.index === bottomLeftCorner.index
    if (Object.values(corners).filter((corner) => corner.index === 0).length > 1 && !isSingleCol && !isSingleRow)
      return { rows: [], cols: [] }

    const [dir1, dir2] = findGridDirection(corners)

    const round = (num: number) => Math.round(num)

    diagram.grid.forEach((point) => {
      rows.push(round(point.y))
      cols.push(round(point.x))
    })

    // Calculate difference between columns and rows.
    const diffRow = rows
      .reduce((acc, row, i) => {
        if (i === 0) return acc
        acc.push(round(row - rows[i - 1]))
        return acc
      }, [] as number[])
      .filter((v) => v > 0)
    const intervalRow = mostCommonValue(diffRow) || Infinity

    // Put the grid points in row-based array (first level is row, second level is col).
    let gridColumnized: GridPointLabel[][] = []
    if (dir1.includes('x')) {
      let prev = cols[0]
      let rowCount = 0
      cols.forEach((col, i) => {
        const yDiff = diagram.grid[i - 1] ? round(Math.abs(diagram.grid[i].y - diagram.grid[i - 1].y)) : 0
        if (
          (i !== 0 && dir1 === '+x' && col < prev) ||
          (i !== 0 && dir1 === '-x' && col > prev) ||
          yDiff >= intervalRow / 2
        ) {
          rowCount += 1
        }

        if (!gridColumnized[rowCount]) gridColumnized[rowCount] = []

        gridColumnized[rowCount].push(diagram.grid[i])
        prev = cols[i]
      })
    } else if (dir1.includes('y')) {
      let prev = rows[0]
      let rowIndex = 0
      rows.forEach((row, i) => {
        if ((i !== 0 && dir1 === '-y' && row < prev) || (i !== 0 && dir1 === '+y' && row > prev)) {
          rowIndex += 1
        } else {
          rowIndex = 0
        }

        if (!gridColumnized[rowIndex]) gridColumnized[rowIndex] = []

        gridColumnized[rowIndex].push(diagram.grid[i])
        prev = rows[i]
      })
    }

    // Re-organize the grid based on visual direction.
    if (dir1 === '-x' || dir2 === '-x') {
      gridColumnized.forEach((row) => row.reverse())
    }

    if (dir1 === '-y' || dir2 === '-y') {
      gridColumnized.reverse()
    }

    // Calculate number of rows and columns.
    const rowCount = gridColumnized.length
    const colCount = gridColumnized.reduce((acc, row) => Math.max(acc, row.length), 0)

    // Calculate row differences for each data point.
    // It will go through the columnized data, for every column, it will calculate the difference between the current and previous row.
    // It will immeddiate skip the first row as there is no previous row.
    // If the previous row's data is not available, it will skip that column. (eg: row 1 with 2 col, row 2 with 3 col, 2-3 can't be calculated)
    let rowDiff = gridColumnized.reduce((acc, row, i) => {
      if (i === 0) return acc

      row.forEach((point, colIdx) => {
        if (!gridColumnized[i - 1][colIdx]) return
        if (!acc[i - 1]) acc[i - 1] = []
        acc[i - 1].push(round(point.y - gridColumnized[i - 1][colIdx].y))
      })

      return acc
    }, [] as number[][])

    // Much simpler, go through each row and calculate the difference between each data point in the row.
    const colDiff: number[][] = calculateColumnDiff(gridColumnized)

    if ((!isSingleRow && !rowDiff.length) || (!isSingleCol && !colDiff.length)) return { rows: [], cols: [] }

    // Find any repeating distance, that will be our col interval.
    // We find index as we need to know which interval for use later.
    let colInterval = mostCommonValue(colDiff.flat())
    let rowInterval = mostCommonValue(rowDiff.flat())

    if ((!isSingleCol && colInterval === null) || (!isSingleRow && rowInterval === null)) return { rows: [], cols: [] }

    // Calculate center of boundary
    const boundaryCenter = diagram.boundary.reduce(
      (acc, point) => {
        acc.x += point.x
        acc.y += point.y
        return acc
      },
      { x: 0, y: 0 },
    )
    boundaryCenter.x /= 4
    boundaryCenter.y /= 4

    // Calculate center of grid lines
    const gridCenter = {
      x: isSingleCol ? 0 : (colInterval! * Math.max(0, colCount - 1)) / 2,
      y: isSingleRow ? 0 : (rowInterval! * Math.max(0, rowCount - 1)) / 2,
    }

    // Grid does not start at 0,0. They start at some offset. However the grid (and thus grid lines)
    // shares the same center as boundary. So we calculate the offset off of that.
    const colOffset = isSingleCol ? getSingleColOffset(diagram.grid) : boundaryCenter.x - gridCenter.x
    const rowOffset = isSingleRow ? getSingleRowOffset(diagram.grid) : boundaryCenter.y - gridCenter.y

    const res = {
      rows: new Array(rowCount).fill(0).map((_, i) => rowOffset + (rowInterval || rowOffset) * i),
      cols: new Array(colCount).fill(0).map((_, i) => colOffset + (colInterval || colOffset) * i),
    }

    return res
  }, [diagram, grid, intervalGridPoints, scale])

  /**
   * Calculate label positions for grid intervals.
   */
  const gridIntervalLabels = useMemo(() => {
    const rows: { x: number; y: number; length: number; label: string }[] = []
    const cols: { x: number; y: number; length: number; label: string }[] = []
    const isVert = diagram?.orientation === 'vertical'
    gridIntervalLines.rows
      .sort((a, b) => a - b)
      .slice(0, -1)
      .forEach((row, i) => {
        const diff = (gridIntervalLines.rows[i + 1] - row) * (1 / scale)
        const interval = isVert ? grid?.intervals?.long_axis || diff : grid?.intervals?.short_axis || diff

        rows.push({
          x: isVert ? scaledPlaneHeight : scaledPlaneWidth,
          y: row,
          length: interval * scale,
          label: `${Math.round(interval)}`,
        })
      })

    gridIntervalLines.cols
      .sort((a, b) => a - b)
      .slice(0, -1)
      .forEach((col, i) => {
        const diff = (gridIntervalLines.cols[i + 1] - col) * (1 / scale)
        const interval = isVert ? grid?.intervals?.short_axis || diff : grid?.intervals?.long_axis || diff

        cols.push({
          x: col,
          y: isVert ? scaledPlaneWidth : scaledPlaneHeight,
          length: interval * scale,
          label: `${Math.round(interval)}`,
        })
      })

    return {
      rows,
      cols,
    }
  }, [gridIntervalLines, scaledPlaneHeight, scaledPlaneWidth, grid, diagram?.orientation, scale])

  /**
   * Export the diagram into an image to be used in file exports.
   */
  useEffect(() => {
    if (stageRef.current === null) return

    dispatch(
      setDiagram({
        imageId,
        diagram: {
          image: stageRef.current?.toDataURL() || '',
          width: diagram?.orientation === 'horizontal' ? canvasWidth : canvasHeight,
          height: diagram?.orientation === 'horizontal' ? canvasHeight : canvasWidth,
        },
      }),
    )
  }, [imageId, canvasWidth, canvasHeight, diagram?.orientation, dispatch])

  /**
   * Calculate value to be used for edge distances labels.
   */
  const edgeDistances = useMemo(() => {
    if (!inspectionItem.grid?.distances_from_edge) return null

    return {
      horizontal: inspectionItem.grid.distances_from_edge.long_axis * scale,
      vertical: inspectionItem.grid.distances_from_edge.short_axis * scale,
    }
  }, [inspectionItem, scale])

  return (
    <Stage
      width={diagram?.orientation === 'horizontal' ? canvasWidth : canvasHeight}
      height={diagram?.orientation === 'horizontal' ? canvasHeight : canvasWidth}
      ref={stageRef}
    >
      {/* Background */}
      <Layer>
        <Rect x={0} y={0} width={canvasWidth} height={!projectedPolygon ? canvasHeight : canvasWidth} fill="#fff" />
      </Layer>

      {/* Objects */}
      <Layer x={showEdgeMeasurement ? 80 : 45} y={showEdgeMeasurement ? 80 : 55}>
        {/* Boundary aligned to polygon (debug only) */}
        {/* {minimumRectangleBoundary?.boundaryAxisAlignment.vertices && (
          <Group>
            {!cameraProfile && (
              <Group>
                {minimumRectangleBoundary.mirrored.intersectionUp2d?.intersectionPoint && (
                  <Arrow
                    stroke="purple"
                    points={[
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.centroid.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.centroid.y) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.intersectionPoint.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.intersectionPoint.y) * scale,
                    ]}
                  />
                )}

                {minimumRectangleBoundary.mirrored.intersectionRight2d?.intersectionPoint && (
                  <Arrow
                    stroke="skyblue"
                    points={[
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.centroid.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.centroid.y) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.intersectionPoint.x) *
                        scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.intersectionPoint.y) *
                        scale,
                    ]}
                  />
                )}
              </Group>
            )}
          </Group>
        )} */}

        {/* Axes line */}
        {/* <Group>
          <Line points={[0, -370, 0, 370]} stroke="purple" strokeWidth={1} dash={[5, 3]} />
          <Line points={[-370, 0, 570, 0]} stroke="purple" strokeWidth={1} dash={[5, 3]} />
        </Group> */}

        {/* Width/Height indicators */}
        {/* <Group>
          <LengthIndicator
            x={0}
            y={-30}
            length={scaledPlaneWidth}
            rotation={270}
            label={`${planeWidth.toFixed(0)}`}
            invertLabelPosition
          />
          <LengthIndicator
            x={-30}
            y={0}
            length={scaledPlaneHeight}
            label={`${planeHeight.toFixed(0)}`}
            invertLabelPosition
          />
        </Group> */}

        {/* Ref polygon */}
        {/* {projectedPolygon && (
          <Group>
            {projectedPolygon.result2d.up && projectedPolygon.result2d.right && (
              <Group>
                <Arrow
                  points={[
                    projectedPolygon.result2d.up.edge[0].x,
                    projectedPolygon.result2d.up.edge[0].y,
                    projectedPolygon.result2d.up.edge[1].x,
                    projectedPolygon.result2d.up.edge[1].y,
                  ]}
                  fill="lightgreen"
                  stroke="lightgreen"
                />
                {projectedPolygon.result2d.up.intersectionPoint && (
                  <Arrow
                    points={[
                      projectedPolygon.result2d.up.centroid.x,
                      projectedPolygon.result2d.up.centroid.y,
                      projectedPolygon.result2d.up.intersectionPoint.x,
                      projectedPolygon.result2d.up.intersectionPoint.y,
                    ]}
                    fill="pink"
                    stroke="pink"
                  />
                )}
                {projectedPolygon.result2d.right.intersectionPoint && (
                  <Arrow
                    points={[
                      projectedPolygon.result2d.right.centroid.x,
                      projectedPolygon.result2d.right.centroid.y,
                      projectedPolygon.result2d.right.intersectionPoint.x,
                      projectedPolygon.result2d.right.intersectionPoint.y,
                    ]}
                    fill="orange"
                    stroke="orange"
                  />
                )}
              </Group>
            )}
            {projectedPolygon.vertices.map((vertex, index) => (
              <Group>
                <Line
                  stroke="#000"
                  strokeWidth={1}
                  points={[
                    vertex.x,
                    vertex.y,
                    projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length].x,
                    projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length].y,
                  ]}
                />
              </Group>
            ))}
          </Group>
        )} */}

        {/* diagram boundary */}
        {/* {diagram?.boundary && (
          <Group>
            <Arrow
              points={[diagram.boundary[0].x, diagram.boundary[0].y, diagram.boundary[1].x, diagram.boundary[1].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[1].x, diagram.boundary[1].y, diagram.boundary[2].x, diagram.boundary[2].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[2].x, diagram.boundary[2].y, diagram.boundary[3].x, diagram.boundary[3].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[3].x, diagram.boundary[3].y, diagram.boundary[0].x, diagram.boundary[0].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
          </Group>
        )} */}

        {diagram && (
          <Group>
            {showEdgeMeasurement && (
              <Group>
                {diagram.orientation === 'vertical' ? (
                  <Group>
                    <LengthIndicator
                      x={0}
                      y={-30}
                      length={scaledPlaneHeight}
                      label={(scaledPlaneHeight * (1 / scale)).toFixed(0)}
                      rotation={270}
                      invertLabelPosition
                    />
                    <LengthIndicator
                      x={-30}
                      y={0}
                      length={scaledPlaneWidth}
                      label={(scaledPlaneWidth * (1 / scale)).toFixed(0)}
                      invertLabelPosition
                    />
                  </Group>
                ) : (
                  <Group>
                    <LengthIndicator
                      x={0}
                      y={-30}
                      length={scaledPlaneWidth}
                      label={(scaledPlaneWidth * (1 / scale)).toFixed(0)}
                      rotation={270}
                      invertLabelPosition
                    />
                    <LengthIndicator
                      x={-30}
                      y={0}
                      length={scaledPlaneHeight}
                      label={(scaledPlaneHeight * (1 / scale)).toFixed(0)}
                      invertLabelPosition
                    />
                  </Group>
                )}
              </Group>
            )}

            {diagram.vertices.map((vertex, index) => (
              <Group>
                <Line
                  stroke="green"
                  strokeWidth={1}
                  points={[
                    vertex.point.x,
                    vertex.point.y,
                    diagram.vertices[(index + 1) % diagram.vertices.length].point.x,
                    diagram.vertices[(index + 1) % diagram.vertices.length].point.y,
                  ]}
                />
                {showEdgeMeasurement && (
                  <Text
                    text={vertex.label.text}
                    x={vertex.label.point.x - 25}
                    y={vertex.label.point.y - 13}
                    width={50}
                    height={26}
                    align="center"
                    verticalAlign="middle"
                    stroke="white"
                    strokeWidth={5}
                    fontSize={15}
                    fontStyle="bold"
                    fillAfterStrokeEnabled
                  />
                )}
              </Group>
            ))}
          </Group>
        )}

        {/* Grid lines */}
        {inspectionItem.grid ? (
          <>
            {/* Edge-based grid's edge distance */}
            {inspectionItem.grid.grid_type === GridType.EdgeBased && edgeDistances ? (
              <>
                {/* Horizontal edge distance */}
                <LengthIndicator
                  x={0}
                  y={-20}
                  length={diagram?.orientation === 'vertical' ? edgeDistances.vertical : edgeDistances.horizontal}
                  label={inspectionItem.grid.distances_from_edge!.long_axis.toFixed(0)}
                  anchor={{ type: 'arrow' }}
                  rotation={270}
                  lineDash={[0, 0]}
                  invertLabelPosition
                />
                <LengthIndicator
                  x={
                    diagram?.orientation === 'vertical'
                      ? scaledPlaneHeight - edgeDistances.vertical
                      : scaledPlaneWidth - edgeDistances.horizontal
                  }
                  y={-20}
                  length={diagram?.orientation === 'vertical' ? edgeDistances.vertical : edgeDistances.horizontal}
                  label={inspectionItem.grid.distances_from_edge!.long_axis.toFixed(0)}
                  rotation={270}
                  lineDash={[0, 0]}
                  anchor={{ type: 'arrow' }}
                  invertLabelPosition
                />

                {/* Vertical edge distance */}
                <LengthIndicator
                  x={-20}
                  y={0}
                  length={diagram?.orientation === 'vertical' ? edgeDistances.horizontal : edgeDistances.vertical}
                  label={inspectionItem.grid.distances_from_edge!.short_axis.toFixed(0)}
                  anchor={{ type: 'arrow' }}
                  lineDash={[0, 0]}
                  invertLabelPosition
                />
                <LengthIndicator
                  x={-20}
                  y={
                    diagram?.orientation === 'vertical'
                      ? scaledPlaneWidth - edgeDistances.horizontal
                      : scaledPlaneHeight - edgeDistances.vertical
                  }
                  length={diagram?.orientation === 'vertical' ? edgeDistances.horizontal : edgeDistances.vertical}
                  label={inspectionItem.grid.distances_from_edge!.short_axis.toFixed(0)}
                  anchor={{ type: 'arrow' }}
                  lineDash={[0, 0]}
                  invertLabelPosition
                />
              </>
            ) : null}

            <Group>
              {gridIntervalLines.rows.map((row) => (
                <Line
                  points={[
                    -20,
                    row,
                    (diagram?.orientation === 'vertical' ? scaledPlaneHeight : scaledPlaneWidth) + 20,
                    row,
                  ]}
                  stroke="black"
                  strokeWidth={1}
                  dash={[2, 2]}
                />
              ))}
              {gridIntervalLines.cols.map((col) => (
                <Line
                  points={[
                    col,
                    -20,
                    col,
                    (diagram?.orientation === 'vertical' ? scaledPlaneWidth : scaledPlaneHeight) + 20,
                  ]}
                  stroke="black"
                  strokeWidth={1}
                  dash={[2, 2]}
                />
              ))}
              {gridIntervalLabels.rows.map((label) => (
                <>
                  <LengthIndicator
                    x={label.x + 20}
                    y={label.y}
                    length={label.length}
                    label={label.label}
                    anchorWidth={0}
                    lineDash={[2, 2]}
                  />
                  <LengthIndicator
                    x={-20}
                    y={label.y}
                    length={label.length}
                    label=""
                    anchorWidth={0}
                    lineDash={[2, 2]}
                  />
                </>
              ))}
              {gridIntervalLabels.cols.map((label) => (
                <>
                  <LengthIndicator
                    x={label.x}
                    y={label.y + 20}
                    length={label.length}
                    label={label.label}
                    anchorWidth={0}
                    lineDash={[2, 2]}
                    rotation={270}
                  />
                  <LengthIndicator
                    x={label.x}
                    y={-20}
                    length={label.length}
                    anchorWidth={0}
                    lineDash={[2, 2]}
                    rotation={270}
                  />
                </>
              ))}
            </Group>

            {/* Grid points */}
            {diagram?.grid && (
              <Group>
                {diagram.grid.map((point) => (
                  <Group>
                    {point.valid ? (
                      <Circle
                        x={point.x}
                        y={point.y}
                        width={10}
                        height={10}
                        fill={getPointColor(settings, point, true)}
                      />
                    ) : (
                      <Circle
                        x={point.x}
                        y={point.y}
                        width={10}
                        height={10}
                        fill="white"
                        stroke="black"
                        strokeWidth={1}
                        dash={[2, 2]}
                      />
                    )}
                    <Text
                      text={point.label}
                      y={point.y - 15}
                      x={point.x + 5}
                      align="center"
                      verticalAlign="middle"
                      stroke="white"
                      fill={getPointColor(settings, point)}
                      strokeWidth={5}
                      fontSize={13}
                      fontStyle="bold"
                      fillAfterStrokeEnabled
                    />
                  </Group>
                ))}
              </Group>
            )}
          </>
        ) : null}
      </Layer>
    </Stage>
  )
}

export default PolygonDiagram
