import { FC, useContext, useState } from 'react'

import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { ChromePicker, ColorResult } from 'react-color'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ResetIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_DEFAULT_BACKGROUND, INPUT_GROUP_STYLES } from 'config/styles'

import { resetPcdTransparency, restorePcdTransparency, setPcdTransparency } from '../../store/editor'
import LayerItem from '../components/LayerItem'
import RangeInput from '../components/RangeInput'

const Settings: FC = () => {
  // Context
  const {
    isPointCloudInvisible,
    updatePointCloudVisibility,
    backgroundColor,
    changeBackgroundColor,
    pointSize,
    changePointSize,
  } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const pcdTransparency = useSelector((state: RootState) => state.editor.pcdTransparency)
  const defaultPointSize = useSelector((state: RootState) => state.editor.defaultPointSize)

  // States
  const [isManualEditingPointSize, setIsManualEditingPointSize] = useState(false)
  const [isManualEditingPcdTransparency, setIsManualEditingPcdTransparency] = useState(false)

  return (
    <>
      {/* Point size */}
      <HStack spacing={0} w="100%">
        {!isManualEditingPointSize && (
          <Text flex={1} whiteSpace="nowrap">
            点のサイズ
          </Text>
        )}
        {pointSize !== undefined && (
          <Box {...INPUT_GROUP_STYLES} height={5} minWidth="5.7em">
            <RangeInput
              isSmall
              key="pointSize"
              testId="pointSize"
              min={1}
              max={10}
              step={1}
              precision={1}
              onManualEdit={setIsManualEditingPointSize}
              value={pointSize}
              displayValue={`${pointSize}`}
              updateValue={(newValue) => {
                if (newValue) {
                  changePointSize(newValue)
                }
              }}
            />
          </Box>
        )}
        <IconButton
          aria-label="reset"
          fontSize="sm"
          icon={<ResetIcon />}
          onClick={(e) => {
            e.stopPropagation()
            changePointSize(defaultPointSize)
          }}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>

      {/* PCD transparency */}
      <HStack spacing={0} w="100%">
        {!isManualEditingPcdTransparency && <Text mr={2}>不透明度</Text>}
        <Spacer />
        {pcdTransparency !== undefined && (
          <Box {...INPUT_GROUP_STYLES} height={5} minWidth="5.7em">
            <RangeInput
              isSmall
              key="pcdTransparency"
              testId="pcdTransparency"
              min={0}
              max={100}
              step={10}
              precision={0}
              value={pcdTransparency * 100}
              unit="%"
              onManualEdit={setIsManualEditingPcdTransparency}
              updateValue={(newValue) => {
                dispatch(setPcdTransparency(newValue / 100))
              }}
            />
          </Box>
        )}
        <IconButton
          aria-label="reset"
          fontSize="sm"
          icon={<ResetIcon />}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(resetPcdTransparency())
            setTimeout(() => dispatch(restorePcdTransparency()))
          }}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>

      {/* Background color */}
      <HStack spacing={0} w="100%">
        <Text mr={9}>背景色</Text>
        <Spacer />
        <Popover>
          <PopoverTrigger>
            <Button
              variant="outline"
              maxWidth={6}
              minWidth={6}
              width={6}
              height={5}
              p={0}
              backgroundColor={backgroundColor}
              borderColor="secondary.700"
              _hover={{ bg: backgroundColor }}
              _focus={{ bg: backgroundColor }}
              _active={{ bg: backgroundColor }}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="auto">
              <ChromePicker
                disableAlpha
                color={backgroundColor}
                onChangeComplete={(color: ColorResult) => changeBackgroundColor(color.hex)}
              />
            </PopoverContent>
          </Portal>
        </Popover>
        <IconButton
          aria-label="reset"
          fontSize="sm"
          icon={<ResetIcon />}
          onClick={(e) => {
            e.stopPropagation()
            changeBackgroundColor(EDITOR_DEFAULT_BACKGROUND)
          }}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>

      {/* PCD visibility toggle */}
      <LayerItem invisible={isPointCloudInvisible} label="点群" updateVisibility={updatePointCloudVisibility} />
    </>
  )
}

export default Settings
