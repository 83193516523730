import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'

import { PanelType } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { TransformTypes } from 'interfaces/canvas'
import { EditorPermissionSet } from 'interfaces/validation'

export enum CursorState {
  DEFAULT = 'default',
  GRAB = 'grab',
  GRABBING = 'grabbing',
  POINTER = 'pointer',
  CROSSHAIR = 'crosshair',
}

export interface EditorState {
  /**
   * User is allowed to do modification for editor-related actions
   */
  permissionSet: EditorPermissionSet

  /**
   * Flag to indicate if volume value should be hidden.
   */
  hidePlaneLabel: boolean

  /**
   * Shape IDs of objects that anchor can be placed on.
   * Once this is set, every other settings will be ignored.
   * Even PCD will be ignored.
   */
  anchorPlacementObjects: string[]

  /**
   * Cursor state
   */
  cursor: CursorState

  /**
   * Allowed transform controls.
   */
  allowedTransformControls?: TransformTypes[]

  /**
   * Current transform type.
   */
  currentTransformType?: TransformTypes

  /**
   * Flag to indicate if currently applied transforms should be reset.
   */
  shouldResetTransforms: boolean

  /**
   * Shape IDs of objects that are currently hovered.
   */
  hoveredElementId: string

  /**
   * Shape IDs of objects that are currently selected.
   */
  selectedElementIds: string[]

  /**
   * Shape IDs of objects that are currently being deleted.
   */
  deletingElementIds: string[]

  /**
   * Object IDs of objects that needs to be hidden.
   */
  hiddenElementIds: string[]

  /**
   * Shape IDs of objects that needs to be dimmed.
   */
  dimmedElementIds: string[]

  /**
   * Flag to indicate if some dragging action is happening.
   */
  isDragging: boolean

  /**
   * Flag to indicate if a job is currently running.
   */
  isJobRunning: boolean

  /**
   * The tool where the job is currently running.
   */
  jobRunningTool: string

  /**
   * Flag to indicate if panning is disabled.
   */
  disablePanning: boolean

  /**
   * Flag to indicate if rotation is disabled.
   */
  disableRotation: boolean

  /**
   * PCD transparency, normalized (0 to 1).
   */
  pcdTransparency: number | undefined

  /**
   * Default point size for PCD.
   */
  defaultPointSize: number

  /**
   * Bounding box of the PCD.
   * Consists of 2 points, min and max.
   */
  pcdBoundingBox?: [PointArray, PointArray]

  /**
   * Flag to indicate if element is being deleted.
   */
  isElementDeleting: boolean

  /**
   * List of collapsed panels.
   */
  collapsedPanel: PanelType[]
}

const initialState: EditorState = {
  permissionSet: {
    BROWSE: [],
    MEASURE: [],
    DETECT: [],
    MODIFY: [],
  },
  hidePlaneLabel: false,
  anchorPlacementObjects: [],
  cursor: CursorState.DEFAULT,
  shouldResetTransforms: false,
  hoveredElementId: '',
  selectedElementIds: [],
  deletingElementIds: [],
  hiddenElementIds: [],
  dimmedElementIds: [],
  collapsedPanel: [PanelType.Settings],
  isDragging: false,
  isJobRunning: false,
  jobRunningTool: '',
  disablePanning: false,
  disableRotation: false,
  pcdTransparency: 1,
  isElementDeleting: false,
  defaultPointSize: 1,
}

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    /**
     * Canvas flags
     */
    setPermissionSet: (state, action: PayloadAction<EditorPermissionSet>) => {
      state.permissionSet = action.payload
    },
    setHidePlaneLabel: (state, action: PayloadAction<boolean>) => {
      state.hidePlaneLabel = action.payload
    },
    setAnchorPlacementObjects: (state, action: PayloadAction<string[]>) => {
      state.anchorPlacementObjects = action.payload
    },
    setCursor: (state, action: PayloadAction<CursorState>) => {
      state.cursor = action.payload
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload
    },
    setDisablePanning: (state, action: PayloadAction<boolean>) => {
      state.disablePanning = action.payload
    },
    setDisableRotation: (state, action: PayloadAction<boolean>) => {
      state.disableRotation = action.payload
    },
    setPcdTransparency: (state, action: PayloadAction<number>) => {
      state.pcdTransparency = action.payload
    },
    resetPcdTransparency: (state) => {
      state.pcdTransparency = undefined
    },
    restorePcdTransparency: (state) => {
      state.pcdTransparency = initialState.pcdTransparency
    },
    setDefaultPointSize: (state, action: PayloadAction<number>) => {
      state.defaultPointSize = action.payload
    },
    setPcdBoundingBox: (state, action: PayloadAction<[PointArray, PointArray]>) => {
      state.pcdBoundingBox = action.payload
    },
    setElementDeleting: (state, action: PayloadAction<boolean>) => {
      state.isElementDeleting = action.payload
    },
    setJobRunning: (state, { payload: { running, tool } }: PayloadAction<{ running: boolean; tool: string }>) => {
      state.isJobRunning = running
      state.jobRunningTool = running ? tool : ''
    },

    /**
     * Element-related actions
     */
    setHoveredElementId: (state, action: PayloadAction<string>) => {
      state.hoveredElementId = action.payload
    },
    setSelectedElementIds: (state, action: PayloadAction<string[]>) => {
      state.selectedElementIds = action.payload
    },
    toggleSelectedElementIds: (
      state,
      { payload: { ids, selected } }: PayloadAction<{ ids: string[]; selected: boolean }>,
    ) => {
      state.selectedElementIds = selected
        ? uniq([...state.selectedElementIds, ...ids])
        : state.selectedElementIds.filter((id) => !ids.includes(id))
    },
    setDeletingElementIds: (state, action: PayloadAction<string[]>) => {
      state.deletingElementIds = action.payload
    },
    toggleHiddenElementIds: (
      state,
      { payload: { ids, invisible } }: PayloadAction<{ ids: string[]; invisible: boolean }>,
    ) => {
      state.hiddenElementIds = invisible
        ? uniq([...state.hiddenElementIds, ...ids])
        : state.hiddenElementIds.filter((id) => !ids.includes(id))
    },
    addDimmedElementId: (state, action: PayloadAction<string>) => {
      state.dimmedElementIds.push(action.payload)
    },
    removeDimmedElementId: (state, action: PayloadAction<string>) => {
      state.dimmedElementIds = state.dimmedElementIds.filter((id) => id !== action.payload)
    },

    /**
     * Controls
     */
    setAllowedTransformControls: (state, action: PayloadAction<TransformTypes[]>) => {
      state.allowedTransformControls = action.payload
      state.currentTransformType = action.payload[0] // eslint-disable-line
    },
    setCurrentTransformType: (state, action: PayloadAction<TransformTypes>) => {
      state.currentTransformType = action.payload
    },
    setShouldResetTransforms: (state, action: PayloadAction<boolean>) => {
      state.shouldResetTransforms = action.payload
    },
    resetTransformControls: (state) => {
      state.allowedTransformControls = undefined
      state.currentTransformType = undefined
      state.shouldResetTransforms = false
    },

    /**
     * Panel collapse state
     */
    setCollapsedPanel: (state, { payload }: PayloadAction<PanelType[]>) => {
      state.collapsedPanel = payload
    },
    openPanel: (state, { payload }: PayloadAction<PanelType>) => {
      if (state.collapsedPanel.includes(payload)) {
        const index = state.collapsedPanel.indexOf(payload)
        state.collapsedPanel.splice(index, 1)
      }
    },
    toggleCollapsedPanel: (state, { payload }: PayloadAction<PanelType>) => {
      const index = state.collapsedPanel.indexOf(payload)
      if (index === -1) {
        state.collapsedPanel.push(payload)
      } else {
        state.collapsedPanel.splice(index, 1)
      }
    },
    forceOpenToolPanel: (state) => {
      if (state.collapsedPanel.includes(PanelType.Tool)) {
        state.collapsedPanel = state.collapsedPanel.filter((type) => type !== PanelType.Tool)
      }
    },

    /**
     * Resets
     */
    reset: () => initialState,
  },
})

export const {
  setPermissionSet,
  setHidePlaneLabel,
  setAnchorPlacementObjects,
  setCursor,
  setIsDragging,
  setDisablePanning,
  setDisableRotation,
  setPcdTransparency,
  resetPcdTransparency,
  restorePcdTransparency,
  setDefaultPointSize,
  setPcdBoundingBox,
  setJobRunning,

  // panel collapse
  setCollapsedPanel,
  openPanel,
  toggleCollapsedPanel,
  forceOpenToolPanel,

  // transform controls
  setAllowedTransformControls,
  setCurrentTransformType,
  setShouldResetTransforms,
  resetTransformControls,

  // shapes-related
  setHoveredElementId,
  setSelectedElementIds,
  toggleSelectedElementIds,

  setDeletingElementIds,

  toggleHiddenElementIds,

  setElementDeleting,

  addDimmedElementId,
  removeDimmedElementId,

  reset,
} = editorSlice.actions

export default editorSlice.reducer
